import {
    CommonApiGetParams,
    ApiResponse,
    IAgentsApiGetById,
    IAgentsApiPostPut,
    IAgentsApiGetList
} from 'src/types';
import { apiClient } from '../client';
import {
    withDefaultHandlers,
    withDefaultHandlersPagination,
    withDefaultHandlersWithoutResult
} from '../helpers/withDefaultHandlers';

const BASE_PATH = '/partner/partner-api/agent-info';

export const apiGetAgents = ({ params }: { params?: CommonApiGetParams }) =>
    withDefaultHandlersPagination(
        apiClient.get<ApiResponse<IAgentsApiGetList[]>>(BASE_PATH, params)
    );

export const apiGetAgentById = (id: string) =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<IAgentsApiGetById>>(`${BASE_PATH}/${id}`)
    );

export const apiPostAgents = (agents: IAgentsApiPostPut) =>
    withDefaultHandlersWithoutResult<{ id: string }>(
        apiClient.post<ApiResponse<{ id: string }>>(BASE_PATH, agents)
    );

export const apiPutAgents = (id: string, agent: IAgentsApiPostPut) =>
    withDefaultHandlersWithoutResult<IAgentsApiGetById>(
        apiClient.put<ApiResponse<IAgentsApiGetById>>(BASE_PATH, agent, {
            params: { id }
        })
    );

export const apiDeleteAgents = (id: string) =>
    withDefaultHandlers(
        apiClient.delete<ApiResponse<IAgentsApiGetList[]>>(BASE_PATH, {
            params: { id }
        })
    );
