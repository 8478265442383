import { Pagination, IAgentsApiGetById } from 'src/types';
import {
    SET_LOADING,
    SET_PAGINATION,
    SET_SUB_AFFILIATE,
    SET_SUB_AFFILIATES,
    AgentsActionTypes
} from '../actions';

export type TAgentInRedux = Record<string, IAgentsApiGetById>;

export interface AgentsState {
    agentsItems: TAgentInRedux;
    loading: boolean;
    pagination: Pagination | undefined;
    currentAgent: IAgentsApiGetById | undefined;
}
const initialState: AgentsState = {
    agentsItems: {},
    loading: false,
    pagination: undefined,
    currentAgent: undefined
};

export const agents = (
    state: AgentsState = initialState,
    action: AgentsActionTypes
): AgentsState => {
    switch (action.type) {
        case SET_SUB_AFFILIATES:
            return {
                ...state,
                agentsItems:
                    action.payload?.reduce<TAgentInRedux>(
                        (prev, curr) => ({
                            ...prev,
                            [curr.id as string]: curr
                        }),
                        {}
                    ) || {},
                loading: false
            };
        case SET_SUB_AFFILIATE:
            return {
                ...state,
                currentAgent: action.payload
            };
        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
