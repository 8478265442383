import { yupResolver } from '@hookform/resolvers/yup';
import { boolean, mixed, object, string } from 'yup';
import { integerAndFloatValidation } from 'src/utils/yupValidations';

export default function serviceValidationYupResolver() {
    const schema = object({
        name: string().required().max(200),
        // category: mixed().required(),
        msrp: integerAndFloatValidation
            .required('Required')
            .moreThan(0, 'Must be greater than 0'),
        cost: integerAndFloatValidation.required('Required'),

        status: string().required('Is Required'),
        billingModel: string().required('Is Required'),
        // restockingFee: integerAndFloatValidation.required('Required'),

        // Paramteres
        taxable: boolean().optional(),
        licenseKeyRestricted: boolean().optional(),
        subscription: boolean().optional(),

        // Delivery
        shippable: boolean().optional(),
        digitalDownload: boolean().optional(),
        signatureConfirmation: boolean().optional(),
        deliveryConfirmation: boolean().optional(),

        // Other
        variants: boolean().optional(),
        associatedId: string().nullable(),
        files: mixed().optional()
    });

    return yupResolver(schema);
}
