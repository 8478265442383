import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { ThemeProvider } from '@mui/material/styles';
import { Routes } from 'src/pages/Routes/Routes';
import { Provider } from 'react-redux';
import { store } from './store';

import '@fontsource/roboto/500.css';
import '@fontsource/roboto/400.css';
import 'react-phone-input-2/lib/material.css';

import CssBaseline from '@mui/material/CssBaseline';
import { Theme } from '@mui/material';
import { createPartnerTheme } from 'src/utils/themes';
import { getPartnerKey } from 'src/utils/getGlobalPartnerKey';

const queryClient = new QueryClient();

export const App = () => {
    const partnerKey = getPartnerKey();

    const theme: Theme = React.useMemo(
        () => createPartnerTheme(partnerKey),
        [partnerKey]
    );

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Provider store={store}>
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </Provider>
            </ThemeProvider>
        </QueryClientProvider>
    );
};
