import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { Control } from 'react-hook-form';
import { FilterRowType, FormFilterRow } from 'src/components/Form';
import { CategoriesFilterForm } from 'src/types';

interface Props {
    control: Control<CategoriesFilterForm, object>;
}

const filters: FilterRowType<CategoriesFilterForm>[] = [
    {
        title: 'Name',
        placeholder: 'type category name',
        name: 'name'
    },
    {
        title: 'Description',
        placeholder: 'type category description',
        name: 'description'
    }
];

export const FilterForm: FC<Props> = ({ control }) => (
    <form>
        <Grid container sx={{ minWidth: 600, width: '50vw', pl: 1, pr: 1 }}>
            {filters.map(filter => (
                <FormFilterRow
                    key={filter.name}
                    control={control}
                    {...filter}
                />
            ))}
        </Grid>
    </form>
);
