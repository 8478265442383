import {
    ApiResponse,
    PartnerCreateEditDto,
    PartnerGetByIdDto,
    PartnersItemDto
} from 'src/types';
import { apiClient } from '../client';
import { withDefaultHandlers } from '../helpers/withDefaultHandlers';

const BASE_PATH = '/partner/partner-api/partner-info';

export const apiGetPartners = () =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<PartnersItemDto[]>>(BASE_PATH)
    );

export const apiGetPartner = (id: string) =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<PartnerGetByIdDto>>(`${BASE_PATH}/${id}`)
    );

export const apiPostPartner = (partner: PartnerCreateEditDto) =>
    withDefaultHandlers(
        apiClient.post<ApiResponse<{ id: string }>>(BASE_PATH, partner)
    );

export const apiPutPartner = (id: string, partner: PartnerCreateEditDto) =>
    withDefaultHandlers(
        apiClient.put<ApiResponse<PartnerCreateEditDto>>(BASE_PATH, partner, {
            params: { id }
        })
    );
