import { FC, useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { FileDto, ServiceEditFormType } from 'src/types';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { FileInput } from 'src/components/Form/FileInput';
import ImagePreview from 'src/components/Cards/ImagesFormCard/ImagePreview';
import Loader from 'src/components/Loader/Loader';
import CancelIcon from '@mui/icons-material/Cancel';
import FileByLinkInput from 'src/components/Cards/ImagesFormCard/FileByLinkInput';
import CardWrapper from 'src/components/Card/CardWrapper';

interface Props {
    title: string;
    description?: string;
    control: Control<ServiceEditFormType, object>;
    setValue: UseFormSetValue<ServiceEditFormType>;
}

export const ImagesFormCard: FC<Props> = ({
    title,
    description,
    control,
    setValue
}) => {
    const [isFileLoading, setIsFileLoading] = useState<boolean>(false);

    const files = useWatch({ control, name: 'files' }) || [];

    const onStartUploadFile = () => {
        setIsFileLoading(true);
    };

    const onSuccessUploadFile = (file: FileDto) => {
        setValue('files', [...files, file], { shouldDirty: true });
        setIsFileLoading(false);
    };

    const onErrorUploadFile = () => {
        setIsFileLoading(false);
    };

    const handleDeleteImage = (fileId: FileDto['id']) => {
        setValue(
            'files',
            files.filter(({ id }) => id !== fileId),
            { shouldDirty: true }
        );
    };

    return (
        <CardWrapper title={title} description={description}>
            {/* Add images block */}
            <Box py={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Loader loading={isFileLoading}>
                    <FileInput
                        title="SELECT AND UPLOAD"
                        onStartUploadFile={onStartUploadFile}
                        onSuccessUploadFile={onSuccessUploadFile}
                        onErrorUploadFile={onErrorUploadFile}
                        inputAccept="image/*"
                        mimeTypes={['image/jpeg', 'image/png']}
                        withIcon={false}
                    />
                </Loader>
                <span style={{ marginLeft: '20px', marginRight: '20px' }}>
                    OR PASTE URL
                </span>
                <FileByLinkInput
                    onStartUploadFile={onStartUploadFile}
                    onSuccessUploadFile={onSuccessUploadFile}
                    onErrorUploadFile={onErrorUploadFile}
                />
            </Box>

            {/* Images */}
            <Box p={4} sx={{ display: files.length ? 'block' : 'none' }}>
                {files.map((file: FileDto) => (
                    <ImagePreview
                        key={file.id}
                        imageId={file.id}
                        onDelete={handleDeleteImage}
                    />
                ))}
            </Box>
        </CardWrapper>
    );
};
