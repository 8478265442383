export default function downloadFileByLink(link: string, name: string) {
    return fetch(link)
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = name;
            link.click();
        })
        .catch(console.error);
}
