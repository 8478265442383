import { Field } from 'src/components/Form';
import {
    tableFields as commonTableFields,
    generateModalInputs as commonModalInputs
} from 'src/components/Services/addServiceCommonFields';
import {
    BillingModelDto,
    ColumnType,
    FormFieldType,
    ServiceDto
} from 'src/types';
import { fCurrency } from 'src/utils/formatNumber';

export const tableFields: ColumnType<ServiceDto>[] = [
    ...commonTableFields,
    {
        dataIndex: 'agentCommission',
        title: 'Agent Commission',
        render: value => fCurrency(value)
    }
];

// MODAL FIELDS
export const generateModalInputs = (
    services: Record<string, ServiceDto>,
    billingModels: Record<string, BillingModelDto>
): Field<ServiceDto>[] => [
    ...commonModalInputs(services, billingModels),
    {
        name: 'agentCommission',
        label: 'Agent Commission',
        type: FormFieldType.Price,
        required: true
    }
];
