import {
    getAuthTokenLS,
    getOrganizationAuthTokenLS
} from 'src/utils/localStorage';

export default (config: any) => {
    const systemToken = getAuthTokenLS();
    const organizationToken = getOrganizationAuthTokenLS();

    let requestAuthToken;

    if (systemToken && !organizationToken) {
        requestAuthToken = systemToken;
    }
    if (systemToken && organizationToken) {
        requestAuthToken = organizationToken;
    }

    if (!config.headers.Authorization && requestAuthToken) {
        config.headers.Authorization = `Bearer ${requestAuthToken}`;
    }
};
