import { AxiosResponse } from 'axios';
import {
    ApiErrorResponse,
    ApiResponse,
    Pagination
} from 'src/types/apiResponse';

const errorHandler = (error: any) => {
    const response = error.response?.data as ApiErrorResponse;

    if (response?.validationErrors) {
        //TODO make some processing of validation errors?
        throw new Error(
            response.validationErrors
                .map(e => `${e.name}:${e.reason}`)
                .join(';')
        );
        //TODO: unify responses of backend services to error handle
    } else if (response?.title) {
        throw new Error(response.title);
    } else if (response?.errors) {
        throw new Error(response.errors.message);
    } else if (response?.detail) {
        throw response;
    }

    throw error.response?.data?.message || error;
};

export const withDefaultHandlers = <TResult>(
    request: Promise<AxiosResponse<ApiResponse<TResult>>>
): Promise<TResult> =>
    request.then(response => response.data?.result).catch(errorHandler);

//TODO: Push backend to set all endpoints to use ApiResponse!
export const withUnwrappedHandlers = <TResult>(
    request: Promise<AxiosResponse<TResult>>
): Promise<TResult> =>
    request.then(response => response.data).catch(errorHandler);

export const withDefaultHandlersWithoutResult = <T>(
    request: Promise<any>
): Promise<T> => request.then(response => response.data).catch(errorHandler);

export const withDefaultHandlersPagination = <TResult>(
    request: Promise<AxiosResponse<ApiResponse<TResult>>>
): Promise<{
    result: TResult;
    pagination: Pagination | undefined;
}> =>
    request
        .then(response => ({
            result: response.data?.result || ([] as unknown as TResult),
            pagination: response.data.pagination
        }))
        .catch(errorHandler);
