import { useHistory, useParams } from 'react-router';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Stack, Button, Typography } from '@mui/material';

import { BillingModelEditFormFields } from 'src/components/BillingModels/Forms/BillingModelEditFormFields';
import { useBillingModels, useBillingModel } from 'src/api/hooks';
import { BillByDaySelectedWeek, BillingModelEditFormType } from 'src/types';
import { routes } from 'src/constants';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import billModelValidationYupResolver from 'src/pages/BillingModels/billModelFormValidation';

const INITIAL_VALUES = {
    name: '',
    description: ''
};

export const BillingModelEdit = () => {
    const { id } = useParams<{ id?: string }>();
    const isEdit = !!id;

    const { createBillingModel, updateBillingModel } = useBillingModels();
    const [buttonLoading, setButtonLoading] = useState(false);
    const {
        fetchBillingModel,
        billingModel,
        loading: billingModelLoading
    } = useBillingModel();
    const history = useHistory();

    useEffect(() => {
        if (id) {
            void fetchBillingModel(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const methods = useForm<BillingModelEditFormType>({
        defaultValues: INITIAL_VALUES,
        resolver: billModelValidationYupResolver()
    });
    const { handleSubmit, control, reset, watch, getValues } = methods;
    const watchType = watch('type');
    const watchBillByDaySelectedWeek = watch('billByDaySelectedWeek');

    useEffect(() => {
        const name = getValues('name');
        const description = getValues('description');
        const type = getValues('type');

        if (!(isEdit && billingModel && billingModel.type === type)) {
            reset(
                watchBillByDaySelectedWeek
                    ? {
                          name,
                          description,
                          type,
                          billByDaySelectedWeek: watchBillByDaySelectedWeek
                      }
                    : {
                          name,
                          description,
                          type
                      }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchType, watchBillByDaySelectedWeek]);

    useEffect(() => {
        if (isEdit && billingModel && billingModel.type === watchType) {
            reset({
                type: billingModel.type,
                name: billingModel.name,
                description: billingModel.description,
                billByCycleDays: billingModel.billByCycleDays,
                billByDateDayOfMonth: billingModel.billByDateDayOfMonth,
                billByDaySelectedWeek: billingModel.billByDaySelectedWeek,
                billByScheduleBufferDays: billingModel.billByScheduleBufferDays,
                billByRelativeDateFrequency:
                    billingModel.billByRelativeDateFrequency,
                billByDaySelectedDay: billingModel.billByDaySelectedDay,
                every: billingModel.every,
                orderplacedbefore: billingModel.orderplacedbefore,
                billingDates: billingModel.billingDates
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchType]);

    useEffect(() => {
        if (billingModel && id && billingModel.id === id) {
            reset({
                type: billingModel.type,
                name: billingModel.name,
                description: billingModel.description,
                billByCycleDays: billingModel.billByCycleDays,
                billByDateDayOfMonth: billingModel.billByDateDayOfMonth,
                billByDaySelectedWeek: billingModel.billByDaySelectedWeek,
                billByScheduleBufferDays: billingModel.billByScheduleBufferDays,
                billByRelativeDateFrequency:
                    billingModel.billByRelativeDateFrequency,
                billByDaySelectedDay: billingModel.billByDaySelectedDay,
                every: billingModel.every,
                orderplacedbefore: billingModel.orderplacedbefore,
                billingDates: billingModel.billingDates
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billingModel]);

    const onSubmit: SubmitHandler<BillingModelEditFormType> = data => {
        let body = data;

        // We got it if we chose type and then delete it
        if (body.type === null) {
            delete body.type;
        }

        setButtonLoading(true);

        if (isEdit && id) {
            updateBillingModel(id, body, () =>
                history.push(routes.billingModelsView)
            ).finally(() => setButtonLoading(false));
        } else {
            createBillingModel(body, () =>
                history.push(routes.billingModelsView)
            ).finally(() => setButtonLoading(false));
        }
    };

    const handleCancel = () => history.push(routes.billingModelsView);

    return (
        <Stack spacing={2} flexGrow={1} mb={3}>
            <Typography variant="h5">
                {isEdit ? 'Edit Billing Model' : 'Add Billing Model'}
            </Typography>
            <FormProvider {...methods}>
                <form>
                    <Stack spacing={2} alignItems="end">
                        <BillingModelEditFormFields
                            control={control}
                            loading={billingModelLoading}
                            type={watchType}
                            billByDayCustom={
                                watchBillByDaySelectedWeek ===
                                BillByDaySelectedWeek.Custom
                            }
                        />
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={handleCancel}
                                variant="outlined"
                                disabled={buttonLoading}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                loading={buttonLoading}
                            >
                                Save
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </form>
            </FormProvider>
        </Stack>
    );
};
