import { useHistory } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Stack, Typography, Button } from '@mui/material';

import { ServiceEditFormFields } from 'src/components/Services/Forms/ServiceEditFormFields';
import { useServices, useShortCategories, useService } from 'src/api/hooks';
import { ServiceEditFormType } from 'src/types';
import { routes } from 'src/constants';
import { getServiceFromForm } from 'src/utils/services';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { DevTool } from '@hookform/devtools';
import serviceValidationYupResolver from 'src/pages/Services/serviceFormValidation';
import useEditFormId from 'src/api/hooks/useEditFormId';

const INITIAL_VALUES: ServiceEditFormType = {
    name: '',
    msrp: 0,
    cost: 0,
    taxable: false,
    subscription: false,
    licenseKeyRestricted: false,
    shippable: false,
    digitalDownload: false,
    signatureConfirmation: false,
    deliveryConfirmation: false,
    files: []
};

export const ServiceEdit = () => {
    const [isEdit, id] = useEditFormId();

    const { shortCategories, fetchShortCategories } = useShortCategories();
    const { createService: createProduct, updateService: updateProduct } =
        useServices();
    const [buttonLoading, setButtonLoading] = useState(false);
    const { fetchService, service, loading: serviceIsLoading } = useService();
    const history = useHistory();

    useEffect(() => {
        fetchShortCategories();
        if (id) {
            fetchService(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const { handleSubmit, control, reset, setValue } =
        useForm<ServiceEditFormType>({
            defaultValues: isEdit ? { id, ...INITIAL_VALUES } : INITIAL_VALUES,
            resolver: serviceValidationYupResolver()
        });

    useEffect(() => {
        if (service && id && service.id === id) {
            reset({
                id,
                name: service.name,
                msrp: service.msrp,
                cost: service.cost,
                taxable: service.taxable,
                shippable: service.shippable,
                digitalDownload: service.digitalDownload,
                signatureConfirmation: service.signatureConfirmation,
                deliveryConfirmation: service.deliveryConfirmation,
                variants: service.variants,
                licenseKeyRestricted: service.licenseKeyRestricted,
                subscription: service.subscription,
                associatedId: service.associatedId,
                files: service.files
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service]);

    const onSubmit: SubmitHandler<ServiceEditFormType> = data => {
        const body = getServiceFromForm(data);
        setButtonLoading(true);

        if (isEdit && id) {
            updateProduct(id, body, () =>
                history.push(routes.services)
            ).finally(() => setButtonLoading(false));
        } else {
            createProduct(body, () => history.push(routes.services)).finally(
                () => setButtonLoading(false)
            );
        }
    };

    const handleCancel = () => history.push(routes.services);

    const inputCategories = Object.values(shortCategories).map(
        ({ id, name }) => ({
            label: name,
            value: id
        })
    );

    return (
        <Stack spacing={2} flexGrow={1} mb={3}>
            <Typography variant="h5">
                {isEdit ? 'Edit service' : 'Create service'}
            </Typography>
            <form>
                <Stack spacing={2} alignItems="end">
                    <ServiceEditFormFields
                        control={control}
                        categories={inputCategories}
                        setValue={setValue}
                        isEdit={isEdit}
                        loading={serviceIsLoading}
                    />
                    <Stack direction="row" spacing={2}>
                        <Button
                            onClick={handleCancel}
                            variant="outlined"
                            disabled={buttonLoading}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            onClick={handleSubmit(onSubmit)}
                            variant="contained"
                            loading={buttonLoading}
                        >
                            {isEdit ? 'Save changes' : 'Continue'}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </form>
            {/* Dev tools for react-hook-from */}
            {/*<DevTool control={control} />*/}
        </Stack>
    );
};
