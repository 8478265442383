import { FC } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Control, Controller, Path } from 'react-hook-form';

interface Props<T = any> {
    label?: string;
    name: Path<T>;
    control: Control<T, object>;
}
export const FormSingleCheckbox: FC<Props> = ({ control, name, label }) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ref }, fieldState }) =>
            label ? (
                <FormControlLabel
                    control={<Checkbox onChange={onChange} />}
                    checked={value ?? false}
                    label={label}
                />
            ) : (
                <Checkbox checked={value ?? false} onChange={onChange} />
            )
        }
    />
);
