import React, { useMemo, useState } from 'react';
import { Card, CardContent, Stack, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { Account, AccountType } from 'src/types';
import { Spin } from 'src/components/Spin/Spin';
import { AccountsAccordion } from 'src/components/Accounts/AccountsAccordion';

import {
    queryAccounts,
    queryLogosForPartnerAccounts
} from 'src/pages/Accounts/queries';
import AccountsDownloadingButtons from 'src/components/Accounts/AccountsDownloadingButtons';
import ButtonsCreateAccounts from 'src/components/Accounts/ButtonsCreateAccounts';
import { accountTypesConfig } from 'src/pages/Accounts/accountTypesConfig';

const byAccountType = (type: AccountType) => (acc: Account) =>
    type === acc.accountType;

const byLogoFileId = (fileId: string) => Boolean(fileId);

const getPartnerWithLogo = (
    acc: Account,
    partnersAccountsWithLogos: Account[]
) => {
    const partnerAccountWithLogo = partnersAccountsWithLogos?.find(
        x => x.id === acc.id
    );

    return {
        ...acc,
        logo: partnerAccountWithLogo?.logo || null
    };
};

export const Accounts = () => {
    const [expanded, setExpanded] = useState<AccountType | null>(null);
    const [searchString, setSearchingString] = useState('');

    const { data: allAccounts, isFetching: allAccountsAreFetching } =
        queryAccounts(searchString);

    const {
        data: partnerAccountsWithLogos,
        isFetching: partnerAccountsWithLogosAreFetching
    } = queryLogosForPartnerAccounts(
        (allAccounts as Account[]).filter(
            x =>
                byAccountType(AccountType.Partner)(x) &&
                byLogoFileId(x.logo?.fileId)
        )
    );

    const dataToDisplay = useMemo(
        () =>
            (allAccounts as Account[]).map(account =>
                account.accountType === AccountType.Partner &&
                !partnerAccountsWithLogosAreFetching
                    ? getPartnerWithLogo(
                          account,
                          partnerAccountsWithLogos || []
                      )
                    : account
            ),
        [
            allAccounts,
            partnerAccountsWithLogos,
            partnerAccountsWithLogosAreFetching
        ]
    );

    return (
        <Stack spacing={2}>
            <Card>
                <CardContent style={{ paddingTop: 18, paddingBottom: 15 }}>
                    <Stack spacing={1}>
                        <Typography variant="h6">Search Accounts</Typography>
                        <Stack direction="row" spacing={1}>
                            <TextField
                                variant="standard"
                                size="small"
                                label="Search Accounts"
                                sx={{ width: '100%' }}
                                onChange={debounce(
                                    e => setSearchingString(e.target.value),
                                    1000
                                )}
                            />
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
            <Card>
                <CardContent style={{ paddingTop: 18, paddingBottom: 15 }}>
                    <Spin loading={allAccountsAreFetching}>
                        <Stack spacing={1}>
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography variant="h6">Accounts</Typography>
                                <ButtonsCreateAccounts />
                            </Stack>
                            <Stack direction="column" spacing={1}>
                                {accountTypesConfig.map(
                                    ({ type, renderAction }, i) => (
                                        <AccountsAccordion
                                            key={i}
                                            data={dataToDisplay as Account[]}
                                            type={type}
                                            index={i}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            renderHeaderActions={renderAction}
                                        />
                                    )
                                )}
                            </Stack>
                        </Stack>
                    </Spin>
                </CardContent>
            </Card>
            <AccountsDownloadingButtons />
        </Stack>
    );
};
