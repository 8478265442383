import { useHistory, useParams } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography, Button } from '@mui/material';

import { useRole } from 'src/api/hooks';
import { RolePostDto } from 'src/types';
import { RoleEditFormFields } from 'src/components/Roles/RoleEditFormFields';
import { routes } from 'src/constants';
import { Spin } from 'src/components/Spin/Spin';

const INITIAL_VALUES = {
    name: '',
    description: ''
};

export const EditRole = () => {
    const { id } = useParams<{ id?: string }>();

    const isEdit = !!id;

    const [buttonLoading, setButtonLoading] = useState(false);
    const { fetchRole, role, loading, updateRole, createRole } = useRole();
    const history = useHistory();

    useEffect(() => {
        if (id) {
            fetchRole(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const { handleSubmit, control, reset } = useForm<RolePostDto>({
        defaultValues: isEdit ? { id, ...INITIAL_VALUES } : INITIAL_VALUES
    });

    useEffect(() => {
        if (role && id && role.id === id) {
            reset(role);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);

    const onSubmit: SubmitHandler<RolePostDto> = data => {
        const { id, ...body } = data;
        setButtonLoading(true);

        if (isEdit && id) {
            updateRole(id, body, () =>
                history.push(routes.permissionsRoles)
            ).finally(() => setButtonLoading(false));
        } else {
            createRole(body, () =>
                history.push(routes.permissionsRoles)
            ).finally(() => setButtonLoading(false));
        }
    };

    const handleCancel = () => history.push(routes.permissionsRoles);

    return (
        <Stack spacing={2} flexGrow={1} mb={3}>
            <Typography variant="h5">
                {isEdit ? 'Edit Role' : 'Add Role'}
            </Typography>
            <Spin loading={loading}>
                <RoleEditFormFields control={control} />
            </Spin>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button
                    onClick={handleCancel}
                    variant="outlined"
                    disabled={buttonLoading}
                >
                    Cancel
                </Button>
                <LoadingButton
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    loading={buttonLoading || loading}
                >
                    {isEdit ? 'Save changes' : 'Continue'}
                </LoadingButton>
            </Stack>
        </Stack>
    );
};
