import { FC } from 'react';
import { Control } from 'react-hook-form';
import { CategoryEditFormType, CategoryPost } from 'src/types';
import { Field, FormCard } from '../../Form';
import { Spin } from 'src/components/Spin/Spin';

interface Props {
    control: Control<CategoryEditFormType, object>;
    isEdit?: boolean;
    loading?: boolean;
}

const fields: Field<CategoryPost>[] = [
    {
        name: 'name',
        label: 'Name',
        required: true,
        inputProps: {
            maxLength: 200
        }
    },
    {
        name: 'description',
        label: 'Description',
        required: true
    }
];

export const CategoryEditFormFields: FC<Props> = ({ control, loading }) => {
    const formFields = fields;
    return (
        <Spin loading={loading}>
            <FormCard
                control={control}
                fields={formFields}
                cardStyle={{ paddingBottom: 80 }}
            />
        </Spin>
    );
};
