import React, { useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

import LoadingButton from '@mui/lab/LoadingButton';

import { ApiErrorResponse } from 'src/types';
import { showMessageAction } from 'src/store/actions';
import { apiExportAccountsInExcel } from 'src/api/endpoints/accounts';
import { ExportAccountType } from 'src/types/partners/common';
import ExportAccountTypesMenu from 'src/components/Accounts/ExportAccountTypesMenu';

const AccountsDownloadingButtons = () => {
    const dispatch = useDispatch();
    const excelButton = useRef<HTMLButtonElement | null>(null);
    const csvButton = useRef<HTMLButtonElement | null>(null);

    const [openExcelMenu, setOpenExcelMenu] = useState(false);
    const [openCsvMenu, setOpenCsvMenu] = useState(false);

    const [isCsvBtnLoading, setIsCsvBtnLoading] = useState(false);
    const [isExcelBtnLoading, setIsExcelBtnLoading] = useState(false);

    const toggleCsvBtnLoading = () =>
        setIsCsvBtnLoading(prevState => !prevState);
    const toggleExcelBtnLoading = () =>
        setIsExcelBtnLoading(prevState => !prevState);

    const toggleExcelMenuOpen = () => setOpenExcelMenu(prevState => !prevState);
    const toggleCsvMenuOpen = () => setOpenCsvMenu(prevState => !prevState);

    const createHandlerDownloadCsvOrExcel =
        (type: 'csv' | 'xlsx') => (accountType: ExportAccountType) => {
            let toggle = toggleExcelBtnLoading;
            if (type === 'csv') {
                toggle = toggleCsvBtnLoading;
            }

            toggle();
            apiExportAccountsInExcel(type, accountType)
                .then(fileDataString => {
                    if (fileDataString) {
                        const blob = new Blob([fileDataString]);
                        const a = window.document.createElement('a');
                        a.href = window.URL.createObjectURL(blob);
                        a.download = `Accounts-${dayjs().format(
                            'MM/DD/YYYY'
                        )}.${type}`;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                })
                .catch(handleCatchFileDownloadingError)
                .finally(toggle);
        };

    const handleCatchFileDownloadingError = (err: ApiErrorResponse) => {
        dispatch(
            showMessageAction({
                message: err.message,
                type: 'error'
            })
        );
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <LoadingButton
                ref={excelButton}
                color="primary"
                variant="contained"
                onClick={toggleExcelMenuOpen}
                loading={isExcelBtnLoading}
            >
                Download excel doc
            </LoadingButton>
            <ExportAccountTypesMenu
                anchorEl={excelButton.current}
                onClick={createHandlerDownloadCsvOrExcel('xlsx')}
                open={openExcelMenu}
                onClose={toggleExcelMenuOpen}
            />
            <LoadingButton
                ref={csvButton}
                variant="contained"
                style={{
                    marginLeft: 30
                }}
                onClick={toggleCsvMenuOpen}
                loading={isCsvBtnLoading}
            >
                Download CSV
            </LoadingButton>
            <ExportAccountTypesMenu
                anchorEl={csvButton.current}
                onClick={createHandlerDownloadCsvOrExcel('csv')}
                open={openCsvMenu}
                onClose={toggleCsvMenuOpen}
            />
        </div>
    );
};

export default AccountsDownloadingButtons;
