import { Paper, TableContainer } from '@mui/material';
import { useMemo } from 'react';
import { useBreakpoints } from 'src/hooks/useBreakpoints';
import { ColumnType } from '../../types/table';
import { Spin } from '../Spin/Spin';
import { DesktopTable } from './DesktopTable';
import { MobileTable } from './MobileTable';

interface TableProps<T> {
    columns: ColumnType<T>[];
    data: T[];
    mobile?: {
        renderSummary?: (row: T) => JSX.Element | string; // For mobile
    };
    renderHead?: boolean;
    asCard?: boolean;
    loading?: boolean;
    selectable?: boolean;
    rowsSelect?: {
        setSelectedRows: () => void;
    };
}

export type TableType = <
    T extends Record<string, any> & { id?: string | null }
>(
    props: TableProps<T>
) => JSX.Element;

export const Table: TableType = ({
    columns,
    data,
    loading,
    selectable,
    rowsSelect,
    mobile,
    renderHead = true,
    asCard = true
}) => {
    const { renderSummary } = mobile || {};

    const isSelectable = selectable || !!rowsSelect;

    const isMobile = useBreakpoints('down', 'md');

    const visibleColumns = useMemo(
        () => columns.filter(({ hidden = false }) => !hidden),
        [columns]
    );

    return (
        <TableContainer
            component={asCard ? Paper : 'div'}
            sx={{ pb: isMobile && loading ? 3 : 0 }}
        >
            <Spin loading={loading}>
                {isMobile ? (
                    <MobileTable
                        data={data}
                        columns={visibleColumns}
                        selectable={isSelectable}
                        mobile={{ renderSummary }}
                    />
                ) : (
                    <DesktopTable
                        columns={visibleColumns}
                        data={data}
                        renderHead={renderHead}
                        selectable={isSelectable}
                    />
                )}
            </Spin>
        </TableContainer>
    );
};
