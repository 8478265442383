import { FC, useState, Dispatch, SetStateAction } from 'react';
import { Control, SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';

import { FilterForm } from './Forms/FilterForm';
import { Modal } from '../Modal';
import { getFilterParam } from 'src/utils/formatters';

interface Props {
    onClose: () => void;
    open: boolean;
    applyFilter: (filter: string) => Promise<void>;
    onCancel: () => void;
    handleSubmit: UseFormHandleSubmit<any>;
    control: Control<any, object>;
    setFilterFormState: Dispatch<SetStateAction<any | undefined>>;
}

export const MidsFiltersModal: FC<Props> = ({
    onClose,
    open,
    applyFilter,
    onCancel,
    control,
    handleSubmit,
    setFilterFormState
}) => {
    const [loading, setLoading] = useState(false);

    const onSubmit: SubmitHandler<any> = data => {
        setFilterFormState(cloneDeep(data));
        const newData: any = cloneDeep(data);

        const filterString = getFilterParam(newData);

        setLoading(true);
        applyFilter(filterString)
            .then(onClose)
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            onOk={handleSubmit(onSubmit)}
            onCancel={onCancel}
            okText="Apply filters"
            loading={loading}
        >
            <FilterForm control={control} />
        </Modal>
    );
};
