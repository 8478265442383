export interface BillingModelDto {
    id: string;
    type: BillingModelType;
    name: string;
    default: string;
    description: string;
    created: string;
    createdBy: string;
    lastUpdate: string;
    updatedBy: string;
}

export interface BillingModelFullDto {
    id: string;
    type: BillingModelType;
    name: string;
    default: string;
    description: string;
    billByCycleDays: number;
    billByDateDayOfMonth: number;
    billByDaySelectedWeek: BillByDaySelectedWeek;
    billByScheduleBufferDays: number;
    billByRelativeDateFrequency: BillByRelativeDateFrequency;
    every: number;
    orderplacedbefore: BillByDaySelectedDay;
    billByDaySelectedDay: BillByDaySelectedDay;
    billingDates: [
        {
            monthNumber: number;
            dayNumber: number;
        }
    ];
    created: string;
    createdBy: string;
    lastUpdate: string;
    updatedBy: string;
}

export enum BillByDaySelectedWeek {
    First = 'First',
    Second = 'Second',
    Third = 'Third',
    Fourth = 'Fourth',
    Last = 'Last',
    Custom = 'Custom'
}

export enum BillByRelativeDateFrequency {
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Yearly = 'Yearly'
}

export enum BillByDaySelectedDay {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}

export interface BillingModelPost {
    type?: BillingModelType;
    name: string;
    description: string;
    billByCycleDays?: number;
    billByDateDayOfMonth?: number;
    billByDaySelectedWeek?: BillByDaySelectedWeek;
    billByScheduleBufferDays?: number;
    billByRelativeDateFrequency?: BillByRelativeDateFrequency;
    billByDaySelectedDay?: BillByDaySelectedDay;
    billByEventName?: string;
    billByEventDescription?: string;
    every?: number;
    orderplacedbefore?: BillByDaySelectedDay;
    billingDates?: [
        {
            monthNumber: number;
            dayNumber: number;
        }
    ];
}

export enum BillingModelType {
    BillByCycle = 'BillByCycle',
    BillByDate = 'BillByDate',
    BillByDay = 'BillByDay',
    BillBySchedule = 'BillBySchedule',
    BillByRelativeDate = 'BillByRelativeDate',
    Event = 'Event'
}

export enum ExtendedBillingModelType {
    BillByCycle = 'BillByCycle',
    BillByDate = 'BillByDate',
    BillByDay = 'BillByDay',
    BillBySchedule = 'BillBySchedule',
    BillByRelativeDate = 'BillByRelativeDate',
    OneTimePurchase = 'OneTimePurchase',
    Event = 'Event'
}

export type BillingModelEditFormType = BillingModelPost;

export type BillingModelsFilterForm = any;
