import { Stack } from '@mui/material';
import { FC } from 'react';
import { useHistory } from 'react-router';
import { routes } from 'src/constants';

import { ColumnType, IMIDListDto } from 'src/types';
import { Link } from '../shared/CustomLink';
import { Table } from '../Table';
import { ActionsTableButton } from '../TablePage/ActionsTableButton';

interface Props {
    data: IMIDListDto[];
    loading?: boolean;
    onDelete: (id: string) => void;
}

export const MidsTable: FC<Props> = ({ data, loading, onDelete }) => {
    const history = useHistory();
    const columns: ColumnType<IMIDListDto>[] = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'First Name',
            dataIndex: 'firstName',
            render: (name, record) => (
                <Link to={routes.mid(record.id)}>{name}</Link>
            )
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName'
        },
        {
            title: 'Account Status',
            dataIndex: 'status'
        },
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            width: '60px',
            style: { paddingLeft: 0, paddingRight: 14 },
            render: (_, { id }) => (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                >
                    <ActionsTableButton
                        menuItems={[
                            {
                                title: 'Edit',
                                onClick: () => history.push(routes.mid(id))
                            },
                            { title: 'Delete', onClick: () => onDelete(id) }
                        ]}
                    />
                </Stack>
            )
        }
    ];

    return <Table columns={columns} data={data} loading={loading} selectable />;
};
