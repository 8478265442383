import { useDispatch, useSelector } from 'react-redux';
import {
    setAuthorizedRoutesAction,
    setUnauthorizedRoutesAction,
    UserRoute
} from 'src/store/actions/routes';
import { useEffect, useState } from 'react';
import { getUserRoutes } from '../../store/selectors';
import { ROUTES } from '../../constants';
import { usePermissions } from './usePermissions';

export const useRoutes = () => {
    const { permissions } = usePermissions();
    const { authorizedRoutes, unauthorizedRoutes } = useSelector(getUserRoutes);

    const dispatch = useDispatch();

    const setAuthorizedRoutes = (userAuthorizedRoutes: UserRoute[]) => {
        dispatch(setAuthorizedRoutesAction(userAuthorizedRoutes));
    };

    const setUnauthorizedRoutes = (userUnauthorizedRoutes: UserRoute[]) => {
        dispatch(setUnauthorizedRoutesAction(userUnauthorizedRoutes));
    };

    useEffect(() => {
        const availableRoutes = ROUTES;
        const authorizedRoutes = availableRoutes.filter(f =>
            permissions.some(p => p === f.permission)
        );
        const unauthorizedRoutes = availableRoutes.filter(
            f => !authorizedRoutes.includes(f)
        );

        setAuthorizedRoutes(authorizedRoutes);
        setUnauthorizedRoutes(unauthorizedRoutes);
    }, [permissions]);

    return {
        authorizedRoutes,
        unauthorizedRoutes
    };
};
