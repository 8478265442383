import { store } from 'src/store';
import { showMessageAction } from 'src/store/actions';
import { ApiErrorResponse } from 'src/types';

export default (err: ApiErrorResponse) => {
    store.dispatch(
        showMessageAction({
            message: err.message,
            type: 'error'
        })
    );
};
