import { FC, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ActionsIcon from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@mui/material';

export interface DropdownMenuItemProps {
    title: string;
    onClick?: () => void;
}
interface Props {
    menuItems: DropdownMenuItemProps[];
    color?: 'inherit' | 'primary';
}

export const ActionsTableButton: FC<Props> = ({
    menuItems,
    color = 'inherit'
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (callback?: () => void) => () => {
        if (callback) {
            callback();
        }
        handleClose();
    };

    return (
        <div>
            <IconButton onClick={handleClick} size="small">
                <ActionsIcon color={color} />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {menuItems.map(({ title, onClick }) => (
                    <MenuItem
                        onClick={handleMenuItemClick(onClick)}
                        key={title}
                        style={{ minWidth: 100 }}
                    >
                        {title}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
