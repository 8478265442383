import { PartnersType } from 'src/types';
import { Theme, ThemeOptions } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const createPartnerTheme = (partnerKey: PartnersType): Theme => {
    let theme;
    theme = createTheme(defaultTheme);
    theme = createTheme(theme, getThemeWithPalette(partnerKey));
    return createTheme(
        responsiveFontSizes(theme),
        getThemeWithComponents(theme)
    );
};

const defaultTheme = {
    typography: {
        fontFamily:
            "Nunito Sans, Helvetica Neue, Heebo, helvetica, 'arial', 'sans-serif'",
        body2: {
            color: '#919191'
        }
    }
};

const getThemeWithPalette = (partnerKey: PartnersType) => {
    switch (partnerKey) {
        case 'midkingz':
            return {
                palette: {
                    primary: {
                        main: '#d23a1e',
                        dark: '#6c1c0d'
                    },
                    divider: 'rgba(0,0,0,0.2)',
                    background: {
                        paper: '#fff',
                        default: '#fff'
                    }
                }
            };
        default:
            return {
                palette: {
                    primary: {
                        main: '#cae9e1',
                        dark: '#5ea991'
                    },
                    background: {
                        default: '#eef4f2',
                        paper: '#fff'
                    },
                    divider: '#f0f0f0',
                    subTextColor: '#919191'
                }
            };
    }
};

const getThemeWithComponents = (theme: Theme): ThemeOptions => ({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase'
                },
                contained: {
                    backgroundColor: theme.palette.text.primary,
                    color: theme.palette.background.default,
                    '&:hover': {
                        backgroundColor: theme.palette.text.secondary,
                        color: theme.palette.background.default
                    }
                },
                outlined: {
                    borderColor: theme.palette.text.primary,
                    color: theme.palette.text.primary,
                    '&:hover': {
                        borderColor: theme.palette.text.secondary,
                        color: theme.palette.text.primary
                    }
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#2b2b2b transparent',
                    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                        backgroundColor: 'transparent',
                        width: '6px',
                        height: '6px'
                    },
                    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb':
                        {
                            borderRadius: 8,
                            backgroundColor: '#6b6b6b',
                            minHeight: 24,
                            border: '3px solid #2b2b2b'
                        },
                    '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
                        {
                            backgroundColor: '#959595'
                        },
                    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
                        {
                            backgroundColor: '#959595'
                        },
                    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
                        {
                            backgroundColor: '#959595'
                        },
                    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner':
                        {
                            backgroundColor: '#2b2b2b'
                        }
                }
            }
        }
    }
});
