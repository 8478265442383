import { FC } from 'react';
import { Control } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { FormFieldType } from 'src/types';
import { Field, FormCard } from '../../Form';
import { VALIDATION_MSG } from 'src/constants';
import IMidEditForm from 'src/pages/MIDs/types';

interface Props {
    control: Control<IMidEditForm, object>;
}

const fields: (Field<IMidEditForm> & TextFieldProps)[] = [
    {
        name: 'primaryContact.firstName',
        label: 'First Name',
        required: true,
        inputProps: {
            maxLength: 50
        },
        xs: 4
    },
    {
        name: 'primaryContact.lastName',
        label: 'Last Name',
        required: true,
        xs: 4
    },
    {
        name: 'primaryContact.emailAddress',
        label: 'Email Address',
        required: true,
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
            message: VALIDATION_MSG.EMAIL
        },
        xs: 4
    },
    {
        name: 'primaryContact.phoneNumber',
        label: 'Phone Number',
        required: true,
        type: FormFieldType.Phone,
        inputProps: {
            maxLength: 16
        },
        xs: 4
    },
    {
        name: 'primaryContact.faxNumber',
        label: 'Fax Number',
        xs: 4
    }
];

export const CompanyContactForm: FC<Props> = ({ control }) => (
    <FormCard
        control={control}
        fields={fields}
        title="Company Contact"
        description="The company contact will be the “primary user” of affiliate
        account. They will receive welcome email that lets them set up
        the account for the first time. The information can be changed
        at any point in the future."
    />
);
