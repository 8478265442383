import { AlertColor } from '@mui/material';
import { HIDE_MESSAGE, MessageActionTypes, SHOW_MESSAGE } from '../actions';

export interface MessageState {
    message: string;
    type: AlertColor;
    showMessage: boolean;
}
const initialState: MessageState = {
    message: '',
    type: 'info',
    showMessage: false
};

export const message = (
    state: MessageState = initialState,
    action: MessageActionTypes
): MessageState => {
    switch (action.type) {
        case SHOW_MESSAGE:
            return {
                ...state,
                ...action.payload,
                showMessage: true
            };
        case HIDE_MESSAGE:
            return {
                ...state,
                showMessage: false
            };
        default:
            return state;
    }
};
