import React from 'react';
import { useHistory } from 'react-router';
import LeadForm from 'src/pages/Leads/LeadForm';
import { routes } from 'src/constants';

const PageLeadForm = () => {
    const history = useHistory();
    const handleCancel = () => {
        history.push(routes.accountsView);
    };

    return <LeadForm onCancel={handleCancel} onSuccessAddLead={handleCancel} />;
};

export default PageLeadForm;
