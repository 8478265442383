import * as yup from 'yup';

export const schema = yup
    .object()
    .shape({
        email: yup.string().required(),
        firstname: yup.string().required().max(100),
        lastname: yup.string().required().max(100),
        phone: yup.string().required(),
        password: yup
            .string()
            .required()
            .matches(
                /[0-9]/,
                "Passwords must have at least one digit ('0'-'9')."
            )
            .matches(
                /[A-Z]/,
                "Passwords must have at least one uppercase ('A'-'Z')."
            )
            .matches(
                /\W/,
                'Passwords must have at least one non alphanumeric character.'
            ),
        passwordConfirm: yup
            .string()
            .required()
            .oneOf(
                [yup.ref('password'), null],
                'Passwords and confirm not equal'
            ),
        country: yup.string().required()
    })
    .required();
