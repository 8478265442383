import { yupResolver } from '@hookform/resolvers/yup';
import { emailValidation } from 'src/utils/yupValidations';
import { object, ref, SchemaOf, string } from 'yup';
import { CreateAccountForm } from './CreateAccount';

export default function createAccountFormValidationYupResolver() {
    const schema: SchemaOf<CreateAccountForm> = object({
        email: emailValidation.required('Is Required'),
        firstname: string().required('Is Required'),
        lastname: string().required('Is Required'),
        phone: string().required('Is Required'),
        country: string().required('Is Required'),
        password: string()
            .min(6, 'Password must be at least 6 characters')
            .matches(
                /(.*[^a-zA-Z].*)/,
                'Password must have at least one non alphanumeric character'
            )
            .matches(
                /(.*[a-z].*)/,
                'Password must have at least one lowercase character'
            )
            .matches(
                /(.*[A-Z].*)/,
                'Password must have at least one uppercase character'
            )
            .matches(
                /(.*\W.*)/,
                'Password must have at least one special character'
            )
            .required('Is Required'),
        passwordConfirm: string()
            .required('Is Required')
            .oneOf([ref('password')], 'Your passwords must match')
    });

    return yupResolver(schema);
}
