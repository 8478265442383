import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryShorDto, ErrorType } from 'src/types';
import { getShortCategories } from 'src/store/selectors';
import { showMessageAction } from 'src/store/actions';
import {
    setLoadingAction,
    setShortCategoriesAction
} from '../../store/actions/shortCategories';
import { apiGetShortCategories } from '../endpoints/categories';

interface ReturnValue {
    shortCategories: Record<string, CategoryShorDto>;
    fetchShortCategories: () => Promise<void>;
    errorData: ErrorType | null;
    loading: boolean;
}

export const useShortCategories = (): ReturnValue => {
    const [errorData, setErrorData] = useState<ErrorType | null>(null);

    const { categories, loading } = useSelector(getShortCategories);

    const dispatch = useDispatch();

    const setShortCategories = (services: CategoryShorDto[]) =>
        dispatch(setShortCategoriesAction(services));

    const setLoading = () => dispatch(setLoadingAction());

    const fetchShortCategories = () => {
        setLoading();
        setErrorData(null);

        return apiGetShortCategories()
            .then(categories => {
                setShortCategories(categories);
            })
            .catch((err: ErrorType) => {
                setShortCategories([]);
                setErrorData(err);
                dispatch(
                    showMessageAction({
                        message: `Failed getting categories: ${err}`,
                        type: 'error'
                    })
                );

                throw err;
            });
    };

    useEffect(() => {
        if (!Object.values(categories).length) {
            fetchShortCategories();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        shortCategories: categories,
        fetchShortCategories,
        loading,
        errorData
    };
};
