import { IOrganizationDto, UserAccountDto } from 'src/types';
import { UserOrganizationAccountDto } from '../../types';

export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_ORGS = 'SET_USER_ORGS';
export const SET_SELECTED_ORGANIZATION = 'SET_SELECTED_ORGANIZATION';
export const SET_USER_ORGANIZATION_DATA = 'SET_USER_ORGANIZATION_DATA';

export type SetIsLoggedIn = {
    type: typeof SET_IS_LOGGED_IN;
    payload: boolean;
};

export type SetUserData = {
    type: typeof SET_USER_DATA;
    payload?: UserAccountDto;
};

export type SetUserOrganizationData = {
    type: typeof SET_USER_ORGANIZATION_DATA;
    payload?: UserOrganizationAccountDto;
};

export type setUserOrganizations = {
    type: typeof SET_USER_ORGS;
    payload?: IOrganizationDto[];
};

export type setSelectedOrganization = {
    type: typeof SET_SELECTED_ORGANIZATION;
    payload?: IOrganizationDto;
};

export type AuthActionTypes =
    | SetIsLoggedIn
    | SetUserData
    | SetUserOrganizationData
    | setUserOrganizations
    | setSelectedOrganization;

export const setIsLoggedInAction = (isLoggedIn: boolean): SetIsLoggedIn => ({
    type: SET_IS_LOGGED_IN,
    payload: isLoggedIn
});

export const setUserDataAction = (data?: UserAccountDto): SetUserData => ({
    type: SET_USER_DATA,
    payload: data
});

export const setUserOrganizationDataAction = (
    data?: UserOrganizationAccountDto
): SetUserOrganizationData => ({
    type: SET_USER_ORGANIZATION_DATA,
    payload: data
});

export const setUserOrganizationsAction = (data?: IOrganizationDto[]) => ({
    type: SET_USER_ORGS,
    payload: data
});

export const setSelectedOrganizationAction = (data?: IOrganizationDto) => ({
    type: SET_SELECTED_ORGANIZATION,
    payload: data
});
