import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAgentAction, showMessageAction } from 'src/store/actions';
import { getAgents } from 'src/store/selectors';
import { ErrorType, IAgentsApiGetById } from 'src/types';
import { apiGetAgentById } from '../endpoints/agents';

type TSetAgent = (agent?: IAgentsApiGetById) => void;
interface ReturnValue {
    setAgent: TSetAgent;
    agent: IAgentsApiGetById | undefined;
    fetchAgent: (id: string) => void;
    errorData: ErrorType | null;
    loading: boolean;
}

export const useAgent = (): ReturnValue => {
    const dispatch = useDispatch();

    const [errorData, setErrorData] = useState<ErrorType | null>(null);
    const [loading, setLoading] = useState<ErrorType | null>(null);

    const { currentAgent: agent } = useSelector(getAgents);
    const setAgent: TSetAgent = agent => dispatch(setAgentAction(agent));

    let isMounted = true;

    const fetchAgent = (id: string) => {
        setErrorData(null);
        setLoading(true);

        apiGetAgentById(id)
            .then(agent => {
                setAgent(agent);
            })
            .catch((err: ErrorType) => {
                setAgent(undefined);
                setErrorData(err);
                dispatch(
                    showMessageAction({
                        message: `Failed getting agent: ${err}`,
                        type: 'error'
                    })
                );

                throw err;
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                } else {
                    setAgent();
                }
            });

        return () => {
            isMounted = false;
        };
    };

    return {
        setAgent,
        agent,
        fetchAgent,
        errorData,
        loading
    };
};
