import {
    IconButton,
    InputBaseComponentProps,
    TextField as MuiTextField,
    TextFieldProps
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/system';
import { Control, Controller, Path, ValidationRule } from 'react-hook-form';
import { integerAndFloatRegex } from 'src/constants/regex';

const TextField = styled(MuiTextField)(`
    .MuiFormHelperText: {
        position: absolute;
        bottom: -20px;
    }

    .MuiButtonBase-root {
        visibility: hidden;
    }

    &:hover .MuiButtonBase-root,
    .MuiInput-root.Mui-focused .MuiButtonBase-root {
        visibility: visible;
    }
`);

interface Props<T = any> {
    name: Path<T>;
    label?: string;
    control: Control<T, object>;
    required?: boolean;
    pattern?: ValidationRule<RegExp>;
    validate?: any; // validation from react hook form
    inputProps?: InputBaseComponentProps;
    allowClear?: boolean;
    disabled?: boolean;
    InputProps?: TextFieldProps['InputProps'];
}

export type FormInputNumberTypeProps<T = any> = Props<T> &
    TextFieldProps & { formType?: string };

type FormInputNumberType = <T = any>(
    props: FormInputNumberTypeProps<T>
) => JSX.Element;

export const FormInputNumber: FormInputNumberType = ({
    name,
    control,
    label,
    required = false,
    pattern,
    validate,
    inputProps,
    allowClear = true,
    InputProps = {},
    disabled,
    ...otherProps
}) => (
    <Controller
        name={name}
        control={control}
        rules={{ required, pattern, validate }}
        render={({ field: { onChange, value, ref }, fieldState }) => {
            const onInputChangeHandler: React.ChangeEventHandler<
                HTMLInputElement | HTMLTextAreaElement
            > = e => {
                let inputValue = e.target.value;

                const regexp = new RegExp(integerAndFloatRegex);

                if (regexp.test(inputValue)) {
                    return onChange(parseFloat(inputValue));
                }

                onChange('');
            };

            const onInputBlurHandler: React.FocusEventHandler<
                HTMLInputElement | HTMLTextAreaElement
            > = e => {
                if (e.target.value === '') {
                    onChange(0);
                }
            };

            return (
                <TextField
                    name={name}
                    onChange={onInputChangeHandler}
                    inputProps={inputProps}
                    InputProps={{
                        endAdornment:
                            allowClear && value && !disabled ? (
                                <IconButton
                                    size="small"
                                    onClick={() => onChange(0)}
                                    tabIndex={-1}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                            ) : undefined,
                        ...InputProps
                    }}
                    inputRef={ref}
                    label={label}
                    onBlur={onInputBlurHandler}
                    value={value}
                    variant="standard"
                    required={required}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    disabled={disabled}
                    {...otherProps}
                />
            );
        }}
    />
);
