import { FC } from 'react';
import {
    Button,
    Card,
    CardContent,
    Chip,
    Grid,
    Stack,
    Typography
} from '@mui/material';

interface Props {
    roles: string[];
    setEditRolesMode: () => void;
}

export const RolesCard: FC<Props> = ({ roles, setEditRolesMode }) => (
    <Card>
        <CardContent
            style={{
                padding: 28,
                paddingBottom: 28,
                paddingTop: 20
            }}
        >
            <Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h6">Roles</Typography>
                    <Button variant="contained" onClick={setEditRolesMode}>
                        Edit Roles
                    </Button>
                </Stack>
                <Grid container spacing={2} mt={1} mb={1}>
                    {roles.map(value => (
                        <Grid item key={value}>
                            <Chip
                                label={value}
                                style={{ width: 'fit-content' }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </CardContent>
    </Card>
);
