import { PermissionType } from '../../types/permissions';

export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';

export type PermissionsActionTypes = SetUserPermissions;

export type SetUserPermissions = {
    type: typeof SET_USER_PERMISSIONS;
    payload: PermissionType[];
};

export const setUserPermissionsAction = (
    permissions: PermissionType[]
): SetUserPermissions => ({
    type: SET_USER_PERMISSIONS,
    payload: permissions
});
