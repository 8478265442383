import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setLoadingAction,
    SetPagination,
    setPaginationAction,
    SetAgents,
    setAgentsAction,
    showMessageAction
} from 'src/store/actions';
import { getAgents } from 'src/store/selectors';
import {
    CommonGetParams,
    ErrorType,
    Pagination,
    IAgentsApiGetById,
    IAgentsApiPostPut
} from 'src/types';
import { getApiCommonParams } from 'src/utils/formatters';
import {
    apiDeleteAgents,
    apiGetAgents,
    apiPostAgents,
    apiPutAgents
} from '../endpoints/agents';
import { TAgentInRedux } from 'src/store/reducers/agents';

type TCreateAgent = (
    body: IAgentsApiPostPut,
    successCalback?: () => void
) => Promise<void>;

type TUpdateAgent = (
    id: string,
    body: IAgentsApiPostPut,
    successCalback?: (agent: IAgentsApiGetById) => void
) => Promise<void>;

type TDeleteAgent = (
    id: string,
    commonParams?: CommonGetParams
) => Promise<void>;

interface ReturnValue {
    fetchAgents: (commonParams?: CommonGetParams) => Promise<void>;
    agents: TAgentInRedux;
    setAgents: (agents: IAgentsApiGetById[]) => SetAgents;
    deleteAgent: TDeleteAgent;
    createAgent: TCreateAgent;
    updateAgent: TUpdateAgent;
    loading: boolean;
    errorData: ErrorType;
    paging?: Pagination;
    setPaging: (pagination?: Pagination) => SetPagination;
}

export const useAgents = (): ReturnValue => {
    const [errorData, setErrorData] = useState<ErrorType | null>(null);

    const {
        agentsItems: agents,
        loading,
        pagination: paging
    } = useSelector(getAgents);

    const dispatch = useDispatch();

    const setPaging = (pagination?: Pagination) =>
        dispatch(setPaginationAction(pagination));

    const setAgents = (agents: IAgentsApiGetById[]) =>
        dispatch(setAgentsAction(agents));

    const setLoading = () => dispatch(setLoadingAction());

    const fetchAgents = (commonParams?: CommonGetParams) => {
        setLoading();
        setErrorData(null);

        const params = commonParams
            ? getApiCommonParams(commonParams)
            : undefined;

        return apiGetAgents({ params })
            .then(({ result, pagination }) => {
                setAgents(result || []);
                setPaging(pagination);
            })
            .catch((err: ErrorType) => {
                setAgents([]);
                setErrorData(err);
                dispatch(
                    showMessageAction({
                        message: `Failed getting agents: ${err}`,
                        type: 'error'
                    })
                );

                throw err.message;
            });
    };

    const deleteAgent: TDeleteAgent = (id, commonParams) =>
        apiDeleteAgents(id)
            .then(() => {
                dispatch(
                    showMessageAction({
                        message: 'Agent deleted successfully',
                        type: 'success'
                    })
                );
                fetchAgents(commonParams);
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed deleting agent: ${err}`,
                        type: 'error'
                    })
                );
                throw err;
            });

    const createAgent: TCreateAgent = (body, successCalback) =>
        apiPostAgents(body)
            .then(agent => {
                dispatch(
                    showMessageAction({
                        message: 'Agent saved successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback();
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed saving agent: ${err}`,
                        type: 'error'
                    })
                );
            });

    const updateAgent: TUpdateAgent = (id, body, successCalback) =>
        apiPutAgents(id, body)
            .then(agent => {
                dispatch(
                    showMessageAction({
                        message: 'Agent changed successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback(agent);
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed changing agent: ${err}`,
                        type: 'error'
                    })
                );
            });

    return {
        agents,
        setAgents,
        fetchAgents,
        deleteAgent,
        createAgent,
        updateAgent,
        paging,
        setPaging,
        loading,
        errorData
    };
};
