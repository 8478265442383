import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showMessageAction } from 'src/store/actions';
import {
    setLoadingAction,
    SetPagination,
    setPaginationAction,
    setServicesAction
} from 'src/store/actions/services';
import {
    apiArchiveService,
    apiDearchiveService,
    apiDeleteService,
    apiGetService,
    apiGetServices,
    apiPostService,
    apiPutService
} from '../endpoints/services';
import { getServices } from 'src/store/selectors';
import {
    CommonGetParams,
    ErrorType,
    Pagination,
    ServiceDto,
    ServicePost,
    SingleServiceDto
} from 'src/types';
import { getApiCommonParams } from 'src/utils/formatters';
import { ServicesState } from 'src/store/reducers/services';

interface ReturnValue {
    fetchServices: (commonParams?: CommonGetParams) => Promise<void>;
    deleteService: (id: string, params?: CommonGetParams) => Promise<void>;
    createService: (body: ServicePost, successCb?: () => void) => Promise<void>;
    updateService: (
        id: string,
        body: ServicePost,
        successCb?: () => void
    ) => Promise<void>;
    archiveService: (
        id: string,
        successCb?: (service: SingleServiceDto) => void
    ) => Promise<void>;
    dearchiveService: (
        id: string,
        successCb?: (service: SingleServiceDto) => void
    ) => Promise<void>;
    services: ServicesState['services'];
    loading: boolean;
    errorData: ErrorType | null;
    paging?: Pagination;
    setPaging: (pagination?: Pagination) => SetPagination;
}

export const useServices = (): ReturnValue => {
    const [errorData, setErrorData] = useState<ErrorType | null>(null);

    const { services, loading, pagination: paging } = useSelector(getServices);

    const dispatch = useDispatch();

    const setPaging = (pagination?: Pagination) =>
        dispatch(setPaginationAction(pagination));

    const setServices = (services: ServiceDto[]) =>
        dispatch(setServicesAction(services));

    const setLoading = () => dispatch(setLoadingAction());

    const fetchServices = (commonParams?: CommonGetParams) => {
        setLoading();
        setErrorData(null);

        const params = commonParams
            ? getApiCommonParams(commonParams)
            : undefined;

        return apiGetServices({ params })
            .then(({ result, pagination }) => {
                setServices(result);
                setPaging(pagination);
            })
            .catch((err: ErrorType) => {
                setServices([]);
                setErrorData(err);
                dispatch(
                    showMessageAction({
                        message: `Failed getting services: ${err}`,
                        type: 'error'
                    })
                );

                // throw err;
            });
    };

    const deleteService = (id: string, commonParams?: CommonGetParams) =>
        apiDeleteService(id)
            .then(() => {
                dispatch(
                    showMessageAction({
                        message: 'Service deleted successfully',
                        type: 'success'
                    })
                );
                fetchServices(commonParams);
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed deleting service: ${err}`,
                        type: 'error'
                    })
                );
                throw err;
            });

    const createService = (body: ServicePost, successCalback?: () => void) =>
        apiPostService(body)
            .then(() => {
                dispatch(
                    showMessageAction({
                        message: 'Service saved successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback();
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed saving service: ${err}`,
                        type: 'error'
                    })
                );
            });

    const updateService = (
        id: string,
        body: ServicePost,
        successCalback?: (service: SingleServiceDto) => void
    ) =>
        apiPutService(id, body)
            .then(service => {
                dispatch(
                    showMessageAction({
                        message: 'Service changed successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback(service);
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed changing service: ${err}`,
                        type: 'error'
                    })
                );
            });

    const archiveService = (
        id: string,
        successCalback?: (service: SingleServiceDto) => void
    ) =>
        apiArchiveService(id)
            .then(service => {
                dispatch(
                    showMessageAction({
                        message: 'Service was archived successfully',
                        type: 'success'
                    })
                );

                if (successCalback) {
                    successCalback(service);
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed service archivation: ${err}`,
                        type: 'error'
                    })
                );
            });

    const dearchiveService = (
        id: string,
        successCalback?: (service: SingleServiceDto) => void
    ) =>
        apiDearchiveService(id)
            .then(service => {
                dispatch(
                    showMessageAction({
                        message: 'Service was dearchived successfully',
                        type: 'success'
                    })
                );

                if (successCalback) {
                    successCalback(service);
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed service dearchivation: ${err}`,
                        type: 'error'
                    })
                );
            });

    return {
        fetchServices,
        deleteService,
        createService,
        updateService,
        dearchiveService,
        archiveService,
        services,
        loading,
        errorData,
        paging,
        setPaging
    };
};

interface ServiceReturnValue {
    service: SingleServiceDto | undefined;
    fetchService: (id: string) => Promise<void>;
    loading: boolean;
    errorData: ErrorType | null;
    setLoading: (loading: boolean) => void;
}

export const useService = (): ServiceReturnValue => {
    const [service, setService] = useState<SingleServiceDto>();
    const [loading, setLoading] = useState(false);
    const [errorData, setErrorData] = useState<ErrorType | null>(null);

    const fetchService = (id: string) => {
        setLoading(true);
        setErrorData(null);

        return apiGetService(id)
            .then(service => {
                setService(service);
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        service,
        fetchService,
        loading,
        setLoading,
        errorData
    };
};
