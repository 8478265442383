import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

export default function categoryValidationYupResolver() {
    const schema = object({
        // Company Information
        name: string().required().max(200),
        description: string().required().max(200)
    });

    return yupResolver(schema);
}
