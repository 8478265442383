import { Pagination, IMIDListDto } from 'src/types';

export const SET_MIDS = 'SET_MIDS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_LOADING = 'SET_LOADING';

export type SetMids = {
    type: typeof SET_MIDS;
    payload: IMIDListDto[];
};

export type SetPagination = {
    type: typeof SET_PAGINATION;
    payload?: Pagination;
};

export type SetLoading = {
    type: typeof SET_LOADING;
};

export type MidsActionTypes = SetMids | SetLoading | SetPagination;

export const setMidsAction = (mids: IMIDListDto[]): SetMids => ({
    type: SET_MIDS,
    payload: mids
});

export const setPaginationAction = (
    pagination?: Pagination
): SetPagination => ({
    type: SET_PAGINATION,
    payload: pagination
});

export const setLoadingAction = () => ({
    type: SET_LOADING
});
