import { IFilePutResponse } from 'src/types/files';
import { ApiErrorResponse } from './apiResponse';

export enum FormFieldType {
    Input = 'input',
    Autocomplete = 'autocomplete',
    AutocompleteWithAdd = 'autocompleteWithAdd',
    Price = 'price',
    Number = 'number',
    Phone = 'phone',
    DateTime = 'dateTime'
}

export type FileLoaderPropsType = {
    onStartUploadFile: () => void;
    onSuccessUploadFile: (file: IFilePutResponse) => void;
    onErrorUploadFile: (e: ApiErrorResponse) => void;
};
