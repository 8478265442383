import { FC } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { FileLoaderPropsType, IFilePutResponse } from 'src/types';
import { BorderedButton } from 'src/components/shared/BorderedButton';
import { uploadFileRequest } from 'src/api/requests/files.requests';
import { useDispatch } from 'react-redux';
import { showMessageAction } from 'src/store/actions';

interface Props extends FileLoaderPropsType {
    title: string;
    inputAccept?: string;
    mimeTypes?: string[];
    withIcon?: boolean;
}

export const FileInput: FC<Props> = ({
    title,
    onStartUploadFile,
    onSuccessUploadFile,
    onErrorUploadFile,
    inputAccept = '*',
    mimeTypes,
    withIcon = true
}) => {
    const dispatch = useDispatch();

    const handleChangeFileInput = (e: any) => {
        if (!e.target.files.length) return;
        e.preventDefault();
        const file = e.target.files[0];

        if (mimeTypes?.length && !mimeTypes?.includes(file.type)) {
            dispatch(
                showMessageAction({
                    message: 'Incorrect file type',
                    type: 'warning'
                })
            );
            return;
        }

        onStartUploadFile();
        uploadFileRequest(file)
            .then((file: IFilePutResponse) => {
                onSuccessUploadFile(file);
            })
            .catch(e => {
                onErrorUploadFile(e);
            });
    };

    return (
        <BorderedButton variant="contained" component="label">
            {withIcon && (
                <AddIcon
                    style={{
                        marginRight: '5px',
                        width: '20px'
                    }}
                />
            )}{' '}
            {title}
            <input
                type="file"
                accept={inputAccept}
                hidden
                onChange={handleChangeFileInput}
            />
        </BorderedButton>
    );
};
