import { FormFieldType } from 'src/types';
import { countries } from 'src/constants';
import { AutocompleteOption, Field } from 'src/components/Form';
import { TextFieldProps } from '@mui/material';

type CreateAccountForm = {
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    password: string;
    passwordConfirm: string;
    country: string;
    organizationIds: string[];
};

export const generateFields = (
    organizations: AutocompleteOption[],
    onAddOption: (value: string) => void
) =>
    [
        {
            name: 'email',
            label: 'Email Address',
            required: true,
            style: {
                marginBottom: 10
            }
        },
        {
            name: 'firstname',
            label: 'First Name',
            required: true,
            style: {
                marginBottom: 10
            }
        },
        {
            name: 'lastname',
            label: 'Last Name',
            required: true,
            style: {
                marginBottom: 10
            }
        },
        {
            name: 'phone',
            label: 'Phone Number',
            required: true,
            type: FormFieldType.Phone,
            style: {
                marginBottom: 10
            }
        },
        {
            name: 'password',
            label: 'Password',
            fieldType: 'password',
            required: true,
            style: {
                marginBottom: 10
            }
        },
        {
            name: 'passwordConfirm',
            label: 'Confirm Password',
            fieldType: 'password',
            required: true,
            style: {
                marginBottom: 10
            }
        },
        {
            name: 'country',
            label: 'Country',
            required: true,
            type: FormFieldType.Autocomplete,
            options: countries,
            style: {
                marginBottom: 20
            }
        },
        {
            name: 'organizationIds',
            label: 'Organizations',
            required: true,
            type: FormFieldType.AutocompleteWithAdd,
            withAddOption: true,
            onAddOption,
            multiple: true,
            options: organizations || [],
            style: {
                marginBottom: 20
            }
        }
    ] as (Field<CreateAccountForm> & TextFieldProps)[];
