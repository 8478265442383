import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessageAction } from 'src/store/actions';
import {
    PartnerCreateEditDto,
    ErrorType,
    PartnersItemDto,
    PartnerGetByIdDto
} from 'src/types';
import {
    apiGetPartner,
    apiGetPartners,
    apiPostPartner,
    apiPutPartner
} from '../endpoints/partners';

export const usePartners = () => {
    const [partners, setPartners] = useState<PartnersItemDto[]>([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        apiGetPartners()
            .then(data => {
                setPartners(data || []);
            })
            .catch(err => {
                dispatch(
                    showMessageAction({
                        message: `Failed getting partners: ${err}`,
                        type: 'error'
                    })
                );
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        partners,
        loading
    };
};

export const usePartner = () => {
    const dispatch = useDispatch();
    const [partner, setPartner] = useState<PartnerGetByIdDto>();
    const [loading, setLoading] = useState(false);
    const [errorData, setErrorData] = useState<ErrorType | null>(null);

    const fetchPartner = (id: string) => {
        setLoading(true);
        setErrorData(null);

        return apiGetPartner(id)
            .then(partner => {
                setPartner(partner);
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createPartner = (
        body: PartnerCreateEditDto,
        successCalback?: () => void
    ) =>
        apiPostPartner(body)
            .then(() => {
                dispatch(
                    showMessageAction({
                        message: 'Partner saved successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback();
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed saving partner: ${err}`,
                        type: 'error'
                    })
                );
            });

    const updatePartner = (
        id: string,
        body: PartnerCreateEditDto,
        successCalback?: (partner: PartnerCreateEditDto) => void
    ) =>
        apiPutPartner(id, body)
            .then(partner => {
                dispatch(
                    showMessageAction({
                        message: 'Partner changed successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback(partner);
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed changing partner: ${err}`,
                        type: 'error'
                    })
                );
            });

    return {
        partner,
        fetchPartner,
        createPartner,
        updatePartner,
        loading,
        setLoading,
        errorData
    };
};
