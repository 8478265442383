import { ChangeEvent, useEffect, useState, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Stack } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { useServices } from 'src/api/hooks';
import { ActionsCard } from 'src/components/TablePage/ActionsCard';
import { PAGE_SIZE, routes } from 'src/constants';
import { Pagination } from 'src/components/Pagination';
import { ServicesTable, ServicesFiltersModal } from 'src/components/Services';
import { ServicesFilterForm, ServiceStatusesExtendedEnum } from 'src/types';
import { generateFields } from './fields';

export const Services = () => {
    const {
        fetchServices,
        services,
        loading,
        paging,
        deleteService,
        archiveService,
        dearchiveService
    } = useServices();
    const history = useHistory();
    const [viewType, setViewType] = useState<ServiceStatusesExtendedEnum>(
        ServiceStatusesExtendedEnum.ACTIVE
    );

    const MENU_ITEMS = [
        {
            title: 'Add Service',
            onClick: () => history.push(routes.servicesNew)
        },
        {
            title: 'Export CSV'
        }
    ];

    const { totalPages } = paging || {};
    const [pageNumber, setPageNumber] = useState(0);
    const [searchString, setSearchingString] = useState('');

    const { actionsMenuItems, title, isArchivedView } = {
        actionsMenuItems:
            viewType === ServiceStatusesExtendedEnum.ARCHIVED
                ? undefined
                : MENU_ITEMS,
        title:
            viewType === ServiceStatusesExtendedEnum.ACTIVE
                ? 'Services'
                : 'Archived',
        isArchivedView: viewType === ServiceStatusesExtendedEnum.ARCHIVED
    };

    const [modalVisible, setModalVisible] = useState(false);
    const hideModal = () => setModalVisible(false);
    const openModal = () => setModalVisible(true);

    const { handleSubmit, control, reset } = useForm<ServicesFilterForm>();
    const [filterFormState, setFilterFormState] =
        useState<ServicesFilterForm>();
    const [filter, setFilter] = useState<string>('');

    const getServices = useCallback(
        (pageNumber: number, innerSearchString?: string) => {
            const queryString = innerSearchString ?? searchString;

            fetchServices({
                searchString: queryString,
                pageNumber: `${pageNumber}`,
                pageSize: PAGE_SIZE,
                filter,
                status: viewType
            });
        },
        [fetchServices, filter, searchString, viewType]
    );

    useEffect(() => {
        getServices(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewType, pageNumber]);

    const handleViewChange = () => {
        setViewType(prevState =>
            prevState === ServiceStatusesExtendedEnum.ARCHIVED
                ? ServiceStatusesExtendedEnum.ACTIVE
                : ServiceStatusesExtendedEnum.ARCHIVED
        );
    };

    const handlePageChange = (e: ChangeEvent<unknown>, page: number) => {
        setPageNumber(page - 1);
        getServices(page - 1);
    };

    const handleSearchChange = (searchString?: string) => {
        setSearchingString(searchString || '');
        getServices(0, searchString);

        if (pageNumber) {
            setPageNumber(0);
        }
    };

    const handleRefresh = () => {
        setFilterFormState(undefined);
        setFilter('');
        reset({});

        fetchServices({
            searchString: searchString,
            pageNumber: `${pageNumber}`,
            pageSize: PAGE_SIZE,
            status: viewType
        });
    };

    const handleDeleteService = useCallback(
        (id: string) => {
            deleteService(id, {
                searchString,
                pageNumber: '0',
                pageSize: PAGE_SIZE
            });
            setPageNumber(0);
        },
        [deleteService, searchString]
    );

    const handleArchiveService = useCallback(
        (id: string) => {
            archiveService(id, () => {
                getServices(pageNumber);
            });
        },
        [archiveService, getServices, pageNumber]
    );

    const handleDearchiveService = useCallback(
        (id: string) => {
            dearchiveService(id, () => {
                getServices(pageNumber);
            });
        },
        [dearchiveService, getServices, pageNumber]
    );

    const handleOpenFilterModal = () => {
        openModal();
    };

    const applyFilter = (filter: string) => {
        setPageNumber(0);
        setFilter(filter);
        return fetchServices({
            searchString,
            pageNumber: '0',
            pageSize: PAGE_SIZE,
            filter
        });
    };

    const handleCancelFilter = () => {
        reset(filterFormState);
        hideModal();
    };

    const fieldsToDisplay = useMemo(
        () =>
            generateFields({
                onDelete: handleDeleteService,
                onArchive: handleArchiveService,
                onDearchive: handleDearchiveService,
                type: viewType,
                history
            }),
        [
            handleDeleteService,
            handleArchiveService,
            handleDearchiveService,
            viewType,
            history
        ]
    );

    return (
        <Stack spacing={2}>
            <ActionsCard
                onSearchChange={handleSearchChange}
                onRefresh={handleRefresh}
                openFilter={handleOpenFilterModal}
                onViewChange={handleViewChange}
                enableArchiveMode
                isArchivedView={isArchivedView}
                actionsMenuItems={actionsMenuItems}
                title={title}
            />

            <ServicesTable
                data={Object.values(services)}
                columns={fieldsToDisplay}
                loading={loading}
            />

            {!isEmpty(services) && (
                <Pagination
                    page={pageNumber + 1}
                    onChange={handlePageChange}
                    count={totalPages}
                />
            )}

            <ServicesFiltersModal
                open={modalVisible}
                onClose={hideModal}
                applyFilter={applyFilter}
                onCancel={handleCancelFilter}
                handleSubmit={handleSubmit}
                setFilterFormState={setFilterFormState}
                control={control}
            />
        </Stack>
    );
};
