import React, { useState } from 'react';
import { Box } from '@mui/material';
import CardWrapper from 'src/components/Card/CardWrapper';
import { FileInput } from 'src/components/Form/FileInput';
import { FileDto, IAgentsApiPostPut } from 'src/types';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import Loader from 'src/components/Loader/Loader';
import FilePreview from 'src/components/Cards/AgreementsCard/FilePreview';

const AgreementsCard = ({
    setValue,
    control
}: {
    control: Control<IAgentsApiPostPut, object>;
    setValue: UseFormSetValue<IAgentsApiPostPut>;
}) => {
    const [isFileLoading, setIsFileLoading] = useState<boolean>(false);

    const files = useWatch({ control, name: 'files' }) || [];

    const onStartUploadFile = () => {
        setIsFileLoading(true);
    };

    const onSuccessUploadFile = (file: FileDto) => {
        setValue(
            'files',
            [...files, { ...file, type: 'SignedAgentAgreement' }],
            { shouldDirty: true }
        );
        setIsFileLoading(false);
    };

    const onErrorUploadFile = () => {
        setIsFileLoading(false);
    };

    const onDeleteFile = (fileId: FileDto['id']) => {
        setValue(
            'files',
            files.filter(({ id }) => id !== fileId),
            { shouldDirty: true }
        );
    };

    return (
        <CardWrapper
            title="Agreements"
            description="Add agreements for an agent"
        >
            <Box py={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Loader loading={isFileLoading}>
                    <FileInput
                        title="SELECT AND UPLOAD"
                        onStartUploadFile={onStartUploadFile}
                        onSuccessUploadFile={onSuccessUploadFile}
                        onErrorUploadFile={onErrorUploadFile}
                        inputAccept="*"
                        withIcon={false}
                    />
                </Loader>
            </Box>
            <Box p={4} sx={{ display: files.length ? 'block' : 'none' }}>
                {files.map((file: any) => (
                    <FilePreview
                        key={file.id}
                        fileId={file.id}
                        onDelete={() => onDeleteFile(file.id)}
                    />
                ))}
            </Box>
        </CardWrapper>
    );
};

export default AgreementsCard;
