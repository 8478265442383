import { IOrganizationDto, UserAccountDto } from 'src/types';
import {
    AuthActionTypes,
    SET_IS_LOGGED_IN,
    SET_USER_DATA,
    SET_USER_ORGS,
    SET_SELECTED_ORGANIZATION,
    SET_USER_ORGANIZATION_DATA
} from '../actions/auth';
import { UserOrganizationAccountDto } from '../../types';

export interface AuthState {
    isLoggedIn: boolean;
    userData?: UserAccountDto;
    userOrganizationData?: UserOrganizationAccountDto;
    organizations?: IOrganizationDto[];
    selectedOrganization?: IOrganizationDto;
}
const initialState: AuthState = {
    isLoggedIn: false
};

export const auth = (
    state: AuthState = initialState,
    action: AuthActionTypes
): AuthState => {
    switch (action.type) {
        case SET_IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.payload
            };
        case SET_USER_DATA:
            return {
                ...state,
                userData: action.payload
            };
        case SET_USER_ORGANIZATION_DATA:
            return {
                ...state,
                userOrganizationData: action.payload
            };
        case SET_USER_ORGS:
            return {
                ...state,
                organizations: action.payload
            };
        case SET_SELECTED_ORGANIZATION:
            return {
                ...state,
                selectedOrganization: action.payload
            };
        default:
            return state;
    }
};
