import { DropdownMenuItemProps } from 'src/components/DropdownMenu';
import { routes } from 'src/constants';
import { ServiceStatusesExtendedEnum } from 'src/types';
import { GenerateFieldsArgs } from './fields';

export const generateActionMenuItems = ({
    serviceId,
    type,
    onDelete,
    onArchive,
    onDearchive,
    history
}: GenerateFieldsArgs): DropdownMenuItemProps[] => {
    switch (type) {
        case ServiceStatusesExtendedEnum.ACTIVE:
            return [
                {
                    title: 'Edit',
                    onClick: () => history.push(routes.service(serviceId))
                },
                { title: 'Copy' },
                // { title: 'Delete', onClick: () => onDelete(serviceId) },
                { title: 'Tag' },
                { title: 'Archive', onClick: () => onArchive(serviceId) }
            ];

        case ServiceStatusesExtendedEnum.ARCHIVED:
            return [
                {
                    title: 'Dearchive',
                    onClick: () => onDearchive(serviceId)
                }
            ];

        default:
            return [];
    }
};
