import { ApiResponse, SignUpBody, SignInBody } from 'src/types';
import { apiClient } from '../client';
import { withDefaultHandlersWithoutResult } from '../helpers/withDefaultHandlers';
import { UserAccountDto, UserOrganizationAccountDto } from '../../types';

export const SIGN_UP_BASE_PATH = '/auth/auth-api';
export const SIGN_IN_BASE_PATH = `${SIGN_UP_BASE_PATH}/signin`;
export const CREATE_USER_PATH = '/auth/auth-api/user-info';

export const apiSignIn = (data: SignInBody) =>
    withDefaultHandlersWithoutResult(
        apiClient.post<ApiResponse<UserAccountDto>>(SIGN_IN_BASE_PATH, data)
    );

export const apiSwitchOrganization = (organizationId: string) =>
    withDefaultHandlersWithoutResult(
        apiClient.post<ApiResponse<UserOrganizationAccountDto>>(
            `${SIGN_IN_BASE_PATH}/${organizationId}`
        )
    );

export const apiSignUp = (data: SignUpBody) =>
    withDefaultHandlersWithoutResult(
        apiClient.post<ApiResponse<string>>(SIGN_UP_BASE_PATH, data)
    );

export const apiCreateUser = (data: SignUpBody) =>
    withDefaultHandlersWithoutResult(
        apiClient.post<ApiResponse<string>>(CREATE_USER_PATH, data)
    );
