import { MerchantCreateOrUpdate } from 'src/types';

const INITIAL_VALUES: MerchantCreateOrUpdate = {
    name: '',

    mid: '',
    dba: '',
    mcc: '',
    rdrToken: '',
    paymentStatus: undefined,
    recordOfPayments: undefined,
    partner: {
        id: null
    },
    agent: {
        id: null
    },
    organizationId: null,
    externalIdentifier: '',
    accountInfo: {
        address: '',
        city: '',
        zip: '',
        country: '',
        address2: '',
        state: '',
        status: 'Pending',
        activateAt: undefined,
        deactivateAt: undefined
    },

    webSite: '',
    timezone: '',

    commissionPaymentInfo: {
        accountHolderType: undefined,
        accountType: undefined,
        bankRouting: '',
        bankAccount: ''
    },

    companyContact: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        faxNumber: ''
    },
    notes: [],
    tokens: [],
    services: []
};

export default INITIAL_VALUES;
