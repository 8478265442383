import { FC, memo } from 'react';
import { Control } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { FormFieldType, MerchantCreateOrUpdate } from 'src/types';
import { Field, FormCard } from '../../Form';
import { bankAccountTypes } from 'src/constants';
import { paymentStatusOptions } from 'src/constants/partners/paymentStatus';
import recordStatusOfPayments from 'src/constants/partners/recordStatusOfPayments';
import { ACCOUNT_HOLDER_TYPES } from 'src/constants/partners/common';

interface Props {
    control: Control<MerchantCreateOrUpdate, object>;
}

const fields: (Field<MerchantCreateOrUpdate> & TextFieldProps)[] = [
    {
        name: 'commissionPaymentInfo.bankAccount',
        label: 'Account Number',
        inputProps: {
            maxLength: 34,
            // TODO: disabled because of Safari autofill, fix later
            // type: 'password',
            autoComplete: 'cc-number'
        },
        xs: 6
    },
    {
        name: 'commissionPaymentInfo.bankRouting',
        label: 'Routing / ABA Number',
        inputProps: {
            maxLength: 9
            // TODO: disabled because of Safari autofill, fix later
            // type: 'password'
            // autoComplete: 'current-password'
        },
        xs: 6
    },
    {
        name: 'commissionPaymentInfo.accountHolderType',
        label: 'Account Holder Type',
        type: FormFieldType.Autocomplete,
        options: Object.values(ACCOUNT_HOLDER_TYPES).map(item => ({
            value: item,
            label: item
        })),
        xs: 6
    },
    {
        name: 'commissionPaymentInfo.accountType',
        label: 'Account Type',
        type: FormFieldType.Autocomplete,
        options: bankAccountTypes,
        required: true,
        xs: 6
    },
    {
        name: 'paymentStatus',
        label: 'Payment Status',
        type: FormFieldType.Autocomplete,
        options: paymentStatusOptions,
        xs: 6
    },
    {
        name: 'recordOfPayments',
        label: 'Record/Status of Payments',
        type: FormFieldType.Autocomplete,
        options: recordStatusOfPayments,
        xs: 6
    },
    {
        name: 'commissionPaymentInfo.taxId',
        label: 'Tax ID (EIN or SSN)',
        type: FormFieldType.Input,
        xs: 6
    }
];

const CommissionPaymentInfoForm: FC<Props> = ({ control }) => (
    <FormCard
        control={control}
        fields={fields}
        title="Banking Information (for Billing)"
        description="The following bank information must be entered correctly as the fees will be withdrawn from this account. If the information is invalid, the merchant will be suspended and no commissions can be paid."
    />
);

export default memo(CommissionPaymentInfoForm);
