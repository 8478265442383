import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessageAction } from 'src/store/actions';
import {
    CommonGetParams,
    ErrorType,
    OrganizationRolesDto,
    Pagination,
    RoleDto,
    RoleInfoDto,
    RolePostDto
} from 'src/types';
import { getApiCommonParams } from 'src/utils/formatters';

import {
    apiGetRoles,
    apiGetRole,
    apiGetOrganizationsRoles,
    apiDeleteRole,
    apiPostRole,
    apiPutRole
} from '../endpoints/roles';

export const useRoles = () => {
    const [roles, setRoles] = useState<RoleDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<Pagination>();

    const dispatch = useDispatch();

    const fetchRoles = (commonParams?: CommonGetParams) => {
        setLoading(true);

        const params = commonParams
            ? getApiCommonParams(commonParams)
            : undefined;

        return apiGetRoles({ params })
            .then(({ result, pagination }) => {
                setRoles(result || []);
                setPagination(pagination);
            })
            .catch((err: ErrorType) => {
                setRoles([]);
                dispatch(
                    showMessageAction({
                        message: `Failed getting roles: ${err}`,
                        type: 'error'
                    })
                );

                throw err.message;
            })
            .finally(() => setLoading(false));
    };

    const deleteRole = (id: string) => {
        apiDeleteRole(id)
            .then(role => {
                dispatch(
                    showMessageAction({
                        message: 'Role deleted successfully',
                        type: 'success'
                    })
                );
                fetchRoles();
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed deleting role: ${err}`,
                        type: 'error'
                    })
                );
            });
    };

    return {
        roles,
        loading,
        pagination,
        fetchRoles,
        deleteRole
    };
};

export const useOrganizationRoles = (userId: string) => {
    const [roles, setRoles] = useState<OrganizationRolesDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<Pagination>();

    const dispatch = useDispatch();

    const fetchRoles = (commonParams?: CommonGetParams) => {
        setLoading(true);

        const params = commonParams
            ? getApiCommonParams(commonParams)
            : undefined;

        return apiGetOrganizationsRoles(userId, { params })
            .then(({ result, pagination }) => {
                setRoles(result || []);
                setPagination(pagination);
            })
            .catch((err: ErrorType) => {
                setRoles([]);
                dispatch(
                    showMessageAction({
                        message: `Failed getting organizations roles: ${err}`,
                        type: 'error'
                    })
                );

                throw err.message;
            })
            .finally(() => setLoading(false));
    };

    return {
        organizationRoles: roles,
        loading,
        pagination,
        fetchOrganizationRoles: fetchRoles
    };
};

export const useRole = () => {
    const dispatch = useDispatch();

    const [role, setRole] = useState<RoleInfoDto>();

    const [loading, setLoading] = useState<ErrorType | null>(null);

    let isMounted = true;

    const fetchRole = (id: string) => {
        setLoading(true);

        apiGetRole(id)
            .then(role => {
                setRole(role);
            })
            .catch((err: ErrorType) => {
                setRole(undefined);
                dispatch(
                    showMessageAction({
                        message: `Failed getting role: ${err}`,
                        type: 'error'
                    })
                );

                throw err;
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });

        return () => {
            isMounted = false;
        };
    };

    const createRole = (body: RolePostDto, successCalback?: () => void) =>
        apiPostRole(body)
            .then(role => {
                dispatch(
                    showMessageAction({
                        message: 'Role saved successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback();
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed saving role: ${err}`,
                        type: 'error'
                    })
                );
            });

    const updateRole = (
        id: string,
        body: RolePostDto,
        successCalback?: () => void
    ) =>
        apiPutRole(id, body)
            .then(role => {
                dispatch(
                    showMessageAction({
                        message: 'Role changed successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback();
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed changing role: ${err}`,
                        type: 'error'
                    })
                );
            });
    return {
        setRole,
        role,
        fetchRole,
        loading,
        createRole,
        updateRole
    };
};
