import React, { FC, memo, useCallback, useMemo } from 'react';
import { AutocompleteOption, FormCard } from '../../Form';
import { Control } from 'react-hook-form';
import { FormFieldType, MerchantCreateOrUpdate } from '../../../types';
import { Modal } from 'src/components/Modal';
import { CreateOrganizationForm } from '../../Organization/Forms/CreateOrganizationForm';
import { reverse } from 'lodash';
import { UseCreateOrgModal } from '../../Organization/hooks/UseCreateOrgModal';

interface Props {
    control: Control<MerchantCreateOrUpdate, object>;
    partnersOptions: AutocompleteOption[];
    agentsOptions: AutocompleteOption[];
    organizationsOptions: AutocompleteOption[];
}

// names are temporary because back is not ready yet
const generateFields = (
    onAddOption: (inputValue: string) => void,
    partners?: AutocompleteOption[],
    agents?: AutocompleteOption[],
    organizations?: AutocompleteOption[]
) => [
    {
        name: 'partner.id',
        required: true,
        label: 'Partner',
        type: FormFieldType.Autocomplete,
        options: partners || [],
        xs: 4
    },
    {
        name: 'agent.id',
        label: 'Agent',
        type: FormFieldType.Autocomplete,
        options: agents || [],
        xs: 4
    },
    {
        name: 'organizationId',
        label: 'Organization',
        type: FormFieldType.AutocompleteWithAdd,
        withAddOption: true,
        onAddOption,
        options: reverse(Array.from(organizations || [])),
        xs: 4
    }
];

const MerchantPartnersForm: FC<Props> = ({
    control,
    partnersOptions,
    agentsOptions,
    organizationsOptions
}) => {
    const { modalState, updateModalState, closeModal } = UseCreateOrgModal();

    const addOptionClickHandler = useCallback(
        (inputValue: string) => {
            updateModalState({ show: true, inputValue });
        },
        [updateModalState]
    );

    const fieldsToDisplay = useMemo(
        () =>
            generateFields(
                addOptionClickHandler,
                partnersOptions,
                agentsOptions,
                organizationsOptions
            ),
        [
            addOptionClickHandler,
            partnersOptions,
            agentsOptions,
            organizationsOptions
        ]
    );

    const handleSuccess = () => {
        closeModal();
    };

    return (
        <>
            <FormCard
                control={control}
                fields={fieldsToDisplay}
                title="Partners"
            />
            <Modal open={modalState.show}>
                <CreateOrganizationForm
                    organizationName={modalState.inputValue}
                    onCancel={closeModal}
                    onSuccess={handleSuccess}
                />
            </Modal>
        </>
    );
};

export default memo(MerchantPartnersForm);
