import { BrandableServiceAuthFeaturesUsersModelsGenerateEmailConfirmationLinkResult } from 'src/api/swagger/authTypes';

export interface IEmailConfirmationLinkResult
    extends BrandableServiceAuthFeaturesUsersModelsGenerateEmailConfirmationLinkResult {}

export interface RoleDto {
    id: string;
    name: string;
    type?: RoleType;
}

export enum RoleType {
    System = 'System',
    Custom = 'Custom'
}

export interface RoleInfoDto {
    id: string;
    name: string;
    description: string;
}

export interface OrganizationRolesDto {
    id: string;
    name: string;
    roles: {
        id: string;
        type: RoleType;
        name: string;
    }[];
}

export interface RolePutDto {
    id?: string;
    name: string;
    description: string;
    permissionSet?: string;
}

export interface RolePostDto {
    id?: string;
    name: string;
    description: string;
}
