import { FC, useState } from 'react';
import MuiButton from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { styled } from '@mui/system';

export interface DropdownMenuItemProps {
    title: string;
    onClick?: () => void;
}
interface Props {
    title: string;
    menuItems: DropdownMenuItemProps[];
}

const Button = styled(MuiButton)`
    width: 164px;
    justify-content: space-between;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    text-transform: none;
    font-weight: normal;
    font-size: 1em;
    height: 39px;
`;

export const DropdownMenu: FC<Props> = ({ title, menuItems }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (callback?: () => void) => () => {
        if (callback) {
            callback();
        }
        handleClose();
    };

    return (
        <div>
            <Button
                size="medium"
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                color="inherit"
            >
                {title}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {menuItems.map(({ title, onClick }) => (
                    <MenuItem
                        onClick={handleMenuItemClick(onClick)}
                        key={title}
                    >
                        {title}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
