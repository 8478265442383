import {
    ApiResponse,
    BillingModelDto,
    BillingModelFullDto,
    CommonApiGetParams,
    BillingModelPost
} from 'src/types';
import { apiClient } from '../client';
import {
    withDefaultHandlers,
    withDefaultHandlersPagination
} from '../helpers/withDefaultHandlers';

const BASE_PATH = '/payment/BillingModel-api/BillingModel-info';

export const apiGetBillingModels = ({
    params
}: {
    params?: CommonApiGetParams;
}) =>
    withDefaultHandlersPagination(
        apiClient.get<ApiResponse<BillingModelDto[]>>(BASE_PATH, params)
    );

export const apiGetBillingModel = (id: string) =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<BillingModelFullDto>>(`${BASE_PATH}/${id}`)
    );

export const apiPostBillingModel = (billingModel: BillingModelPost) =>
    withDefaultHandlers(
        apiClient.post<ApiResponse<{ id: string }>>(BASE_PATH, billingModel)
    );

export const apiPutBillingModel = (
    id: string,
    billingModel: BillingModelPost
) =>
    withDefaultHandlers(
        apiClient.put<ApiResponse<BillingModelDto>>(BASE_PATH, billingModel, {
            params: { id }
        })
    );

export const apiDeleteBillingModel = (id: string) =>
    withDefaultHandlers(
        apiClient.delete<ApiResponse<BillingModelDto>>(BASE_PATH, {
            params: { id }
        })
    );
