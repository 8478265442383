export const SET_USER_AUTHORIZED_ROUTES = 'SET_USER_AUTHORIZED_ROUTES';
export const SET_USER_UNAUTHORIZED_ROUTES = 'SET_USER_UNAUTHORIZED_ROUTES';

export type UserRoute = {
    url: string;
    title: string;
    component: any;
    permission: number;
};

export type UserRoutesActionTypes =
    | SetUserAuthorizedRoutes
    | SetUserUnauthorizedRoutes;

export type SetUserAuthorizedRoutes = {
    type: typeof SET_USER_AUTHORIZED_ROUTES;
    payload: UserRoute[];
};

export type SetUserUnauthorizedRoutes = {
    type: typeof SET_USER_UNAUTHORIZED_ROUTES;
    payload: UserRoute[];
};

export const setAuthorizedRoutesAction = (
    payload: UserRoute[]
): SetUserAuthorizedRoutes => ({
    type: SET_USER_AUTHORIZED_ROUTES,
    payload: payload
});

export const setUnauthorizedRoutesAction = (
    payload: UserRoute[]
): SetUserUnauthorizedRoutes => ({
    type: SET_USER_UNAUTHORIZED_ROUTES,
    payload: payload
});
