import { Pagination, CategoryDto } from 'src/types';
import {
    CategoriesActionTypes,
    SET_LOADING,
    SET_PAGINATION,
    SET_CATEGORIES
} from '../actions/categories';

export interface CategoriesState {
    categories: Record<string, CategoryDto>;
    loading: boolean;
    pagination: Pagination | undefined;
}
const initialState: CategoriesState = {
    categories: {},
    loading: false,
    pagination: undefined
};

export const categories = (
    state: CategoriesState = initialState,
    action: CategoriesActionTypes
): CategoriesState => {
    switch (action.type) {
        case SET_CATEGORIES:
            return {
                ...state,
                categories:
                    action.payload?.reduce<Record<string, CategoryDto>>(
                        (prev, curr) => ({
                            ...prev,
                            [curr.id]: curr
                        }),
                        {}
                    ) || {},
                loading: false
            };
        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
