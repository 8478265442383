import {
    Button,
    Card,
    CardContent,
    Stack,
    Link,
    TextField,
    Typography,
    Box,
    useTheme
} from '@mui/material';
import { useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from 'src/api/hooks';
import { routes } from 'src/constants';
import { IOrganizationDto } from 'src/types';
import { getFilteredOrganizations } from 'src/utils/organizations';

export const Organizations = () => {
    const theme = useTheme();
    const [searchString, setSearchString] = useState('');
    const [hovered, setHovered] = useState<null | string>(null);
    const history = useHistory();
    const { setSelectedOrganization, organizations, selectedOrganization } =
        useAuth();

    const createOrganization = () => {
        history.push(routes.organizationNew);
    };

    const selectOrganization = (organization: IOrganizationDto) => {
        setSelectedOrganization(organization);
    };

    const filteredOrganizations = useMemo(
        () => getFilteredOrganizations(organizations, searchString),
        [organizations, searchString]
    );

    const sortedOrganizations = useMemo(
        () =>
            filteredOrganizations.sort(({ id }) =>
                id === selectedOrganization?.id ? -1 : 1
            ),
        [filteredOrganizations, selectedOrganization]
    );

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: '15vh' }}
        >
            <Card sx={{ width: '480px' }}>
                <CardContent>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ marginBottom: 10 }}
                    >
                        Organizations
                    </Typography>
                    <Button
                        variant="contained"
                        style={{ width: '100%', marginBottom: '20px' }}
                        onClick={createOrganization}
                    >
                        CREATE ANOTHER ORGANIZATION
                    </Button>
                    <Stack direction="row" spacing={1}>
                        <TextField
                            variant="standard"
                            size="small"
                            label="Search Organizations"
                            sx={{ width: '100%', marginBottom: 2 }}
                            onChange={e => setSearchString(e.target.value)}
                        />
                    </Stack>
                    <Box style={{ height: 300, overflowY: 'scroll' }}>
                        <Stack
                            direction="column"
                            spacing={1}
                            style={{
                                minHeight: 20
                            }}
                        >
                            {sortedOrganizations.map(
                                ({ id, name }: IOrganizationDto) => (
                                    <Card
                                        key={id}
                                        sx={{
                                            width: '100%',
                                            border: '1px solid rgba(0, 0, 0, 0.08)',
                                            color: 'black',
                                            borderStyle: 'none',
                                            borderColor: 'initial',
                                            borderWidth: '2px',
                                            backgroundColor:
                                                selectedOrganization?.id === id
                                                    ? '#f6f7fb'
                                                    : 'initial',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                borderColor:
                                                    theme.palette.primary.main,
                                                borderStyle: 'solid'
                                            }
                                        }}
                                        onClick={() =>
                                            selectOrganization({ id, name })
                                        }
                                    >
                                        <CardContent
                                            style={{ paddingBottom: 12 }}
                                        >
                                            <div
                                                style={{
                                                    fontSize: 20,
                                                    marginBottom: 16
                                                }}
                                            >
                                                {name}
                                            </div>
                                            <Link
                                                style={{
                                                    fontSize: 12,
                                                    textDecoration: 'none'
                                                }}
                                            >
                                                {id}
                                            </Link>
                                        </CardContent>
                                    </Card>
                                )
                            )}
                        </Stack>
                    </Box>
                </CardContent>
            </Card>
        </Stack>
    );
};
