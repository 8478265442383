import { useHistory } from 'react-router';
import { routes } from 'src/constants';
import { CreateOrganizationForm } from '../../components/Organization/Forms/CreateOrganizationForm';

export const CreateOrganization = () => {
    const history = useHistory();

    const handleCancel = () => {
        history.push(routes.dashboard);
    };

    const handleSuccess = () => {
        history.push(routes.dashboard);
    };

    return (
        <CreateOrganizationForm
            stackSx={{ mt: 37.5 }}
            selectOrgAfterCreation={true}
            onCancel={handleCancel}
            onSuccess={handleSuccess}
        />
    );
};
