export const timezones = [
    {
        label: '(UTC-12:00) International Date Line West',
        value: '(UTC-12:00) International Date Line West'
    },
    {
        label: '(UTC-11:00) Coordinated Universal Time-11',
        value: '(UTC-11:00) Coordinated Universal Time-11'
    },
    { label: '(UTC-10:00) Hawaii', value: '(UTC-10:00) Hawaii' },
    { label: '(UTC-09:00) Alaska', value: '(UTC-09:00) Alaska' },
    {
        label: '(UTC-08:00) Baja California',
        value: '(UTC-08:00) Baja California'
    },
    {
        label: '(UTC-07:00) Pacific Time (US & Canada)',
        value: '(UTC-07:00) Pacific Time (US & Canada)'
    },
    {
        label: '(UTC-08:00) Pacific Time (US & Canada)',
        value: '(UTC-08:00) Pacific Time (US & Canada)'
    },
    { label: '(UTC-07:00) Arizona', value: '(UTC-07:00) Arizona' },
    {
        label: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
        value: '(UTC-07:00) Chihuahua, La Paz, Mazatlan'
    },
    {
        label: '(UTC-07:00) Mountain Time (US & Canada)',
        value: '(UTC-07:00) Mountain Time (US & Canada)'
    },
    {
        label: '(UTC-06:00) Central America',
        value: '(UTC-06:00) Central America'
    },
    {
        label: '(UTC-06:00) Central Time (US & Canada)',
        value: '(UTC-06:00) Central Time (US & Canada)'
    },
    {
        label: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
        value: '(UTC-06:00) Guadalajara, Mexico City, Monterrey'
    },
    { label: '(UTC-06:00) Saskatchewan', value: '(UTC-06:00) Saskatchewan' },
    {
        label: '(UTC-05:00) Bogota, Lima, Quito',
        value: '(UTC-05:00) Bogota, Lima, Quito'
    },
    {
        label: '(UTC-05:00) Eastern Time (US & Canada)',
        value: '(UTC-05:00) Eastern Time (US & Canada)'
    },
    {
        label: '(UTC-05:00) Indiana (East)',
        value: '(UTC-05:00) Indiana (East)'
    },
    { label: '(UTC-04:30) Caracas', value: '(UTC-04:30) Caracas' },
    { label: '(UTC-04:00) Asuncion', value: '(UTC-04:00) Asuncion' },
    {
        label: '(UTC-04:00) Atlantic Time (Canada)',
        value: '(UTC-04:00) Atlantic Time (Canada)'
    },
    { label: '(UTC-04:00) Cuiaba', value: '(UTC-04:00) Cuiaba' },
    {
        label: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
        value: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan'
    },
    { label: '(UTC-04:00) Santiago', value: '(UTC-04:00) Santiago' },
    { label: '(UTC-03:30) Newfoundland', value: '(UTC-03:30) Newfoundland' },
    { label: '(UTC-03:00) Brasilia', value: '(UTC-03:00) Brasilia' },
    { label: '(UTC-03:00) Buenos Aires', value: '(UTC-03:00) Buenos Aires' },
    {
        label: '(UTC-03:00) Cayenne, Fortaleza',
        value: '(UTC-03:00) Cayenne, Fortaleza'
    },
    { label: '(UTC-03:00) Greenland', value: '(UTC-03:00) Greenland' },
    { label: '(UTC-03:00) Montevideo', value: '(UTC-03:00) Montevideo' },
    { label: '(UTC-03:00) Salvador', value: '(UTC-03:00) Salvador' },
    {
        label: '(UTC-02:00) Coordinated Universal Time-02',
        value: '(UTC-02:00) Coordinated Universal Time-02'
    },
    {
        label: '(UTC-02:00) Mid-Atlantic - Old',
        value: '(UTC-02:00) Mid-Atlantic - Old'
    },
    { label: '(UTC-01:00) Azores', value: '(UTC-01:00) Azores' },
    {
        label: '(UTC-01:00) Cape Verde Is.',
        value: '(UTC-01:00) Cape Verde Is.'
    },
    { label: '(UTC) Casablanca', value: '(UTC) Casablanca' },
    {
        label: '(UTC) Coordinated Universal Time',
        value: '(UTC) Coordinated Universal Time'
    },
    { label: '(UTC) Edinburgh, London', value: '(UTC) Edinburgh, London' },
    {
        label: '(UTC+01:00) Edinburgh, London',
        value: '(UTC+01:00) Edinburgh, London'
    },
    { label: '(UTC) Dublin, Lisbon', value: '(UTC) Dublin, Lisbon' },
    { label: '(UTC) Monrovia, Reykjavik', value: '(UTC) Monrovia, Reykjavik' },
    {
        label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
        value: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
    },
    {
        label: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
        value: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague'
    },
    {
        label: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
        value: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris'
    },
    {
        label: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
        value: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb'
    },
    {
        label: '(UTC+01:00) West Central Africa',
        value: '(UTC+01:00) West Central Africa'
    },
    { label: '(UTC+01:00) Windhoek', value: '(UTC+01:00) Windhoek' },
    {
        label: '(UTC+02:00) Athens, Bucharest',
        value: '(UTC+02:00) Athens, Bucharest'
    },
    { label: '(UTC+02:00) Beirut', value: '(UTC+02:00) Beirut' },
    { label: '(UTC+02:00) Cairo', value: '(UTC+02:00) Cairo' },
    { label: '(UTC+02:00) Damascus', value: '(UTC+02:00) Damascus' },
    { label: '(UTC+02:00) E. Europe', value: '(UTC+02:00) E. Europe' },
    {
        label: '(UTC+02:00) Harare, Pretoria',
        value: '(UTC+02:00) Harare, Pretoria'
    },
    {
        label: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
        value: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'
    },
    { label: '(UTC+03:00) Istanbul', value: '(UTC+03:00) Istanbul' },
    { label: '(UTC+02:00) Jerusalem', value: '(UTC+02:00) Jerusalem' },
    { label: '(UTC+02:00) Tripoli', value: '(UTC+02:00) Tripoli' },
    { label: '(UTC+03:00) Amman', value: '(UTC+03:00) Amman' },
    { label: '(UTC+03:00) Baghdad', value: '(UTC+03:00) Baghdad' },
    { label: '(UTC+02:00) Kaliningrad', value: '(UTC+02:00) Kaliningrad' },
    {
        label: '(UTC+03:00) Kuwait, Riyadh',
        value: '(UTC+03:00) Kuwait, Riyadh'
    },
    { label: '(UTC+03:00) Nairobi', value: '(UTC+03:00) Nairobi' },
    {
        label: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
        value: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk'
    },
    {
        label: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
        value: '(UTC+04:00) Samara, Ulyanovsk, Saratov'
    },
    { label: '(UTC+03:30) Tehran', value: '(UTC+03:30) Tehran' },
    {
        label: '(UTC+04:00) Abu Dhabi, Muscat',
        value: '(UTC+04:00) Abu Dhabi, Muscat'
    },
    { label: '(UTC+04:00) Baku', value: '(UTC+04:00) Baku' },
    { label: '(UTC+04:00) Port Louis', value: '(UTC+04:00) Port Louis' },
    { label: '(UTC+04:00) Tbilisi', value: '(UTC+04:00) Tbilisi' },
    { label: '(UTC+04:00) Yerevan', value: '(UTC+04:00) Yerevan' },
    { label: '(UTC+04:30) Kabul', value: '(UTC+04:30) Kabul' },
    {
        label: '(UTC+05:00) Ashgabat, Tashkent',
        value: '(UTC+05:00) Ashgabat, Tashkent'
    },
    { label: '(UTC+05:00) Yekaterinburg', value: '(UTC+05:00) Yekaterinburg' },
    {
        label: '(UTC+05:00) Islamabad, Karachi',
        value: '(UTC+05:00) Islamabad, Karachi'
    },
    {
        label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
        value: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi'
    },
    {
        label: '(UTC+05:30) Sri Jayawardenepura',
        value: '(UTC+05:30) Sri Jayawardenepura'
    },
    { label: '(UTC+05:45) Kathmandu', value: '(UTC+05:45) Kathmandu' },
    {
        label: '(UTC+06:00) Nur-Sultan (Astana)',
        value: '(UTC+06:00) Nur-Sultan (Astana)'
    },
    { label: '(UTC+06:00) Dhaka', value: '(UTC+06:00) Dhaka' },
    {
        label: '(UTC+06:30) Yangon (Rangoon)',
        value: '(UTC+06:30) Yangon (Rangoon)'
    },
    {
        label: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
        value: '(UTC+07:00) Bangkok, Hanoi, Jakarta'
    },
    { label: '(UTC+07:00) Novosibirsk', value: '(UTC+07:00) Novosibirsk' },
    {
        label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
        value: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi'
    },
    { label: '(UTC+08:00) Krasnoyarsk', value: '(UTC+08:00) Krasnoyarsk' },
    {
        label: '(UTC+08:00) Kuala Lumpur, Singapore',
        value: '(UTC+08:00) Kuala Lumpur, Singapore'
    },
    { label: '(UTC+08:00) Perth', value: '(UTC+08:00) Perth' },
    { label: '(UTC+08:00) Taipei', value: '(UTC+08:00) Taipei' },
    { label: '(UTC+08:00) Ulaanbaatar', value: '(UTC+08:00) Ulaanbaatar' },
    { label: '(UTC+08:00) Irkutsk', value: '(UTC+08:00) Irkutsk' },
    {
        label: '(UTC+09:00) Osaka, Sapporo, Tokyo',
        value: '(UTC+09:00) Osaka, Sapporo, Tokyo'
    },
    { label: '(UTC+09:00) Seoul', value: '(UTC+09:00) Seoul' },
    { label: '(UTC+09:30) Adelaide', value: '(UTC+09:30) Adelaide' },
    { label: '(UTC+09:30) Darwin', value: '(UTC+09:30) Darwin' },
    { label: '(UTC+10:00) Brisbane', value: '(UTC+10:00) Brisbane' },
    {
        label: '(UTC+10:00) Canberra, Melbourne, Sydney',
        value: '(UTC+10:00) Canberra, Melbourne, Sydney'
    },
    {
        label: '(UTC+10:00) Guam, Port Moresby',
        value: '(UTC+10:00) Guam, Port Moresby'
    },
    { label: '(UTC+10:00) Hobart', value: '(UTC+10:00) Hobart' },
    { label: '(UTC+09:00) Yakutsk', value: '(UTC+09:00) Yakutsk' },
    {
        label: '(UTC+11:00) Solomon Is., New Caledonia',
        value: '(UTC+11:00) Solomon Is., New Caledonia'
    },
    { label: '(UTC+11:00) Vladivostok', value: '(UTC+11:00) Vladivostok' },
    {
        label: '(UTC+12:00) Auckland, Wellington',
        value: '(UTC+12:00) Auckland, Wellington'
    },
    {
        label: '(UTC+12:00) Coordinated Universal Time+12',
        value: '(UTC+12:00) Coordinated Universal Time+12'
    },
    { label: '(UTC+12:00) Fiji', value: '(UTC+12:00) Fiji' },
    { label: '(UTC+12:00) Magadan', value: '(UTC+12:00) Magadan' },
    {
        label: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
        value: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old'
    },
    { label: "(UTC+13:00) Nuku'alofa", value: "(UTC+13:00) Nuku'alofa" },
    { label: '(UTC+13:00) Samoa', value: '(UTC+13:00) Samoa' }
];
