import { Card, CardContent, Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { menuItems } from 'src/constants';
import { usePermissions } from '../../api/hooks/usePermissions';

export const Dashboard = () => {
    const history = useHistory();
    const { permissions } = usePermissions();

    return (
        <Stack direction={'row'} flexWrap={'wrap'}>
            {menuItems
                .filter(m => permissions.some(p => p === m.permission))
                .map(({ title, url }, i) => (
                    <Card
                        key={title}
                        style={{
                            marginRight: 20,
                            marginBottom: 20,
                            width: 400,
                            height: 200,
                            cursor: 'pointer',
                            padding: 15
                        }}
                        onClick={() => history.push(url)}
                    >
                        <CardContent>
                            <Typography variant="h6" component="div">
                                {title}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
        </Stack>
    );
};
