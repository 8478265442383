import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Stack } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { useUsers } from 'src/api/hooks';
import { PAGE_SIZE, routes } from 'src/constants';
import { Pagination } from 'src/components/Pagination';
import { UsersTable } from 'src/components/Users/UsersTable';
import { useHistory } from 'react-router';

export const ManagementUsers = () => {
    const { fetchUsers, users, loading, pagination } = useUsers();
    const history = useHistory();

    const { totalPages } = pagination || {};
    const [pageNumber, setPageNumber] = useState(0);

    const getUsers = (pageNumber: number, innerSearchString?: string) => {
        fetchUsers({
            pageNumber: `${pageNumber}`,
            pageSize: PAGE_SIZE
        });
    };

    useEffect(() => {
        getUsers(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePageChange = (e: ChangeEvent<unknown>, page: number) => {
        setPageNumber(page - 1);
        getUsers(page - 1);
    };

    return (
        <Stack spacing={2}>
            <Stack justifyContent="flex-end" direction="row">
                <Button
                    variant="contained"
                    onClick={() => history.push(routes.managementUserNew)}
                >
                    Add User
                </Button>
            </Stack>
            <UsersTable data={Object.values(users)} loading={loading} />
            {!isEmpty(users) && (
                <Pagination
                    page={pageNumber + 1}
                    onChange={handlePageChange}
                    count={totalPages}
                />
            )}
        </Stack>
    );
};
