import { AppBar as MuiAppBar, styled } from '@mui/material';

const drawerWidth = 240;

export const AppBar = styled<any>(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => {
    const transitions = theme.transitions;
    const zIndex = theme.zIndex;
    return {
        zIndex: zIndex.drawer + 1,
        transition: transitions.create(['width', 'margin'], {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen
        }),
        backgroundColor: theme.palette.darkBackground,
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: transitions.create(['width', 'margin'], {
                easing: transitions.easing.sharp,
                duration: transitions.duration.enteringScreen
            })
        })
    };
});
