import { FC, useState, Dispatch, SetStateAction } from 'react';
import { Control, SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';

import { FilterFormType, AgentFilter } from 'src/types';
import { getAgentsFilterParam } from 'src/utils/formatters';
import { Modal } from '../Modal';
import { FilterForm } from './Forms/FilterForm';

interface Props {
    onClose: () => void;
    open: boolean;
    applyFilter: (filter: AgentFilter) => Promise<void>;
    onCancel: () => void;
    handleSubmit: UseFormHandleSubmit<FilterFormType>;
    control: Control<FilterFormType, object>;
    setFilterFormState: Dispatch<SetStateAction<FilterFormType | undefined>>;
}

export const AgentsFiltersModal: FC<Props> = ({
    onClose,
    open,
    applyFilter,
    onCancel,
    control,
    handleSubmit,
    setFilterFormState
}) => {
    const [loading, setLoading] = useState(false);

    const onSubmit: SubmitHandler<FilterFormType> = data => {
        setFilterFormState(cloneDeep(data));
        const newData: any = cloneDeep(data);

        const filterString = getAgentsFilterParam(newData);

        setLoading(true);
        applyFilter(filterString)
            .then(onClose)
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            onOk={handleSubmit(onSubmit)}
            onCancel={onCancel}
            okText="Apply filters"
            loading={loading}
        >
            <FilterForm control={control} />
        </Modal>
    );
};
