import { PermissionType } from '../../types/permissions';
import {
    PermissionsActionTypes,
    SET_USER_PERMISSIONS
} from '../actions/permissions';

export interface PermissionsState {
    permissions: PermissionType[];
}

export const permissions = (
    state: PermissionsState = {
        permissions: []
    },
    action: PermissionsActionTypes
): PermissionsState => {
    switch (action.type) {
        case SET_USER_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload
            };
        default:
            return state;
    }
};
