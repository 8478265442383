import { Pagination, BillingModelDto } from 'src/types';

export const SET_BILLING_MODELS = 'SET_BILLING_MODELS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_LOADING = 'SET_LOADING';

export type SetBillingModels = {
    type: typeof SET_BILLING_MODELS;
    payload: BillingModelDto[];
};

export type SetPagination = {
    type: typeof SET_PAGINATION;
    payload?: Pagination;
};

export type SetLoading = {
    type: typeof SET_LOADING;
};

export type BillingModelsActionTypes =
    | SetBillingModels
    | SetLoading
    | SetPagination;

export const setBillingModelsAction = (
    billingModels: BillingModelDto[]
): SetBillingModels => ({
    type: SET_BILLING_MODELS,
    payload: billingModels
});

export const setPaginationAction = (
    pagination?: Pagination
): SetPagination => ({
    type: SET_PAGINATION,
    payload: pagination
});

export const setLoadingAction = () => ({
    type: SET_LOADING
});
