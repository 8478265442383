import { Field } from 'src/components/Form';
import { extendedBillingModelTypeName } from 'src/constants';
import {
    BillingModelDto,
    ColumnType,
    ExtendedBillingModelType,
    FormFieldType,
    ServiceDto
} from 'src/types';
import { ServiceStatusesEnum } from 'src/types/services';
import { fCurrency } from 'src/utils/formatNumber';

export const tableFields: ColumnType<ServiceDto>[] = [
    {
        dataIndex: 'name',
        title: 'Service'
    },
    {
        dataIndex: 'cost',
        title: 'Cost',
        render: value => fCurrency(value)
    },
    {
        dataIndex: 'msrp',
        title: 'MSRP',
        render: value => fCurrency(value)
    },
    {
        dataIndex: 'associatedId',
        title: 'Associated Id #'
    },
    {
        dataIndex: 'billingModelId',
        title: 'Billing Model',
        render: (billingModelId: ExtendedBillingModelType) =>
            extendedBillingModelTypeName[billingModelId] || billingModelId
    },
    {
        dataIndex: 'status',
        title: 'Status'
    },
    {
        dataIndex: 'partnerCommission',
        title: 'Partner Commission',
        render: value => fCurrency(value)
    }
];

// MODAL FIELDS
export const generateModalInputs = (
    services: Record<string, ServiceDto>,
    billingModels: Record<string, BillingModelDto>
): Field<ServiceDto>[] => [
    {
        name: 'serviceId',
        label: 'Service',
        type: FormFieldType.Autocomplete,
        options: Object.values(services).map(service => ({
            label: service.name,
            value: service.id
        })),

        required: true
    },
    {
        name: 'cost',
        label: 'Cost',
        type: FormFieldType.Price,
        required: true
    },
    {
        name: 'msrp',
        label: 'MSRP',
        type: FormFieldType.Price,
        required: true
    },
    {
        name: 'associatedId',
        label: 'Associated Id #',
        type: FormFieldType.Input,
        required: true
    },
    {
        name: 'billingModelId',
        label: 'Billing Model',
        type: FormFieldType.Autocomplete,
        options: Object.values(billingModels).map(value => ({
            label: extendedBillingModelTypeName[value.type] || value.type,
            value: value.type
        })),
        required: true
    },
    {
        name: 'status',
        label: 'Status',
        type: FormFieldType.Autocomplete,
        options: Object.values(ServiceStatusesEnum).map(value => ({
            label: value,
            value
        })),
        required: true
    },
    {
        name: 'partnerCommission',
        label: 'Partner Commission',
        type: FormFieldType.Price,
        required: true
    }
];
