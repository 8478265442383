import { CategoryShorDto } from 'src/types';

export const SET_SHORT_CATEGORIES = 'SET_SHORT_CATEGORIES';
export const SET_LOADING = 'SET_LOADING';

export type SetShortCategories = {
    type: typeof SET_SHORT_CATEGORIES;
    payload: CategoryShorDto[];
};

export type SetLoading = {
    type: typeof SET_LOADING;
};

export type ShortCategoriesActionTypes = SetShortCategories | SetLoading;

export const setShortCategoriesAction = (
    categories: CategoryShorDto[]
): SetShortCategories => ({
    type: SET_SHORT_CATEGORIES,
    payload: categories
});

export const setLoadingAction = () => ({
    type: SET_LOADING
});
