import React, { memo } from 'react';
import { Control, Controller, Path } from 'react-hook-form';
import NotesCard from 'src/components/Cards/NotesCard/NotesCard';
import { INote } from 'src/types/partners/common';
import {
    IAgentsApiPostPut,
    MerchantCreateOrUpdate,
    PartnerCreateEditDto
} from 'src/types';
import dayjs from 'dayjs';
import IMidEditForm from 'src/pages/MIDs/types';
import { useDispatch } from 'react-redux';
import { showMessageAction } from '../../../store/actions';

const ControlledNotesCard = ({
    name,
    control
}: {
    name:
        | Path<MerchantCreateOrUpdate>
        | Path<PartnerCreateEditDto>
        | Path<IAgentsApiPostPut>
        | Path<IMidEditForm>;
    control: Control<any>;
}) => {
    const dispatch = useDispatch();
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                const checkDuplicate = (note: INote) => {};
                const handleAddNewNote = (newNote: string) => {
                    const note: INote = {
                        note: newNote,
                        created: dayjs().utc().format()
                    };

                    if (Array.isArray(value)) {
                        if (value.some(item => item.note === note.note)) {
                            dispatch(
                                showMessageAction({
                                    type: 'warning',
                                    message:
                                        'Note with the same text already exists'
                                })
                            );
                            return;
                        }
                        onChange([...value, note]);
                    } else {
                        onChange([note]);
                    }
                };

                const handleDelete = (note: INote) => {
                    if (Array.isArray(value)) {
                        onChange(
                            value.filter(
                                ({ note: oldNote }) => oldNote !== note.note
                            )
                        );
                    }
                };

                const handleEdit = (newNote: INote, prevNote: INote) => {
                    if (Array.isArray(value)) {
                        if (value.some(item => item.note === newNote.note)) {
                            dispatch(
                                showMessageAction({
                                    type: 'warning',
                                    message:
                                        'Note with the same text already exists'
                                })
                            );
                            return;
                        }
                        onChange(
                            value.map(currentNote =>
                                prevNote.note === currentNote.note
                                    ? newNote
                                    : currentNote
                            )
                        );
                    }
                };

                return (
                    <NotesCard
                        notes={value}
                        onAdd={handleAddNewNote}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                    />
                );
            }}
        />
    );
};

export default memo(ControlledNotesCard);
