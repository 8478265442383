import { Pagination } from 'src/types';
import { IAlert } from 'src/types/alerts';

export const SET_VERIFI_ALERTS = 'SET_VERIFI_ALERTS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_LOADING = 'SET_LOADING';

export type SetVerifiAlerts = {
    type: typeof SET_VERIFI_ALERTS;
    payload: IAlert[];
};

export type SetPagination = {
    type: typeof SET_PAGINATION;
    payload?: Pagination;
};

export type SetLoading = {
    type: typeof SET_LOADING;
    payload: boolean;
};

export type VerifiAlertsActionTypes =
    | SetVerifiAlerts
    | SetLoading
    | SetPagination;

export const setVerifiAlertsAction = (
    verifiAlerts: IAlert[]
): SetVerifiAlerts => ({
    type: SET_VERIFI_ALERTS,
    payload: verifiAlerts
});

export const setPaginationAction = (
    pagination?: Pagination
): SetPagination => ({
    type: SET_PAGINATION,
    payload: pagination
});

export const setLoadingAction = (loading: boolean) => ({
    type: SET_LOADING,
    payload: loading
});
