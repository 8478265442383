import React, { useMemo } from 'react';
import { IAgentsApiPostPut, FormFieldType } from 'src/types';
import { statusesOfPaymentsOptions } from 'src/constants';
import { Field, FormCard } from 'src/components/Agents/Forms/FormCard';
import { Control } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';

const StatusOfPaymentsForm = ({
    control
}: {
    control: Control<IAgentsApiPostPut>;
}) => {
    const fields: (Field & TextFieldProps)[] = useMemo(
        () => [
            {
                name: 'outgoingStatusOfPayment',
                label: 'Record/Status of Incoming payments',
                type: FormFieldType.Autocomplete,
                options: statusesOfPaymentsOptions,
                xs: 4
            },
            {
                name: 'incomingStatusOfPayment',
                label: 'Record/Status of Outgoing payments',
                type: FormFieldType.Autocomplete,
                options: statusesOfPaymentsOptions,
                xs: 4
            },
            {
                xs: 2
            }
        ],
        []
    );

    return (
        <FormCard
            control={control}
            fields={fields}
            title="Status of payments"
        />
    );
};

export default StatusOfPaymentsForm;
