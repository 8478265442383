import React, { FC } from 'react';

import {
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Tooltip
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useHistory } from 'react-router';
import { menuItems } from 'src/constants';
import { Drawer } from './styles';
import { useTheme } from '@mui/system';
import { getUserPermissions } from '../../store/selectors';
import { useSelector } from 'react-redux';

interface Props {
    open: boolean;
    toggleDrawer: () => void;
}

export const NavMenu: FC<Props> = ({ open, toggleDrawer }) => {
    const history = useHistory();
    const handleRoute = (url: string) => () => history.push(url);
    const { permissions } = useSelector(getUserPermissions);

    const theme = useTheme();

    return (
        <Drawer variant="permanent" open={open}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1]
                }}
            >
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <List
                sx={{
                    pt: 3
                }}
            >
                {menuItems
                    .filter(m => permissions.some(p => p === m.permission))
                    .map(({ url, title, Icon }) => (
                        <React.Fragment key={url}>
                            <Tooltip
                                disableHoverListener={open}
                                title={title}
                                placement="right"
                            >
                                <ListItem
                                    button
                                    key={url}
                                    onClick={handleRoute(url)}
                                    sx={{
                                        paddingLeft: theme.spacing(2),
                                        [theme.breakpoints.up('sm')]: {
                                            paddingLeft: theme.spacing(3)
                                        },
                                        cursor: 'pointer'
                                    }}
                                >
                                    <ListItemIcon>
                                        {Icon ? <Icon /> : <DashboardIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={title} />
                                </ListItem>
                            </Tooltip>
                        </React.Fragment>
                    ))}
            </List>
        </Drawer>
    );
};
