import {
    Card,
    CardContent,
    Stack,
    SxProps,
    Theme,
    Typography
} from '@mui/material';
import { FormInputText } from '../../Form';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getAuth } from '../../../store/selectors';
import { useAuth } from '../../../api/hooks';
import { FC, useEffect, useState } from 'react';
import { apiPostOrganizations } from '../../../api/endpoints/organizations';
import { IOrganizationDto } from 'src/types';

export interface CreateOrganizationFormProps {
    selectOrgAfterCreation?: boolean;
    stackSx?: SxProps<Theme>;
    organizationName?: string;
    onCancel: () => void;
    onSuccess: () => void;
}

export const CreateOrganizationForm: FC<CreateOrganizationFormProps> = ({
    selectOrgAfterCreation,
    organizationName,
    stackSx,
    onCancel,
    onSuccess
}) => {
    const [loading, setLoading] = useState(false);
    const { userData } = useSelector(getAuth);
    const { addUserOrganization, setSelectedOrganization } = useAuth();
    const { control, handleSubmit, setValue } = useForm();

    useEffect(() => {
        if (!organizationName) return;

        setValue('name', organizationName);
    }, [organizationName, setValue]);

    const onSubmit = (data: any) => {
        const { name } = data;
        setLoading(true);
        apiPostOrganizations({
            name,
            userId: userData?.id || ''
        })
            .then(result => {
                const organization: IOrganizationDto = {
                    name: result.name,
                    id: result.id
                };
                //TODO: refactor organization list update. Try to avoid caching organizations completely
                addUserOrganization(organization);

                if (selectOrgAfterCreation) {
                    setSelectedOrganization(organization);
                }
            })
            .then(() => {
                onSuccess();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Stack alignItems="center" justifyContent="center" sx={stackSx}>
            <Card sx={{ width: '480px' }}>
                <CardContent>
                    <Typography
                        variant="h6"
                        component="div"
                        style={{ marginBottom: 10 }}
                    >
                        Create an Organization
                    </Typography>
                    <form>
                        <Stack direction="row" spacing={1}>
                            <FormInputText
                                control={control}
                                name="name"
                                variant="standard"
                                size="small"
                                label="Organization Name"
                                required
                                sx={{ width: '100%', marginBottom: 4 }}
                            />
                        </Stack>
                        <LoadingButton
                            variant="contained"
                            loading={loading}
                            style={{
                                width: '45%',
                                marginBottom: '20px'
                            }}
                            onClick={handleSubmit(onSubmit)}
                        >
                            CREATE ORGANIZATION
                        </LoadingButton>
                        <LoadingButton
                            variant="contained"
                            loading={loading}
                            style={{
                                width: '45%',
                                marginBottom: '20px',
                                marginLeft: '15px'
                            }}
                            onClick={onCancel}
                        >
                            CANCEL
                        </LoadingButton>
                    </form>
                </CardContent>
            </Card>
        </Stack>
    );
};
