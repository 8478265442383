import React from 'react';
import { Box, Chip, CircularProgress } from '@mui/material';
import { useQuery } from 'react-query';
import { apiGetFile } from 'src/api/endpoints/files';
import CancelIcon from '@mui/icons-material/Cancel';

interface ImagePreviewType {
    imageId: string;
    onDelete: (imageId: string) => void;
    alt?: string;
    width?: number;
    height?: number;
}

const ImagePreview = ({
    imageId,
    onDelete,
    alt = 'Not image',
    width = 100,
    height = 100
}: ImagePreviewType) => {
    const { data, isFetching, isError } = useQuery(
        ['image', imageId],
        () => apiGetFile(imageId),
        {
            refetchOnWindowFocus: false,
            enabled: !!imageId
        }
    );

    const handleClick = () => {
        data && data.link && window.open(data.link, '_blank');
    };

    if (isFetching) {
        return <CircularProgress />;
    }

    if (isError) {
        return <Chip label="Error" size="small" variant="outlined" />;
    }

    if (!data || !data.link) {
        return null;
    }

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-block',
                margin: '0 10px 10px 0'
            }}
        >
            <img
                src={data.link}
                alt={alt}
                width={width}
                height={height}
                onClick={handleClick}
                style={{ cursor: 'pointer' }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                    cursor: 'pointer'
                }}
                onClick={() => onDelete(imageId)}
            >
                <CancelIcon color="primary" />
            </Box>
        </Box>
    );
};

export default ImagePreview;
