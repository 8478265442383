import React, { ComponentProps, FC, CSSProperties } from 'react';
import {
    CardContent,
    Modal as MuiModal,
    Stack,
    Typography,
    Button
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { StyledCard, StyledModal } from './styles';

interface Props {
    title?: string;
    titleFontWeight?: '300' | '600' | '900';
    width?: string | number;
    onOk?: () => void;
    onCancel?: () => void;
    okText?: string;
    cancelText?: string;
    cardStyle?: CSSProperties;
    loading?: boolean;
}

export const Modal: FC<
    Props &
        Omit<ComponentProps<typeof MuiModal>, 'children' | 'BackdropComponent'>
> = ({
    children,
    title,
    titleFontWeight = '300',
    cardStyle,
    onOk,
    onCancel,
    cancelText,
    okText,
    loading,
    ...props
}) => (
    <StyledModal {...props}>
        <StyledCard style={cardStyle}>
            <CardContent>
                <Typography
                    fontWeight={titleFontWeight}
                    gutterBottom
                    variant="h6"
                    component="div"
                >
                    {title}
                </Typography>
                {children}
                <Stack
                    mt={2}
                    direction="row"
                    justifyContent="flex-end"
                    spacing={2}
                >
                    {(!!cancelText || !!onCancel) && (
                        <Button
                            variant="outlined"
                            onClick={onCancel}
                            disabled={loading}
                        >
                            {cancelText || 'Cancel'}
                        </Button>
                    )}
                    {(!!okText || !!onOk) && (
                        <LoadingButton
                            loading={loading}
                            variant="contained"
                            onClick={onOk}
                        >
                            {okText || 'Ok'}
                        </LoadingButton>
                    )}
                </Stack>
            </CardContent>
        </StyledCard>
    </StyledModal>
);
