import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessageAction } from 'src/store/actions';
import {
    CommonGetParams,
    ErrorType,
    Pagination,
    UserDto,
    UserInfoDto,
    UserManagementPost
} from 'src/types';
import { getApiCommonParams } from 'src/utils/formatters';

import {
    apiGetUsers,
    apiGetUser,
    apiPutUsers,
    apiPostUsers
} from '../endpoints/users';

export const useUsers = () => {
    const [users, setUsers] = useState<UserDto[]>([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState<Pagination>();

    const dispatch = useDispatch();

    const fetchUsers = (commonParams?: CommonGetParams) => {
        setLoading(true);

        const params = commonParams
            ? getApiCommonParams(commonParams)
            : undefined;

        return apiGetUsers({ params })
            .then(({ result, pagination }) => {
                setUsers(result || []);
                setPagination(pagination);
            })
            .catch((err: ErrorType) => {
                setUsers([]);
                dispatch(
                    showMessageAction({
                        message: `Failed getting users: ${err}`,
                        type: 'error'
                    })
                );

                throw err.message;
            })
            .finally(() => setLoading(false));
    };

    return {
        users,
        loading,
        pagination,
        fetchUsers
    };
};

export const useUser = () => {
    const dispatch = useDispatch();

    const [user, setUser] = useState<UserInfoDto>();

    const [loading, setLoading] = useState<ErrorType | null>(null);

    let isMounted = true;

    const fetchUser = (id: string) => {
        setLoading(true);

        apiGetUser(id)
            .then(user => {
                setUser(user);
            })
            .catch((err: ErrorType) => {
                setUser(undefined);
                dispatch(
                    showMessageAction({
                        message: `Failed getting user: ${err}`,
                        type: 'error'
                    })
                );

                throw err;
            })
            .finally(() => {
                if (isMounted) {
                    setLoading(false);
                }
            });

        return () => {
            isMounted = false;
        };
    };

    const updateUser = (
        id: string,
        body: Partial<UserManagementPost>,
        successCalback?: (user: Partial<UserInfoDto>) => void
    ) =>
        apiPutUsers(id, body)
            .then(user => {
                dispatch(
                    showMessageAction({
                        message: 'User changed successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback(user);
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed changing user: ${err}`,
                        type: 'error'
                    })
                );
            });

    const createUser = (
        body: Partial<UserManagementPost>,
        successCalback?: (user: Partial<UserInfoDto>) => void
    ) =>
        apiPostUsers(body)
            .then(user => {
                dispatch(
                    showMessageAction({
                        message: 'User saved successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback(user);
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed saving user: ${err}`,
                        type: 'error'
                    })
                );
            });

    return {
        setUser,
        user,
        fetchUser,
        updateUser,
        createUser,
        loading
    };
};
