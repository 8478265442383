import * as React from 'react';
import { Button, Stack, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';

type Props = {
    onSubmit: () => void;
    handleCancel: () => void;
    loading: boolean;
    buttonSettings?: {
        cancel?: { text: string };
        confirm?: { text: string };
    };
};

const StyledContainer = styled(Box)`
    position: sticky;
    bottom: 0;
    background: white;
    width: 100%;
    border-radius: 4px;
    padding: 16px;
    z-index: 100;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
`;

export const StickySaveChangesBottomToolbar: React.FC<Props> = ({
    onSubmit,
    handleCancel,
    loading,
    buttonSettings
}) => (
    <StyledContainer>
        <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
                onClick={handleCancel}
                variant="outlined"
                disabled={loading}
            >
                {buttonSettings?.cancel?.text ?? 'Cancel'}
            </Button>
            <LoadingButton
                onClick={onSubmit}
                variant="contained"
                loading={loading}
            >
                {buttonSettings?.confirm?.text ?? 'Confirm'}
            </LoadingButton>
        </Stack>
    </StyledContainer>
);
