import React, { memo, useEffect, useState } from 'react';

import { Header } from 'src/components/Header';
import { NavMenu } from 'src/components/NavMenu/NavMenu';
import { Box } from '@mui/system';
import { Container, Toolbar } from '@mui/material';

import { Message } from 'src/components/Message/Message';
import { useAuth } from 'src/api/hooks';
import { usePermissions } from 'src/api/hooks/usePermissions';
import { useBreakpoints } from 'src/hooks/useBreakpoints';

const MainLayout: React.FC = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const { permissions, isAutoPermission } = usePermissions();
    const [open, setOpen] = useState(true);
    const downSm = useBreakpoints('down', 'sm');

    const toggleDrawer = () => setOpen(prev => !prev);

    const onlyOrganizationChange =
        permissions.filter(p => !isAutoPermission(p)).length === 0;
    const showNavbar = !onlyOrganizationChange;

    useEffect(() => {
        downSm ? setOpen(false) : setOpen(true);
    }, [downSm]);

    return (
        <Box sx={{ display: 'flex' }}>
            {isLoggedIn && (
                <>
                    <Header
                        showNavbar={onlyOrganizationChange && showNavbar}
                        toggleDrawer={toggleDrawer}
                        open={showNavbar && open}
                        showLogOutButton
                    />
                    {showNavbar && (
                        <NavMenu toggleDrawer={toggleDrawer} open={open} />
                    )}
                </>
            )}
            <Box
                component="main"
                sx={theme => ({
                    backgroundColor: theme.palette.background.default
                })}
            >
                <Toolbar />
                <Container maxWidth="xl" sx={{ mt: 2, mb: 4 }}>
                    {children}
                    <Message />
                </Container>
            </Box>
        </Box>
    );
};

export default MainLayout;
