import { useEffect, useRef } from 'react';

export default (effect: Function, deps: Array<any>) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            return;
        }

        effect();
    }, deps);
};
