import { Stack, TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/system';
import { debounce } from 'lodash';

import { DropdownMenu, DropdownMenuItemProps } from '../DropdownMenu';

const TextField = styled(MuiTextField)`
    height: 39px;

    .MuiInput-underline::before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    }

    .MuiInput-input {
        padding: 8px 0;
    }
`;

export const SearchFilters = ({
    onSearchChange,
    actionsMenuItems
}: {
    onSearchChange: (value: string) => void;
    actionsMenuItems?: DropdownMenuItemProps[];
}) => (
    <Stack direction="row" alignItems="center" gap={2} flexWrap="wrap">
        <TextField
            variant="standard"
            size="small"
            placeholder="Start type to search"
            sx={{ width: 200 }}
            onChange={debounce(e => onSearchChange(e.target.value), 1000)}
        />
        {actionsMenuItems?.length && (
            <DropdownMenu title="Actions" menuItems={actionsMenuItems} />
        )}
    </Stack>
);
