import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessageAction } from 'src/store/actions';
import { apiGetProcessors } from 'src/api/endpoints/mids';
import { IMIDProcessorsDto } from 'src/types';

export const useProcessors = () => {
    const [processors, setProcessors] = useState<IMIDProcessorsDto[]>([]);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const fetchProcessors = () => {
        setLoading(true);

        return apiGetProcessors()
            .then(processors => {
                setProcessors(processors || []);
            })
            .catch(err => {
                dispatch(
                    showMessageAction({
                        message: `Failed getting processors: ${err}`,
                        type: 'error'
                    })
                );
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        void fetchProcessors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        fetchProcessors,
        loading,
        processors
    };
};
