import { BillingModelType, ExtendedBillingModelType } from 'src/types';

export const billingModelTypeName: Record<BillingModelType, string> = {
    [BillingModelType.BillByCycle]: 'Bill by Cycle',
    [BillingModelType.BillByDate]: 'Bill by Date',
    [BillingModelType.BillByDay]: 'Bill by Day',
    [BillingModelType.BillBySchedule]: 'Bill by Schedule',
    [BillingModelType.BillByRelativeDate]: 'Bill by Relative Date',
    [BillingModelType.Event]: 'Event'
};

export const extendedBillingModelTypeName: Record<
    ExtendedBillingModelType,
    string
> = {
    ...billingModelTypeName,
    [ExtendedBillingModelType.OneTimePurchase]: 'One Time Purchase'
};
