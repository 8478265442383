import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

export default (onBlock: Function) => {
    const history = useHistory();

    const canGoOut = useRef<boolean>(true);
    const unblockAndGo = useRef<(url?: string) => void>(() => {});

    const setCanGoOut = useCallback(
        (can: boolean) => (canGoOut.current = can),
        []
    );

    useEffect(() => {
        let pathname: string;
        const unblock = history.block(prompt => {
            // when we want to go out and got blocking prevent message or modal
            // -> we need to get that new path where we wanted to go
            pathname = prompt.pathname;

            if (canGoOut.current) {
                unblockAndGo.current();
                return;
            } else {
                onBlock();
                return false;
            }
        });

        // when we want to force go out -> we need to unblock and go
        unblockAndGo.current = (url: string) => {
            unblock();
            history.push(url || pathname);
        };

        return () => unblock();
    }, []);

    return {
        setCanGoOut,
        unblockAndGo: unblockAndGo.current
    };
};
