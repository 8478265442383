import { FC, memo } from 'react';
import { Control } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { FormFieldType, MerchantCreateOrUpdate } from 'src/types';
import { Field, FormCard } from '../../Form/FormCard';
import { VALIDATION_MSG } from 'src/constants';

interface Props {
    control: Control<MerchantCreateOrUpdate, object>;
}

const fields: (Field<MerchantCreateOrUpdate> & TextFieldProps)[] = [
    {
        name: 'companyContact.firstName',
        label: 'First Name',
        required: true,
        inputProps: {
            maxLength: 50
        },
        xs: 4
    },
    {
        name: 'companyContact.lastName',
        label: 'Last Name',
        required: true,
        xs: 4
    },
    {
        name: 'companyContact.emailAddress',
        label: 'Email Address',
        required: true,
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
            message: VALIDATION_MSG.EMAIL
        },
        xs: 4
    },
    {
        name: 'companyContact.phoneNumber',
        label: 'Phone Number',
        required: true,
        type: FormFieldType.Phone,
        inputProps: {
            maxLength: 16
        },
        xs: 4
    },
    {
        name: 'companyContact.faxNumber',
        label: 'Fax Number',
        xs: 4
    }
];

const CompanyContactForm: FC<Props> = ({ control }) => (
    <FormCard
        control={control}
        fields={fields}
        title="Company Contact"
        description="The company contact will be the “primary user” of affiliate account. They will receive welcome email that lets them set up the account for the first time. The information can be changed at any point in the future."
    />
);

export default memo(CompanyContactForm);
