import { FC } from 'react';
import { Control } from 'react-hook-form';

import { RolePostDto } from 'src/types';
import { Field, FormCard } from '../Form';

interface Props {
    control: Control<RolePostDto, object>;
}

const fields: Field<RolePostDto>[] = [
    {
        name: 'name',
        label: 'Name',
        required: true,
        inputProps: {
            maxLength: 100
        },
        gridProps: {
            md: 6
        }
    },
    {
        xs: 6
    },
    {
        name: 'description',
        label: 'Description',
        required: true,
        inputProps: {
            maxLength: 100
        },
        gridProps: {
            md: 6
        }
    }
];

export const RoleEditFormFields: FC<Props> = ({ control }) => (
    <FormCard
        control={control}
        fields={fields}
        cardStyle={{ paddingBottom: 20 }}
    />
);
