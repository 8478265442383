import { FC, useMemo } from 'react';
import { Control, Path, UseFormSetValue } from 'react-hook-form';
import { CardContent, Stack, Card } from '@mui/material';
import {
    FormFieldType,
    ServiceEditFormType,
    ServicePost,
    ServiceStatusesEnum
} from 'src/types';
import {
    Field,
    FormCard,
    AutocompleteOption,
    LabeledSingleCheckboxes
} from '../../Form';
import { Spin } from 'src/components/Spin/Spin';
import { ImagesFormCard } from 'src/components/Cards/ImagesFormCard/ImagesFormCard';
import { extendedBillingModelTypeName } from 'src/constants/billingModels';

interface Props {
    control: Control<ServiceEditFormType, object>;
    categories: AutocompleteOption[];
    isEdit?: boolean;
    loading?: boolean;
    setValue: UseFormSetValue<ServiceEditFormType>;
}

const generateFields = (
    _categories: AutocompleteOption[]
): Field<ServicePost>[] => [
    {
        name: 'name',
        label: 'Name',
        required: true,
        inputProps: {
            maxLength: 200
        },
        gridProps: {
            md: 7
        }
    },
    {
        name: 'msrp',
        label: 'MSRP',
        required: false,
        type: FormFieldType.Price,
        inputProps: {
            maxLength: 200
        },
        gridProps: {
            md: 7
        }
    },
    {
        name: 'cost',
        label: 'Cost',
        required: true,
        type: FormFieldType.Price,
        gridProps: {
            md: 7
        }
    },
    {
        name: 'associatedId',
        label: 'Associated Id #',
        type: FormFieldType.Input,
        gridProps: {
            md: 7
        }
    },
    {
        name: 'billingModel',
        label: 'Billing Model',
        required: true,
        type: FormFieldType.Autocomplete,
        options: Object.values(extendedBillingModelTypeName).map(value => ({
            label: value,
            value
        })),
        gridProps: {
            md: 7
        }
    },
    {
        name: 'status',
        label: 'Status',
        required: true,
        type: FormFieldType.Autocomplete,
        options: Object.values(ServiceStatusesEnum).map(value => ({
            label: value,
            value
        })),
        gridProps: {
            md: 7
        }
    }
];

const parameters: {
    label: string;
    name: Path<ServiceEditFormType>;
}[] = [
    {
        label: 'Taxable',
        name: 'taxable'
    },
    {
        label: 'License Key Restricted',
        name: 'licenseKeyRestricted'
    },
    {
        label: 'Subscription',
        name: 'subscription'
    }
];

const delivery: {
    label: string;
    name: Path<ServiceEditFormType>;
}[] = [
    {
        label: 'Shippable',
        name: 'shippable'
    },
    {
        label: 'Digital Download',
        name: 'digitalDownload'
    },
    {
        label: 'Signature Confirmation',
        name: 'signatureConfirmation'
    },
    {
        label: 'Delivery Confirmation',
        name: 'deliveryConfirmation'
    }
];

const variants: {
    label: string;
    name: Path<ServiceEditFormType>;
}[] = [
    {
        label: 'Variants',
        name: 'variants'
    }
];

const params = [
    {
        label: 'Parameters',
        checkboxes: parameters
    },
    {
        label: 'Delivery',
        checkboxes: delivery
    },
    {
        label: 'Variants/Groups',
        checkboxes: variants
    }
];

export const ServiceEditFormFields: FC<Props> = ({
    control,
    categories,
    isEdit,
    loading = false,
    setValue
}) => {
    const formFields = useMemo(() => generateFields(categories), [categories]);

    return (
        <Spin loading={loading}>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <FormCard control={control} fields={formFields} />
                <ImagesFormCard
                    setValue={setValue}
                    control={control}
                    title="Images"
                    description="Upload images of service"
                />
                <Card>
                    <CardContent>
                        <Stack spacing={2}>
                            {params.map(({ label, checkboxes }) => (
                                <LabeledSingleCheckboxes
                                    key={label}
                                    label={label}
                                    checkboxes={checkboxes}
                                    control={control}
                                />
                            ))}
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
        </Spin>
    );
};
