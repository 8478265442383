import { AlertColor } from '@mui/material';

export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';

export type ShowMessage = {
    type: typeof SHOW_MESSAGE;
    payload: {
        message: string;
        type?: AlertColor;
    };
};

export type HideMessage = {
    type: typeof HIDE_MESSAGE;
};

export type MessageActionTypes = ShowMessage | HideMessage;

export const showMessageAction = (message: {
    message: string;
    type?: AlertColor;
}): ShowMessage => ({
    type: SHOW_MESSAGE,
    payload: message
});

export const hideMessageAction = () => ({
    type: HIDE_MESSAGE
});
