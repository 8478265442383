import { useCallback, useState } from 'react';

interface CreateOrgModalState {
    show: boolean;
    inputValue: string;
}

interface UseCreateOrgModalReturnValue {
    modalState: CreateOrgModalState;
    updateModalState: (state: CreateOrgModalState) => void;
    closeModal: () => void;
}

const createOrgModalInitialState: CreateOrgModalState = {
    show: false,
    inputValue: ''
};

export const UseCreateOrgModal = (): UseCreateOrgModalReturnValue => {
    const [createOrgModalState, setCreateOrgModalState] =
        useState<CreateOrgModalState>(createOrgModalInitialState);

    const updateModalState = useCallback((state: CreateOrgModalState) => {
        setCreateOrgModalState(prev => ({ ...prev, ...state }));
    }, []);

    const closeModal = useCallback(() => {
        setCreateOrgModalState(createOrgModalInitialState);
    }, []);

    return {
        modalState: createOrgModalState,
        updateModalState,
        closeModal
    };
};
