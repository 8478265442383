import { Pagination } from 'src/types';
import { IAlert } from 'src/types/alerts';
import { SET_LOADING, SET_PAGINATION } from '../actions/billingModels';
import {
    EthocaAlertsActionTypes,
    SET_ETHOCA_ALERTS
} from './../actions/ethocaAlerts';

export interface EthocaAlertsState {
    ethocaAlerts: IAlert[];
    loading: boolean;
    pagination: Pagination | undefined;
}
const initialState: EthocaAlertsState = {
    ethocaAlerts: [],
    loading: false,
    pagination: undefined
};

export const ethocaAlerts = (
    state: EthocaAlertsState = initialState,
    action: EthocaAlertsActionTypes
): EthocaAlertsState => {
    switch (action.type) {
        case SET_ETHOCA_ALERTS:
            return {
                ...state,
                ethocaAlerts: action.payload,
                loading: false
            };
        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
};
