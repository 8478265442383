import { Field } from 'src/components/Form';
import { BillingModelDto, ColumnType, ServiceDto } from 'src/types';

import {
    generateModalInputs as commonModalInputs,
    tableFields as commonTableFields
} from 'src/components/Services/addServiceCommonFields';

export const tableFields: ColumnType<ServiceDto>[] = commonTableFields;

// MODAL FIELDS
export const generateFields = (
    services: Record<string, ServiceDto>,
    billingModels: Record<string, BillingModelDto>
): Field<ServiceDto>[] => commonModalInputs(services, billingModels);
