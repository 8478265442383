import { removeUserDataLS, removeUserOrganizationsLS } from './user';
import { removeAuthTokenLS, removeAuthLS } from './auth';
import { removeSelectedOrganizationLS } from './selectedOrganizations';

export const clearLocalStorage = () => {
    removeAuthTokenLS();
    removeAuthLS();
    removeUserDataLS();
    removeUserOrganizationsLS();
    removeSelectedOrganizationLS();
};
