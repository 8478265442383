import React, { useMemo, memo } from 'react';
import { Field, FormCard } from 'src/components/Form';
import { Control } from 'react-hook-form';
import {
    IAgentsApiPostPut,
    FormFieldType,
    MerchantCreateOrUpdate,
    PartnerCreateEditDto
} from 'src/types';
import { STATUS_OPTIONS } from 'src/constants/partners/common';

type StatusFormProps = {
    control:
        | Control<MerchantCreateOrUpdate, object>
        | Control<IAgentsApiPostPut, object>
        | Control<PartnerCreateEditDto, object>;
    keyWord: 'Merchant' | 'Agent' | 'Partner';
};

const StatusCard: React.FC<StatusFormProps> = ({ control, keyWord }) => {
    const fields: Field[] = useMemo(
        () => [
            {
                name: 'accountInfo.status',
                label: 'Status',
                type: FormFieldType.Autocomplete,
                options: STATUS_OPTIONS,
                xs: 4
            },
            {
                name: 'accountInfo.activateAt',
                label: 'Activate At',
                type: FormFieldType.DateTime,
                inputProps: {
                    dateTimeInputProps: {
                        disablePast: true
                    }
                },
                xs: 4
            },
            {
                name: 'accountInfo.deactivateAt',
                label: 'Deactivate At',
                type: FormFieldType.DateTime,
                inputProps: {
                    dateTimeInputProps: {
                        disablePast: true
                    }
                },
                xs: 4
            }
        ],
        []
    );
    return (
        <>
            <FormCard
                control={control}
                fields={fields}
                title={`${keyWord} status`}
                description={`Set a status for the ${keyWord.toLowerCase()}`}
            />
        </>
    );
};

export default memo(StatusCard);
