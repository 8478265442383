import React from 'react';

import { AUTH_MAIN_ROUTE, AUTH_ROUTES } from 'src/constants';
import { Redirect, Route, Switch } from 'react-router-dom';

const AuthRoutes = () => (
    <Switch>
        {AUTH_ROUTES.map(item => (
            <Route
                path={item.url}
                component={item.component}
                key={item.url}
                exact
            />
        ))}
        <Route path="/" render={() => <Redirect to={AUTH_MAIN_ROUTE} />} />
    </Switch>
);

export default AuthRoutes;
