const US = [
    {
        label: 'Alabama',
        value: 'Alabama'
    },
    {
        label: 'Alaska',
        value: 'Alaska'
    },
    {
        label: 'American Samoa',
        value: 'American Samoa'
    },
    {
        label: 'Arizona',
        value: 'Arizona'
    },
    {
        label: 'Arkansas',
        value: 'Arkansas'
    },
    {
        label: 'California',
        value: 'California'
    },
    {
        label: 'Colorado',
        value: 'Colorado'
    },
    {
        label: 'Connecticut',
        value: 'Connecticut'
    },
    {
        label: 'Delaware',
        value: 'Delaware'
    },
    {
        label: 'District Of Columbia',
        value: 'District Of Columbia'
    },
    {
        label: 'Federated States Of Micronesia',
        value: 'Federated States Of Micronesia'
    },
    {
        label: 'Florida',
        value: 'Florida'
    },
    {
        label: 'Georgia',
        value: 'Georgia'
    },
    {
        label: 'Guam',
        value: 'Guam'
    },
    {
        label: 'Hawaii',
        value: 'Hawaii'
    },
    {
        label: 'Idaho',
        value: 'Idaho'
    },
    {
        label: 'Illinois',
        value: 'Illinois'
    },
    {
        label: 'Indiana',
        value: 'Indiana'
    },
    {
        label: 'Iowa',
        value: 'Iowa'
    },
    {
        label: 'Kansas',
        value: 'Kansas'
    },
    {
        label: 'Kentucky',
        value: 'Kentucky'
    },
    {
        label: 'Louisiana',
        value: 'Louisiana'
    },
    {
        label: 'Maine',
        value: 'Maine'
    },
    {
        label: 'Marshall Islands',
        value: 'Marshall Islands'
    },
    {
        label: 'Maryland',
        value: 'Maryland'
    },
    {
        label: 'Massachusetts',
        value: 'Massachusetts'
    },
    {
        label: 'Michigan',
        value: 'Michigan'
    },
    {
        label: 'Minnesota',
        value: 'Minnesota'
    },
    {
        label: 'Mississippi',
        value: 'Mississippi'
    },
    {
        label: 'Missouri',
        value: 'Missouri'
    },
    {
        label: 'Montana',
        value: 'Montana'
    },
    {
        label: 'Nebraska',
        value: 'Nebraska'
    },
    {
        label: 'Nevada',
        value: 'Nevada'
    },
    {
        label: 'New Hampshire',
        value: 'New Hampshire'
    },
    {
        label: 'New Jersey',
        value: 'New Jersey'
    },
    {
        label: 'New Mexico',
        value: 'New Mexico'
    },
    {
        label: 'New York',
        value: 'New York'
    },
    {
        label: 'North Carolina',
        value: 'North Carolina'
    },
    {
        label: 'North Dakota',
        value: 'North Dakota'
    },
    {
        label: 'Northern Mariana Islands',
        value: 'Northern Mariana Islands'
    },
    {
        label: 'Ohio',
        value: 'Ohio'
    },
    {
        label: 'Oklahoma',
        value: 'Oklahoma'
    },
    {
        label: 'Oregon',
        value: 'Oregon'
    },
    {
        label: 'Palau',
        value: 'Palau'
    },
    {
        label: 'Pennsylvania',
        value: 'Pennsylvania'
    },
    {
        label: 'Puerto Rico',
        value: 'Puerto Rico'
    },
    {
        label: 'Rhode Island',
        value: 'Rhode Island'
    },
    {
        label: 'South Carolina',
        value: 'South Carolina'
    },
    {
        label: 'South Dakota',
        value: 'South Dakota'
    },
    {
        label: 'Tennessee',
        value: 'Tennessee'
    },
    {
        label: 'Texas',
        value: 'Texas'
    },
    {
        label: 'Utah',
        value: 'Utah'
    },
    {
        label: 'Vermont',
        value: 'Vermont'
    },
    {
        label: 'Virgin Islands',
        value: 'Virgin Islands'
    },
    {
        label: 'Virginia',
        value: 'Virginia'
    },
    {
        label: 'Washington',
        value: 'Washington'
    },
    {
        label: 'West Virginia',
        value: 'West Virginia'
    },
    {
        label: 'Wisconsin',
        value: 'Wisconsin'
    },
    {
        label: 'Wyoming',
        value: 'Wyoming'
    }
];

const CA = [
    {
        label: 'Alberta',
        value: 'Alberta'
    },
    {
        label: 'British Columbia',
        value: 'British Columbia'
    },
    {
        label: 'Manitoba',
        value: 'Manitoba'
    },
    {
        label: 'New Brunswick',
        value: 'New Brunswick'
    },
    {
        label: 'Newfoundland and Labrador',
        value: 'Newfoundland and Labrador'
    },
    {
        label: 'Northwest Territories',
        value: 'Northwest Territories'
    },
    {
        label: 'Nova Scotia',
        value: 'Nova Scotia'
    },
    {
        label: 'Nunavut',
        value: 'Nunavut'
    },
    {
        label: 'Ontario',
        value: 'Ontario'
    },
    {
        label: 'Prince Edward Island',
        value: 'Prince Edward Island'
    },
    {
        label: 'Quebec',
        value: 'Quebec'
    },
    {
        label: 'Saskatchewan',
        value: 'Saskatchewan'
    },
    {
        label: 'Yukon Territory',
        value: 'Yukon Territory'
    }
];

export const states = {
    US,
    CA
} as Record<string, any>;
