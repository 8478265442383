import { Pagination, CategoryDto } from 'src/types';

export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_LOADING = 'SET_LOADING';

export type SetCategories = {
    type: typeof SET_CATEGORIES;
    payload: CategoryDto[];
};

export type SetPagination = {
    type: typeof SET_PAGINATION;
    payload?: Pagination;
};

export type SetLoading = {
    type: typeof SET_LOADING;
};

export type CategoriesActionTypes = SetCategories | SetLoading | SetPagination;

export const setCategoriesAction = (
    categories: CategoryDto[]
): SetCategories => ({
    type: SET_CATEGORIES,
    payload: categories
});

export const setPaginationAction = (
    pagination?: Pagination
): SetPagination => ({
    type: SET_PAGINATION,
    payload: pagination
});

export const setLoadingAction = () => ({
    type: SET_LOADING
});
