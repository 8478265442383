import { IOrganizationDto } from 'src/types';

export const getFilteredOrganizations = (
    organizations: IOrganizationDto[],
    searchValue: string
) => {
    const value = searchValue.toLowerCase();
    return organizations.filter(
        ({ id, name }) =>
            id.toLowerCase().includes(value) ||
            name.toLowerCase().includes(value)
    );
};
