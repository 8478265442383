import {
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack
} from '@mui/material';
import { useState } from 'react';
import { apiPostVerifiAlert } from 'src/api/endpoints/verifiAlerts';
import { useVerifiAlerts } from 'src/api/hooks/useVerifiAlerts';
import { FileInput } from 'src/components/Form/FileInput';
import Loader from 'src/components/Loader/Loader';
import { IFilePutResponse } from 'src/types';
import { AlertType } from 'src/types/alerts';

type Props = {
    /**
     * 0 - Verifi tab active
     * 1 - Ethoca tab active
     */
    currentTab: number;
};

export const VerifiEthocaUpload = ({ currentTab }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [alertType, setAlertType] = useState<AlertType>('verifi');
    const alertTypeByTab = currentTab === 0 ? 'verifi' : 'ethoca';
    const { fetchVerifiAlerts } = useVerifiAlerts();

    const onStartUploadFile = () => {
        setIsLoading(true);
    };

    const onSuccessUploadFile = async (file: IFilePutResponse) => {
        try {
            await apiPostVerifiAlert({
                alertType,
                originalFileName: file.originalFileName,
                prefix: file.prefix
            });

            fetchVerifiAlerts(undefined, alertTypeByTab);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onErrorUploadFile = () => {
        setIsLoading(false);
    };

    const changeAlertTypeHandler = (e: SelectChangeEvent) => {
        setAlertType(e.target.value as AlertType);
    };

    return (
        <Loader loading={isLoading}>
            <Stack
                sx={{
                    marginLeft: 'auto !important',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 2,
                    marginRight: '16px !important'
                }}
            >
                <InputLabel id="alert-type-label-id">Alert Type</InputLabel>
                <Select
                    labelId="alert-type-label-id"
                    onChange={changeAlertTypeHandler}
                    value={alertType}
                >
                    <MenuItem value="verifi">Verifi</MenuItem>
                    <MenuItem value="ethoca">Ethoca</MenuItem>
                </Select>
            </Stack>
            <FileInput
                title="Upload"
                onStartUploadFile={onStartUploadFile}
                onSuccessUploadFile={onSuccessUploadFile}
                onErrorUploadFile={onErrorUploadFile}
                inputAccept=".xls, .xlsx"
                mimeTypes={[
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel'
                ]}
                withIcon={false}
            />
        </Loader>
    );
};
