import { Pagination, IMIDListDto } from 'src/types';
import {
    MidsActionTypes,
    SET_LOADING,
    SET_PAGINATION,
    SET_MIDS
} from '../actions/mids';

export type MIDsReduxType = Record<string, IMIDListDto>;

export interface MidsState {
    mids: MIDsReduxType;
    loading: boolean;
    pagination: Pagination | undefined;
}
const initialState: MidsState = {
    mids: {},
    loading: false,
    pagination: undefined
};

export const mids = (
    state: MidsState = initialState,
    action: MidsActionTypes
): MidsState => {
    switch (action.type) {
        case SET_MIDS:
            return {
                ...state,
                mids:
                    action.payload?.reduce<MIDsReduxType>(
                        (prev, curr) => ({
                            ...prev,
                            [curr.id as string]: curr
                        }),
                        {}
                    ) || {},
                loading: false
            };
        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
