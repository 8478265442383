import { FC } from 'react';
import { Control } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { IAgentsApiPostPut, FormFieldType } from 'src/types';
import { Field, FormCard } from './FormCard';

interface Props {
    control: Control<IAgentsApiPostPut, object>;
}

const fields: (Field<IAgentsApiPostPut> & TextFieldProps)[] = [
    {
        name: 'supportContact.emailAddress',
        label: 'Support Email',
        xs: 6
    },
    {
        name: 'supportContact.phoneNumber',
        label: 'Support Phone',
        type: FormFieldType.Phone,
        inputProps: {
            maxLength: 16
        },
        xs: 6
    },
    {
        name: 'accountingSupportContact.emailAddress',
        label: 'Accounting Support Email',
        required: true,
        xs: 6
    },
    {
        name: 'accountingSupportContact.phoneNumber',
        label: 'Accounting Support Phone',
        required: true,
        type: FormFieldType.Phone,
        inputProps: {
            maxLength: 16
        },
        xs: 6
    }
];

export const SupportContactsForm: FC<Props> = ({ control }) => (
    <FormCard
        control={control}
        fields={fields}
        title="Support Contacts"
        description="The support information is displayed in the support area of the merchant control panel, and merchants are directed to use this contact information if they have any technical questions. By default, the contact information above is used, but you can fill out this information to have something else displayed."
    />
);
