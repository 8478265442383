import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessageAction } from 'src/store/actions';
import { IMIDBankDto } from 'src/types';
import { apiGetBanks } from 'src/api/endpoints/mids';

export const useBanks = () => {
    const [banks, setBanks] = useState<IMIDBankDto[]>([]);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const fetchBanks = () => {
        setLoading(true);

        return apiGetBanks()
            .then(banks => {
                setBanks(banks || []);
            })
            .catch(err => {
                dispatch(
                    showMessageAction({
                        message: `Failed getting banks list: ${err}`,
                        type: 'error'
                    })
                );
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchBanks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        fetchBanks,
        loading,
        banks
    };
};
