import { Pagination } from 'src/types';
import { IAlert } from 'src/types/alerts';
import { SET_LOADING, SET_PAGINATION } from '../actions/billingModels';
import { RdrAlertsActionTypes, SET_RDR_ALERTS } from '../actions/rdrAlerts';

export interface RdrAlertsState {
    rdrAlerts: IAlert[];
    loading: boolean;
    pagination: Pagination | undefined;
}
const initialState: RdrAlertsState = {
    rdrAlerts: [],
    loading: false,
    pagination: undefined
};

export const rdrAlerts = (
    state: RdrAlertsState = initialState,
    action: RdrAlertsActionTypes
): RdrAlertsState => {
    switch (action.type) {
        case SET_RDR_ALERTS:
            return {
                ...state,
                rdrAlerts: action.payload,
                loading: false
            };
        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
