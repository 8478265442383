import { LOCAL_STORAGE_SELECTED_ORGANIZATIONS } from 'src/constants';
import { IOrganizationDto } from 'src/types';

export const getSelectedOrganizationLS = (): IOrganizationDto | void => {
    const organizations = localStorage.getItem(
        LOCAL_STORAGE_SELECTED_ORGANIZATIONS
    );

    return organizations && JSON.parse(organizations);
};

export const setSelectedOrganizationLS = (organization: IOrganizationDto) => {
    localStorage.setItem(
        LOCAL_STORAGE_SELECTED_ORGANIZATIONS,
        JSON.stringify(organization)
    );
};

export const removeSelectedOrganizationLS = () => {
    localStorage.removeItem(LOCAL_STORAGE_SELECTED_ORGANIZATIONS);
};
