import { PartnerCreateEditDto } from 'src/types';

const INITIAL_VALUES: PartnerCreateEditDto = {
    name: '',
    accountInfo: {
        address: '',
        city: '',
        zip: '',
        country: '',
        address2: '',
        state: ''
    },
    commissionPaymentInfo: {
        accountHolder: '',
        bankRouting: '',
        bankAccount: ''
    },
    logo: null,
    primaryContact: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        faxNumber: ''
    },

    notes: [],
    services: []
};

export default INITIAL_VALUES;
