import { FormState, UseFormGetValues } from 'react-hook-form/dist/types/form';

export const logFormState = (
    getValues: UseFormGetValues<any>,
    formState: FormState<any>
) => {
    if (process.env.NODE_ENV === 'development') {
        console.group('VALUES');
        console.log(getValues());
        console.log('formState.isDirty', formState.isDirty);
        console.log('formState.dirtyFields', formState.dirtyFields);
        console.log('errors', formState.errors);
        console.groupEnd();
    }
};
