import React from 'react';
import ReactDOM from 'react-dom';
import { Box, CircularProgress } from '@mui/material';

type LoaderType = 'fullBlock' | 'fullScreen' | 'simple';

type LoaderProps = {
    loading?: boolean;
    type?: LoaderType;
};

const getLoaderByType = (type: LoaderType) => {
    switch (type) {
        case 'fullBlock':
            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress />
                </Box>
            );
        case 'fullScreen':
            return ReactDOM.createPortal(
                <Box
                    sx={{
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress />
                </Box>,
                document.body
            );
        case 'simple':
        default:
            return <CircularProgress />;
    }
};

const Loader: React.FC<LoaderProps> = ({
    loading = true,
    type = 'simple',
    children
}) => {
    if (loading) {
        return getLoaderByType(type);
    }

    return <>{children}</>;
};

export default Loader;
