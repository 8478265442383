import { yupResolver } from '@hookform/resolvers/yup';
import { mixed, object, SchemaOf, string, array } from 'yup';
import {
    bankRoutingValidation,
    notesValidation,
    partnerStatusValidation,
    servicesValidation,
    supportAndAccountingContactValidation
} from 'src/utils/yupValidations';
import { businessTypes } from 'src/constants';
import { IAgentsApiPostPut } from 'src/types';

export default function agentValidationYupResolver() {
    const schema: SchemaOf<IAgentsApiPostPut> = object({
        name: string().required().max(200),
        webSite: string().nullable(),
        timezone: string().nullable(),
        partner: object({
            id: string().nullable()
        }).optional(),

        outgoingStatusOfPayment: mixed().oneOf(
            ['Unknown', 'Received'],
            'Choose one of'
        ),
        incomingStatusOfPayment: mixed().oneOf(
            ['Unknown', 'Received'],
            'Choose one of'
        ),

        accountInfo: object({
            address: string().required().max(500),
            city: string().required().max(50),
            zip: string().required().max(16),

            country: string().required(),
            address2: string().nullable(),
            state: string().required().max(40),

            email: string().nullable(),
            notes: string().nullable(),
            ...partnerStatusValidation
        }),

        commissionPaymentInfo: object({
            accountHolder: string().required(),
            bankRouting: bankRoutingValidation,
            bankAccount: string().required().max(34),
            taxId: string().required(),
            businessType: mixed().oneOf(
                [...businessTypes.map(({ value }) => value), null],
                'Must be chosen one in the list'
            )
        }),
        primaryContact: object({
            firstName: string().required().max(50),
            lastName: string().required().max(100),
            emailAddress: string()
                .required()
                .email('Must be a valid email')
                .max(100),
            phoneNumber: string().required().max(15),
            faxNumber: string().max(15).nullable()
        }),

        ...supportAndAccountingContactValidation,
        ...partnerStatusValidation,
        ...notesValidation,
        ...servicesValidation,
        files: array(mixed()),
        whitelistedName: string().ensure(),
        urlForWhitelistedName: string().ensure()
    });

    return yupResolver(schema);
}
