import React, { useState, memo } from 'react';
import CardWrapper from 'src/components/Card/CardWrapper';
import {
    Button,
    IconButton,
    Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { Modal } from 'src/components/Modal';
import { Control, Controller } from 'react-hook-form';
import { MerchantCreateOrUpdate } from 'src/types';

const getTokenType = (token: string) => {
    switch (token[0]) {
        case 'E':
            return 'Ethoca';
        case 'V':
            return 'Verifi';
        default:
            return '-';
    }
};

const TokensCard = ({
    control
}: {
    control: Control<MerchantCreateOrUpdate>;
}) => {
    return (
        <Controller
            name="tokens"
            control={control}
            render={({ field: { onChange, value: tokens } }) => {
                const [newToken, setNewToken] = useState('');
                const [isModalOpen, setIsModalOpen] = useState(false);

                const handleAddToken = () => {
                    if (Array.isArray(tokens)) {
                        onChange([...tokens, { token: newToken }]);
                    } else {
                        onChange([{ token: newToken }]);
                    }
                    handleCloseModal();
                    setNewToken('');
                };

                const handleDeleteToken = (token: string) => {
                    if (Array.isArray(tokens)) {
                        onChange(tokens.filter(value => value.token !== token));
                    }
                };

                const handleCloseModal = () => setIsModalOpen(false);
                const handleChangeInput: React.ChangeEventHandler<
                    HTMLInputElement
                > = e => setNewToken(e.target.value);

                return (
                    <>
                        <CardWrapper title="Tokens">
                            <TableContainer
                                component={Paper}
                                sx={{ margin: '15px 0' }}
                            >
                                <Table sx={{ borderCollapse: 'unset' }}>
                                    <TableHead>
                                        <TableRow
                                            sx={{ th: { fontWeight: 'bold' } }}
                                        >
                                            <TableCell>Value</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(!tokens || tokens.length === 0) && (
                                            <TableRow>
                                                <TableCell colSpan={3}>
                                                    <Typography variant="subtitle1">
                                                        No tokens
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {tokens &&
                                            tokens.map(({ token }) => (
                                                <TableRow
                                                    key={token}
                                                    sx={{
                                                        td: {
                                                            borderBottom:
                                                                '1px solid #c2c2c2'
                                                        }
                                                    }}
                                                >
                                                    <TableCell>
                                                        {token}
                                                    </TableCell>
                                                    <TableCell>
                                                        {token
                                                            ? getTokenType(
                                                                  token
                                                              )
                                                            : ''}
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ width: '50px' }}
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            onClick={() =>
                                                                handleDeleteToken(
                                                                    token as string
                                                                )
                                                            }
                                                        >
                                                            <ClearIcon fontSize="small" />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button
                                variant="contained"
                                onClick={() => setIsModalOpen(true)}
                            >
                                Add new token
                            </Button>
                        </CardWrapper>
                        <Modal
                            title="Add new token"
                            open={isModalOpen}
                            okText="Add token"
                            onOk={handleAddToken}
                            onClose={handleCloseModal}
                        >
                            <Input
                                value={newToken}
                                onChange={handleChangeInput}
                                placeholder="Type token"
                            />
                        </Modal>
                    </>
                );
            }}
        />
    );
};
export default memo(TokensCard);
