import React from 'react';

import { Control } from 'react-hook-form';

import { Field, FormCard } from 'src/components/Form';
import { LeadTypeDto } from 'src/types/leads';
import { AccountType, FormFieldType } from 'src/types';

const fields: Field<LeadTypeDto>[] = [
    {
        name: 'firstName',
        label: 'First Name',
        required: true,
        inputProps: {
            maxLength: 200
        },
        gridProps: {
            md: 4
        }
    },
    {
        name: 'lastName',
        label: 'Last Name',
        required: true,
        inputProps: {
            maxLength: 200
        },
        gridProps: {
            md: 4
        }
    },
    {
        name: 'email',
        label: 'Email Address',
        required: true,
        inputProps: {
            maxLength: 200
        },
        gridProps: {
            md: 4
        }
    },
    {
        type: FormFieldType.Phone,
        name: 'phoneNumber',
        label: 'Phone Number',
        inputProps: {
            maxLength: 200
        },
        gridProps: {
            md: 4
        }
    },
    {
        name: 'companyName',
        label: 'Company Name',
        inputProps: {
            maxLength: 200
        },
        gridProps: {
            md: 4
        }
    },
    {
        name: 'accountType',
        label: 'Account Type',
        type: FormFieldType.Autocomplete,
        options: [
            ...Object.values(AccountType)
                .filter(value => value !== AccountType.Lead)
                .map(value => ({
                    label: value,
                    value
                }))
        ],
        placeholder: 'Type or select',
        inputProps: {
            maxLength: 200
        },
        gridProps: {
            md: 4
        }
    }
];

const LeadFormFields = ({ control }: { control: Control<LeadTypeDto> }) => (
    <FormCard control={control} fields={fields} spacing={4} />
);

export default LeadFormFields;
