import React, { useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import LeadFormFields from 'src/pages/Leads/LeadFormFields';
import { LeadTypeDto } from 'src/types/leads';
import { AccountType } from 'src/types';
import { showMessageAction } from 'src/store/actions';
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import useEditFormId from 'src/api/hooks/useEditFormId';
import { Spin } from 'src/components/Spin/Spin';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from 'src/pages/Leads/leadFormValidation';
import {
    apiGetLeadById,
    apiPostLead,
    apiPutLead
} from 'src/api/endpoints/leads';
import catchDispatchError from 'src/utils/catchDispatchError';

const INITIAL_VALUES: LeadTypeDto = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    accountType: ''
};

export type OnSuccessAddLeadType = (newlead: any) => void;

const LeadForm = ({
    onCancel,
    onSuccessAddLead
}: {
    onCancel: () => void;
    onSuccessAddLead?: OnSuccessAddLeadType;
}) => {
    const dispatch = useDispatch();
    const [isEdit, id] = useEditFormId();

    const [submitting, setSubmitting] = useState(false);

    const { handleSubmit, control, reset, getValues } = useForm<LeadTypeDto>({
        defaultValues: INITIAL_VALUES,
        resolver: yupResolver(schema)
    });

    const { isFetching } = useQuery(
        ['leadForm', id],
        () => apiGetLeadById(id as string).then(reset),
        {
            enabled: isEdit && !!id,
            refetchOnWindowFocus: false,
            retry: 2
        }
    );

    const onSubmit = () => {
        setSubmitting(true);

        let func: any = saveNewLead;

        if (isEdit && !!id) {
            func = () => saveEditedLead(id);
        }

        func()
            .catch(catchDispatchError)
            .finally(() => {
                setSubmitting(false);
            });
    };

    const saveEditedLead = (id: string) =>
        apiPutLead(id, getValues()).then((lead: LeadTypeDto) => {
            dispatch(
                showMessageAction({
                    message: `Lead was updated`,
                    type: 'success'
                })
            );
            onSuccessAddLead && onSuccessAddLead(lead);
        });

    const saveNewLead = () =>
        apiPostLead(getValues()).then(({ id }) => {
            dispatch(
                showMessageAction({
                    message: `Lead was saved`,
                    type: 'success'
                })
            );
            onSuccessAddLead &&
                onSuccessAddLead({
                    value: id,
                    label: getValues().firstName
                });
        });

    return (
        <Stack spacing={2} flexGrow={1} mb={3}>
            <Typography variant="h5">
                {isEdit ? 'Edit Lead' : 'New Lead'}
            </Typography>
            <Spin loading={isFetching}>
                <LeadFormFields control={control} />
            </Spin>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button onClick={onCancel} variant="outlined">
                    Cancel
                </Button>
                <LoadingButton
                    onClick={handleSubmit(onSubmit)}
                    variant="contained"
                    loading={submitting}
                >
                    Save
                </LoadingButton>
            </Stack>
        </Stack>
    );
};

export default LeadForm;
