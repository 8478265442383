import { Field } from 'src/components/Form';
import { countries } from 'src/constants';
import { FormFieldType } from 'src/types';
import { CreateAccountForm } from './CreateAccount';

export const fields: Field<CreateAccountForm>[] = [
    {
        name: 'email',
        label: 'Email Address',
        required: true
    },
    {
        name: 'firstname',
        label: 'First Name',
        required: true
    },
    {
        name: 'lastname',
        label: 'Last Name',
        required: true
    },
    {
        name: 'phone',
        label: 'Phone Number',
        required: true,
        type: FormFieldType.Phone
    },
    {
        name: 'password',
        label: 'Password',
        fieldType: 'password',
        required: true
    },
    {
        name: 'passwordConfirm',
        label: 'Confirm Password',
        fieldType: 'password',
        required: true
    },
    {
        name: 'country',
        label: 'Country',
        required: true,
        type: FormFieldType.Autocomplete,
        options: countries
    }
];
