import { FC, useMemo, useState } from 'react';
import { Control } from 'react-hook-form';
import { FormFieldType } from 'src/types';
import { AutocompleteOption, Field, FormCard } from '../../Form';

import ModalLeadForm from 'src/pages/Leads/ModalLeadForm';
import { BILLING_STATUSES, STATUSES } from 'src/constants/partners/common';
import IMidEditForm from 'src/pages/MIDs/types';

interface FieldsOptions {
    partners: AutocompleteOption[];
    merchants: AutocompleteOption[];
    agents: AutocompleteOption[];
    banks: AutocompleteOption[];
    processors: AutocompleteOption[];
    leads: AutocompleteOption[];
    // Only for testing -> need to refactor
    setNewLead: (newLead: AutocompleteOption) => void;
}

interface Props extends FieldsOptions {
    control: Control<IMidEditForm, object>;
}

export const MidDetailsForm: FC<Props> = ({
    control,
    partners,
    agents,
    merchants,
    banks,
    processors,
    leads,
    setNewLead
}) => {
    const [isNewLeadModalOpen, setIsNewLeadModalOpen] =
        useState<boolean>(false);

    const handleCloseNewLeadModal = () => setIsNewLeadModalOpen(false);
    const handleAddNewLeadByModal = (newLead: AutocompleteOption) => {
        setIsNewLeadModalOpen(false);
        setNewLead(newLead);
    };

    const formFields: Field<IMidEditForm>[] = useMemo<Field<IMidEditForm>[]>(
        () => [
            {
                name: 'mididentifier',
                label: 'MID Identifier',
                required: true,
                xs: 6
            },
            { xs: 6 },
            {
                name: 'midalias',
                label: 'MID Alias',
                required: true,
                xs: 6
            },
            {
                name: 'bankMid',
                label: 'Bank MID',
                freeSolo: true,
                type: FormFieldType.Autocomplete,
                options: banks,
                required: true,
                xs: 6
            },
            {
                name: 'processor',
                label: 'Processor',
                freeSolo: true,
                type: FormFieldType.Autocomplete,
                options: processors,
                required: true,
                xs: 6
            },
            // We will make it visible when API will be ready

            // {
            //     name: 'leads',
            //     label: 'Leads',
            //     freeSolo: true,
            //     type: FormFieldType.Autocomplete,
            //     options: leads,
            //     autocompleteProps: {
            //         multiple: true,
            //         filterSelectedOptions: true
            //     },
            //     required: true,
            //     buttonProps: {
            //         onClick: () => setIsNewLeadModalOpen(true)
            //     },
            //     xs: 6
            // },
            {
                xs: 6
            },
            {
                name: 'partner.id',
                label: 'Partner',
                type: FormFieldType.Autocomplete,
                options: partners,
                xs: 4
            },
            {
                name: 'agent.id',
                label: 'Agent',
                type: FormFieldType.Autocomplete,
                options: agents,
                xs: 4
            },
            {
                name: 'merchant.id',
                label: 'Merchant',
                type: FormFieldType.Autocomplete,
                options: merchants,
                xs: 4
            },
            {
                name: 'billingStatus',
                label: 'Billing Status',
                type: FormFieldType.Autocomplete,
                options: Object.values(BILLING_STATUSES).map(item => ({
                    value: item,
                    label: item
                })),
                xs: 6
            },
            {
                name: 'accountInfo.status',
                label: 'Status',
                type: FormFieldType.Autocomplete,
                options: Object.values(STATUSES).map(item => ({
                    value: item,
                    label: item
                })),
                xs: 6
            },
            {
                name: 'url',
                label: 'URL',
                xs: 6
            }
        ],
        [partners, merchants, agents, processors, banks, leads]
    );

    return (
        <>
            <FormCard
                title={'Mid Details'}
                control={control}
                fields={formFields}
            />
            <ModalLeadForm
                isOpen={isNewLeadModalOpen}
                onClose={handleCloseNewLeadModal}
                onSuccessAddLead={handleAddNewLeadByModal}
            />
        </>
    );
};
