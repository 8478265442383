import { ComponentProps, CSSProperties, useCallback, useRef } from 'react';
import {
    InputBaseComponentProps,
    TextField as MuiTextField,
    TextFieldProps,
    Autocomplete,
    Popper,
    Button,
    Box,
    FilterOptionsState
} from '@mui/material';
import { styled } from '@mui/system';
import { Control, Controller, Path, ValidationRule } from 'react-hook-form';

const TextField = styled(MuiTextField)(`
    .MuiFormHelperText: {
        position: absolute;
        bottom: -20px;
    }
`);

export interface AutocompleteOption {
    label: string;
    value: string;
}

interface Props<T = any> {
    name: Path<T>;
    options: AutocompleteOption[];
    label?: string;
    control: Control<T, object>;
    withAddOption?: boolean;
    onAddOption?: (value: string) => void;
    required?: boolean;
    pattern?: ValidationRule<RegExp>;
    inputProps?: InputBaseComponentProps;
    placeholder?: string;
    multiple: boolean;
    textFieldProps?: TextFieldProps;
    autocompleteProps?: Partial<ComponentProps<typeof Autocomplete>>;
    buttonProps?: Partial<ComponentProps<typeof Button>>;
    style?: CSSProperties;
    freeSolo?: boolean;
}

export type FormAutoCompleteTypeProps<T = any> = Props<T>;

type FormAutoCompleteType = <T = any>(
    props: FormAutoCompleteTypeProps<T>
) => JSX.Element;

const PlusButton = (props: ComponentProps<typeof Button>) => (
    <Box
        sx={{
            alignSelf: 'stretch',
            display: 'flex',
            alignItems: 'flex-end',
            marginLeft: '10px'
        }}
    >
        <Button
            {...props}
            variant="contained"
            sx={{ height: '30px', width: '30px', minWidth: '30px' }}
            // @ts-ignore
        >
            +
        </Button>
    </Box>
);

const ADD_NEW_OPTION_VALUE = 'ADD_NEW_OPTION';

const generateAddNewOption = (value: string): AutocompleteOption => ({
    value: ADD_NEW_OPTION_VALUE,
    label: `+ Add "${value}"`
});

const getFilteredOptionsByInputValue = (
    options: AutocompleteOption[],
    state: FilterOptionsState<AutocompleteOption>
) =>
    options.filter(option =>
        option.label.toLowerCase().includes(state.inputValue.toLowerCase())
    );

const containsAddNewOption = (array: AutocompleteOption[]) =>
    array.some(x => x.value === ADD_NEW_OPTION_VALUE);

export const FormAutoComplete: FormAutoCompleteType = ({
    name,
    control,
    label,
    required = false,
    withAddOption = false,
    onAddOption,
    pattern,
    inputProps,
    textFieldProps = {},
    autocompleteProps,
    options,
    placeholder,
    style,
    freeSolo,
    buttonProps,
    multiple,
    ...otherProps
}) => {
    const inputRef = useRef<HTMLElement | null>(null);
    const organizationToAddRef = useRef<string>('');

    const filterOptions = useCallback(
        (
            options: AutocompleteOption[],
            state: FilterOptionsState<AutocompleteOption>
        ) => {
            const filteredOptions = getFilteredOptionsByInputValue(
                options,
                state
            );

            if (!withAddOption) return filteredOptions;

            if (state.inputValue !== '' && filteredOptions.length === 0) {
                filteredOptions.push(generateAddNewOption(state.inputValue));
                organizationToAddRef.current = state.inputValue;
            }

            return filteredOptions;
        },
        [withAddOption]
    );
    const addNewOptionHandler = (
        data: string | AutocompleteOption[],
        onChange: (value: string | AutocompleteOption[]) => void
    ) => {
        const isArray = Array.isArray(data);

        if (isArray) {
            onChange([...data.slice(0, -1)]);
        } else {
            onChange(data);
        }

        onAddOption?.(organizationToAddRef.current);
        inputRef.current?.blur();
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Controller
                name={name}
                control={control}
                rules={{ required, pattern }}
                render={({ field: { onChange, value }, fieldState }) => {
                    let valueToPaste;

                    if (Array.isArray(value)) {
                        // For multiple
                        valueToPaste = value;
                    } else {
                        valueToPaste =
                            options.find(
                                ({ value: optValue }) => value === optValue
                            ) || (freeSolo ? value : '');
                    }

                    return (
                        <Autocomplete
                            disablePortal
                            options={options}
                            multiple={multiple}
                            filterOptions={filterOptions}
                            onChange={(
                                _e,
                                data: AutocompleteOption | AutocompleteOption[]
                            ) => {
                                const isDataArray = Array.isArray(data);

                                if (isDataArray) {
                                    if (containsAddNewOption(data)) {
                                        return addNewOptionHandler(
                                            data,
                                            onChange
                                        );
                                    }

                                    return onChange(data);
                                } else if (
                                    data?.value === ADD_NEW_OPTION_VALUE
                                ) {
                                    return addNewOptionHandler(
                                        data?.value,
                                        onChange
                                    );
                                }

                                return onChange(data?.value ?? '');
                            }}
                            value={valueToPaste}
                            renderOption={(props, option) => (
                                <li
                                    {...props}
                                    key={(option as AutocompleteOption).value}
                                >
                                    {(option as AutocompleteOption).label}
                                </li>
                            )}
                            freeSolo={freeSolo}
                            sx={{ width: '100%' }}
                            style={style}
                            forcePopupIcon
                            PopperComponent={props => (
                                <Popper
                                    {...props}
                                    modifiers={[
                                        {
                                            name: 'flip'
                                            // enabled: false
                                        }
                                    ]}
                                />
                            )}
                            {...autocompleteProps}
                            renderInput={params => (
                                <TextField
                                    label={label}
                                    placeholder={placeholder}
                                    {...params}
                                    {...textFieldProps}
                                    inputRef={inputRef}
                                    variant="standard"
                                    required={required}
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message}
                                    onChange={
                                        freeSolo
                                            ? e => onChange(e.target.value)
                                            : undefined
                                    }
                                    inputProps={{
                                        ...params.inputProps,
                                        'aria-autocomplete': 'none'
                                    }}
                                />
                            )}
                        />
                    );
                }}
            />
            {buttonProps && <PlusButton {...buttonProps} />}
        </div>
    );
};
