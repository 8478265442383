import { string, object } from 'yup';

export const schema = object().shape({
    firstName: string().required(),
    lastName: string().required(),
    email: string().required().email('Mail should be valid'),
    phoneNumber: string(),
    companyName: string(),
    accountType: string().nullable()
});
