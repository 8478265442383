import { Pagination, ServiceDto } from 'src/types';

export const SET_SERVICES = 'SET_SERVICES';
export const SET_SERVICE = 'SET_SERVICE';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_LOADING = 'SET_LOADING';

export type SetServices = {
    type: typeof SET_SERVICES;
    payload: ServiceDto[];
};

export type SetService = {
    type: typeof SET_SERVICE;
    payload: ServiceDto;
};

export type SetPagination = {
    type: typeof SET_PAGINATION;
    payload?: Pagination;
};

export type SetLoading = {
    type: typeof SET_LOADING;
};

export type ServicesActionTypes =
    | SetServices
    | SetService
    | SetLoading
    | SetPagination;

export const setServicesAction = (services: ServiceDto[]): SetServices => ({
    type: SET_SERVICES,
    payload: services
});

export const setServiceAction = (services: ServiceDto): SetService => ({
    type: SET_SERVICE,
    payload: services
});

export const setPaginationAction = (
    pagination?: Pagination
): SetPagination => ({
    type: SET_PAGINATION,
    payload: pagination
});

export const setLoadingAction = () => ({
    type: SET_LOADING
});
