import {
    BrandableServicePartnerEnumsAccountHolderType,
    BrandableServicePartnerEnumsBillingStatus,
    BrandableServicePartnerEnumsServiceStatus,
    BrandableServicePartnerEnumsStatus
} from 'src/api/swagger/partnerTypes';
import { PartnerStatusType } from 'src/types';

type Helper<T> = {
    // @ts-ignore
    [key in T]: key;
};

const makeOptions = (obj: { [key: string]: string }) => {
    return Object.values(obj).map(value => ({
        label: value,
        value
    }));
};

export const ACCOUNT_HOLDER_TYPES: Helper<BrandableServicePartnerEnumsAccountHolderType> =
    {
        Personal: 'Personal',
        Business: 'Business'
    };

export const BILLING_STATUSES: Helper<BrandableServicePartnerEnumsBillingStatus> =
    {
        Paid: 'Paid',
        Unpaid: 'Unpaid'
    };

export const STATUSES: Helper<BrandableServicePartnerEnumsStatus> = {
    Active: 'Active',
    Deactivated: 'Deactivated',
    Collections: 'Collections',
    Notes: 'Notes',
    Pending: 'Pending'
};

export const STATUS_OPTIONS = makeOptions(STATUSES);

export const SERVICE_STATUSES: Helper<BrandableServicePartnerEnumsServiceStatus> =
    {
        Billed: 'Billed',
        Flagged: 'Flagged',
        Enrolled: 'Enrolled',
        Disabled: 'Disabled'
    };

export const SERVICE_STATUSES_OPTIONS = makeOptions(SERVICE_STATUSES);
