import React, { FC, useEffect } from 'react';

import {
    Route,
    Switch,
    useLocation,
    Redirect,
    useHistory
} from 'react-router-dom';

import { MAIN_ROUTE } from 'src/constants';
import { useAuth } from 'src/api/hooks';
import { useRoutes } from 'src/api/hooks/useRoutes';
import { usePermissions } from 'src/api/hooks/usePermissions';
import { routes } from 'src/constants';
import MainLayout from 'src/pages/MainLayout';
import AuthRoutes from 'src/pages/Routes/AuthRoutes';
import { CircularProgress } from '@mui/material';
import Loader from 'src/components/Loader/Loader';

export const Routes: FC = () => {
    const history = useHistory();
    const location = useLocation();
    const { setIsLoggedInFromLocalStorage, isLoggedIn } = useAuth();

    const { permissions, isAutoPermission, isChangeOrganizationPermission } =
        usePermissions();

    let { authorizedRoutes, unauthorizedRoutes } = useRoutes();

    useEffect(() => {
        const isLoggedIn = setIsLoggedInFromLocalStorage();

        if (isLoggedIn) {
            history.push(location.pathname);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onlyOrganizationChange =
        permissions.filter(p => !isAutoPermission(p)).length === 0;

    if (!isLoggedIn) {
        return (
            <MainLayout>
                <AuthRoutes />
            </MainLayout>
        );
    }

    if (authorizedRoutes.length === 0) {
        return <Loader type="fullScreen" />;
    }

    if (onlyOrganizationChange) {
        return (
            <MainLayout>
                {authorizedRoutes.map(item =>
                    isChangeOrganizationPermission(item.permission) ? (
                        <Route
                            path={item.url}
                            component={item.component}
                            key={item.url}
                            exact
                        />
                    ) : (
                        <Route
                            path={item.url}
                            key={item.url}
                            render={() => (
                                <Redirect to={routes.organizations} />
                            )}
                        />
                    )
                )}
                {unauthorizedRoutes.map(item => (
                    <Redirect key={item.url} to={MAIN_ROUTE} />
                ))}
                <Route path="/" render={() => <Redirect to={MAIN_ROUTE} />} />
            </MainLayout>
        );
    }

    return (
        <MainLayout>
            <Switch>
                {authorizedRoutes.map(item => (
                    <Route
                        path={item.url}
                        component={item.component}
                        key={item.url}
                        exact
                    />
                ))}
                {unauthorizedRoutes.map(item => (
                    <Redirect key={item.url} to={MAIN_ROUTE} />
                ))}
                <Route path="/" render={() => <Redirect to={MAIN_ROUTE} />} />
            </Switch>
        </MainLayout>
    );
};
