import { InputAdornment } from '@mui/material';
import {
    FormAutoComplete,
    FormAutoCompleteTypeProps,
    FormInputText,
    FormInputTextTypeProps,
    FormPhoneInput,
    FormPhoneInputProps
} from 'src/components/Form';
import { FormFieldType } from 'src/types';
import FormDateTimeInput, {
    FormDateTimeInputProps
} from 'src/components/Form/FormDateTimeInput';
import { FormInputNumber } from './FormInputNumber';

interface Props<T = any> {
    type?: FormFieldType;
    inputProps:
        | FormInputTextTypeProps<T>
        | FormAutoCompleteTypeProps<T>
        | FormDateTimeInputProps<T>
        | FormPhoneInputProps<T>;
    fieldType?: string;
}

type FormInputType = <T = any>(props: Props<T>) => JSX.Element | null;

export const FormInput: FormInputType = ({ type, inputProps, fieldType }) => {
    switch (type) {
        case FormFieldType.DateTime:
            return (
                <FormDateTimeInput
                    {...(inputProps as FormDateTimeInputProps)}
                />
            );
        case FormFieldType.Phone:
            return <FormPhoneInput {...(inputProps as FormPhoneInputProps)} />;
        case FormFieldType.Autocomplete:
            return (
                <FormAutoComplete
                    {...(inputProps as FormAutoCompleteTypeProps)}
                />
            );
        case FormFieldType.AutocompleteWithAdd:
            return (
                <FormAutoComplete
                    {...(inputProps as FormAutoCompleteTypeProps)}
                />
            );

        case FormFieldType.Price:
            return (
                <FormInputNumber
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                        )
                    }}
                    inputProps={{
                        step: '0.01',
                        lang: 'en-US',
                        min: '0.00'
                    }}
                    type="number"
                    {...inputProps}
                />
            );
        case FormFieldType.Number:
            return <FormInputText type="number" {...inputProps} />;
        case FormFieldType.Input:
        default:
            return <FormInputText type={fieldType || 'text'} {...inputProps} />;
    }
};
