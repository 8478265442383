import { IAgentsApiPostPut } from 'src/types';
import { BrandableServicePartnerFeaturesAgentsModelsCreateOrUpdateCommissionPaymentInfoCommand } from 'src/api/swagger/partnerTypes';

const accountInfo = {
    address: '',
    city: '',
    zip: '',
    country: '',
    address2: '',
    state: '',
    status: undefined,
    activateAt: undefined,
    deactivateAt: undefined
};

const companyContact = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    faxNumber: ''
};

const comissionPayment: BrandableServicePartnerFeaturesAgentsModelsCreateOrUpdateCommissionPaymentInfoCommand =
    {
        accountHolder: '',
        bankRouting: '',
        bankAccount: '',
        taxId: '',
        businessType: undefined
    };

const INITIAL_VALUES: IAgentsApiPostPut = {
    name: '',
    commissionPaymentInfo: {
        ...comissionPayment
    },
    accountInfo: {
        ...accountInfo
    },
    supportContact: {
        emailAddress: '',
        phoneNumber: ''
    },
    accountingSupportContact: {
        emailAddress: '',
        phoneNumber: ''
    },
    partner: {
        id: ''
    },
    primaryContact: {
        ...companyContact
    },

    outgoingStatusOfPayment: 'Unknown',
    incomingStatusOfPayment: 'Unknown',
    notes: [],
    files: []
};

export default INITIAL_VALUES;
