import { FC } from 'react';
import { Control } from 'react-hook-form';
import {
    BillingModelEditFormType,
    BillingModelPost,
    FormFieldType,
    BillingModelType
} from 'src/types';
import { Field, FormCard } from '../../Form';
import { getAdditionalEditFormFields } from './AdditionalFields';
import { Spin } from 'src/components/Spin/Spin';
import { billingModelTypeName } from 'src/constants';

interface Props {
    control: Control<BillingModelEditFormType, object>;
    loading?: boolean;
    type?: BillingModelType;
    billByDayCustom?: boolean;
}

const fields: Field<BillingModelPost>[] = [
    {
        name: 'name',
        label: 'Name',
        required: true,
        inputProps: {
            maxLength: 200
        }
    },
    {
        name: 'description',
        label: 'Description',
        required: true,
        xs: 6
    },
    {
        name: 'type',
        label: 'Type',
        placeholder: 'Select or Search',
        type: FormFieldType.Autocomplete,
        options: Object.entries(billingModelTypeName).map(([value, label]) => ({
            value,
            label
        })),
        xs: 6
    }
];

export const BillingModelEditFormFields: FC<Props> = ({
    control,
    loading,
    type,
    billByDayCustom
}) => {
    const additionaFields = type
        ? getAdditionalEditFormFields(type, control, billByDayCustom)
        : null;

    return (
        <Spin loading={loading}>
            <FormCard
                control={control}
                fields={fields}
                cardStyle={{ paddingBottom: 80 }}
            >
                {additionaFields}
            </FormCard>
        </Spin>
    );
};
