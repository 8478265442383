import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showMessageAction } from 'src/store/actions';
import {
    setLoadingAction,
    SetPagination,
    setPaginationAction,
    setBillingModelsAction
} from 'src/store/actions/billingModels';
import {
    apiDeleteBillingModel,
    apiGetBillingModel,
    apiGetBillingModels,
    apiPostBillingModel,
    apiPutBillingModel
} from '../endpoints/billingModels';
import { getBillingModels } from 'src/store/selectors';
import {
    CommonGetParams,
    ErrorType,
    Pagination,
    BillingModelDto,
    BillingModelPost,
    BillingModelFullDto
} from 'src/types';
import { getApiCommonParams } from 'src/utils/formatters';

interface ReturnValue {
    fetchBillingModels: (commonParams?: CommonGetParams) => Promise<void>;
    deleteBillingModel: (id: string, params?: CommonGetParams) => Promise<void>;
    createBillingModel: (
        body: BillingModelPost,
        successCb?: () => void
    ) => Promise<void>;
    updateBillingModel: (
        id: string,
        body: BillingModelPost,
        successCb?: () => void
    ) => Promise<void>;
    billingModels: Record<string, BillingModelDto>;
    loading: boolean;
    errorData: ErrorType | null;
    paging?: Pagination;
    setPaging: (pagination?: Pagination) => SetPagination;
}

export const useBillingModels = (): ReturnValue => {
    const [errorData, setErrorData] = useState<ErrorType | null>(null);

    const {
        billingModels,
        loading,
        pagination: paging
    } = useSelector(getBillingModels);

    const dispatch = useDispatch();

    const setPaging = (pagination?: Pagination) =>
        dispatch(setPaginationAction(pagination));

    const setBillingModels = (billingModels: BillingModelDto[]) =>
        dispatch(setBillingModelsAction(billingModels));

    const setLoading = () => dispatch(setLoadingAction());

    const fetchBillingModels = (commonParams?: CommonGetParams) => {
        setLoading();
        setErrorData(null);

        const params = commonParams
            ? getApiCommonParams(commonParams)
            : undefined;

        return apiGetBillingModels({ params })
            .then(({ result, pagination }) => {
                setBillingModels(result);
                setPaging(pagination);
            })
            .catch((err: ErrorType) => {
                setBillingModels([]);
                setErrorData(err);
                dispatch(
                    showMessageAction({
                        message: `Failed getting billingModels: ${err}`,
                        type: 'error'
                    })
                );

                throw err;
            });
    };

    const deleteBillingModel = (id: string, commonParams?: CommonGetParams) =>
        apiDeleteBillingModel(id)
            .then(() => {
                dispatch(
                    showMessageAction({
                        message: 'Billing Model deleted successfully',
                        type: 'success'
                    })
                );
                fetchBillingModels(commonParams);
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed deleting billing model: ${err}`,
                        type: 'error'
                    })
                );
                throw err;
            });

    const createBillingModel = (
        body: BillingModelPost,
        successCalback?: () => void
    ) =>
        apiPostBillingModel(body)
            .then(() => {
                dispatch(
                    showMessageAction({
                        message: 'Billing model saved successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback();
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed saving billing model: ${err}`,
                        type: 'error'
                    })
                );
            });

    const updateBillingModel = (
        id: string,
        body: BillingModelPost,
        successCalback?: (billingModel: BillingModelDto) => void
    ) =>
        apiPutBillingModel(id, body)
            .then(billingModel => {
                dispatch(
                    showMessageAction({
                        message: 'Billing model changed successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback(billingModel);
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed changing billing model: ${err}`,
                        type: 'error'
                    })
                );
            });

    return {
        fetchBillingModels,
        deleteBillingModel,
        createBillingModel,
        updateBillingModel,
        billingModels,
        loading,
        errorData,
        paging,
        setPaging
    };
};

interface BillingModelReturnValue {
    billingModel: BillingModelFullDto | undefined;
    fetchBillingModel: (id: string) => Promise<void>;
    loading: boolean;
    errorData: ErrorType | null;
}

export const useBillingModel = (): BillingModelReturnValue => {
    const [billingModel, setBillingModel] = useState<BillingModelFullDto>();
    const [loading, setLoading] = useState(false);
    const [errorData, setErrorData] = useState<ErrorType | null>(null);

    const fetchBillingModel = (id: string) => {
        setLoading(true);
        setErrorData(null);

        return apiGetBillingModel(id)
            .then(billingModel => {
                setBillingModel(billingModel);
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        billingModel,
        fetchBillingModel,
        loading,
        errorData
    };
};
