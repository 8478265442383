import React, { FC, useCallback, useMemo } from 'react';
import { Control } from 'react-hook-form';
import { Stack } from '@mui/material';

import { FormFieldType, UserInfoDtoForm } from 'src/types';
import { Spin } from 'src/components/Spin/Spin';
import { countries, VALIDATION_MSG } from 'src/constants';
import { AutocompleteOption, FormCard } from '../../Form';
import { RolesCard } from './RolesCard';
import { UseCreateOrgModal } from '../../Organization/hooks/UseCreateOrgModal';
import { Modal } from '../../Modal';
import { CreateOrganizationForm } from '../../Organization/Forms/CreateOrganizationForm';

interface Props {
    control: Control<UserInfoDtoForm, object>;
    organizationsOptions: AutocompleteOption[];
    loading?: boolean;
    setEditRolesMode: () => void;
    roles: string[];
}

const generateFields = (
    organizations: AutocompleteOption[],
    onAddOption: (value: string) => void
) => [
    {
        name: 'firstName',
        label: 'First Name',
        required: true,
        inputProps: {
            maxLength: 100
        },
        gridProps: {
            md: 6
        }
    },
    {
        xs: 6
    },
    {
        name: 'lastName',
        label: 'Last Name',
        required: true,
        inputProps: {
            maxLength: 100
        },
        gridProps: {
            md: 6
        }
    },
    {
        xs: 6
    },
    {
        name: 'email',
        label: 'Email',
        required: true,
        pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
            message: VALIDATION_MSG.EMAIL
        },
        gridProps: {
            md: 6
        }
    },
    {
        xs: 6
    },
    {
        name: 'phoneNumber',
        label: 'Phone Number',
        required: true,
        type: FormFieldType.Phone,
        inputProps: {
            maxLength: 16
        },
        gridProps: {
            md: 6
        }
    },
    {
        xs: 6
    },
    {
        name: 'country',
        label: 'Country',
        type: FormFieldType.Autocomplete,
        options: countries,
        required: true,
        gridProps: {
            md: 6
        }
    },
    {
        xs: 6
    },
    {
        name: 'organizations',
        label: 'Organizations',
        type: FormFieldType.AutocompleteWithAdd,
        withAddOption: true,
        onAddOption,
        options: organizations || [],
        multiple: true,
        required: true,
        gridProps: {
            md: 6
        }
    }
];

export const UserEditFormFields: FC<Props> = ({
    control,
    organizationsOptions,
    loading,
    setEditRolesMode,
    roles
}) => {
    const { modalState, updateModalState, closeModal } = UseCreateOrgModal();

    const addOptionClickHandler = useCallback(
        (inputValue: string) => {
            updateModalState({ show: true, inputValue });
        },
        [updateModalState]
    );

    const handleSuccess = () => {
        closeModal();
    };

    const fieldsToDisplay = useMemo(
        () => generateFields(organizationsOptions, addOptionClickHandler),
        [addOptionClickHandler, organizationsOptions]
    );

    return (
        <Spin loading={loading}>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <FormCard
                    control={control}
                    fields={fieldsToDisplay}
                    cardStyle={{ paddingBottom: 40 }}
                />
                <RolesCard roles={roles} setEditRolesMode={setEditRolesMode} />
            </Stack>

            <Modal open={modalState.show}>
                <CreateOrganizationForm
                    organizationName={modalState.inputValue}
                    onCancel={closeModal}
                    onSuccess={handleSuccess}
                />
            </Modal>
        </Spin>
    );
};
