import React from 'react';
import {
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Popover
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { AccountType } from 'src/types';

const draftFilterConfig = [
    AccountType.Partner,
    AccountType.Merchant,
    AccountType.Agent
];

const DraftsFilter = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton onClick={handleClick} style={{ padding: '0' }}>
                <FilterAltIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <List>
                    {draftFilterConfig.map(type => (
                        <ListItem disablePadding>
                            <ListItemButton onClick={handleClose}>
                                <ListItemText primary={type} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </>
    );
};

export default DraftsFilter;
