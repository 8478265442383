import { FC } from 'react';

import { ColumnType, ServiceDto } from 'src/types';
import { Table } from '../Table';

interface Props {
    data: ServiceDto[];
    columns: ColumnType<ServiceDto>[];
    loading?: boolean;
}

export const ServicesTable: FC<Props> = ({ data, columns, loading }) => (
    <Table
        columns={columns}
        data={data}
        loading={loading}
        mobile={{ renderSummary: row => row.name }}
    />
);
