import { useQuery } from 'react-query';
import { Account, AccountStatus, AccountType } from 'src/types';
import { getApiCommonParams } from 'src/utils/formatters';
import { apiGetAccounts } from 'src/api/endpoints/accounts';
import { apiGetFile } from 'src/api/endpoints/files';

export const queryAccounts = (searchString: string) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery<Account[]>(
        ['accounts', searchString],
        () => {
            const params = searchString
                ? getApiCommonParams({ searchString })
                : undefined;

            return apiGetAccounts({ params }).then(
                ({ result }) => result

                // For mocking drafts
                //     .concat([
                //     {
                //         id: '12312312e2e',
                //         name: 'Draft 1',
                //         status: AccountStatus.Pending,
                //         created: '2021-12-10T09:06:33.634179',
                //         contact: 'Test Test',
                //         accountType: AccountType.Draft
                //     }
                // ])
            );
        },
        {
            refetchOnWindowFocus: false,
            initialData: []
        }
    );

export const queryLogosForPartnerAccounts = (partnerAccounts: Account[]) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useQuery<Account[]>(
        ['partnerAccountLogos', partnerAccounts],
        () =>
            Promise.all(
                partnerAccounts.map(x => {
                    if (!x.logo) return x;

                    return apiGetFile(x.logo.fileId).then(file => ({
                        ...x,
                        logo: {
                            fileId: x.logo.fileId,
                            link: file.link as string
                        }
                    }));
                })
            ).then(partnerAccountsWithLogos => partnerAccountsWithLogos),
        {
            refetchOnWindowFocus: false,
            initialData: []
        }
    );
