import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessageAction } from 'src/store/actions';
import { MerchantEditDto, ErrorType, MerchantCreateOrUpdate } from 'src/types';
import {
    apiGetMerchants,
    apiGetMerchant,
    apiPostMerchant,
    apiPutMerchant
} from '../endpoints/merchants';

export const useMerchants = () => {
    const [merchants, setMerchants] = useState<MerchantEditDto[]>([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        apiGetMerchants()
            .then(data => {
                setMerchants(data || []);
            })
            .catch(err => {
                dispatch(
                    showMessageAction({
                        message: `Failed getting partners: ${err}`,
                        type: 'error'
                    })
                );
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        merchants,
        loading
    };
};

export const useMerchant = () => {
    const [merchant, setMerchant] = useState<MerchantEditDto>();
    const [loading, setLoading] = useState(false);
    const [errorData, setErrorData] = useState<ErrorType | null>(null);
    const dispatch = useDispatch();

    const fetchMerchant = (id: string) => {
        setLoading(true);
        setErrorData(null);

        return apiGetMerchant(id)
            .then(merchant => {
                setMerchant(merchant);
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const createMerchant = (
        body: MerchantCreateOrUpdate,
        successCalback?: () => void
    ) =>
        apiPostMerchant(body)
            .then(() => {
                dispatch(
                    showMessageAction({
                        message: 'Merchant saved successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback();
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed saving merchant: ${err}`,
                        type: 'error'
                    })
                );
            });

    const updateMerchant = (
        id: string,
        body: Partial<MerchantCreateOrUpdate>,
        successCalback?: (merchant: MerchantCreateOrUpdate) => void
    ) =>
        apiPutMerchant(id, body)
            .then(merchant => {
                dispatch(
                    showMessageAction({
                        message: 'Merchant changed successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback(merchant);
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed changing merchant: ${err}`,
                        type: 'error'
                    })
                );
            });

    return {
        merchant,
        fetchMerchant,
        createMerchant,
        updateMerchant,
        loading,
        setLoading,
        errorData
    };
};
