import { Pagination, IAgentsApiGetById } from 'src/types';

export const SET_SUB_AFFILIATES = 'SET_SUB_AFFILIATES';
export const SET_SUB_AFFILIATE = 'SET_SUB_AFFILIATE';
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_LOADING = 'SET_LOADING';

export type SetAgents = {
    type: typeof SET_SUB_AFFILIATES;
    payload: IAgentsApiGetById[];
};

export type SetAgent = {
    type: typeof SET_SUB_AFFILIATE;
    payload?: IAgentsApiGetById;
};

export type SetPagination = {
    type: typeof SET_PAGINATION;
    payload?: Pagination;
};

export type SetLoading = {
    type: typeof SET_LOADING;
};

export type AgentsActionTypes =
    | SetAgents
    | SetAgent
    | SetLoading
    | SetPagination;

export const setAgentsAction = (agents: IAgentsApiGetById[]): SetAgents => ({
    type: SET_SUB_AFFILIATES,
    payload: agents
});

export const setAgentAction = (agent?: IAgentsApiGetById): SetAgent => ({
    type: SET_SUB_AFFILIATE,
    payload: agent
});

export const setPaginationAction = (
    pagination?: Pagination
): SetPagination => ({
    type: SET_PAGINATION,
    payload: pagination
});

export const setLoadingAction = () => ({
    type: SET_LOADING
});
