import { useState } from 'react';
import { apiPostRdrAlert } from 'src/api/endpoints/rdrAlerts';
import { FileInput } from 'src/components/Form/FileInput';
import Loader from 'src/components/Loader/Loader';
import { IFilePutResponse } from 'src/types';

export const RdrsUpload = () => {
    const [isLoading, setIsLoading] = useState(false);

    const onStartUploadFile = () => {
        setIsLoading(true);
    };

    const onSuccessUploadFile = async (file: IFilePutResponse) => {
        try {
            await apiPostRdrAlert({
                originalFileName: file.originalFileName,
                prefix: file.prefix
            });
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onErrorUploadFile = () => {
        setIsLoading(false);
    };

    return (
        <Loader loading={isLoading}>
            <FileInput
                title="Upload"
                onStartUploadFile={onStartUploadFile}
                onSuccessUploadFile={onSuccessUploadFile}
                onErrorUploadFile={onErrorUploadFile}
                inputAccept=".xls, .xlsx"
                mimeTypes={[
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel'
                ]}
                withIcon={false}
            />
        </Loader>
    );
};
