import { FC } from 'react';
import { Button, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from 'src/api/hooks';
import { AppBar } from './styles';

interface Props {
    toggleDrawer?: () => void;
    open?: boolean;
    showLogOutButton?: boolean;
    showNavbar?: boolean;
}

export const Header: FC<Props> = ({
    toggleDrawer,
    open,
    showLogOutButton,
    showNavbar = true
}) => {
    const { logOut } = useAuth();

    return (
        <AppBar open={open}>
            <Toolbar>
                {!!toggleDrawer && !showNavbar && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' })
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                {showLogOutButton && (
                    <Button
                        onClick={logOut}
                        variant="contained"
                        sx={{ marginLeft: 'auto' }}
                    >
                        Log out
                    </Button>
                )}
            </Toolbar>
        </AppBar>
    );
};
