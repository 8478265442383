import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, Modal } from '@mui/material';
import { INote } from 'src/types/partners/common';
import NewNote from 'src/components/Cards/NotesCard/NewNote';
import NotesTable from 'src/components/Cards/NotesCard/NotesTable';
import ModalEditNote from './ModalEditNote';

type NotesFormProps = {
    notes?: INote[];
    onAdd: (noteString: string) => void;
    onDelete: (note: INote) => void;
    onEdit: (note: INote, prevnote: INote) => void;
};

const NotesCard: React.FC<NotesFormProps> = ({
    notes = [],
    onAdd,
    onDelete,
    onEdit
}) => {
    const [noteToEdit, setNoteToEdit] = useState<INote | null>(null);

    return (
        <Card sx={{ width: '100%' }}>
            <CardContent>
                <Grid container spacing={2} sx={{ overflowX: 'auto' }}>
                    <Grid item md={12} lg={4}>
                        <NewNote onAdd={onAdd} />
                    </Grid>
                    <Grid item md={12} lg={8}>
                        <Typography variant="h6" component="div">
                            History
                        </Typography>

                        <NotesTable
                            notes={notes}
                            onDelete={onDelete}
                            onEdit={(note: INote) => setNoteToEdit(note)}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <ModalEditNote
                noteToEdit={noteToEdit}
                onClose={() => {
                    setNoteToEdit(null);
                }}
                onEdit={onEdit}
            />
        </Card>
    );
};

export default NotesCard;
