import ClearIcon from '@mui/icons-material/Clear';
import {
    Button,
    IconButton,
    Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import CardWrapper from 'src/components/Card/CardWrapper';
import { Modal } from 'src/components/Modal';
import { MerchantCreateOrUpdate } from 'src/types';

const RDRTableRow = ({
    rdrToken,
    onChange
}: {
    rdrToken: string;
    onChange: (...events: any[]) => void;
}) => {
    const handleDeleteRdrToken = () => {
        onChange('');
    };

    return (
        <TableRow>
            {rdrToken ? (
                <>
                    <TableCell>{rdrToken}</TableCell>
                    <TableCell sx={{ width: '50px' }}>
                        <IconButton size="small" onClick={handleDeleteRdrToken}>
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    </TableCell>
                </>
            ) : (
                <TableCell>
                    <Typography variant="subtitle1">No RDR token</Typography>
                </TableCell>
            )}
        </TableRow>
    );
};

export const RDRCard = ({
    control
}: {
    control: Control<MerchantCreateOrUpdate>;
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newToken, setNewToken] = useState('');

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        setNewToken(e.target.value);
    };

    return (
        <Controller
            name="rdrToken"
            control={control}
            render={({ field: { onChange, value: rdrToken } }) => {
                const handleChangeToken = () => {
                    onChange(newToken);
                    handleCloseModal();
                };

                return (
                    <>
                        <CardWrapper title="RDR">
                            <TableContainer
                                component={Paper}
                                sx={{ margin: '15px 0' }}
                            >
                                <Table sx={{ borderCollapse: 'unset' }}>
                                    <TableHead>
                                        <TableRow
                                            sx={{ th: { fontWeight: 'bold' } }}
                                        >
                                            <TableCell>Value</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <RDRTableRow
                                            onChange={onChange}
                                            rdrToken={rdrToken || ''}
                                        />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button
                                variant="contained"
                                onClick={() => setIsModalOpen(true)}
                            >
                                Add new RDR
                            </Button>
                        </CardWrapper>
                        <Modal
                            title="Add new RDR"
                            open={isModalOpen}
                            okText="Add RDR"
                            onOk={handleChangeToken}
                            onClose={handleCloseModal}
                        >
                            <Input
                                value={newToken}
                                onChange={handleInputChange}
                                placeholder="Type RDR"
                            />
                        </Modal>
                    </>
                );
            }}
        />
    );
};
