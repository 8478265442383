import { Pagination, BillingModelDto } from 'src/types';
import {
    BillingModelsActionTypes,
    SET_LOADING,
    SET_PAGINATION,
    SET_BILLING_MODELS
} from '../actions/billingModels';

export interface BillingModelsState {
    billingModels: Record<string, BillingModelDto>;
    loading: boolean;
    pagination: Pagination | undefined;
}
const initialState: BillingModelsState = {
    billingModels: {},
    loading: false,
    pagination: undefined
};

export const billingModels = (
    state: BillingModelsState = initialState,
    action: BillingModelsActionTypes
): BillingModelsState => {
    switch (action.type) {
        case SET_BILLING_MODELS:
            return {
                ...state,
                billingModels:
                    action.payload?.reduce<Record<string, BillingModelDto>>(
                        (prev, curr) => ({
                            ...prev,
                            [curr.id]: curr
                        }),
                        {}
                    ) || {},
                loading: false
            };
        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
