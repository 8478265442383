import cloneDeep from 'lodash/cloneDeep';
import {
    Condition,
    ServiceEditFormType,
    ServicePost,
    ServicesFilterForm
} from 'src/types';
import { getFilterWithoutEmptyValues } from './formatters';

export const getServiceFromForm = (values: ServiceEditFormType) => {
    const { id, ...data } = cloneDeep(values);

    const body = Object.entries(data).reduce(
        (prev, [key, value]) => ({
            ...prev,
            [key]: key === 'category' ? { id: value } : value
        }),
        {}
    );

    return body as ServicePost;
};

export const getServicesFilterParam = (
    filterForm: Partial<ServicesFilterForm>
) => {
    const readyValues = getFilterWithoutEmptyValues(filterForm);

    return Object.entries(readyValues).reduce((prev, [key, values]) => {
        const fullName = key === 'category' ? 'category.id' : key;
        return `${prev}${prev ? ',' : ''}${fullName}${
            values?.condition === Condition.Equals ? '=' : '%'
        }${values?.value}`;
    }, '');
};
