import { Table } from 'src/components/Table';
import { ColumnType } from 'src/types';
import { IAlert } from 'src/types/alerts';
import { RdrAlert } from 'src/types/rdrAlerts';
import { getDateMonthDayYear } from 'src/utils/date';

const columns: ColumnType<RdrAlert>[] = [
    {
        title: 'ID',
        dataIndex: 'id'
    },
    {
        title: 'File name',
        dataIndex: 'originalFileName',
        render: (_, row) => (
            <a target="_blank" rel="noopener noreferrer" href={row.filePath}>
                {row.originalFileName}
            </a>
        )
    },
    {
        title: 'Status',
        dataIndex: 'processingStatus'
    },
    {
        title: 'Created By',
        dataIndex: 'createdBy'
    },
    {
        title: 'Created At',
        dataIndex: 'created',
        render: (_, row) => getDateMonthDayYear(row.created)
    }
];

export const RdrsTable = ({
    rdrAlerts,
    loading
}: {
    rdrAlerts: IAlert[];
    loading?: boolean;
}) => (
    <Table
        columns={columns}
        data={rdrAlerts}
        loading={loading}
        mobile={{ renderSummary: row => row.originalFileName }}
    />
);
