import { ChangeEvent, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { useRoles } from 'src/api/hooks';
import { PAGE_SIZE } from 'src/constants';
import { Pagination } from 'src/components/Pagination';
import { RolesTable } from 'src/components/Roles/RolesTable';

export const Roles = () => {
    const { fetchRoles, roles, loading, pagination, deleteRole } = useRoles();

    const { totalPages } = pagination || {};
    const [pageNumber, setPageNumber] = useState(0);

    const getRoles = (pageNumber: number, innerSearchString?: string) => {
        fetchRoles({
            pageNumber: `${pageNumber}`,
            pageSize: PAGE_SIZE
        });
    };

    useEffect(() => {
        getRoles(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePageChange = (e: ChangeEvent<unknown>, page: number) => {
        setPageNumber(page - 1);
        getRoles(page - 1);
    };

    return (
        <Stack spacing={2}>
            {/*
            //TODO: return when completely process manually added roles
            <Stack justifyContent="flex-end" direction="row">
                <Button
                    variant="contained"
                    onClick={() => history.push(routes.permissionsRoleNew)}
                >
                    Add Role
                </Button>
            </Stack>*/}
            <RolesTable
                data={Object.values(roles)}
                loading={loading}
                onDelete={deleteRole}
            />
            {!isEmpty(roles) && (
                <Pagination
                    page={pageNumber + 1}
                    onChange={handlePageChange}
                    count={totalPages}
                />
            )}
        </Stack>
    );
};
