import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { routes } from 'src/constants';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { PermissionType } from 'src/types/permissions';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ContactsIcon from '@mui/icons-material/Contacts';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useRoutes } from 'src/api/hooks/useRoutes';
import { useHistory } from 'react-router';

const ButtonsCreateAccounts = () => {
    const history = useHistory();

    const { authorizedRoutes } = useRoutes();

    return (
        <div>
            <Tooltip title="Create lead">
                <IconButton onClick={() => history.push(routes.leadsNew)}>
                    <AssignmentIndIcon color="action" />
                </IconButton>
            </Tooltip>
            {authorizedRoutes.some(
                r => r.permission === PermissionType.AgentEdit
            ) && (
                <Tooltip title="Create partner">
                    <IconButton
                        onClick={() => history.push(routes.partnersNew)}
                    >
                        <AddBusinessIcon color="action" />
                    </IconButton>
                </Tooltip>
            )}
            {authorizedRoutes.some(
                r => r.permission === PermissionType.MerchantEdit
            ) && (
                <Tooltip title="Create merchant">
                    <IconButton
                        onClick={() => history.push(routes.merchantsNew)}
                    >
                        <ContactsIcon color="action" />
                    </IconButton>
                </Tooltip>
            )}
            {authorizedRoutes.some(
                r => r.permission === PermissionType.AgentEdit
            ) && (
                <Tooltip title="Create agent">
                    <IconButton onClick={() => history.push(routes.agentsNew)}>
                        <AddBoxIcon color="action" />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

export default ButtonsCreateAccounts;
