import React, { useCallback, useEffect, useState } from 'react';

import { debounce } from 'lodash';

import { uploadFileRequest } from 'src/api/requests/files.requests';
import { CircularProgress, Input } from '@mui/material';
import { FileLoaderPropsType } from 'src/types';
import { useDispatch } from 'react-redux';
import { showMessageAction } from 'src/store/actions';

interface FormImageByLinkInputProps extends FileLoaderPropsType {}

const FileByLinkInput = ({
    onErrorUploadFile,
    onStartUploadFile,
    onSuccessUploadFile
}: FormImageByLinkInputProps) => {
    const dispatch = useDispatch();
    const [httpLink, setHttpLink] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [loadingHttpLink, setLoadingHttpLink] = useState(false);
    const [isNotImage, setIsNotImage] = useState(false);

    const onChangeHttpLink = useCallback(
        debounce(e => {
            setHttpLink(e.target.value);
            setLoadingHttpLink(false);
        }, 1000),
        [debounce]
    );

    const onChangeInput: React.ChangeEventHandler<HTMLInputElement> = e => {
        setIsNotImage(false);
        setLoadingHttpLink(true);
        setInputValue(e.target.value);
        onChangeHttpLink(e);
    };

    useEffect(() => {
        if (httpLink) {
            fetch(httpLink)
                .then(async response => {
                    const blob = await response.blob();
                    if (blob.type.includes('image')) {
                        onStartUploadFile();
                        const file = new File([blob], httpLink, {
                            type: blob.type
                        });
                        uploadFileRequest(file)
                            .then(imageFile => {
                                onSuccessUploadFile(imageFile);
                                setInputValue('');
                            })
                            .catch(e => {
                                onErrorUploadFile(e);
                            });
                    } else {
                        setIsNotImage(true);
                    }
                })
                .catch(e => {
                    console.error(e.message);
                    dispatch(
                        showMessageAction({
                            message: 'Cannot upload from this url',
                            type: 'warning'
                        })
                    );
                });
        } else {
            setIsNotImage(false);
        }
    }, [httpLink]);

    return (
        <>
            {loadingHttpLink && <CircularProgress size={20} />}
            <Input
                value={inputValue}
                onChange={onChangeInput}
                placeholder="https://"
                sx={{ marginLeft: '20px' }}
                error={isNotImage}
            />
        </>
    );
};

export default FileByLinkInput;
