import React, { FC, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import { ColumnType } from 'src/types';
import { Control, useFieldArray } from 'react-hook-form';
import { ActionsTableButton } from 'src/components/TablePage/ActionsTableButton';
import { Table } from 'src/components/Table';
import { MidServiceEditModal } from './MidServiceEditModal';
import { extendedBillingModelTypeName } from 'src/constants';
import { IMIDFeeScheduleListDto } from 'src/types/payments';
import IMidEditForm from 'src/pages/MIDs/types';

interface Props {
    services: IMIDFeeScheduleListDto[];
    loading: boolean;
    control: Control<IMidEditForm, object>;
}

export const ServicesForm: FC<Props> = ({ services, loading, control }) => {
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'services'
    });
    const [showEditServiceModal, setShowEditServiceModal] = useState(false);
    const [currentServiceId, setCurrentServiceId] = useState<string | null>(
        null
    );

    const handleChangeService = (serviceData: any) => {
        const index = fields.findIndex(
            ({ serviceId }) => serviceId === currentServiceId
        );
        setShowEditServiceModal(false);
        if (currentServiceId) {
            update(index, {
                ...fields[index],
                ...serviceData
            });
        } else {
            append(serviceData);
        }
    };

    const handleAddService = () => {
        setCurrentServiceId(null);
        setShowEditServiceModal(true);
    };

    const handleEditService = (serviceId: string) => {
        setCurrentServiceId(serviceId);
        setShowEditServiceModal(true);
    };

    const handleCloseServiceModal = () => {
        setCurrentServiceId(null);
        setShowEditServiceModal(false);
    };

    const handleRemoveService = (id: string) => {
        const index = fields.findIndex(({ serviceId }) => serviceId === id);
        remove(index);
    };

    const columns: ColumnType<any>[] = [
        {
            title: 'Services',
            dataIndex: 'serviceId',
            render: (_, { serviceId }) =>
                services.find(({ service: { id } }) => id === serviceId)
                    ?.service.name || serviceId
        },
        {
            title: 'Billing Model',
            dataIndex: 'billingModelId',
            render: (_, { billingModelId }: { billingModelId: string }) =>
                // @ts-ignore
                extendedBillingModelTypeName[billingModelId] || billingModelId
        },
        {
            title: 'Price',
            dataIndex: 'cost',
            render: (_, { cost }) => `$${cost}`
        },
        {
            title: 'Date Added',
            dataIndex: 'date'
        },
        {
            title: 'Status',
            dataIndex: 'status'
        },
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            width: '60px',
            style: { paddingLeft: 0, paddingRight: 14 },
            render: (_, { serviceId }) => (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                >
                    <ActionsTableButton
                        menuItems={[
                            {
                                title: 'Edit',
                                onClick: () => handleEditService(serviceId)
                            },
                            {
                                title: 'Delete',
                                onClick: () => handleRemoveService(serviceId)
                            }
                        ]}
                    />
                </Stack>
            )
        }
    ];

    return (
        <Card sx={{ width: '100%' }}>
            <CardContent
                style={{ padding: 28, paddingBottom: 28, paddingTop: 20 }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ marginBottom: '15px' }}
                >
                    Services
                </Typography>
                <Grid
                    container
                    spacing={2}
                    style={{
                        minHeight: 200,
                        paddingLeft: 10,
                        paddingTop: 20
                    }}
                >
                    <Table columns={columns} data={fields} loading={loading} />
                    <Button
                        onClick={handleAddService}
                        variant="contained"
                        sx={{ marginTop: '15px' }}
                    >
                        Add Service
                    </Button>
                    <MidServiceEditModal
                        currentService={fields.find(
                            ({ serviceId }) => serviceId === currentServiceId
                        )}
                        services={services}
                        open={showEditServiceModal}
                        onCancel={handleCloseServiceModal}
                        onSubmit={handleChangeService}
                    />
                </Grid>
            </CardContent>
        </Card>
    );
};
