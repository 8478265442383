import React from 'react';
import { IMIDEditDto } from 'src/types';
import CardWrapper from 'src/components/Card/CardWrapper';
import Paper from '@mui/material/Paper';
import {
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography
} from '@mui/material';

const MidActivitiesCard = ({
    activities
}: {
    activities: IMIDEditDto['activities'];
}) => {
    console.log('activities', activities);
    if (!activities) {
        return null;
    }

    return (
        <CardWrapper title="Activities">
            <TableContainer
                component={Paper}
                sx={{ marginTop: 2, overflow: 'hidden' }}
            >
                <Table
                    sx={{ minWidth: 500, maxWidth: '100%' }}
                    size="small"
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow sx={{ background: '#e1e1e1' }}>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {activities.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Typography variant="subtitle1">
                                        No activities
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        {activities.map(({ id, name, type, timestamp }) => {
                            return (
                                <TableRow key={id}>
                                    <TableCell>{name}</TableCell>
                                    <TableCell align="right">{type}</TableCell>
                                    <TableCell align="right">
                                        {timestamp}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </CardWrapper>
    );
};

export default MidActivitiesCard;
