import { Stack } from '@mui/material';
import { History } from 'history';
import { Link } from 'react-router-dom';
import { ActionsTableButton } from 'src/components/TablePage/ActionsTableButton';
import { routes } from 'src/constants';
import { ColumnType, ServiceDto, ServiceStatusesExtendedEnum } from 'src/types';
import { getDateMonthDayYear } from 'src/utils/date';
import { generateActionMenuItems } from './actionMenu';

export type GenerateFieldsArgs = {
    serviceId: string;
    type: ServiceStatusesExtendedEnum;
    onDelete: (id: string) => void;
    onArchive: (id: string) => void;
    onDearchive: (id: string) => void;
    history: History;
};

export const generateFields = ({
    onDelete,
    onArchive,
    onDearchive,
    type,
    history
}: Omit<GenerateFieldsArgs, 'serviceId'>): ColumnType<ServiceDto>[] => [
    {
        title: 'ID',
        dataIndex: 'id'
    },
    {
        title: 'Service',
        dataIndex: 'name',
        render: (service, record) => (
            <Link to={routes.service(record.id)}>{service}</Link>
        )
    },
    {
        title: 'Type',
        dataIndex: 'type',
        render: () => 'Service'
    },
    {
        title: 'SKU ID',
        dataIndex: 'skuId'
    },
    {
        title: 'Category',
        dataIndex: 'category',
        render: (value: ServiceDto['category']) => value?.name
    },
    {
        title: 'Cost',
        dataIndex: 'cost'
    },
    {
        title: 'Price Model',
        dataIndex: 'priceModel',
        render: value => value || 'N/A'
    },
    {
        title: 'Subscription',
        dataIndex: 'subscription',
        render: (value: ServiceDto['subscription']) => value.toString()
    },
    {
        title: 'Updated',
        dataIndex: 'lastUpdate',
        render: (value: ServiceDto['lastUpdate'], record) =>
            getDateMonthDayYear(value || record.created)
    },
    {
        title: '',
        dataIndex: 'actions',
        align: 'center',
        width: '60px',
        style: { paddingLeft: 0, paddingRight: 14 },
        render: (_, { id }) => (
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
            >
                <ActionsTableButton
                    menuItems={generateActionMenuItems({
                        history,
                        onDelete,
                        onArchive,
                        onDearchive,
                        serviceId: id,
                        type
                    })}
                />
            </Stack>
        )
    }
];
