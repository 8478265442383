import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/system';

export const Link = styled(RouterLink)(
    ({ theme }) => `
        color: #5467B6;
        text-decoration: none;

        &:hover {
            opacity: 0.8;
        }
    `
);
