import { getUserPermissions } from 'src/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionId, PermissionType } from 'src/types/permissions';
import { setUserPermissionsAction } from 'src/store/actions/permissions';
import { useEffect } from 'react';
import { getAuth } from '../../store/selectors';

export const usePermissions = () => {
    const { permissions } = useSelector(getUserPermissions);
    const { userData, userOrganizationData } = useSelector(getAuth);

    const dispatch = useDispatch();

    const setUserPermissions = (userPermissions: PermissionType[]) => {
        dispatch(setUserPermissionsAction(userPermissions));
    };

    useEffect(() => {
        setUserPermissions(
            userOrganizationData?.permissionIds ?? userData?.permissionIds ?? []
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, userOrganizationData]);

    const mapPermissions = (permissionIds: number[]): PermissionType[] => {
        const permissionTypes: PermissionType[] = [];
        permissionIds.forEach(p => {
            permissionTypes.push.apply(
                permissionTypes,
                mapPermissionIdToPermissionType(p)
            );
        });

        if (
            permissionTypes.some(
                p =>
                    p === PermissionType.AgentsView ||
                    p === PermissionType.MerchantsView ||
                    p === PermissionType.PartnersView
            )
        ) {
            permissionTypes.push(PermissionType.AccountsView);
            permissionTypes.push(PermissionType.AccountEdit);
        }

        permissionTypes.push.apply(permissionTypes, getAutoPermissions());

        return permissionTypes;
    };

    const isAutoPermission = (permissionId: number) =>
        getAutoPermissions().some(p => p === permissionId);

    const isChangeOrganizationPermission = (permissionId: number) =>
        permissionId === PermissionType.OrganizationsView ||
        permissionId === PermissionType.OrganizationEdit;

    function getAutoPermissions(): PermissionType[] {
        return [
            PermissionType.Dashboard,
            PermissionType.OrganizationsView,
            PermissionType.OrganizationEdit,
            PermissionType.Login
        ];
    }

    const isOnlyOrganizationPermissions = (permissions: number[]): boolean =>
        permissions.filter(p => !isAutoPermission(p)).length === 0;

    function mapPermissionIdToPermissionType(
        permissionId: number
    ): PermissionType[] {
        const permissionTypes: PermissionType[] = [];
        switch (permissionId) {
            case PermissionId.AccountBankingInfoManagement:
                permissionTypes.push(PermissionType.ViewBankingInfo);
                permissionTypes.push(PermissionType.EditBankingInfo);
                return permissionTypes;
            case PermissionId.AccountCompanyInfoManagement:
                permissionTypes.push(PermissionType.ViewCompanyInfo);
                permissionTypes.push(PermissionType.EditCompanyInfo);
                return permissionTypes;
            case PermissionId.AccountOrganizationManagement:
                permissionTypes.push(PermissionType.OrganizationEdit);
                return permissionTypes;
            case PermissionId.AccountPrimaryUserManagement:
                permissionTypes.push(PermissionType.ViewPrimaryUser);
                permissionTypes.push(PermissionType.EditPrimaryUser);
                return permissionTypes;
            case PermissionId.PartnersManagement:
                permissionTypes.push(PermissionType.PartnersView);
                permissionTypes.push(PermissionType.PartnerEdit);
                return permissionTypes;
            case PermissionId.AgentsManagement:
                permissionTypes.push(PermissionType.AgentsView);
                permissionTypes.push(PermissionType.AgentEdit);
                return permissionTypes;
            case PermissionId.MerchantsManagement:
                permissionTypes.push(PermissionType.MerchantEdit);
                permissionTypes.push(PermissionType.MerchantsView);
                return permissionTypes;
            case PermissionId.RolesManagement:
                permissionTypes.push(PermissionType.RolesView);
                permissionTypes.push(PermissionType.RoleEdit);
                return permissionTypes;
            case PermissionId.UserManagement:
                permissionTypes.push(PermissionType.UsersView);
                permissionTypes.push(PermissionType.UserEdit);
                return permissionTypes;
            case PermissionId.ServicesManagement:
                permissionTypes.push(PermissionType.ServicesView);
                permissionTypes.push(PermissionType.ServiceEdit);
                return permissionTypes;
            case PermissionId.BillingModelsManagement:
                permissionTypes.push(PermissionType.BillingModelsView);
                permissionTypes.push(PermissionType.BillingModelEdit);
                return permissionTypes;
            case PermissionId.CategoriesManagement:
                permissionTypes.push(PermissionType.CategoriesView);
                permissionTypes.push(PermissionType.CategoryEdit);
                return permissionTypes;
            case PermissionId.CompanyContactManagement:
                permissionTypes.push(PermissionType.ViewCompanyContact);
                permissionTypes.push(PermissionType.EditCompanyContact);
                return permissionTypes;
            case PermissionId.FeeScheduleManagement:
                permissionTypes.push(PermissionType.FeeScheduleView);
                permissionTypes.push(PermissionType.FeeScheduleEdit);
                return permissionTypes;
            case PermissionId.MIDManagement:
                permissionTypes.push(PermissionType.MidsView);
                permissionTypes.push(PermissionType.MidEdit);
                return permissionTypes;
            default: {
                return permissionTypes;
            }
        }
    }

    return {
        permissions,
        mapPermissions,
        setUserPermissions,
        isAutoPermission,
        getAutoPermissions,
        isOnlyOrganizationPermissions,
        isChangeOrganizationPermission
    };
};
