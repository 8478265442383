import { ICreateOrUpdateMID, IMIDBankDto, IMIDProcessorsDto } from 'src/types';
import IMidEditForm from 'src/pages/MIDs/types';

export const getMidsFromForm = (
    data: IMidEditForm,
    banks: IMIDBankDto[],
    processors: IMIDProcessorsDto[]
): ICreateOrUpdateMID => {
    const {
        bankMid = '',
        processor = '',
        partner,
        agent,
        notes,
        ...otherData
    } = data;

    const newBankMid = banks.find(({ id }) => id === bankMid)
        ? { id: bankMid }
        : { name: bankMid };

    const newProcessor = processors.find(({ id }) => id === processor)
        ? { id: processor }
        : { name: processor };

    const additionalFields: Partial<Record<string, any>> = {};

    if (partner?.id) {
        additionalFields.partner = partner;
    }
    if (agent?.id) {
        additionalFields.agent = agent;
    }
    // if (notes?.id || notes.note) {
    //     additionalFields.notes = [notes];
    // }

    return {
        ...otherData,
        bankMid: newBankMid,
        processor: newProcessor,
        ...additionalFields
    };
};
