import { ApiResponse, FilePost, FilePostResponse } from 'src/types';
import { IAlert, IApiGetAlertsResponse } from 'src/types/alerts';
import { CommonApiGetParams } from '../../types/common';
import { apiClient } from '../client';
import { withDefaultHandlersPagination } from '../helpers/withDefaultHandlers';

const BASE_PATH = '/verifi/rdr-api';

export const apiGetRdrAlerts = ({
    params
}: {
    params?: CommonApiGetParams;
}): Promise<IApiGetAlertsResponse> =>
    withDefaultHandlersPagination(
        apiClient.get<ApiResponse<IAlert[]>>(BASE_PATH, params)
    );

export const apiPostRdrAlert = (rdrAlert: FilePost) =>
    apiClient.post<ApiResponse<FilePostResponse>>(BASE_PATH, rdrAlert);
