import { Grid, InputAdornment, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { Control } from 'react-hook-form';
import { Field, FormInput } from 'src/components/Form';
import { BillByScheduleForm } from './BillByScheduleForm';
import {
    BillingModelPost,
    BillingModelType,
    BillingModelEditFormType,
    FormFieldType,
    BillByDaySelectedWeek,
    BillByRelativeDateFrequency,
    BillByDaySelectedDay
} from 'src/types';

const Fields = (
    billByDayCustom?: boolean
): Record<BillingModelType, AdditionaField[]> => ({
    [BillingModelType.BillByCycle]: [
        {
            title: 'Days to Next Billing',
            description: 'Subscription will bill every N days',
            formFields: [
                {
                    name: 'billByCycleDays',
                    label: 'Every',
                    type: FormFieldType.Number,
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position="end">Days</InputAdornment>
                        )
                    },
                    inputProps: {
                        min: '0'
                    }
                }
            ]
        }
    ],
    [BillingModelType.BillByDate]: [
        {
            title: 'Billing Date',
            description: 'Subscription will bill on Nth day of each month',
            formFields: [
                {
                    name: 'billByDateDayOfMonth',
                    label: 'Day of Month',
                    type: FormFieldType.Number,
                    inputProps: {
                        min: '0',
                        max: '31'
                    }
                }
            ]
        }
    ],
    [BillingModelType.BillByDay]: billByDayCustom
        ? [
              {
                  title: 'Billing Day',
                  description:
                      'Subscription will bill on the specified day of each month',
                  formFields: [
                      {
                          name: 'billByDaySelectedWeek',
                          label: 'Week',
                          type: FormFieldType.Autocomplete,
                          options: Object.values(BillByDaySelectedWeek).map(
                              value => ({ label: value, value })
                          ),
                          placeholder: 'Select or Search'
                      },
                      {
                          name: 'every',
                          label: 'Every',
                          type: FormFieldType.Number,
                          InputProps: {
                              endAdornment: (
                                  <InputAdornment position="end">
                                      Week(s)
                                  </InputAdornment>
                              )
                          },
                          inputProps: {
                              min: '0'
                          }
                      },
                      {
                          name: 'billByDaySelectedDay',
                          label: 'On',
                          type: FormFieldType.Autocomplete,
                          options: Object.values(BillByDaySelectedDay).map(
                              value => ({
                                  label: value,
                                  value
                              })
                          ),
                          placeholder: 'Select or Search'
                      }
                  ]
              },
              {
                  title: 'For orders placed on or before',
                  description: 'This will only apply to the initial order',
                  formFields: [
                      {
                          name: 'orderplacedbefore',
                          label: 'On',
                          type: FormFieldType.Autocomplete,
                          options: Object.values(BillByDaySelectedDay).map(
                              value => ({
                                  label: value,
                                  value
                              })
                          ),
                          placeholder: 'Select or Search'
                      }
                  ]
              }
          ]
        : [
              {
                  title: 'Billing Day',
                  description:
                      'Subscription will bill on the specified day of each month',
                  formFields: [
                      {
                          name: 'billByDaySelectedWeek',
                          label: 'Week',
                          type: FormFieldType.Autocomplete,
                          options: Object.values(BillByDaySelectedWeek).map(
                              value => ({ label: value, value })
                          ),
                          placeholder: 'Select or Search'
                      },
                      {
                          name: 'billByDaySelectedDay',
                          label: 'Day',
                          type: FormFieldType.Autocomplete,
                          options: Object.values(BillByDaySelectedDay).map(
                              value => ({
                                  label: value,
                                  value
                              })
                          ),
                          placeholder: 'Select or Search'
                      }
                  ]
              }
          ],
    [BillingModelType.BillByRelativeDate]: [
        {
            title: 'Description',
            description:
                'Subscription will bill on a relative schedule every N intervals',
            formFields: [
                {
                    name: 'billByDateDayOfMonth',
                    label: 'Day of Month',
                    type: FormFieldType.Number,
                    inputProps: {
                        min: '0',
                        max: '31'
                    }
                },
                {
                    name: 'billByRelativeDateFrequency',
                    label: 'Frequency',
                    type: FormFieldType.Autocomplete,
                    options: Object.values(BillByRelativeDateFrequency).map(
                        value => ({
                            label: value,
                            value
                        })
                    ),
                    placeholder: 'Select or Search'
                }
            ]
        }
    ],
    [BillingModelType.BillBySchedule]: [
        {
            title: 'Billing Schedule',
            description:
                'Subscription will bill on the specified day of each month',
            formFields: [
                {
                    name: 'billByScheduleBufferDays',
                    label: 'Buffer Days',
                    required: true,
                    type: FormFieldType.Number,
                    inputProps: {
                        min: '0'
                    }
                }
            ]
        },
        {
            title: 'Billing Schedule',
            description:
                'Subscription will bill on the specified day of each month',
            formFields: [
                {
                    name: 'billByScheduleBufferDays',
                    label: 'Buffer Days',
                    Component: <BillByScheduleForm />
                }
            ]
        }
    ],
    [BillingModelType.Event]: []
});

interface AdditionaField {
    title: string;
    description?: string;
    formFields: (Field<BillingModelPost> & { Component?: ReactNode })[];
}
interface Props {
    fields: AdditionaField[];
    control: Control<BillingModelEditFormType, object>;
}

export const AdditionalFieldsGrid = ({ fields, control }: Props) =>
    fields.length > 0 ? (
        <Grid container spacing={2} mt={2}>
            {fields.map(({ title, description, formFields }, index) => (
                <React.Fragment key={index}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2">{title}</Typography>
                        <Typography variant="body2">{description}</Typography>
                    </Grid>
                    {formFields.map(
                        ({ name, type, Component, ...otherProps }, index) => (
                            <React.Fragment key={name || index}>
                                {index > 0 && <Grid item xs={6} />}
                                <Grid item xs={6}>
                                    {Component}
                                    {!Component && name && (
                                        <FormInput
                                            type={type}
                                            inputProps={{
                                                name,
                                                control,
                                                fullWidth: true,
                                                ...otherProps
                                            }}
                                        />
                                    )}
                                </Grid>
                            </React.Fragment>
                        )
                    )}
                </React.Fragment>
            ))}
        </Grid>
    ) : null;

export const getAdditionalEditFormFields = (
    type: BillingModelType,
    control: Control<BillingModelEditFormType, object>,
    billByDayCustom?: boolean
): ReactNode => {
    if (type) {
        return (
            <AdditionalFieldsGrid
                control={control}
                fields={Fields(billByDayCustom)[type] || []}
            />
        );
    }
    return null;
};
