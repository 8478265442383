import { FC } from 'react';
import {
    Card,
    CardContent,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { SearchFilters } from './SearchFilters';
import { DropdownMenuItemProps } from '../DropdownMenu';
import ArchiveIcon from '@mui/icons-material/Archive';

interface Props {
    onSearchChange: (value: string) => void;
    onRefresh: () => void;
    openFilter: () => void;

    isArchivedView?: boolean;
    enableArchiveMode?: boolean;
    onViewChange?: () => void;

    actionsMenuItems?: DropdownMenuItemProps[];
    title?: string;
}

export const ActionsCard: FC<Props> = ({
    onSearchChange,
    onRefresh,
    openFilter,
    isArchivedView,
    enableArchiveMode,
    onViewChange,
    actionsMenuItems,
    title
}) => (
    <Card>
        <CardContent style={{ paddingTop: 18, paddingBottom: 15 }}>
            <Stack spacing={1} flexWrap="wrap">
                <Typography variant="h6">{title}</Typography>
                <Stack direction="row" gap={1} flexWrap="wrap">
                    <SearchFilters
                        onSearchChange={onSearchChange}
                        actionsMenuItems={actionsMenuItems}
                    />
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={1}
                    >
                        <IconButton onClick={openFilter}>
                            <FilterAltIcon />
                        </IconButton>
                        <IconButton onClick={onRefresh}>
                            <RefreshIcon />
                        </IconButton>
                        {enableArchiveMode && onViewChange && (
                            <IconButton
                                color={isArchivedView ? 'primary' : 'default'}
                                onClick={onViewChange}
                            >
                                <ArchiveIcon />
                            </IconButton>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </CardContent>
    </Card>
);
