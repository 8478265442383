import React, { FC, useState } from 'react';
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Stack,
    TextField,
    Typography,
    CardHeader as MuiCardHeader
} from '@mui/material';
import { styled } from '@mui/system';
import {
    RoleDto,
    OrganizationRolesDto,
    RolesByOrganizationDto,
    RoleType
} from 'src/types';
import { Spin } from '../Spin/Spin';
import { UserInfoDto } from '../../types';

interface Props {
    user?: UserInfoDto;
    systemRoles: RoleDto[];
    organizationRoles: OrganizationRolesDto[];
    selectedRoles: RoleDto[];
    selectedOrganizationRoles: RolesByOrganizationDto[];
    loading: boolean;
    onCancel: () => void;
    onSave: (
        systemRoles: RoleDto[],
        organizationRoles: RolesByOrganizationDto[]
    ) => void;
}

const CardHeader = styled(MuiCardHeader)(
    ({ theme }) => `
        justify-content: space-between;
        .MuiCardHeader-title {
            font-size: 1.25em;
            font-weight: 500;
        }
        .MuiCardHeader-action {
            margin: 0;
        }
    `
);

export const RolesSelect: FC<Props> = ({
    user,
    systemRoles,
    organizationRoles,
    loading,
    selectedRoles: defaultSelectedRoles,
    selectedOrganizationRoles: defaultSelectedOrganizationRoles,
    onCancel,
    onSave
}) => {
    const [selectedRoles, setSelectedRoles] = useState(defaultSelectedRoles);
    const [selectedOrganizationRoles, setSelectedOrganizationRoles] = useState(
        defaultSelectedOrganizationRoles
    );
    const [selectedOrganization, setSelectedOrganization] = useState('');

    const handleSelectRole = (id: string, name: string) => () => {
        setSelectedRoles(prev => {
            const index = prev.findIndex(({ id: prevId }) => id === prevId);
            if (index !== -1) {
                return [...prev.slice(0, index), ...prev.slice(index + 1)];
            }
            return [
                ...prev,
                {
                    id,
                    name
                }
            ];
        });
    };

    const changeRoleSelect = (
        prev: RolesByOrganizationDto[],
        index: number,
        organizationRole: {
            organizationId: string;
            organizationName: string;
            roleId: string;
            roleName: string;
        }
    ) => {
        const roleIndex = prev[index].roles.findIndex(
            ({ id: roleId }) => organizationRole.roleId === roleId
        );
        return roleIndex !== -1
            ? [
                  ...prev[index].roles.slice(0, roleIndex),
                  ...prev[index].roles.slice(roleIndex + 1)
              ]
            : [
                  ...prev[index].roles,
                  {
                      id: organizationRole.roleId,
                      name: organizationRole.roleName
                  }
              ];
    };

    const getOrganizationsRolesList = (orgId: string) => {
        const organization = organizationRoles.find(
            roleItem => orgId === roleItem.id
        );

        const roles = (organization?.roles || []).filter(
            x => x.type === RoleType.Custom
        );

        return roles;
    };

    const handleSelectRoleByOrganization =
        (organizationRole: {
            organizationId: string;
            organizationName: string;
            roleId: string;
            roleName: string;
        }) =>
        () => {
            setSelectedOrganizationRoles(prev => {
                const selectedOrganizationIdx = prev.findIndex(
                    ({ organizationId }) =>
                        organizationId === organizationRole.organizationId
                );
                if (selectedOrganizationIdx !== -1) {
                    return [
                        ...prev.slice(0, selectedOrganizationIdx),
                        {
                            ...prev[selectedOrganizationIdx],
                            roles: changeRoleSelect(
                                prev,
                                selectedOrganizationIdx,
                                organizationRole
                            )
                        },
                        ...prev.slice(selectedOrganizationIdx + 1)
                    ];
                }
                return [
                    ...prev,
                    {
                        organizationId: organizationRole.organizationId,
                        organizationName: organizationRole.organizationName,
                        roles: [
                            {
                                id: organizationRole.roleId,
                                name: organizationRole.roleName
                            }
                        ]
                    }
                ];
            });
        };

    const handleSave = () => {
        onSave(selectedRoles, selectedOrganizationRoles);
    };

    const organizationOptions = organizationRoles
        .filter(
            org =>
                !selectedOrganizationRoles.find(
                    item => org.id === item.organizationId
                )
        )
        .map(org => ({
            label: org.name,
            id: org.id
        }));

    const handleAddOrganization = () => {
        const newOrg = organizationRoles.find(
            ({ id }) => id === selectedOrganization
        );
        setSelectedOrganizationRoles(prev => [
            ...prev,
            {
                organizationId: newOrg?.id,
                organizationName: newOrg?.name,
                roles: []
            } as any
        ]);
        setSelectedOrganization('');
    };

    return (
        <Spin loading={loading}>
            <Typography variant="h6">
                {user ? `Roles for ${user.firstName} ${user.lastName}` : ''}
            </Typography>
            <Stack spacing={3}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" component="div">
                            System Roles
                        </Typography>
                        {systemRoles.map(({ id, name }) => (
                            <FormControlLabel
                                key={id}
                                control={
                                    <Checkbox
                                        name={id}
                                        onChange={handleSelectRole(id, name)}
                                        checked={
                                            !!selectedRoles.find(
                                                ({ id: selectedRoleId }) =>
                                                    id === selectedRoleId
                                            )
                                        }
                                    />
                                }
                                label={name}
                            />
                        ))}
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Typography variant="h6" component="div">
                            Organization Roles
                        </Typography>
                    </CardContent>
                    {selectedOrganizationRoles.map(
                        ({ organizationId, organizationName = '', roles }) => (
                            <React.Fragment key={organizationId}>
                                <Typography
                                    variant="subtitle1"
                                    style={{
                                        backgroundColor:
                                            'rgba(63, 81, 181, 0.1)',
                                        textAlign: 'center',
                                        padding: 8
                                    }}
                                >
                                    {organizationName}
                                </Typography>
                                <CardContent>
                                    <CardHeader title={'Select Roles'} />
                                    <Stack>
                                        {getOrganizationsRolesList(
                                            organizationId
                                        ).map(({ id, name }) => (
                                            <FormControlLabel
                                                key={id}
                                                control={
                                                    <Checkbox
                                                        onChange={handleSelectRoleByOrganization(
                                                            {
                                                                organizationId,
                                                                organizationName,
                                                                roleId: id,
                                                                roleName: name
                                                            }
                                                        )}
                                                        checked={
                                                            !!selectedOrganizationRoles.find(
                                                                ({
                                                                    roles,
                                                                    organizationId:
                                                                        selectedOrganizationId
                                                                }) =>
                                                                    organizationId ===
                                                                        selectedOrganizationId &&
                                                                    roles &&
                                                                    roles.find(
                                                                        role =>
                                                                            role.id ===
                                                                            id
                                                                    )
                                                            )
                                                        }
                                                    />
                                                }
                                                label={name}
                                            />
                                        ))}
                                    </Stack>
                                </CardContent>
                            </React.Fragment>
                        )
                    )}
                </Card>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="h6" component="div">
                        Add organization
                    </Typography>
                    <Autocomplete
                        disablePortal
                        options={organizationOptions}
                        value={
                            organizationOptions.find(
                                org => org.id === selectedOrganization
                            ) || null
                        }
                        onChange={(e, data) =>
                            setSelectedOrganization(data?.id || '')
                        }
                        sx={{ width: 300 }}
                        renderInput={params => (
                            <TextField {...params} label="Organizations" />
                        )}
                    />
                    <Button
                        variant="contained"
                        onClick={handleAddOrganization}
                        disabled={!selectedOrganization}
                    >
                        Add
                    </Button>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Button variant="outlined" onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                </Stack>
            </Stack>
        </Spin>
    );
};
