import { ActionsTableButton } from 'src/components/TablePage/ActionsTableButton';
import { ColumnType } from 'src/types';
import { INote } from 'src/types/partners/common';
import { getDateMonthDayYear } from 'src/utils/date';

export const generateNoteFields = ({
    onDelete,
    onEdit
}: {
    onDelete: (note: INote) => void;
    onEdit: (note: INote) => void;
}): ColumnType<INote>[] => [
    {
        dataIndex: 'id',
        title: 'Id'
    },
    {
        dataIndex: 'note',
        title: 'Note'
    },
    {
        dataIndex: 'createdBy',
        title: 'Created By'
    },
    {
        dataIndex: 'created',
        title: 'Created',
        render: (_, { created = '' }) => getDateMonthDayYear(created)
    },
    {
        dataIndex: 'actions',
        title: '',
        render: (_, row) => (
            <ActionsTableButton
                menuItems={[
                    {
                        title: 'Edit',
                        onClick: () => onEdit(row)
                    },
                    {
                        title: 'Delete',
                        onClick: () => onDelete(row)
                    }
                ]}
            />
        )
    }
];
