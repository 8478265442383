import { useHistory, useParams } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Stack, Button, Typography } from '@mui/material';

import { CategoryEditFormFields } from 'src/components/Categories/Forms/CategoryEditFormFields';
import { useCategories, useCategory } from 'src/api/hooks';
import { CategoryEditFormType } from 'src/types';
import { routes } from 'src/constants';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import categoryValidationYupResolver from 'src/pages/Categories/categoryFormValidation';
import useEditFormId from 'src/api/hooks/useEditFormId';

const INITIAL_VALUES = {
    name: '',
    description: ''
};

export const CategoryEdit = () => {
    const [isEdit, id] = useEditFormId();

    const { createCategory, updateCategory } = useCategories();
    const [buttonLoading, setButtonLoading] = useState(false);
    const { fetchCategory, category, loading: categoryLoading } = useCategory();
    const history = useHistory();

    useEffect(() => {
        if (id) {
            fetchCategory(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const { handleSubmit, control, reset } = useForm<CategoryEditFormType>({
        defaultValues: isEdit ? { id, ...INITIAL_VALUES } : INITIAL_VALUES,
        resolver: categoryValidationYupResolver()
    });

    useEffect(() => {
        if (category && id && category.id === id) {
            reset({
                id,
                name: category.name,
                description: category.description
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    const onSubmit: SubmitHandler<CategoryEditFormType> = data => {
        const body = data;
        setButtonLoading(true);

        if (isEdit && id) {
            updateCategory(id, body, () =>
                history.push(routes.categoriesView)
            ).finally(() => setButtonLoading(false));
        } else {
            createCategory(body, () =>
                history.push(routes.categoriesView)
            ).finally(() => setButtonLoading(false));
        }
    };

    const handleCancel = () => history.push(routes.categoriesView);

    return (
        <Stack spacing={2} flexGrow={1} mb={3}>
            <Typography variant="h5">
                {isEdit ? 'Edit Category' : 'Create Category'}
            </Typography>
            <form>
                <Stack spacing={2} alignItems="end">
                    <CategoryEditFormFields
                        control={control}
                        isEdit={isEdit}
                        loading={categoryLoading}
                    />
                    <Stack direction="row" spacing={2}>
                        <Button
                            onClick={handleCancel}
                            variant="outlined"
                            disabled={buttonLoading}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            onClick={handleSubmit(onSubmit)}
                            variant="contained"
                            loading={buttonLoading}
                        >
                            Save
                        </LoadingButton>
                    </Stack>
                </Stack>
            </form>
        </Stack>
    );
};
