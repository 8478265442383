import { FC } from 'react';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Stack } from '@mui/material';
import { styled } from '@mui/system';

import { routes } from 'src/constants';
import { ColumnType, CategoryDto } from 'src/types';
import { getDateMonthDayYearTime } from 'src/utils/date';
import { Table } from '../Table';
import { ActionsTableButton } from '../TablePage/ActionsTableButton';

interface Props {
    data: CategoryDto[];
    loading?: boolean;
    onDelete: (id: string) => void;
}

const Link = styled(RouterLink)(
    ({ theme }) => `
        text-decoration: none;

        &:hover {
            opacity: 0.8;
        }
    `
);

export const CategoriesTable: FC<Props> = ({ data, loading, onDelete }) => {
    const history = useHistory();

    const columns: ColumnType<CategoryDto>[] = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'Total Services',
            dataIndex: 'totalProducts'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            render: (name, record) => (
                <Link to={routes.category(record.id)}>{name}</Link>
            )
        },
        {
            title: 'Description',
            dataIndex: 'description'
        },
        {
            title: 'Created',
            dataIndex: 'created',
            render: created => getDateMonthDayYearTime(created)
        },
        {
            title: 'Added By',
            dataIndex: 'createdBy'
        },
        {
            title: 'Last Update',
            dataIndex: 'lastUpdate',
            render: (lastUpdate, record) =>
                getDateMonthDayYearTime(lastUpdate || record.created)
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            render: (updatedBy, record) => updatedBy || record.createdBy
        },
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            width: '60px',
            style: { paddingLeft: 0, paddingRight: 14 },
            render: (_, { id }) => (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                >
                    <ActionsTableButton
                        menuItems={[
                            {
                                title: 'Edit',
                                onClick: () => history.push(routes.category(id))
                            },
                            { title: 'Delete', onClick: () => onDelete(id) }
                        ]}
                    />
                </Stack>
            )
        }
    ];

    return <Table columns={columns} data={data} loading={loading} />;
};
