import { FC } from 'react';
import { useHistory } from 'react-router';
import { Button, Stack } from '@mui/material';

import { billingModelTypeName, routes } from 'src/constants';
import { ColumnType, BillingModelDto, BillingModelType } from 'src/types';
import { getDateMonthDayYear } from 'src/utils/date';
import { Table } from '../Table';
import { ActionsTableButton } from '../TablePage/ActionsTableButton';

interface Props {
    data: BillingModelDto[];
    loading?: boolean;
    onDelete: (id: string) => void;
}

export const BillingModelsTable: FC<Props> = ({ data, loading, onDelete }) => {
    const history = useHistory();

    const columns: ColumnType<BillingModelDto>[] = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (type: BillingModelType) =>
                billingModelTypeName[type] || type
        },
        {
            title: 'Default',
            dataIndex: 'default'
        },
        {
            title: 'Updated',
            dataIndex: 'lastUpdate',
            render: (lastUpdate, record) =>
                getDateMonthDayYear(lastUpdate || record.created)
        },
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            width: '60px',
            style: { paddingLeft: 0, paddingRight: 14 },
            render: (_, { id }) => (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                >
                    <ActionsTableButton
                        menuItems={[
                            {
                                title: 'Edit',
                                onClick: () =>
                                    history.push(routes.billingModel(id))
                            },
                            { title: 'Delete', onClick: () => onDelete(id) }
                        ]}
                    />
                </Stack>
            ),
            mobile: {
                render: (_, { id }) => (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        spacing={1}
                    >
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() =>
                                history.push(routes.billingModel(id))
                            }
                        >
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => onDelete(id)}
                        >
                            Delete
                        </Button>
                    </Stack>
                ),
                fullWidth: true
            }
        }
    ];

    return (
        <Table
            columns={columns}
            data={data}
            loading={loading}
            mobile={{ renderSummary: row => row.name }}
        />
    );
};
