import { Card, CardContent, Stack, Typography } from '@mui/material';
import { useRdrAlerts } from 'src/api/hooks/useRdrs';
import { useIntervalAsync } from 'src/hooks/useIntervalAsync';
import { RdrsTable } from './RdrsTable';
import { RdrsUpload } from './RdrsUpload';

const FETCH_RDR_ALERTS_TIMEOUT = 5000;

export const RdrsView = () => {
    const { rdrAlerts, fetchRdrAlerts } = useRdrAlerts();

    useIntervalAsync({
        callback: fetchRdrAlerts,
        delay: FETCH_RDR_ALERTS_TIMEOUT,
        onMount: true
    });

    return (
        <Stack spacing={2}>
            <Card>
                <CardContent>
                    <Stack
                        spacing={1}
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6">RDRs</Typography>
                        <RdrsUpload />
                    </Stack>
                </CardContent>
            </Card>

            <RdrsTable rdrAlerts={rdrAlerts} />
        </Stack>
    );
};
