import { useEffect, useRef } from 'react';
import {
    setIntervalAsync,
    clearIntervalAsync
} from 'set-interval-async/dynamic';

export const useIntervalAsync = ({
    callback,
    delay,
    onMount = false
}: {
    callback: Function;
    delay: number;
    onMount?: boolean;
}) => {
    const savedCallback = useRef<Function>();

    // Remember the latest callback
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (onMount) {
            savedCallback?.current && savedCallback?.current();
        }
    }, [onMount]);

    // Set up the interval
    useEffect(() => {
        function tick() {
            return savedCallback?.current && savedCallback?.current();
        }

        if (delay !== null) {
            const interval = setIntervalAsync(tick, delay);

            return () => {
                clearIntervalAsync(interval);
            };
        }
    }, [callback, delay]);
};
