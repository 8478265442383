import { AgentStatusOfPayment } from 'src/types';
import { AutocompleteOption } from 'src/components/Form';

export const accountInfoCategory = [
    'address',
    'city',
    'zip',
    'country',
    'address2',
    'state'
];

export const bankInfoCategory = ['accountHolder', 'bankAccount', 'bankRouting'];

export const primaryContactCategory = [
    'emailAddress',
    'faxNumber',
    'firstName',
    'lastName',
    'phoneNumber'
];

export const statusesOfPayments: AgentStatusOfPayment[] = [
    'Unknown',
    'Received'
];
export const statusesOfPaymentsOptions: AutocompleteOption[] =
    statusesOfPayments.map(status => ({ value: status, label: status }));
