import { FC, memo } from 'react';
import { Control } from 'react-hook-form';
import { FormFieldType, IAgentsApiPostPut } from 'src/types';
import { AutocompleteOption, FormCard } from 'src/components/Form';

interface Props {
    control: Control<IAgentsApiPostPut, object>;
    partnersOptions: AutocompleteOption[];
}

// names are temporary because back is not ready yet
const fields = (partners?: AutocompleteOption[]) => [
    {
        name: 'partner.id',
        required: true,
        label: 'Partner',
        type: FormFieldType.Autocomplete,
        options: partners || [],
        xs: 4
    }
];

const AgentPartnersForm: FC<Props> = ({ control, partnersOptions }) => (
    <FormCard
        control={control}
        fields={fields(partnersOptions)}
        title="Partners"
    />
);

export default memo(AgentPartnersForm);
