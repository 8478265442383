import { useDispatch, useSelector } from 'react-redux';
import {
    setIsLoggedInAction,
    setSelectedOrganizationAction,
    setUserDataAction,
    setUserOrganizationDataAction,
    setUserOrganizationsAction,
    showMessageAction
} from 'src/store/actions';
import {
    apiCreateUser,
    apiSignIn,
    apiSignUp,
    apiSwitchOrganization
} from 'src/api/endpoints/auth';
import {
    ApiErrorResponse,
    IOrganizationDto,
    SignInBody,
    SignUpBody
} from 'src/types';
import {
    getAuthLS,
    getSelectedOrganizationLS,
    getUserDataLS,
    getUserOrganizationsLS,
    removeAuthLS,
    removeAuthTokenLS,
    removeSelectedOrganizationLS,
    removeUserDataLS,
    removeUserOrganizationsLS,
    setAuthLS,
    setAuthTokenLS,
    setSelectedOrganizationLS,
    setUserDataLS,
    setUserOrganizationsLS,
    setUserOrganizationDataLS,
    removeUserOrganizationDataLS,
    removeOrganizationAuthTokenLS,
    setOrganizationAuthTokenLS,
    getUserOrganizationDataLS
} from 'src/utils/localStorage';
import { getAuth } from 'src/store/selectors';
import { UserAccountDto, UserOrganizationAccountDto } from '../../types';
import { usePermissions } from './usePermissions';
import { useHistory } from 'react-router';
import { routes } from '../../constants';

export const setAuthToken = (token?: string) => {
    if (token) {
        setAuthTokenLS(token);
    } else {
        removeAuthTokenLS();
    }
};

export const SetOrganizationAuthToken = (token?: string) => {
    if (token) {
        setOrganizationAuthTokenLS(token);
    } else {
        removeOrganizationAuthTokenLS();
    }
};

export const useAuth = () => {
    const dispatch = useDispatch();
    const {
        organizations = [],
        selectedOrganization,
        isLoggedIn,
        userData,
        userOrganizationData
    } = useSelector(getAuth);

    const history = useHistory();

    const { mapPermissions } = usePermissions();

    const setIsLoggedIn = (isLoggedIn: boolean) => {
        if (isLoggedIn) {
            setAuthLS();
        } else {
            removeAuthLS();
        }
        dispatch(setIsLoggedInAction(isLoggedIn));
    };

    const setUserData = (userData?: UserAccountDto) => {
        if (userData) {
            dispatch(setUserDataAction(userData));
            dispatch(setUserOrganizationsAction(userData.organizations));
            setUserDataLS(userData);

            if (userData.organizations) {
                setUserOrganizationsLS(userData.organizations);
            } else {
                removeUserOrganizationsLS();
            }
        } else {
            dispatch(setUserDataAction());
            dispatch(setUserOrganizationsAction());
            removeUserDataLS();
            removeUserOrganizationsLS();
        }
    };

    const setUserOrganizationData = (
        userOrganizationData?: UserOrganizationAccountDto
    ) => {
        if (userOrganizationData) {
            dispatch(setUserOrganizationDataAction(userOrganizationData));
            setUserOrganizationDataLS(userOrganizationData);
            history.push(routes.dashboard);
        } else {
            dispatch(setUserOrganizationDataAction());
            removeUserOrganizationDataLS();
        }
    };

    const setSelectedOrganization = (organization?: IOrganizationDto) => {
        dispatch(setSelectedOrganizationAction(organization));
        if (organization) {
            setSelectedOrganizationLS(organization);
            fetchOrganizationToken(organization?.id);
        } else {
            removeSelectedOrganizationLS();
            removeOrganizationAuthTokenLS();
            dispatch(setUserOrganizationDataAction());
            if (userData) {
                history.push(routes.dashboard);
            }
        }
    };

    const clearSelectedOrganization = () => {
        setSelectedOrganization();
    };

    const newOrganization = () => {
        history.push(routes.organizationNew);
    };

    const setIsLoggedInFromLocalStorage = () => {
        const userData = getUserDataLS();
        const userOrganizations = getUserOrganizationsLS();
        const selectedOrganization = getSelectedOrganizationLS();
        const userOrganizationData = getUserOrganizationDataLS();
        dispatch(setUserDataAction(userData || undefined));
        dispatch(setUserOrganizationsAction(userOrganizations));
        dispatch(setUserOrganizationDataAction(userOrganizationData));
        setSelectedOrganization(selectedOrganization || undefined);
        const isLoggedIn = getAuthLS();
        dispatch(setIsLoggedInAction(isLoggedIn));
        return isLoggedIn;
    };

    const fetchOrganizationToken = (organizationId: string) => {
        if (!userData || !organizationId) {
            return;
        }

        apiSwitchOrganization(organizationId)
            .then((userOrganizationAccount: UserOrganizationAccountDto) => {
                userOrganizationAccount.permissionIds = mapPermissions(
                    userOrganizationAccount.permissionIds
                );
                setUserOrganizationData(userOrganizationAccount);
                SetOrganizationAuthToken(
                    userOrganizationAccount.jwtBearerToken
                );
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: String(err) || 'Switch organization error',
                        type: 'error'
                    })
                );

                throw err;
            });
    };

    const fetchSignIn = (data: SignInBody) =>
        apiSignIn(data)
            .then((userData: UserAccountDto) => {
                userData.permissionIds = mapPermissions(userData.permissionIds);
                setUserData(userData);
                setAuthToken(userData.jwtBearerToken);
                return userData;
            })
            .catch((err: ApiErrorResponse) => {
                let message: string;

                if (err.detail === 'invalid_credentials') {
                    message = 'Username or password is incorrect';
                } else {
                    message = String(err) || 'Sign In Error';
                }

                dispatch(
                    showMessageAction({
                        message,
                        type: 'error'
                    })
                );

                throw err;
            });

    const fetchSignUp = (data: SignUpBody) =>
        apiSignUp(data).catch((err: any) => {
            dispatch(
                showMessageAction({
                    message: String(err) || 'Sign Up Error',
                    type: 'error'
                })
            );

            throw err;
        });

    const fetchCreateUser = (data: SignUpBody) =>
        apiCreateUser(data).catch((err: any) => {
            dispatch(
                showMessageAction({
                    message: String(err) || 'Sign Up Error',
                    type: 'error'
                })
            );

            throw err;
        });

    const logOut = () => {
        setIsLoggedIn(false);
        setAuthToken();
        setUserData();
        setSelectedOrganization();
    };

    const addUserOrganization = (organization: IOrganizationDto) => {
        const newOrgs = [...organizations, organization];

        dispatch(setUserOrganizationsAction(newOrgs));
        setUserOrganizationsLS(newOrgs);
    };

    return {
        addUserOrganization,
        clearSelectedOrganization,
        newOrganization,
        setSelectedOrganization,
        setIsLoggedIn,
        setIsLoggedInFromLocalStorage,
        fetchSignIn,
        fetchOrganizationToken,
        fetchSignUp,
        fetchCreateUser,
        logOut,
        organizations,
        selectedOrganization,
        isLoggedIn,
        userData,
        userOrganizationData
    };
};
