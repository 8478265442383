import { CategoryShorDto } from 'src/types';
import {
    ShortCategoriesActionTypes,
    SET_LOADING,
    SET_SHORT_CATEGORIES
} from '../actions/shortCategories';

export interface ShortCategoriesState {
    categories: Record<string, CategoryShorDto>;
    loading: boolean;
}
const initialState: ShortCategoriesState = {
    categories: {},
    loading: false
};

export const shortCategories = (
    state: ShortCategoriesState = initialState,
    action: ShortCategoriesActionTypes
): ShortCategoriesState => {
    switch (action.type) {
        case SET_SHORT_CATEGORIES:
            return {
                ...state,
                categories:
                    action.payload?.reduce<Record<string, CategoryShorDto>>(
                        (prev, curr) => ({
                            ...prev,
                            [curr.id]: curr
                        }),
                        {}
                    ) || {},
                loading: false
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
