import { yupResolver } from '@hookform/resolvers/yup';
import { mixed, object, string } from 'yup';
import { ExtendedBillingModelType } from 'src/types';

export default function billModelValidationYupResolver() {
    const schema = object({
        // Company Information
        name: string().required().max(200),
        description: string().required().max(200),
        type: mixed().oneOf([...Object.values(ExtendedBillingModelType), null])
    });

    return yupResolver(schema);
}
