import { PartnerStatusFieldsType } from 'src/types/partners/common';
import {
    BrandableServicePartnerFeaturesAgentsModelsAgentEditDto,
    BrandableServicePartnerFeaturesAgentsModelsAgentListDto,
    BrandableServicePartnerFeaturesAgentsModelsCreateOrUpdateAgentCommand
} from 'src/api/swagger/partnerTypes';

export interface IAgentsApiGetById
    extends BrandableServicePartnerFeaturesAgentsModelsAgentEditDto {}
export interface IAgentsApiGetList
    extends BrandableServicePartnerFeaturesAgentsModelsAgentListDto {}
export interface IAgentsApiPostPut
    extends BrandableServicePartnerFeaturesAgentsModelsCreateOrUpdateAgentCommand {}

export interface AccountInfoDto extends PartnerStatusFieldsType {
    address: string;
    city: string;
    zip: string;
    country: string;
    address2: string;
    state: string;
}

export interface BankInfoDto {
    accountHolder: string;
    bankRouting: string;
    bankAccount: string;
    taxId: string;
    businessType: string;
}

export interface PrimaryContactDto {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    faxNumber: string;
}

export interface SupportContactDto {
    emailAddress: string;
    phoneNumber: string;
}

export type AgentStatusOfPayment = 'Unknown' | 'Received';

export type AgentFilter = string;

export enum Condition {
    Equals = 'equals',
    Contains = 'contains'
}

export type AgentFormFilter = Record<
    keyof Omit<IAgentsApiPostPut, 'address2'>,
    {
        value: string;
        condition: Condition;
    }
>;

export type FilterFormType = Record<
    keyof Omit<IAgentsApiPostPut, 'address2' | 'country'>,
    {
        value: string;
        condition: Condition;
    }
> & {
    country: {
        value: {
            value: string;
            label: string;
        };
        condition: Condition;
    };
};
