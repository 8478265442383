import { FC } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { FormFieldType, IAgentsApiPostPut } from 'src/types';
import { Field, FormCard } from './FormCard';
import { countries, states } from 'src/constants';
import { AutocompleteOption } from 'src/components/Form';

interface FieldsOptions {
    partnersAsOptions: AutocompleteOption[];
}
interface Props extends FieldsOptions {
    control: Control<IAgentsApiPostPut, object>;
}

const fields = (
    country: string,
    partnersAsOptions: FieldsOptions['partnersAsOptions']
): (Field<IAgentsApiPostPut> & TextFieldProps)[] => [
    {
        name: 'name',
        label: 'Agent ISO',
        required: true,
        xs: 6
    },
    {
        xs: 6
    },
    {
        name: 'accountInfo.country',
        label: 'Country',
        type: FormFieldType.Autocomplete,
        options: countries,
        required: true,
        inputProps: {
            maxLength: 2
        },
        xs: 4
    },
    { xs: 2 },
    {
        name: 'accountInfo.address',
        label: 'Address 1',
        required: true
    },
    {
        name: 'accountInfo.address2',
        label: 'Address 2'
    },
    {
        name: 'accountInfo.city',
        label: 'City',
        required: true,
        inputProps: {
            maxLength: 50
        },
        xs: 4
    },
    {
        name: 'accountInfo.state',
        label: 'State',
        required: true,
        inputProps: {
            maxLength: 40
        },
        type: states[country] ? FormFieldType.Autocomplete : undefined,
        options: states[country],
        xs: 4
    },
    {
        name: 'accountInfo.zip',
        label: 'PostalCode',
        required: true,
        inputProps: {
            maxLength: 16
        },
        xs: 4
    }
];

export const AgentInfoForm: FC<Props> = ({ control, partnersAsOptions }) => {
    const country = useWatch({ control, name: 'accountInfo.country' });

    return (
        <FormCard
            control={control}
            fields={fields(country, partnersAsOptions)}
            title="Agent Information"
        />
    );
};
