import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showMessageAction } from 'src/store/actions';
import {
    setLoadingAction,
    setPaginationAction,
    setMidsAction
} from 'src/store/actions/mids';
import {
    apiDeleteMid,
    apiGetMid,
    apiGetMids,
    apiPostMid,
    apiPutMid
} from '../endpoints/mids';
import { getMids } from 'src/store/selectors';
import {
    CommonGetParams,
    ErrorType,
    ICreateOrUpdateMID,
    IMIDEditDto,
    IMIDListDto,
    Pagination
} from 'src/types';
import { getApiCommonParams } from 'src/utils/formatters';

export const useMids = () => {
    const [errorData, setErrorData] = useState<ErrorType | null>(null);
    const { mids, loading, pagination: paging } = useSelector(getMids);

    const dispatch = useDispatch();

    const setPaging = (pagination?: Pagination) =>
        dispatch(setPaginationAction(pagination));

    const setMids = (mids: IMIDListDto[]) => dispatch(setMidsAction(mids));

    const setLoading = () => dispatch(setLoadingAction());

    const fetchMids = (commonParams?: CommonGetParams) => {
        setLoading();
        setErrorData(null);
        const params = commonParams
            ? getApiCommonParams(commonParams)
            : undefined;
        return apiGetMids({ params })
            .then(({ result, pagination }) => {
                setMids(result);
                setPaging(pagination);
            })
            .catch((err: ErrorType) => {
                setMids([]);
                setErrorData(err);
                dispatch(
                    showMessageAction({
                        message: `Failed getting MIDs: ${err}`,
                        type: 'error'
                    })
                );
                throw err;
            });
    };

    const deleteMid = (id: string, commonParams?: CommonGetParams) =>
        apiDeleteMid(id)
            .then(() => {
                dispatch(
                    showMessageAction({
                        message: 'MID deleted successfully',
                        type: 'success'
                    })
                );
                fetchMids(commonParams);
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed deleting MID: ${err}`,
                        type: 'error'
                    })
                );
                throw err;
            });

    const createMid = (body: ICreateOrUpdateMID, successCalback?: () => void) =>
        apiPostMid(body)
            .then(() => {
                dispatch(
                    showMessageAction({
                        message: 'MID saved successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback();
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed saving MID: ${err}`,
                        type: 'error'
                    })
                );
            });

    const updateMid = (
        id: string,
        body: ICreateOrUpdateMID,
        successCalback?: (mid: IMIDEditDto) => void
    ) =>
        apiPutMid(id, body)
            .then(mid => {
                dispatch(
                    showMessageAction({
                        message: 'MID changed successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback(mid);
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed changing mid: ${err}`,
                        type: 'error'
                    })
                );
            });
    return {
        fetchMids,
        deleteMid,
        createMid,
        updateMid,
        mids,
        loading,
        errorData,
        paging,
        setPaging
    };
};

export const useMid = () => {
    const [mid, setMid] = useState<IMIDEditDto>();
    const [loading, setLoading] = useState(false);
    const [errorData, setErrorData] = useState<ErrorType | null>(null);

    const fetchMid = (id: string) => {
        setLoading(true);
        setErrorData(null);
        return apiGetMid(id)
            .then(mid => {
                setMid(mid);
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return {
        mid,
        fetchMid,
        loading,
        errorData
    };
};
