import { useCallback, useEffect, useMemo } from 'react';

import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
    AutocompleteOption,
    Field,
    FormAutoCompleteTypeProps,
    FormInput
} from 'src/components/Form';
import Loader from 'src/components/Loader/Loader';
import { Modal } from 'src/components/Modal';
import { IPartnerService, ServiceDto } from 'src/types';
import { ServicesState } from 'src/store/reducers/services';

const PartnerServiceModal = ({
    onCancel,
    onAddService,
    onSelectService,
    modalTitle,
    fields,
    services,
    loading
}: {
    onCancel: () => void;
    onAddService: (values: IPartnerService) => void;
    onSelectService?: (service: ServiceDto) => void;
    services: ServicesState['services'];
    loading: boolean;
    modalTitle: string;
    fields: Field<ServiceDto>[];
}) => {
    const { handleSubmit, control, watch, getValues, setValue } =
        useForm<ServiceDto>();

    const currentServiceId = watch('serviceId');

    const setFieldsValuesOnSelect = useCallback(
        (serviceId: string, fields: Field<ServiceDto>[]) => {
            fields.forEach(field => {
                if (!field.name || field.name === 'serviceId') return;

                const value =
                    services[serviceId][field.name as keyof ServiceDto];

                setValue(field.name, value);
            });

            setValue('name', services[serviceId].name);
        },
        [services, setValue]
    );

    useEffect(() => {
        if (currentServiceId) {
            setFieldsValuesOnSelect(currentServiceId, fields);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentServiceId]);

    const addService = () => {
        onAddService(getValues());
    };

    const filteredServicesOptions: AutocompleteOption[] = useMemo(
        () =>
            Object.values(services).map(service => ({
                label: service.name,
                value: service.id
            })),
        [services]
    );

    return (
        <Modal
            title={modalTitle}
            open
            loading={loading}
            onOk={handleSubmit(addService)}
            okText="Add service"
            onCancel={onCancel}
            cardStyle={{ width: '60%' }}
        >
            <Loader loading={loading} type="fullBlock">
                <Grid container spacing={2}>
                    {fields.map(field => (
                        <Grid key={field.name} item xs={6}>
                            <FormInput
                                type={field.type}
                                fieldType={field.type}
                                inputProps={{
                                    ...(field as any),
                                    name: field.name,
                                    label: field.label,
                                    options:
                                        field.name === 'serviceId'
                                            ? filteredServicesOptions
                                            : (
                                                  field as FormAutoCompleteTypeProps<ServiceDto>
                                              ).options,
                                    fullWidth: true,
                                    control
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Loader>
        </Modal>
    );
};

export default PartnerServiceModal;
