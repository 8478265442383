import { Drawer as MuiDrawer } from '@mui/material';
import { styled } from '@mui/system';

const drawerWidth = 240;

export const Drawer = styled(MuiDrawer, {
    shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => {
    const transitions = theme.transitions as any;
    return {
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: transitions.create('width', {
                easing: transitions.easing.sharp,
                duration: transitions.duration.enteringScreen
            }),
            backgroundColor: theme.palette.darkBackground,
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: transitions.create('width', {
                    easing: transitions.easing.sharp,
                    duration: transitions.duration.leavingScreen
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9)
                }
            })
        }
    };
});
