export enum ServiceStatusesEnum {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive'
}

export enum ServiceStatusesExtendedEnum {
    ALL = 'All',
    ACTIVE = 'Active',
    ARCHIVED = 'Archived'
}

export interface SingleServiceDto {
    id: string;
    name: string;
    msrp: number;
    cost: number;
    created: string;
    createdBy: string;
    lastUpdate: string;
    updatedBy: string;
    category: {
        id: string;
        name: string;
        description: string;
    };
    taxable: boolean;
    shippable: boolean;
    digitalDownload: boolean;
    signatureConfirmation: boolean;
    deliveryConfirmation: boolean;
    variants: boolean;
    licenseKeyRestricted: boolean;
    subscription: boolean;
    associatedId?: string;
    files: FileDto[];
}
export interface ServiceDto {
    id: string;
    serviceId: string;
    name: string;
    msrp: number;
    cost: number;
    associatedId: string;
    status: ServiceStatusesEnum;
    billingModelId: string;
    partnerCommission: number;
    agentCommission: number;

    created: string;
    createdBy: string;
    lastUpdate: string;
    updatedBy: string;
    category: {
        id: string;
        name: string;
        description: string;
    };
    subscription: boolean;
}

export interface ServicePost {
    name: string;
    price: number;
    msrp: number;
    cost: number;
    category: {
        id: string;
    };
    // TODO: add enums
    billingModel: string;
    status: string;
    taxable?: boolean;
    shippable?: boolean;
    digitalDownload?: boolean;
    signatureConfirmation?: boolean;
    deliveryConfirmation?: boolean;
    variants?: boolean;
    licenseKeyRestricted?: boolean;
    subscription?: boolean;
    associatedId?: string;
    files?: FileDto[];
}

export interface FileDto {
    id: string;
    originalFileName: string;
}

export type ServicesFilterForm = Record<keyof ServiceDto, string>;

export type ServiceEditFormType = {
    id?: string;
    name: string;
    msrp: number;
    cost: number;
    taxable?: boolean;
    shippable?: boolean;
    digitalDownload?: boolean;
    signatureConfirmation?: boolean;
    deliveryConfirmation?: boolean;
    variants?: boolean;
    licenseKeyRestricted?: boolean;
    subscription?: boolean;
    associatedId?: string;
    files?: FileDto[];
};
