export enum PermissionType {
    Login,
    ServicesView,
    CategoriesView,
    BillingModelsView,
    UsersView,
    RolesView,
    OrganizationsView,
    Dashboard,
    CategoryEdit,
    ServiceEdit,
    AccountEdit,
    AccountsView,
    PartnersView,
    AgentsView,
    MerchantsView,
    ViewPrimaryUser,
    EditPrimaryUser,
    AgentEdit,
    PartnerEdit,
    MerchantEdit,
    BillingModelEdit,
    OrganizationEdit,
    MidsView,
    MidEdit,
    UserEdit,
    RoleEdit,
    ViewBankingInfo,
    EditBankingInfo,
    ViewCompanyInfo,
    EditCompanyInfo,
    ViewCompanyContact,
    EditCompanyContact,
    FeeScheduleView,
    FeeScheduleEdit,
    SwitchingBetweenOrganizations
}

export enum PermissionId {
    PartnersManagement = 1,
    ServicesManagement,
    CategoriesManagement,
    UserManagement,
    RolesManagement,
    BillingModelsManagement,
    CompanyContactManagement,
    MIDManagement,
    AgentsManagement,
    MerchantsManagement,
    CRMIntegrationManagement,
    AccountOrganizationManagement,
    AccountCompanyInfoManagement,
    AccountBankingInfoManagement,
    AccountPrimaryUserManagement,
    FeeScheduleManagement,
    SwitchingBetweenOrganizations,
    SearchOrganization
}
