import {
    ApiResponse,
    CategoryDto,
    CategoryShorDto,
    CommonApiGetParams,
    CategoryPost
} from 'src/types';
import { apiClient } from '../client';
import {
    withDefaultHandlers,
    withDefaultHandlersPagination
} from '../helpers/withDefaultHandlers';

const BASE_PATH = '/product/category-api/category-info';

export const apiGetShortCategories = () =>
    withDefaultHandlers<CategoryShorDto[]>(apiClient.get(`${BASE_PATH}/short`));

export const apiGetCategories = ({ params }: { params?: CommonApiGetParams }) =>
    withDefaultHandlersPagination(
        apiClient.get<ApiResponse<CategoryDto[]>>(BASE_PATH, params)
    );

export const apiGetCategory = (id: string) =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<CategoryDto>>(`${BASE_PATH}/${id}`)
    );

export const apiPostCategory = (category: CategoryPost) =>
    withDefaultHandlers(
        apiClient.post<ApiResponse<{ id: string }>>(BASE_PATH, category)
    );

export const apiPutCategory = (id: string, category: CategoryPost) =>
    withDefaultHandlers(
        apiClient.put<ApiResponse<CategoryDto>>(BASE_PATH, category, {
            params: { id }
        })
    );

export const apiDeleteCategory = (id: string) =>
    withDefaultHandlers(
        apiClient.delete<ApiResponse<CategoryDto>>(BASE_PATH, {
            params: { id }
        })
    );
