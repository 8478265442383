import { memo, useMemo, useState } from 'react';

import { Button } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useServices } from 'src/api/hooks';
import CardWrapper from 'src/components/Card/CardWrapper';
import PartnerServiceModal from 'src/components/Partners/PartnerServiceModal';
import { Table as CustomTable } from 'src/components/Table';
import { ActionsTableButton } from 'src/components/TablePage/ActionsTableButton';
import { ServicesState } from 'src/store/reducers/services';
import {
    ColumnType,
    IAgentsApiPostPut,
    IPartnerService,
    MerchantCreateOrUpdate,
    PartnerCreateEditDto,
    ServiceDto
} from 'src/types';
import { showMessageAction } from '../../store/actions';
import { Field } from '../Form';

// This function needs to filter services that are already chosen
const balanceServices = (
    services: ServicesState['services'],
    existingsServices: IPartnerService[]
): ServicesState['services'] => {
    const obj: ServicesState['services'] = {};

    for (let key in services) {
        const service = services[key];

        if (
            !existingsServices.find(({ serviceId }) => serviceId === service.id)
        ) {
            obj[key] = service;
        }
    }

    return obj;
};

const getServiceName = (
    allServices: Record<string, ServiceDto>,
    service: ServiceDto
) =>
    Object.entries(allServices).find(([key]) => key === service.serviceId)?.[1]
        ?.name;

const ServicesCard = ({
    control,
    tableFields,
    modalInputs,
    loading,
    onServiceSelectModal,
    modalTitle,
    disableAddService = false,
    messageForDisabling = 'Need to do smth before add service'
}: {
    tableFields: ColumnType<ServiceDto>[];
    modalInputs: Field<ServiceDto>[];
    onServiceSelectModal?: (service: ServiceDto) => void;
    modalTitle: string;
    control:
        | Control<PartnerCreateEditDto>
        | Control<MerchantCreateOrUpdate>
        | Control<IAgentsApiPostPut>;
    loading: boolean;
    disableAddService?: boolean;
    messageForDisabling?: string;
}) => {
    const dispatch = useDispatch();

    const { services: allServices } = useServices();

    const visibleTableFields = useMemo(
        () => tableFields?.filter(({ hidden = false }) => !hidden),
        [tableFields]
    );

    const [newServiceModalIsOpen, setNewServiceModalIsOpen] =
        useState<boolean>(false);

    const openNewServiceModal = () => {
        if (disableAddService) {
            dispatch(
                showMessageAction({
                    message: messageForDisabling,
                    type: 'warning'
                })
            );
            return;
        }

        setNewServiceModalIsOpen(true);
    };
    const closeNewServiceModal = () => setNewServiceModalIsOpen(false);

    return (
        <Controller
            name="services"
            // need to delete this 'as' after back will be ready
            control={control as Control<PartnerCreateEditDto>}
            render={({ field: { onChange, value: existingServices } }) => {
                const handleAddService = (service: IPartnerService) => {
                    if (Array.isArray(existingServices)) {
                        onChange([...existingServices, service]);
                    } else {
                        onChange([service]);
                    }
                    closeNewServiceModal();
                };

                const handleDeleteService = (serviceId: string) => {
                    existingServices &&
                        onChange(
                            existingServices.filter(
                                ({ serviceId: ID }) => serviceId !== ID
                            )
                        );
                };

                const fieldsWithActions: ColumnType<ServiceDto>[] = [
                    ...visibleTableFields,
                    {
                        dataIndex: 'action',
                        title: '',
                        render: (_value, { serviceId }) => (
                            <ActionsTableButton
                                menuItems={[
                                    {
                                        title: 'Delete',
                                        onClick: () =>
                                            handleDeleteService(serviceId)
                                    }
                                ]}
                            />
                        ),
                        mobile: {
                            render: (_, { serviceId }) => (
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={() =>
                                        handleDeleteService(serviceId)
                                    }
                                >
                                    Delete
                                </Button>
                            ),
                            fullWidth: true
                        }
                    }
                ];

                const servicesToDisplay = existingServices?.map(s => ({
                    ...s,
                    name: getServiceName(allServices, s) || ''
                }));

                return (
                    <>
                        <CardWrapper title="Services">
                            <CustomTable
                                columns={fieldsWithActions}
                                data={servicesToDisplay as ServiceDto[]}
                                loading={loading}
                                mobile={{ renderSummary: row => row.name }}
                            />
                            <Button
                                variant="contained"
                                sx={{ mt: 2 }}
                                onClick={openNewServiceModal}
                            >
                                Add service
                            </Button>
                        </CardWrapper>
                        {newServiceModalIsOpen && (
                            <PartnerServiceModal
                                modalTitle={modalTitle}
                                fields={modalInputs}
                                onSelectService={onServiceSelectModal}
                                onCancel={closeNewServiceModal}
                                onAddService={handleAddService}
                                loading={loading}
                                services={
                                    existingServices
                                        ? balanceServices(
                                              allServices,
                                              existingServices
                                          )
                                        : allServices
                                }
                            />
                        )}
                    </>
                );
            }}
        />
    );
};

export default memo(ServicesCard);
