import { apiPostFile, apiPutFile } from 'src/api/endpoints/files';

export const uploadFileRequest = async (file: File): Promise<any> =>
    apiPostFile({
        originalFileName: file.name
    }).then(({ data }) =>
        apiPutFile(data.link, file).then(() => ({
            id: data.id,
            originalFileName: file.name,
            link: data.link,
            prefix: data.prefix
        }))
    );
