import { Pagination, ServiceDto } from 'src/types';
import {
    ServicesActionTypes,
    SET_LOADING,
    SET_PAGINATION,
    SET_SERVICE,
    SET_SERVICES
} from '../actions/services';

export interface ServicesState {
    services: Record<string, ServiceDto>;
    loading: boolean;
    pagination: Pagination | undefined;
    currentService: ServiceDto | undefined;
}
const initialState: ServicesState = {
    services: {},
    loading: false,
    pagination: undefined,
    currentService: undefined
};

export const services = (
    state: ServicesState = initialState,
    action: ServicesActionTypes
): ServicesState => {
    switch (action.type) {
        case SET_SERVICES:
            return {
                ...state,
                services:
                    action.payload?.reduce<Record<string, ServiceDto>>(
                        (prev, curr) => ({
                            ...prev,
                            [curr.id]: curr
                        }),
                        {}
                    ) || {},
                loading: false
            };
        case SET_SERVICE:
            return {
                ...state,
                currentService: action.payload
            };
        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
