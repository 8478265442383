import { FC } from 'react';
import { Spinning, SpinningContainer, CircularProgress } from './styles';

interface Props {
    loading?: boolean;
    size?: 'small' | 'middle' | 'large';
}

const spinnerSize = {
    small: {
        height: 18,
        width: 18
    },
    middle: {
        height: 40,
        width: 40
    },
    large: {
        height: 60,
        width: 60
    }
};

export const Spin: FC<Props> = ({ children, loading, size = 'middle' }) =>
    loading ? (
        <div style={{ position: 'relative', width: '100%' }}>
            <div>
                <Spinning>
                    <CircularProgress style={spinnerSize[size]} />
                </Spinning>
            </div>
            <SpinningContainer>{children}</SpinningContainer>
        </div>
    ) : (
        <>{children}</>
    );
