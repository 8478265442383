import React from 'react';

import { AccountType } from 'src/types';
import ContactsFilter from 'src/components/Accounts/ContactsFilter';

type AccountTypesConfigType = {
    type: AccountType;
    renderAction?: React.ReactNode;
}[];

export const accountTypesConfig: AccountTypesConfigType = Object.values(
    AccountType
).map(value => {
    if (value === AccountType.Contact) {
        return {
            type: value,
            renderAction: <ContactsFilter />
        };
    }
    return {
        type: value
    };
});
