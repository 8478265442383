import { Accordion, AccordionDetails, Box, Typography } from '@mui/material';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/system';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';
import { Account, AccountType } from 'src/types';
import {
    AccountsTable,
    GroupIcon
} from 'src/components/Accounts/AccountsTable';
import React from 'react';

const AccordionSummary = styled(MuiAccordionSummary)(() => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-content': {
        marginLeft: '10px !important'
    }
}));

const getDataByAccountType = (data: Account[], type: AccountType): Account[] =>
    data.filter(item => item.name && item.accountType === type);

const sortByAlphabetic = (data: Account[]) =>
    Array.from(data).sort((a, b) => a.name.localeCompare(b.name));

type AccountsAccordionProps = {
    data: Account[];
    type: AccountType;
    index: number;
    expanded: AccountType | null;
    setExpanded: React.Dispatch<React.SetStateAction<AccountType | null>>;
    renderHeaderActions?: React.ReactNode;
};

export const AccountsAccordion = ({
    data,
    type,
    index,
    expanded,
    setExpanded,
    renderHeaderActions
}: AccountsAccordionProps) => {
    const accounts = sortByAlphabetic(getDataByAccountType(data, type));
    return accounts.length ? (
        <div key={index}>
            <Accordion
                style={{
                    width: '100%',
                    boxShadow: 'none'
                }}
                expanded={expanded === type}
                onChange={() =>
                    setExpanded(prev => (prev === type ? null : type))
                }
            >
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={expanded === type ? <Remove /> : <Add />}
                >
                    <Box display="flex" alignItems="center" width="100%">
                        <GroupIcon type={type} />
                        <Typography
                            style={{
                                marginLeft: 10,
                                fontWeight: '500'
                            }}
                        >
                            {type} Accounts
                        </Typography>
                        {renderHeaderActions && (
                            <Box ml="auto" onClick={e => e.stopPropagation()}>
                                {renderHeaderActions}
                            </Box>
                        )}
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                    <AccountsTable data={accounts} />
                </AccordionDetails>
            </Accordion>
        </div>
    ) : null;
};
