import React, { FC } from 'react';
import { Grid } from '@mui/material';
import { Control } from 'react-hook-form';
import {
    AutocompleteOption,
    FilterRowType,
    FormFilterRow
} from 'src/components/Form';
import { FormFieldType, ServicesFilterForm } from 'src/types';

interface Props {
    control: Control<ServicesFilterForm, object>;
    categories: AutocompleteOption[];
}

const filters = (
    categories: AutocompleteOption[]
): FilterRowType<ServicesFilterForm>[] => [
    {
        title: 'Name',
        placeholder: 'type service name',
        name: 'name'
    },
    {
        title: 'Category',
        placeholder: 'select category',
        name: 'category',
        type: FormFieldType.Autocomplete,
        options: categories
    }
];

export const FilterForm: FC<Props> = ({ control, categories }) => (
    <form>
        <Grid container sx={{ minWidth: 600, width: '50vw', pl: 1, pr: 1 }}>
            {filters(categories).map(filter => (
                <FormFilterRow
                    key={filter.name}
                    control={control}
                    {...filter}
                />
            ))}
        </Grid>
    </form>
);
