import { ComponentProps, CSSProperties, FC } from 'react';
import { Control, Path, ValidationRule } from 'react-hook-form';
import {
    Card,
    CardContent,
    Grid,
    GridSize,
    InputBaseComponentProps,
    TextFieldProps,
    Typography
} from '@mui/material';

import { FormFieldType } from 'src/types';
import {
    FormAutoCompleteTypeProps,
    FormInputTextTypeProps
} from 'src/components/Form';
import { FormInput } from './FormInput';
import { FormDateTimeInputProps } from 'src/components/Form/FormDateTimeInput';

interface Props<T = any> {
    control: Control<T>;
    fields: (Field & TextFieldProps)[];
    title?: string;
    description?: string;
    cardStyle?: CSSProperties;
    spacing?: number;
}

export interface CommonField<T = any> {
    name?: Path<T>;
    label?: string;
    show?: boolean;
    xs?: GridSize;
    pattern?: ValidationRule<RegExp>;
    inputProps?: InputBaseComponentProps;
    type?: FormFieldType;
    gridProps?: ComponentProps<typeof Grid>;
}

export type Field<T = any> = CommonField<T> &
    TextFieldProps &
    (
        | Omit<FormAutoCompleteTypeProps<T>, 'control'>
        | Omit<FormInputTextTypeProps<T>, 'control'>
        | Omit<FormDateTimeInputProps<T>, 'control'>
        | { xs: GridSize; gridProps?: ComponentProps<typeof Grid> }
    ) & { fieldType?: string };

export const FormCard: FC<Props> = ({
    control,
    fields,
    title,
    description,
    cardStyle,
    children,
    spacing = 2
}) => (
    <Card sx={{ width: '100%', overflow: 'initial' }} style={cardStyle}>
        <CardContent>
            {!!title && (
                <Typography variant="h6" component="div">
                    {title}
                </Typography>
            )}
            {!!description && (
                <Typography
                    marginBottom={1}
                    variant="body2"
                    component="div"
                    sx={{ maxWidth: 716, opacity: 0.6 }}
                >
                    {description}
                </Typography>
            )}
            <Grid container spacing={spacing}>
                {fields.map(
                    (
                        { name, xs, type, gridProps, fieldType, ...otherProps },
                        index
                    ) => (
                        <Grid
                            key={name || index}
                            item
                            xs={xs || 12}
                            style={name ? {} : { padding: 0 }}
                            {...gridProps}
                        >
                            {name && (
                                <FormInput
                                    type={type}
                                    fieldType={fieldType}
                                    inputProps={{
                                        name,
                                        control,
                                        fullWidth: true,
                                        ...otherProps
                                    }}
                                />
                            )}
                        </Grid>
                    )
                )}
            </Grid>
            {children}
        </CardContent>
    </Card>
);
