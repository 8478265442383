import { FC } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { FormFieldType } from 'src/types';
import { Field, FormCard } from '../../Form/FormCard';
import { countries, languages, states, timezones } from 'src/constants';
import IMidEditForm from 'src/pages/MIDs/types';

interface Props {
    control: Control<IMidEditForm, object>;
}

const fields = (
    country: string | null | undefined
): (Field<IMidEditForm> & TextFieldProps)[] => [
    {
        name: 'name',
        label: 'Company name',
        required: true,
        xs: 6
    },
    {
        name: 'dba',
        label: 'DBA',
        required: true,
        xs: 6
    },
    {
        name: 'externalIdentifier',
        label: 'External Identifier',
        required: true,
        xs: 6
    },
    {
        name: 'accountInfo.country',
        label: 'Country',
        type: FormFieldType.Autocomplete,
        options: countries,
        required: true,
        inputProps: {
            maxLength: 2
        },
        xs: 6
    },
    {
        name: 'accountInfo.address',
        label: 'Address 1',
        required: true,
        xs: 6
    },
    {
        name: 'accountInfo.address2',
        label: 'Address 2',
        xs: 6
    },
    {
        name: 'accountInfo.city',
        label: 'City',
        required: true,
        inputProps: {
            maxLength: 50
        },
        xs: 4
    },
    {
        name: 'accountInfo.state',
        label: 'State',
        required: true,
        inputProps: {
            maxLength: 40
        },
        type:
            country && states[country] ? FormFieldType.Autocomplete : undefined,
        options: country && states[country],
        xs: 4
    },
    {
        name: 'accountInfo.zip',
        label: 'Postal Code',
        required: true,
        inputProps: {
            maxLength: 16
        },
        xs: 4
    },
    {
        name: 'language',
        label: 'Language',
        type: FormFieldType.Autocomplete,
        options: languages,
        xs: 6
    }
];

export const CompanyInfoForm: FC<Props> = ({ control }) => {
    const country = useWatch({ control, name: 'accountInfo.country' });

    return (
        <FormCard
            control={control}
            fields={fields(country)}
            title="Company Information"
        />
    );
};
