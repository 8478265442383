import { FC } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { FormFieldType, PartnerCreateEditDto } from 'src/types';
import { Field, FormCard } from '../../Form/FormCard';
import { countries, states } from 'src/constants';

interface Props {
    control: Control<PartnerCreateEditDto, object>;
}

const fields = (
    country: string | null | undefined
): (Field<PartnerCreateEditDto> & TextFieldProps)[] => [
    {
        name: 'name',
        label: 'Partner name',
        required: true,
        xs: 6
    },
    {
        name: 'whitelistedName',
        label: 'Whitelisted name',
        type: FormFieldType.Input,
        xs: 6
    },
    {
        name: 'accountInfo.country',
        label: 'Country',
        type: FormFieldType.Autocomplete,
        options: countries,
        required: true,
        inputProps: {
            maxLength: 2
        },
        xs: 6
    },
    {
        name: 'whitelistedUrl',
        label: 'Whitelisted URL',
        type: FormFieldType.Input,
        xs: 6
    },
    {
        name: 'accountInfo.address',
        label: 'Address 1',
        required: true
    },
    {
        name: 'accountInfo.address2',
        label: 'Address 2'
    },
    {
        name: 'accountInfo.city',
        label: 'City',
        required: true,
        inputProps: {
            maxLength: 50
        },
        xs: 4
    },
    {
        name: 'accountInfo.state',
        label: 'State',
        required: true,
        inputProps: {
            maxLength: 40
        },
        type:
            country && states[country] ? FormFieldType.Autocomplete : undefined,
        options: country && states[country],
        xs: 4
    },
    {
        name: 'accountInfo.zip',
        label: 'Postal Code',
        required: true,
        inputProps: {
            maxLength: 16
        },
        xs: 4
    }
];
export const PartnerInfoForm: FC<Props> = ({ control }) => {
    const country = useWatch({ control, name: 'accountInfo.country' });

    return (
        <FormCard control={control} fields={fields(country)} title="Partner" />
    );
};
