import React from 'react';
import { Modal } from 'src/components/Modal/Modal';
import { Box, Button, Typography } from '@mui/material';

type LeaveFormModalProps = {
    open: boolean;
    onClose: () => void;
    onLeave: () => void;
    onSave: () => void;
};

const LeaveFormModal: React.FC<LeaveFormModalProps> = ({
    open,
    onClose,
    onLeave,
    onSave
}) => (
    <Modal
        open={open}
        title="You are leaving the page"
        titleFontWeight="900"
        onBackdropClick={onClose}
    >
        <Box>
            <Typography sx={{ padding: '20px 0px' }}>
                The changes you made will not be saved
                <br />
                {/*<Typography component="span" fontWeight="500">*/}
                {/*    Do you want to save a draft and continue later?*/}
                {/*</Typography>*/}
            </Typography>
            <Box
                display="flex"
                justifyContent="space-between"
                mt={3}
                sx={{
                    '& .MuiButtonBase-root:not(:last-child)': {
                        marginRight: '10px'
                    }
                }}
            >
                <Button variant="contained" onClick={onClose}>
                    Stay on the page
                </Button>
                {/*<Button variant="contained" onClick={onSave}>*/}
                {/*    Save as Draft*/}
                {/*</Button>*/}
                <Button variant="outlined" onClick={onLeave}>
                    Don't save and leave
                </Button>
            </Box>
        </Box>
    </Modal>
);

export default LeaveFormModal;
