import { FC } from 'react';
import { Control, Path, ValidationRule } from 'react-hook-form';
import {
    Card,
    CardContent,
    Grid,
    GridSize,
    InputBaseComponentProps,
    TextFieldProps,
    Typography
} from '@mui/material';
import { FormFieldType, IAgentsApiPostPut } from 'src/types';
import {
    FormAutoCompleteTypeProps,
    FormInputTextTypeProps
} from 'src/components/Form';
import { FormInput } from '../../Form/FormInput';

interface Props {
    control: Control<IAgentsApiPostPut, object>;
    fields: (Field & TextFieldProps)[];
    title: string;
    description?: string;
}

export interface CommonField {
    name?: Path<IAgentsApiPostPut>;
    label?: string;
    xs?: GridSize;
    pattern?: ValidationRule<RegExp>;
    inputProps?: InputBaseComponentProps;
    type?: FormFieldType;
}

export type Field<T = any> = CommonField &
    (
        | Omit<FormAutoCompleteTypeProps<T>, 'control'>
        | Omit<FormInputTextTypeProps<T>, 'control'>
        | { xs: GridSize }
    );

export const FormCard: FC<Props> = ({
    control,
    fields,
    title,
    description
}) => (
    <Card style={{ width: '100%', overflow: 'initial' }}>
        <CardContent>
            <Typography marginBottom={1} variant="h6" component="div">
                {title}
            </Typography>
            {!!description && (
                <Typography
                    marginBottom={1}
                    variant="body2"
                    component="div"
                    sx={{ maxWidth: 716, opacity: 0.6 }}
                >
                    {description}
                </Typography>
            )}
            <Grid container spacing={2}>
                {fields.map(({ name, xs, type, ...otherProps }, index) => (
                    <Grid key={name || index} item xs={xs || 12}>
                        {name && (
                            <FormInput
                                type={type}
                                inputProps={{
                                    name,
                                    control,
                                    fullWidth: true,
                                    ...otherProps
                                }}
                            />
                        )}
                    </Grid>
                ))}
            </Grid>
        </CardContent>
    </Card>
);
