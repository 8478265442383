import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showMessageAction } from 'src/store/actions';
import {
    setLoadingAction,
    SetPagination,
    setPaginationAction,
    setCategoriesAction
} from 'src/store/actions/categories';
import {
    apiDeleteCategory,
    apiGetCategory,
    apiGetCategories,
    apiPostCategory,
    apiPutCategory
} from '../endpoints/categories';
import { getCategories } from 'src/store/selectors';
import {
    CommonGetParams,
    ErrorType,
    Pagination,
    CategoryDto,
    CategoryPost
} from 'src/types';
import { getApiCommonParams } from 'src/utils/formatters';

interface ReturnValue {
    fetchCategories: (commonParams?: CommonGetParams) => Promise<void>;
    deleteCategory: (id: string, params?: CommonGetParams) => Promise<void>;
    createCategory: (
        body: CategoryPost,
        successCb?: () => void
    ) => Promise<void>;
    updateCategory: (
        id: string,
        body: CategoryPost,
        successCb?: () => void
    ) => Promise<void>;
    categories: Record<string, CategoryDto>;
    loading: boolean;
    errorData: ErrorType | null;
    paging?: Pagination;
    setPaging: (pagination?: Pagination) => SetPagination;
}

export const useCategories = (): ReturnValue => {
    const [errorData, setErrorData] = useState<ErrorType | null>(null);

    const {
        categories,
        loading,
        pagination: paging
    } = useSelector(getCategories);

    const dispatch = useDispatch();

    const setPaging = (pagination?: Pagination) =>
        dispatch(setPaginationAction(pagination));

    const setCategories = (categories: CategoryDto[]) =>
        dispatch(setCategoriesAction(categories));

    const setLoading = () => dispatch(setLoadingAction());

    const fetchCategories = (commonParams?: CommonGetParams) => {
        setLoading();
        setErrorData(null);

        const params = commonParams
            ? getApiCommonParams(commonParams)
            : undefined;

        return apiGetCategories({ params })
            .then(({ result, pagination }) => {
                setCategories(result);
                setPaging(pagination);
            })
            .catch((err: ErrorType) => {
                setCategories([]);
                setErrorData(err);
                dispatch(
                    showMessageAction({
                        message: `Failed getting categories: ${err}`,
                        type: 'error'
                    })
                );

                throw err;
            });
    };

    const deleteCategory = (id: string, commonParams?: CommonGetParams) =>
        apiDeleteCategory(id)
            .then(() => {
                dispatch(
                    showMessageAction({
                        message: 'Category deleted successfully',
                        type: 'success'
                    })
                );
                fetchCategories(commonParams);
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed deleting category: ${err}`,
                        type: 'error'
                    })
                );
                throw err;
            });

    const createCategory = (body: CategoryPost, successCalback?: () => void) =>
        apiPostCategory(body)
            .then(() => {
                dispatch(
                    showMessageAction({
                        message: 'Category saved successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback();
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed saving category: ${err}`,
                        type: 'error'
                    })
                );
            });

    const updateCategory = (
        id: string,
        body: CategoryPost,
        successCalback?: (category: CategoryDto) => void
    ) =>
        apiPutCategory(id, body)
            .then(category => {
                dispatch(
                    showMessageAction({
                        message: 'Category changed successfully',
                        type: 'success'
                    })
                );
                if (successCalback) {
                    successCalback(category);
                }
            })
            .catch((err: any) => {
                dispatch(
                    showMessageAction({
                        message: `Failed changing category: ${err}`,
                        type: 'error'
                    })
                );
            });

    return {
        fetchCategories,
        deleteCategory,
        createCategory,
        updateCategory,
        categories,
        loading,
        errorData,
        paging,
        setPaging
    };
};

interface CategoryReturnValue {
    category: CategoryDto | undefined;
    fetchCategory: (id: string) => Promise<void>;
    loading: boolean;
    errorData: ErrorType | null;
}

export const useCategory = (): CategoryReturnValue => {
    const [category, setCategory] = useState<CategoryDto>();
    const [loading, setLoading] = useState(false);
    const [errorData, setErrorData] = useState<ErrorType | null>(null);

    const fetchCategory = (id: string) => {
        setLoading(true);
        setErrorData(null);

        return apiGetCategory(id)
            .then(category => {
                setCategory(category);
            })
            .catch(err => {
                setErrorData(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        category,
        fetchCategory,
        loading,
        errorData
    };
};
