import {
    ApiResponse,
    IOrganizationDto,
    CommonApiGetParams,
    ICreateOrganizationResult
} from 'src/types';
import { apiClient } from '../client';
import {
    withDefaultHandlersPagination,
    withUnwrappedHandlers
} from '../helpers/withDefaultHandlers';

const BASE_PATH = '/auth/auth-api/organization-info';

export const apiGetOrganizations = ({
    params
}: {
    params?: CommonApiGetParams;
}) =>
    withDefaultHandlersPagination<IOrganizationDto[]>(
        apiClient.get<ApiResponse<IOrganizationDto[]>>(BASE_PATH, params)
    );

export const apiPostOrganizations = (organization: {
    name: string;
    userId: string;
}) =>
    withUnwrappedHandlers<ICreateOrganizationResult>(
        apiClient.post<ICreateOrganizationResult>(BASE_PATH, organization)
    );
