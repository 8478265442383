import { apiGetServices } from '../endpoints/mids';
import { showMessageAction } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { MidServiceDto } from '../../pages/MIDs/types';
import { useState } from 'react';

export default () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const fetchServicesByPartnerId = (partnerId: string) => {
        setLoading(true);
        return apiGetServices(partnerId)
            .catch(e => {
                dispatch(
                    showMessageAction({ type: 'error', message: e.message })
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        fetchServicesByPartnerId,
        loading
    };
};
