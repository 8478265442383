import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setLoadingAction,
    SetPagination,
    setPaginationAction,
    showMessageAction
} from 'src/store/actions';
import { SetRdrAlerts, setRdrAlertsAction } from 'src/store/actions/rdrAlerts';
import { getRdrAlerts } from 'src/store/selectors/index';
import { CommonGetParams, ErrorType, Pagination } from 'src/types';
import { getApiCommonParams } from 'src/utils/formatters';
import { apiGetRdrAlerts } from '../endpoints/rdrAlerts';
import { IAlert } from './../../types/alerts';

interface ReturnValue {
    fetchRdrAlerts: (commonParams?: CommonGetParams) => Promise<void>;
    rdrAlerts: IAlert[];
    loading: boolean;
    errorData: ErrorType;
    setRdrAlerts: (rdrAlerts: IAlert[]) => SetRdrAlerts;
    pagination?: Pagination;
    setPaging: (pagination?: Pagination) => SetPagination;
}

export const useRdrAlerts = (): ReturnValue => {
    const dispatch = useDispatch();
    const [errorData, setErrorData] = useState<ErrorType | null>(null);

    const { rdrAlerts, loading, pagination } = useSelector(getRdrAlerts);

    const setLoading = () => dispatch(setLoadingAction());

    const setRdrAlerts = (rdrAlerts: IAlert[]) =>
        dispatch(setRdrAlertsAction(rdrAlerts));

    const setPaging = (pagination?: Pagination) =>
        dispatch(setPaginationAction(pagination));

    const fetchRdrAlerts = async (commonParams?: CommonGetParams) => {
        setLoading();
        setErrorData(null);

        const params = commonParams
            ? getApiCommonParams(commonParams)
            : undefined;

        return apiGetRdrAlerts({ params })
            .then(({ result, pagination }) => {
                setRdrAlerts(result || []);
                setPaging(pagination);
            })
            .catch((err: ErrorType) => {
                setRdrAlerts([]);
                setErrorData(err);
                dispatch(
                    showMessageAction({
                        message: `Failed getting rdr alerts: ${err}`,
                        type: 'error'
                    })
                );
                throw err.message;
            });
    };

    return {
        rdrAlerts,
        loading,
        pagination,
        fetchRdrAlerts,
        setRdrAlerts,
        setPaging,
        errorData
    };
};
