import {
    Checkbox,
    Stack,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableHead
} from '@mui/material';
import { useTable } from 'src/hooks/table/useTable';
import { StyledTableRow, StyledTbodyTableRow } from './styles';
import { TableType } from './Table';

export const DesktopTable: TableType = ({
    data,
    columns,
    selectable,
    renderHead
}) => {
    const { selectedRows, handleSelectAllRows, handleRowClick } = useTable({
        data,
        selectable
    });

    return (
        <MuiTable size="small" sx={{ minWidth: 650 }}>
            {renderHead && (
                <TableHead>
                    <StyledTableRow>
                        {selectable && (
                            <TableCell style={{ width: 15 }}>
                                <Checkbox
                                    onClick={handleSelectAllRows}
                                    checked={
                                        selectedRows.length >= data?.length
                                    }
                                />
                            </TableCell>
                        )}
                        {columns.map(
                            ({
                                title,
                                key,
                                dataIndex,
                                render,
                                mobile,
                                ...props
                            }) => (
                                <TableCell key={key ?? dataIndex} {...props}>
                                    {title}
                                </TableCell>
                            )
                        )}
                    </StyledTableRow>
                </TableHead>
            )}
            <TableBody>
                {data?.length ? (
                    data.map((row, index) => (
                        <StyledTbodyTableRow
                            key={index}
                            onClick={handleRowClick(index)}
                            style={
                                selectedRows.includes(index)
                                    ? { backgroundColor: '#eee' }
                                    : undefined
                            }
                        >
                            {selectable && (
                                <TableCell style={{ width: 15, paddingTop: 8 }}>
                                    <Checkbox
                                        checked={selectedRows.includes(index)}
                                    />
                                </TableCell>
                            )}
                            {columns.map(
                                ({
                                    dataIndex,
                                    key,
                                    render,
                                    title,
                                    mobile,
                                    ...props
                                }) => (
                                    <TableCell
                                        key={key ?? dataIndex}
                                        {...props}
                                    >
                                        {render
                                            ? render(row[dataIndex], row)
                                            : row[dataIndex]}
                                    </TableCell>
                                )
                            )}
                        </StyledTbodyTableRow>
                    ))
                ) : (
                    <StyledTableRow>
                        <TableCell colSpan={columns.length}>
                            <Stack
                                sx={{ pt: 2, pb: 2 }}
                                direction="row"
                                justifyContent="center"
                            >
                                No Data
                            </Stack>
                        </TableCell>
                    </StyledTableRow>
                )}
            </TableBody>
        </MuiTable>
    );
};
