import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import IsoIcon from '@mui/icons-material/Iso';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import mockPartnerImg from 'src/assets/partner-mock-img.png';
import { Table as CustomTable } from 'src/components/Table';
import { routes } from 'src/constants';
import { Account, AccountType } from 'src/types';
import { generateAccountFields } from './tableFields';

export const GroupIcon = ({
    type,
    logo,
    isAccordionImg = true
}: {
    type: AccountType;
    logo?: string;
    isAccordionImg?: boolean;
}) => {
    switch (type) {
        case AccountType.Merchant:
            return <StorefrontIcon color="action" />;
        case AccountType.Partner:
            return isAccordionImg ? (
                <ApartmentIcon color="action" />
            ) : (
                <img
                    width={50}
                    height={50}
                    alt={type}
                    src={logo || mockPartnerImg}
                />
            );
        case AccountType.Lead:
            return <IsoIcon color="action" />;
        case AccountType.Agent:
        default:
            return <BusinessIcon color="action" />;
    }
};

export const AccountsTable = ({ data }: { data: Account[] }) => {
    const history = useHistory();

    const handleAccountClick = useCallback(
        ({ id, accountType }: Account) => {
            switch (accountType) {
                case AccountType.Partner:
                    return history.push(routes.partner(id));
                case AccountType.Merchant:
                    return history.push(routes.merchant(id));
                case AccountType.Agent:
                    return history.push(routes.agent(id));
                case AccountType.Lead:
                    return history.push(routes.lead(id));
                default:
                    return () => {};
            }
        },
        [history]
    );

    const fieldsToDisplay = useMemo(
        () => generateAccountFields({ onAccountClick: handleAccountClick }),
        [handleAccountClick]
    );

    return (
        <CustomTable
            columns={fieldsToDisplay}
            data={data}
            asCard={false}
            renderHead={false}
            mobile={{
                renderSummary: row => row.name
            }}
        />
    );
};
