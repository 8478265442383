import { FC } from 'react';
import { Stack, FormLabel, Box } from '@mui/material';
import { Control } from 'react-hook-form';
import { FormSingleCheckbox } from './FormSingleCheckbox';

interface Props<T = any> {
    label?: string;
    checkboxes: { name: string; label: string }[];
    control: Control<T, object>;
}

export const LabeledSingleCheckboxes: FC<Props> = ({
    label,
    checkboxes,
    control
}) => (
    <Stack>
        <FormLabel>{label}</FormLabel>
        <Box>
            {checkboxes.map(({ name, label }) => (
                <FormSingleCheckbox
                    key={name}
                    control={control}
                    name={name}
                    label={label}
                />
            ))}
        </Box>
    </Stack>
);
