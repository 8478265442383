import { FC } from 'react';
import { Control } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { FormFieldType, IAgentsApiPostPut } from 'src/types';
import { businessTypes } from 'src/constants';
import { Field, FormCard } from './FormCard';

interface Props {
    control: Control<IAgentsApiPostPut, object>;
}

const fields: (Field<IAgentsApiPostPut> & TextFieldProps)[] = [
    {
        name: 'commissionPaymentInfo.bankAccount',
        label: 'Account Number',
        required: true,
        inputProps: {
            maxLength: 34,
            // TODO: disabled because of Safari autofill, fix later
            // type: 'password',
            autoComplete: 'cc-number'
        },
        xs: 6
    },
    {
        name: 'commissionPaymentInfo.bankRouting',
        label: 'Routing / ABA Number',
        // required: true,
        inputProps: {
            maxLength: 9
            // type: 'password',
            // autoComplete: 'current-password'
        },
        xs: 6
    },
    {
        name: 'commissionPaymentInfo.accountHolder',
        label: 'Legal Name',
        required: true,
        inputProps: {
            maxLength: 26
        },
        xs: 6
    },
    {
        name: 'commissionPaymentInfo.businessType',
        label: 'Business Type',
        type: FormFieldType.Autocomplete,
        options: businessTypes,
        // required: true,
        xs: 6
    },
    {
        name: 'commissionPaymentInfo.taxId',
        label: 'Tax ID (EIN or SSN)',
        required: true,
        inputProps: {
            maxLength: 26
        },
        xs: 6
    }
];

export const CommissionPaymentInfoForm: FC<Props> = ({ control }) => (
    <FormCard
        control={control}
        fields={fields}
        title="Commission Payment Information"
        description="The following bank information must be entered correctly in order to deposit earner commission directly into the agent’s account."
    />
);
