import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessageAction } from 'src/store/actions';
import { apiGetFeeScheduleServices } from '../endpoints/feeScheduleService';
import { IMIDFeeScheduleListDto } from 'src/types/payments';

export const useFeeScheduleServices = () => {
    const [scheduleServices, setScheduleServices] = useState<
        IMIDFeeScheduleListDto[]
    >([]);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const fetchScheduleServices = () => {
        setLoading(true);

        return apiGetFeeScheduleServices()
            .then(services => {
                setScheduleServices(services || []);
            })
            .catch(err => {
                dispatch(
                    showMessageAction({
                        message: `Failed getting fee schedule services: ${err}`,
                        type: 'error'
                    })
                );
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchScheduleServices();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        fetchScheduleServices,
        loading,
        scheduleServices
    };
};
