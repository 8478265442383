import { Card, Modal as MuiModal } from '@mui/material';
import { styled } from '@mui/system';

export const StyledModal = styled(MuiModal)`
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
` as typeof MuiModal;

export const StyledCard = styled(Card)`
    min-width: 450px;
    max-height: 85vh;
    overflow-y: auto;

    @media screen and (max-width: 480px) {
        min-width: 90vw;
    }
`;
