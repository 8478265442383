import { useEffect, useMemo, useState } from 'react';
import useServicesByPartnerId from 'src/api/hooks/useServicesByPartnerId';
import { MidServiceDto } from 'src/pages/MIDs/types';
import { ServicesState } from 'src/store/reducers/services';
import { ServiceDto } from 'src/types';

export const useServicesForPartner = ({
    partnerId,
    services
}: {
    partnerId: string;
    services: Record<string, ServiceDto>;
}) => {
    const [allowedServicesForPartner, setAllowedServicesForPartner] = useState<
        MidServiceDto[]
    >([]);

    const { fetchServicesByPartnerId, loading: loadingServicesForPartner } =
        useServicesByPartnerId();

    useEffect(() => {
        // We fetch services allowed for partner
        if (!partnerId) {
            return;
        }

        fetchServicesByPartnerId(partnerId).then(servicesForPartner => {
            servicesForPartner &&
                setAllowedServicesForPartner(servicesForPartner);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnerId]);

    /*
        We get all services and then filter only that allowed - baaaaaaad idea))
     */
    // TODO: refactor this shit in task https://its.velvetech.com/browse/BVB-443

    const filteredServices: ServicesState['services'] = useMemo(
        () =>
            allowedServicesForPartner.reduce((acc, service) => {
                if (!service.serviceId) {
                    return acc;
                }
                acc[service.serviceId] = services[service.serviceId];
                return acc;
            }, {} as ServicesState['services']),
        [allowedServicesForPartner, services]
    );

    const noServicesForPartner = Object.values(filteredServices).length === 0;

    const disableAddService = !partnerId || noServicesForPartner;

    const messageForDisabling = (() => {
        if (!partnerId) {
            return 'Please, first add partner';
        }

        if (noServicesForPartner) {
            return 'There is no allowed services for this partner';
        }
    })();

    return {
        filteredServices,
        disableAddService,
        messageForDisabling,
        loadingServicesForPartner
    };
};
