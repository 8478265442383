import React, { useEffect, useState } from 'react';
import { Box, Grid, Modal, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import NewNote from './NewNote';
import { INote } from '../../../types/partners/common';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '10px'
};

interface ModalEditNoteProps {
    noteToEdit: INote | null;
    onEdit: (note: INote, prevnote: INote) => void;
    onClose: () => void;
}

const ModalEditNote = ({ noteToEdit, onEdit, onClose }: ModalEditNoteProps) => {
    const [noteText, setNoteText] = useState<string>(noteToEdit?.note || '');

    const handleEdit = () => {
        if (noteText && noteToEdit) {
            setNoteText('');
            onEdit({ ...noteToEdit, note: noteText }, noteToEdit);
            onClose();
        }
    };

    useEffect(() => {
        setNoteText(noteToEdit?.note || '');
    }, [noteToEdit]);

    return (
        <Modal open={!!noteToEdit} onClose={onClose}>
            <Box sx={style}>
                <Typography variant="h6" component="div">
                    Edit note
                </Typography>
                <Box my={2}>
                    <TextField
                        value={noteText}
                        placeholder="Type note here"
                        multiline
                        rows={4}
                        maxRows={4}
                        onChange={e => setNoteText(e.target.value)}
                    />
                </Box>
                <LoadingButton variant="contained" onClick={handleEdit}>
                    Save edited note
                </LoadingButton>
            </Box>
        </Modal>
    );
};

export default ModalEditNote;
