import React, { useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const NewNote = ({ onAdd }: { onAdd: any }) => {
    const [newNote, setNewNote] = useState<string>('');

    const handleAddNewNote = () => {
        if (newNote) {
            setNewNote('');
            onAdd(newNote);
        }
    };

    return (
        <>
            <Typography variant="h6" component="div">
                Notes
            </Typography>
            <Box my={2}>
                <TextField
                    value={newNote}
                    placeholder="Type note here"
                    multiline
                    rows={4}
                    maxRows={4}
                    onChange={e => setNewNote(e.target.value)}
                />
            </Box>
            <LoadingButton variant="contained" onClick={handleAddNewNote}>
                Save new note
            </LoadingButton>
        </>
    );
};

export default NewNote;
