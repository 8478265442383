export const businessTypes = [
    { label: 'Individual', value: 'Individual' },
    { label: 'Partnership', value: 'Partnership' },
    { label: 'Corporation', value: 'Corporation' },
    { label: 'LLC Taxed As Partnership', value: 'LLCTaxedAsPartnership' },
    { label: 'LLC Taxed As Corporation', value: 'LLCTaxedAsCorporation' },
    {
        label: 'LLC Taxed As Disregarded Entity',
        value: 'LLCTaxedAsDisregardedEntity'
    },
    { label: 'Foreign Entity', value: 'ForeignEntity' }
];
