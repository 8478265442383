import { Chip } from '@mui/material';
import { FC } from 'react';

import { ColumnType, RoleDto } from 'src/types';
import { Table } from '../Table';

interface Props {
    data: RoleDto[];
    loading?: boolean;
    onDelete: (id: string) => void;
}

export const RolesTable: FC<Props> = ({ data, loading, onDelete }) => {
    const columns: ColumnType<RoleDto>[] = [
        {
            title: 'Role',
            dataIndex: 'name'
        },
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'Role status',
            dataIndex: 'type',
            render: value => <Chip label={value} />
        }
        //TODO: Revert back when we will correct process roles deletion on backend
        /*        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            width: '60px',
            render: (_, { id }) => (
                <ActionsTableButton
                    menuItems={[
                        {
                            title: 'Edit',
                            onClick: () =>
                                history.push(routes.permissionsRole(id))
                        },
                        {
                            title: 'Delete',
                            onClick: () => onDelete(id)
                        }
                    ]}
                />
            )
        }*/
    ];

    return (
        <Table
            columns={columns}
            data={data}
            loading={loading}
            mobile={{ renderSummary: row => row.name }}
        />
    );
};
