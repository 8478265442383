import ReactPhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import { Control, Controller, Path, ValidationRule } from 'react-hook-form';

const StyledInput = styled.div<{
    $isError?: boolean;
    $required?: boolean;
}>`
    .react-tel-input {
        .special-label {
            left: -4px;
            color: rgba(0, 0, 0, 0.6);
            font-size: 12px;
            ${({ $isError }) => ($isError ? 'color: #d32f2f;' : '')}

            ${({ $required }) =>
                $required
                    ? `
                &::after {
                    content: ' *';
                    color: #d32f2f;
                }
            `
                    : ''}
        }
        .form-control {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid rgb(200, 200, 200);
            padding: 18px 14px 10px 58px;
            width: 100%;
            ${({ $isError }) =>
                $isError
                    ? `
                    border-color: #d32f2f;
                    border-width: 2px;
                    padding-bottom: 9px;
                `
                    : ''}

            &:focus {
                padding-bottom: 9px;
                border-bottom: 2px solid #5467b6;
                box-shadow: none;
            }
        }
        .selected-flag .flag {
            top: 56%;
        }
    }
`;

export interface FormPhoneInputProps<T = any> {
    name: Path<T>;
    label?: string;
    control: Control<T, object>;
    required?: boolean;
    pattern?: ValidationRule<RegExp>;
}

type FormPhoneInputType = <T = any>(
    props: FormPhoneInputProps<T>
) => JSX.Element;

export const FormPhoneInput: FormPhoneInputType = ({
    name,
    label,
    control,
    required,
    pattern
}) => (
    <Controller
        name={name}
        control={control}
        rules={{ required, pattern }}
        render={({ field: { onChange, value, ref }, fieldState }) => (
            <StyledInput $isError={!!fieldState.error} $required={required}>
                <ReactPhoneInput
                    value={value as string}
                    onChange={value => onChange('+' + value)}
                    specialLabel={label || 'Phone'}
                    autocompleteSearch
                    preferredCountries={['us', 'gb']}
                    country={'us'}
                />
            </StyledInput>
        )}
    />
);
