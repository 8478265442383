import { CircularProgress as MuiCircularProgress } from '@mui/material';
import { styled } from '@mui/system';

export const SpinningContainer = styled('div')`
    clear: both;
    opacity: 0.5;
    user-select: none;
    pointer-events: none;
    position: relative;
    transition: opacity 0.3s;
`;

export const Spinning = styled('div')`
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #000000d9;
    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    text-align: center;
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: block;
    width: 100%;
    height: 100%;
    max-height: 500px;
`;

export const CircularProgress = styled(MuiCircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px;
`;
