// @ts-nocheck
import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { Control, Path } from 'react-hook-form';
import { FilterRowType, FormFilterRow } from 'src/components/Form';
import { FilterFormType, FormFieldType, IAgentsApiPostPut } from 'src/types';
import { countries } from 'src/constants';

interface Props {
    control: Control<FilterFormType, object>;
}

interface Filter {
    title: string;
    placeholder: string;
    name: Path<Omit<IAgentsApiPostPut, 'address2'>>;
    type?: FormFieldType;
}

const legalFilters: FilterRowType<
    Omit<IAgentsApiPostPut, 'address2' | 'partner'>
>[] = [
    {
        title: 'Name',
        placeholder: 'type agent name',
        name: 'name'
    },
    {
        title: 'Address',
        placeholder: 'type address',
        name: 'address'
    },
    {
        title: 'City',
        placeholder: 'type city name',
        name: 'city'
    },
    {
        title: 'Country',
        placeholder: 'select country code from drop down',
        name: 'country',
        type: FormFieldType.Autocomplete,
        options: countries
    },
    {
        title: 'State',
        placeholder: 'type state',
        name: 'state'
    }
];

const bankingInfoFilters: Filter[] = [
    {
        title: 'Legal entity',
        placeholder: 'type legal entity',
        name: 'accountHolder'
    },
    {
        title: 'Bank Routing',
        placeholder: 'type bank routing',
        name: 'bankRouting'
    },
    {
        title: 'Bank Account',
        placeholder: 'type bank account',
        name: 'bankAccount'
    }
];

const primaryContactFilters: Filter[] = [
    {
        title: 'First name',
        placeholder: 'type first name',
        name: 'firstName'
    },
    {
        title: 'Last name',
        placeholder: 'type last name',
        name: 'lastName'
    },
    {
        title: 'Email address',
        placeholder: 'type email address',
        name: 'emailAddress'
    },
    {
        title: 'Phone number',
        placeholder: 'type phone number',
        name: 'phoneNumber'
    },
    {
        title: 'Fax number',
        placeholder: 'type fax number',
        name: 'faxNumber'
    }
];

export const FilterForm: FC<Props> = ({ control }) => (
    <form>
        <Grid container sx={{ minWidth: 600, width: '50vw', pl: 1, pr: 1 }}>
            {legalFilters.map(filter => (
                <FormFilterRow
                    key={filter.name}
                    control={control}
                    {...filter}
                />
            ))}
        </Grid>
        <Typography variant="h6">Banking Info</Typography>
        <Grid container sx={{ minWidth: 600, width: '50vw', pl: 1, pr: 1 }}>
            {bankingInfoFilters.map(filter => (
                <FormFilterRow
                    key={filter.name}
                    control={control}
                    {...filter}
                />
            ))}
        </Grid>
        <Typography variant="h6">Primary Contact</Typography>
        <Grid container sx={{ minWidth: 600, width: '50vw', pl: 1, pr: 1 }}>
            {primaryContactFilters.map(filter => (
                <FormFilterRow
                    key={filter.name}
                    control={control}
                    {...filter}
                />
            ))}
        </Grid>
    </form>
);
