import { Fragment } from 'react';
import { Path, Control } from 'react-hook-form';
import { Grid } from '@mui/material';
import { FormFieldType } from 'src/types';
import { FormFilterCondition } from './FormFilterCondition';
import { AutocompleteOption } from './FormAutocomplete';
import { FormInput } from './FormInput';

export interface FilterRowType<T = any> {
    title: string;
    placeholder: string;
    name: Path<T>;
    type?: FormFieldType;
    options?: AutocompleteOption[];
}

type FormFilterRowType = <T = any>(
    props: FilterRowType<T> & { control: Control<T, object> }
) => JSX.Element;

export const FormFilterRow: FormFilterRowType = ({
    name,
    placeholder,
    title,
    control,
    options,
    type
}) => (
    <Fragment key={name}>
        <Grid display="flex" alignItems="center" item xs={3}>
            {title}
        </Grid>
        <Grid display="flex" alignItems="center" item xs={3}>
            <FormFilterCondition name={`${name}.condition`} control={control} />
        </Grid>
        <Grid display="flex" alignItems="center" item xs={6}>
            <FormInput<any>
                type={type}
                inputProps={
                    {
                        control,
                        name: `${name}.value`,
                        placeholder,
                        options,
                        fullWidth: true
                    } as any
                }
            />
        </Grid>
    </Fragment>
);
