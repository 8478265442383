import { Pagination as MuiPagination, PaginationProps } from '@mui/material';
import { FC } from 'react';

export const Pagination: FC<PaginationProps> = props => (
    <MuiPagination
        shape="rounded"
        color="primary"
        hidePrevButton
        hideNextButton
        sx={{
            '.MuiPaginationItem-root:not(.Mui-selected)': {
                backgroundColor: '#fff'
            },
            'div.MuiPaginationItem-root': {
                minHeight: 32,
                borderRadius: 1,
                pt: '4px'
            }
        }}
        {...props}
    />
);
