import { TableRow } from '@mui/material';
import { styled } from '@mui/system';

export const StyledTableRow = styled(TableRow)(
    ({ theme }) => `
        .MuiTableCell-root {
            padding: 16px;
        }
    `
);

export const StyledTbodyTableRow = styled(StyledTableRow)(
    ({ theme }) => `
        &:last-child .MuiTableCell-root {
            border-bottom: 0;
        }
        .   MuiTableCell-root {
            vertical-align: top;
        }
    `
);
