export enum AccountType {
    Merchant = 'Merchant',
    Partner = 'Partner',
    Agent = 'Agent',
    Lead = 'Lead',
    Contact = 'Contact'
}

export enum AccountStatus {
    Pending = 'Pending',
    Active = 'Active',
    Collections = 'Collections',
    Notes = 'Notes'
}

export type Account = {
    id: string;
    name: string;
    status: AccountStatus;
    logo: {
        link: string;
        fileId: string;
    };
    dba: string;
    created: string;
    contact: string;
    accountType: AccountType;
};
