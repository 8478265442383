import { Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FormInput } from 'src/components/Form';
import { extendedBillingModelTypeName } from 'src/constants';
import { FeeScheduleServiceDto, FormFieldType } from 'src/types';
import { Modal } from '../../Modal';
import { SERVICE_STATUSES_OPTIONS } from 'src/constants/partners/common';
import { IMIDFeeScheduleListDto } from 'src/types/payments';

interface Props {
    open: boolean;
    onCancel: () => void;
    onSubmit: any;
    currentService?: any;
    services?: IMIDFeeScheduleListDto[];
}

export const MidServiceEditModal: FC<Props> = ({
    open,
    onCancel,
    onSubmit,
    currentService,
    services
}) => {
    const { handleSubmit, control, reset } = useForm<any>();

    const serviceId = useWatch({ control, name: 'serviceId' });

    const cancelModal = () => {
        onCancel();
        reset({
            serviceId: undefined
        });
    };

    const submitModal = (serviceData: any) => {
        onSubmit(serviceData);
        reset({
            serviceId: undefined
        });
    };

    useEffect(() => {
        if (currentService) {
            reset(currentService || {});
        } else {
            const service = services?.find(
                ({ service: { id } }) => id === serviceId
            );
            reset({
                serviceId,
                cost: service?.cost || '',
                billingModelId: service?.billingModel.id || ''
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, serviceId, currentService]);

    const serviceName =
        services?.find(
            ({ service: { id } }) => id === currentService?.serviceId
        )?.service.name || currentService?.serviceId;

    return (
        <Modal
            open={open}
            onOk={handleSubmit(submitModal)}
            onCancel={cancelModal}
            okText="Save"
            cardStyle={{
                width: 500
            }}
        >
            <Typography variant="h6" component="div">
                {currentService ? 'Edit' : 'Add'} Service
            </Typography>
            {currentService ? (
                `Service: ${serviceName}`
            ) : (
                <FormInput
                    type={FormFieldType.Autocomplete}
                    inputProps={{
                        name: 'serviceId',
                        required: true,
                        options:
                            services?.map(item => ({
                                label: item.service.name,
                                value: item.service.id
                            })) || [],
                        label: 'Service',
                        control,
                        fullWidth: true
                    }}
                />
            )}
            <FormInput
                type={FormFieldType.Autocomplete}
                inputProps={{
                    name: 'billingModelId',
                    required: true,
                    label: 'Billing Model',
                    options: [
                        ...Object.entries(extendedBillingModelTypeName).map(
                            ([value, label]) => ({
                                value,
                                label
                            })
                        )
                    ],
                    control,
                    fullWidth: true,
                    style: {
                        marginTop: 15
                    }
                }}
            />
            <FormInput
                inputProps={{
                    name: 'cost',
                    required: true,
                    type: FormFieldType.Number,
                    label: 'Price',
                    control,
                    fullWidth: true,
                    style: {
                        marginTop: 15
                    }
                }}
            />
            <FormInput
                type={FormFieldType.Autocomplete}
                inputProps={{
                    name: 'status',
                    required: true,
                    options: SERVICE_STATUSES_OPTIONS,
                    label: 'Status',
                    control,
                    fullWidth: true,
                    style: {
                        marginTop: 15
                    }
                }}
            />
        </Modal>
    );
};
