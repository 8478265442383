import { Alert as MuiAlert, Snackbar } from '@mui/material';
import { styled } from '@mui/system';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideMessageAction } from 'src/store/actions';
import { getMessage } from 'src/store/selectors';

const Alert = styled(MuiAlert)(
    ({ theme }) => `
        padding-left: 10px;
        padding-right: 20px;
        width: 100%;
        background-color: ${theme.palette.background.paper};
        box-shadow:  0px 5px 10px 2px rgba(34, 60, 80, 0.2);
        box-shadow:  ${(theme.shadows as string[])[7]};
    `
);

export const Message: FC = () => {
    const dispatch = useDispatch();
    const { message, type, showMessage } = useSelector(getMessage);

    const handleClose = (event?: any, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(hideMessageAction());
    };

    return (
        <Snackbar
            open={showMessage}
            autoHideDuration={4000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert
                severity={type}
                sx={{ width: '100%', backgroundColor: '#fff' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};
