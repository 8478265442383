import { FC, useState, Dispatch, SetStateAction } from 'react';
import { Control, SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';

import { CategoriesFilterForm } from 'src/types';
import { FilterForm } from './Forms/FilterForm';
import { Modal } from '../Modal';
import { getFilterParam } from 'src/utils/formatters';

interface Props {
    onClose: () => void;
    open: boolean;
    applyFilter: (filter: string) => Promise<void>;
    onCancel: () => void;
    handleSubmit: UseFormHandleSubmit<CategoriesFilterForm>;
    control: Control<CategoriesFilterForm, object>;
    setFilterFormState: Dispatch<
        SetStateAction<CategoriesFilterForm | undefined>
    >;
}

export const CategoriesFiltersModal: FC<Props> = ({
    onClose,
    open,
    applyFilter,
    onCancel,
    control,
    handleSubmit,
    setFilterFormState
}) => {
    const [loading, setLoading] = useState(false);

    const onSubmit: SubmitHandler<CategoriesFilterForm> = data => {
        setFilterFormState(cloneDeep(data));
        const newData: any = cloneDeep(data);

        const filterString = getFilterParam(newData);

        setLoading(true);
        applyFilter(filterString)
            .then(onClose)
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            onOk={handleSubmit(onSubmit)}
            onCancel={onCancel}
            okText="Apply filters"
            loading={loading}
        >
            <FilterForm control={control} />
        </Modal>
    );
};
