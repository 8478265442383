import { getPartnerKey } from 'src/utils/getGlobalPartnerKey';

export default (config: any) => {
    switch (getPartnerKey()) {
        case 'midkingz':
            config.headers['TenantId'] = 'nXdLyEQgAfXZfsV4Ae7F0';
            break;
        default:
            config.headers['TenantId'] = '4hB7Rb4lqm1zAp8QjgRsv';
            break;
    }
};
