export const getDateMonthDayYearTime = (dateString: string) =>
    new Date(dateString).toLocaleString('en', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });

export const getDateMonthDayYear = (dateString: string) =>
    new Date(dateString).toLocaleString('en', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });

export const utcString = (string: string | undefined | null) => {
    if (!string) {
        return;
    }

    if (string[string.length - 1] === 'Z') {
        return string;
    }

    return string + 'Z';
};
