import { FC, useState, Dispatch, SetStateAction } from 'react';
import { Control, SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';

import { ServicesFilterForm } from 'src/types';
import { useShortCategories } from 'src/api/hooks';
import { FilterForm } from './Forms/FilterForm';
import { Modal } from '../Modal';
import { getServicesFilterParam } from 'src/utils/services';

interface Props {
    onClose: () => void;
    open: boolean;
    applyFilter: (filter: string) => Promise<void>;
    onCancel: () => void;
    handleSubmit: UseFormHandleSubmit<ServicesFilterForm>;
    control: Control<ServicesFilterForm, object>;
    setFilterFormState: Dispatch<
        SetStateAction<ServicesFilterForm | undefined>
    >;
}

export const ServicesFiltersModal: FC<Props> = ({
    onClose,
    open,
    applyFilter,
    onCancel,
    control,
    handleSubmit,
    setFilterFormState
}) => {
    const [loading, setLoading] = useState(false);
    const { shortCategories } = useShortCategories();

    const onSubmit: SubmitHandler<ServicesFilterForm> = data => {
        setFilterFormState(cloneDeep(data));
        const newData: any = cloneDeep(data);

        const filterString = getServicesFilterParam(newData);

        setLoading(true);
        applyFilter(filterString)
            .then(onClose)
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            onOk={handleSubmit(onSubmit)}
            onCancel={onCancel}
            okText="Apply filters"
            loading={loading}
        >
            <FilterForm
                control={control}
                categories={Object.values(shortCategories).map(
                    ({ name, id }) => ({
                        label: name,
                        value: id
                    })
                )}
            />
        </Modal>
    );
};
