import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    Stack,
    Typography
} from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';
import { useTable } from 'src/hooks/table/useTable';
import { TableType } from './Table';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ColumnType } from 'src/types';

const AccordionRow = React.memo(
    <T extends Record<string, any>>({
        row,
        columns,
        renderSummary
    }: {
        row: T;
        columns: ColumnType<T>[];
        renderSummary?: (row: T) => JSX.Element | string;
    }) => {
        const [expanded, setExpanded] = useState(false);

        const toggleAccordeon = () => {
            setExpanded(prev => !prev);
        };

        return (
            <Accordion
                expanded={expanded}
                onChange={toggleAccordeon}
                sx={{
                    boxShadow: 'none'
                }}
            >
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{
                        p: [0, 2],
                        borderBottom: theme =>
                            expanded
                                ? `1px solid ${theme.palette.grey[400]}`
                                : ''
                    }}
                    expandIcon={
                        <KeyboardArrowDownIcon
                            sx={{ transform: expanded ? 'rotate(180)' : '' }}
                        />
                    }
                >
                    <AccordionDetails
                        sx={{
                            fontWeight: 600,
                            '&.MuiAccordionDetails-root': {
                                padding: 0
                            }
                        }}
                    >
                        {renderSummary?.(row)}
                    </AccordionDetails>
                </AccordionSummary>
                <Stack spacing={3} p={[3, 2, 0]}>
                    {columns.map(({ dataIndex, title, render, mobile }) => {
                        const cellValue = row[dataIndex] || 'No info';

                        const { render: renderMobile, fullWidth } =
                            mobile || {};

                        return (
                            <Box
                                key={dataIndex}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexWrap: 'wrap',
                                    gap: 2
                                }}
                            >
                                {title && <div>{title}</div>}
                                <Box width={fullWidth ? '100%' : 'auto'}>
                                    {renderMobile?.(cellValue, row) ??
                                        render?.(cellValue, row) ??
                                        cellValue}
                                </Box>
                            </Box>
                        );
                    })}
                </Stack>
            </Accordion>
        );
    }
);

export const MobileTable: TableType = ({
    data,
    columns,
    selectable,
    mobile = {}
}) => {
    const { selectedRows, handleSelectAllRows, handleRowClick } = useTable({
        data,
        selectable
    });

    const visibleColumns = useMemo(
        () => columns.filter(({ mobile }) => !mobile?.hiddenOnMobile),
        [columns]
    );

    return (
        <Box p={2}>
            {selectable && (
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <Typography>Select all</Typography>
                    <Checkbox
                        onClick={handleSelectAllRows}
                        checked={selectedRows.length >= data?.length}
                    />
                </Stack>
            )}
            {data.length ? (
                data.map((row, index) => (
                    <Stack direction="row">
                        {selectable && (
                            <Checkbox
                                onClick={handleRowClick(index)}
                                checked={selectedRows.includes(index)}
                            />
                        )}
                        <AccordionRow
                            key={row.id}
                            row={row}
                            columns={visibleColumns}
                            renderSummary={mobile.renderSummary}
                        />
                    </Stack>
                ))
            ) : (
                <Typography align="center">No data</Typography>
            )}
        </Box>
    );
};
