import {
    ApiResponse,
    CreateMerchantResult,
    MerchantCreateOrUpdate,
    MerchantEditDto
} from 'src/types';
import { apiClient } from '../client';
import {
    withDefaultHandlers,
    withUnwrappedHandlers
} from '../helpers/withDefaultHandlers';

const BASE_PATH = '/partner/merchant-api/merchant-info';

export const apiGetMerchants = () =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<MerchantEditDto[]>>(BASE_PATH)
    );

export const apiGetMerchant = (id: string) =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<MerchantEditDto>>(`${BASE_PATH}/${id}`)
    );

export const apiPostMerchant = (merchant: MerchantCreateOrUpdate) =>
    withDefaultHandlers(
        apiClient.post<ApiResponse<{ id: string }>>(BASE_PATH, merchant)
    );

export const apiPutMerchant = (
    id: string,
    merchant: Partial<MerchantCreateOrUpdate>
) =>
    withDefaultHandlers(
        apiClient.put<ApiResponse<MerchantCreateOrUpdate>>(
            BASE_PATH,
            merchant,
            {
                params: { id }
            }
        )
    );

export const apiPutMerchantMid = (
    merchantId: string | null,
    mid: string // mid name
) =>
    withUnwrappedHandlers<{ message: string; success: boolean }>(
        apiClient.put<{ message: string; success: boolean }>(
            `${BASE_PATH}/mid?mid=${mid}&${
                merchantId && 'merchantId=' + merchantId
            }`
        )
    );
