import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';

import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FormInput } from 'src/components/Form';
import { routes } from 'src/constants';

import { useAuth } from 'src/api/hooks';
import { useDispatch } from 'react-redux';
import { showMessageAction } from 'src/store/actions';

import { generateFields } from './fieldsConfig';
import { schema } from './validation';
import { useFieldsOptions } from '../../../utils/mids';
import {
    UserCreateBody,
    UserCreateBodyWithAutocompleteOptions
} from '../../../types';
import { UseCreateOrgModal } from '../../../components/Organization/hooks/UseCreateOrgModal';
import { CreateOrganizationForm } from '../../../components/Organization/Forms/CreateOrganizationForm';
import { Modal } from '../../../components/Modal';

type PasswordConfirm = { passwordConfirm: string };
type UserCreateForm = UserCreateBodyWithAutocompleteOptions & PasswordConfirm;

const INITIAL_FORM_STATE: UserCreateForm = {
    email: '',
    country: '',
    firstname: '',
    lastname: '',
    phone: '',
    password: '',
    passwordConfirm: '',
    organizationIds: []
};

export const CreateMangementUser = () => {
    const { control, handleSubmit } = useForm<UserCreateForm>({
        defaultValues: INITIAL_FORM_STATE,
        resolver: yupResolver(schema)
    });

    const { fetchCreateUser } = useAuth();
    const dispatch = useDispatch();
    const [buttonLoading, setButtonLoading] = useState(false);
    const { organizationsOptions } = useFieldsOptions();
    const theme = useTheme();
    const history = useHistory();

    const { modalState, updateModalState, closeModal } = UseCreateOrgModal();
    const addOptionClickHandler = useCallback(
        (inputValue: string) => {
            updateModalState({ show: true, inputValue });
        },
        [updateModalState]
    );

    const handleSuccess = () => {
        closeModal();
    };

    const onSubmit = (data: UserCreateForm) => {
        const { passwordConfirm, ...requestData } = data;
        if (passwordConfirm !== requestData.password) {
            dispatch(
                showMessageAction({
                    message: `Your passwords do not match`,
                    type: 'error'
                })
            );
            return;
        }

        const request: UserCreateBody = {
            ...requestData,
            organizationIds: requestData.organizationIds.map(org => org.value)
        };

        setButtonLoading(true);
        fetchCreateUser(request)
            .then(() => {
                dispatch(
                    showMessageAction({
                        message: `User saved successfully`,
                        type: 'success'
                    })
                );
                setButtonLoading(false);
                history.push(routes.managementUsers);
            })
            .catch((err: any) => {
                setButtonLoading(false);
                dispatch(
                    showMessageAction({
                        message: `Failed saving user: ${err}`,
                        type: 'error'
                    })
                );
            });
    };

    const fieldsToDisplay = useMemo(
        () => generateFields(organizationsOptions, addOptionClickHandler),
        [organizationsOptions, addOptionClickHandler]
    );

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '85vh' }}
        >
            <Card sx={{ width: '480px' }}>
                <CardContent>
                    <Typography
                        variant="h6"
                        style={{
                            display: 'flex',
                            marginBottom: '20px',
                            justifyContent: 'center',
                            color: '#5467B6'
                        }}
                    >
                        Create user account
                    </Typography>
                    <Typography
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginBottom: '20px',
                            fontSize: 14,
                            color: theme.palette.grey[400]
                        }}
                    >
                        Start using our tools right away! Create an account
                        today!
                    </Typography>
                    <form>
                        {fieldsToDisplay.map(
                            (
                                { name, xs, type, fieldType, ...otherProps },
                                index
                            ) => (
                                <Grid key={name || index} item xs={xs || 12}>
                                    {name && (
                                        <FormInput
                                            type={type}
                                            fieldType={fieldType}
                                            inputProps={{
                                                name,
                                                control,
                                                fullWidth: true,
                                                ...otherProps
                                            }}
                                        />
                                    )}
                                </Grid>
                            )
                        )}
                        <Typography
                            style={{
                                marginBottom: 20,
                                fontSize: 14,
                                color: '#5467B6'
                            }}
                        >
                            By clicking the <b>Create account</b> button below
                            you agree to our terms of service and privacy
                            statement.
                        </Typography>
                        <LoadingButton
                            loading={buttonLoading}
                            variant="contained"
                            type="submit"
                            style={{ width: '100%' }}
                            onClick={handleSubmit(onSubmit)}
                        >
                            CREATE USER ACCOUNT
                        </LoadingButton>
                    </form>
                </CardContent>
            </Card>

            <Modal open={modalState.show}>
                <CreateOrganizationForm
                    organizationName={modalState.inputValue}
                    onCancel={closeModal}
                    onSuccess={handleSuccess}
                />
            </Modal>
        </Stack>
    );
};
