import { FC } from 'react';
import { useHistory } from 'react-router';
import { Stack } from '@mui/material';
import { ColumnType, IAgentsApiGetById } from 'src/types';
import { Table } from '../Table';
import { routes } from 'src/constants';
import { ActionsTableButton } from '../TablePage/ActionsTableButton';

interface Props {
    data: IAgentsApiGetById[];
    loading?: boolean;
    deleteAgent: (id: string) => void;
}

export const AgentsTable: FC<Props> = ({ data, loading, deleteAgent }) => {
    const history = useHistory();

    const columns: ColumnType<IAgentsApiGetById>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (_, { name }) => name || '-'
        },
        {
            title: 'Agent ID',
            dataIndex: 'id'
        },
        {
            title: 'Contact',
            dataIndex: 'primaryContact',
            key: 'contact',
            render: ({ firstName, lastName }) => `${firstName} ${lastName}`
        },
        {
            title: 'Contact Email',
            dataIndex: 'primaryContact',
            key: 'contactEmail',
            render: ({ emailAddress }) => emailAddress
        },
        {
            title: '',
            dataIndex: 'actions',
            align: 'center',
            width: '112px',
            style: { paddingLeft: 0, paddingRight: 14 },
            render: (_, { id }) => (
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={1}
                >
                    <ActionsTableButton
                        menuItems={[
                            {
                                title: 'Edit',
                                onClick: () =>
                                    history.push(routes.agent(id as string))
                            },
                            {
                                title: 'Delete',
                                onClick: () => deleteAgent(id as string)
                            }
                        ]}
                    />
                </Stack>
            )
        }
    ];

    return <Table columns={columns} data={data} loading={loading} />;
};
