import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

type CardWrapperProps = {
    title?: string;
    description?: string;
};

const CardWrapper: React.FC<CardWrapperProps> = ({
    children,
    title,
    description
}) => {
    return (
        <Card sx={{ width: '100%' }}>
            <CardContent>
                {!!title && (
                    <Typography marginBottom={1} variant="h6" component="div">
                        {title}
                    </Typography>
                )}
                {!!description && (
                    <Typography
                        marginBottom={1}
                        variant="body2"
                        component="div"
                        sx={{ maxWidth: 716, opacity: 0.6 }}
                    >
                        {description}
                    </Typography>
                )}
                {children}
            </CardContent>
        </Card>
    );
};

export default CardWrapper;
