import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, SchemaOf, array, mixed } from 'yup';
import {
    bankRoutingValidation,
    notesValidation,
    partnerStatusValidation,
    servicesValidation
} from 'src/utils/yupValidations';
import { PartnerCreateEditDto } from 'src/types';

export default function partnerFormValidationYupResolver() {
    const schema: SchemaOf<PartnerCreateEditDto> = object({
        name: string().required().max(100),
        whitelistedName: string().nullable(),
        whitelistedUrl: string().nullable(),
        accountInfo: object({
            address: string().required().max(500),
            city: string().required().max(50),
            zip: string().required().max(16),
            country: string().required().nullable(),
            address2: string().max(500).nullable(),
            state: string().required().max(40),
            email: string(),
            ...partnerStatusValidation,
            // this field we son't need
            notes: mixed().optional()
        }),
        logo: object({
            fileId: string()
        }).nullable(),

        commissionPaymentInfo: object({
            accountHolder: string().required().max(26),
            bankRouting: bankRoutingValidation,
            bankAccount: string().max(34).nullable()
        }),

        primaryContact: object({
            firstName: string().required().max(50),
            lastName: string().required().max(100),
            emailAddress: string()
                .required()
                .email('Must be a valid email')
                .max(100),
            phoneNumber: string().required().max(15),
            faxNumber: string().max(15).nullable()
        }),
        ...notesValidation,
        ...servicesValidation
    });

    return yupResolver(schema);
}
