import { AlertType } from './../../types/alerts';
import { withDefaultHandlersPagination } from './../helpers/withDefaultHandlers';
import { CommonApiGetParams } from './../../types/common';
import { FilePostResponse } from './../../types/files';
import { ApiResponse } from './../../types/apiResponse';
import { apiClient } from './../client';
import { FilePost } from 'src/types';
import { IAlert, IApiGetAlertsResponse } from 'src/types/alerts';

const BASE_PATH = '/verifi/alerts/bulk-update';

export const apiGetVerifiAlerts = ({
    params,
    alertType
}: {
    params?: CommonApiGetParams;
    alertType?: AlertType;
}): Promise<IApiGetAlertsResponse> =>
    withDefaultHandlersPagination(
        apiClient.get<ApiResponse<IAlert[]>>(
            `${BASE_PATH}/files/?alertType=${alertType}`,
            params
        )
    );

export const apiPostVerifiAlert = (
    verifiAlert: FilePost & { alertType: string }
) => apiClient.post<ApiResponse<FilePostResponse>>(BASE_PATH, verifiAlert);
