import {
    IconButton,
    InputBaseComponentProps,
    TextField as MuiTextField,
    TextFieldProps
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/system';
import { Control, Controller, Path, ValidationRule } from 'react-hook-form';

const TextField = styled(MuiTextField)(`
    .MuiFormHelperText: {
        position: absolute;
        bottom: -20px;
    }

    .MuiButtonBase-root {
        visibility: hidden;
    }

    &:hover .MuiButtonBase-root,
    .MuiInput-root.Mui-focused .MuiButtonBase-root {
        visibility: visible;
    }
`);

interface Props<T = any> {
    name: Path<T>;
    label?: string;
    control: Control<T, object>;
    required?: boolean;
    pattern?: ValidationRule<RegExp>;
    validate?: any; // validation from react hook form
    inputProps?: InputBaseComponentProps;
    allowClear?: boolean;
    disabled?: boolean;
    InputProps?: TextFieldProps['InputProps'];
}

export type FormInputTextTypeProps<T = any> = Props<T> &
    TextFieldProps & { formType?: string };

type FormInputTextType = <T = any>(
    props: FormInputTextTypeProps<T>
) => JSX.Element;

export const FormInputText: FormInputTextType = ({
    name,
    control,
    label,
    required = false,
    pattern,
    validate,
    inputProps,
    allowClear = true,
    InputProps = {},
    disabled,
    ...otherProps
}) => (
    <Controller
        name={name}
        control={control}
        rules={{ required, pattern, validate }}
        render={({ field: { onChange, value, ref }, fieldState }) => (
            <TextField
                name={name}
                onChange={onChange}
                inputProps={inputProps}
                InputProps={{
                    endAdornment:
                        allowClear && value && !disabled ? (
                            <IconButton
                                size="small"
                                onClick={() => onChange('')}
                                tabIndex={-1}
                            >
                                <ClearIcon fontSize="small" />
                            </IconButton>
                        ) : undefined,
                    ...InputProps
                }}
                inputRef={ref}
                value={value || ''}
                label={label}
                variant="standard"
                required={required}
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={disabled}
                {...otherProps}
            />
        )}
    />
);
