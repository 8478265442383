import { AlertType } from './../../types/alerts';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    SetPagination,
    setPaginationAction,
    showMessageAction
} from 'src/store/actions';
import { CommonGetParams, ErrorType, Pagination } from 'src/types';
import { getApiCommonParams } from 'src/utils/formatters';
import { SetVerifiAlerts } from '../../store/actions/verifiAlerts';
import { IAlert } from '../../types/alerts';
import {
    setVerifiAlertsAction,
    setLoadingAction
} from './../../store/actions/verifiAlerts';
import { getVerifiAlerts } from './../../store/selectors/index';
import { apiGetVerifiAlerts } from './../endpoints/verifiAlerts';

interface ReturnValue {
    verifiAlerts: IAlert[];
    loading: boolean;
    errorData: ErrorType;
    pagination?: Pagination;
    fetchVerifiAlerts: (
        commonParams?: CommonGetParams,
        alertType?: AlertType
    ) => Promise<void>;
    updateVerifiAlerts: (verifiAlerts: IAlert[]) => SetVerifiAlerts;
    setPaging: (pagination?: Pagination) => SetPagination;
}

export const useVerifiAlerts = (): ReturnValue => {
    const dispatch = useDispatch();
    const [errorData, setErrorData] = useState<ErrorType | null>(null);

    const { verifiAlerts, loading, pagination } = useSelector(getVerifiAlerts);

    const setLoading = useCallback(
        (loading: boolean) => dispatch(setLoadingAction(loading)),
        [dispatch]
    );

    const setVerifiAlerts = useCallback(
        (verifiAlerts: IAlert[]) =>
            dispatch(setVerifiAlertsAction(verifiAlerts)),
        [dispatch]
    );

    const setPaging = useCallback(
        (pagination?: Pagination) => dispatch(setPaginationAction(pagination)),
        [dispatch]
    );

    const fetchVerifiAlerts = useCallback(
        async (commonParams?: CommonGetParams, alertType?: AlertType) => {
            setLoading(true);
            setErrorData(null);

            const params = commonParams
                ? getApiCommonParams(commonParams)
                : undefined;

            return apiGetVerifiAlerts({ params, alertType })
                .then(({ result, pagination }) => {
                    setVerifiAlerts(result || []);
                    setPaging(pagination);
                })
                .catch((err: ErrorType) => {
                    setVerifiAlerts([]);
                    setErrorData(err);
                    dispatch(
                        showMessageAction({
                            message: `Failed getting verifi alerts: ${err}`,
                            type: 'error'
                        })
                    );
                    throw err.message;
                })
                .finally(() => setLoading(false));
        },
        [dispatch, setLoading, setPaging, setVerifiAlerts]
    );

    return {
        verifiAlerts,
        loading,
        pagination,
        fetchVerifiAlerts: fetchVerifiAlerts,
        updateVerifiAlerts: setVerifiAlerts,
        setPaging,
        errorData
    };
};
