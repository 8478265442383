export const routes = {
    login: '/login',
    createAccount: '/create-account',
    createUser: '/create-user',
    agents: '/users/agents',
    agentsView: '/users/agents/view',
    agentsNew: '/users/agents/new',
    agent: (id?: string) => `/users/agents/${id ?? ':id'}`,
    users: '/users',
    usersView: '/users/view',
    services: '/users/services',
    servicesNew: '/users/services/new',
    service: (id?: string) => `/users/services/${id ?? ':id'}`,
    categoriesView: '/users/services/categories',
    categoriesNew: '/users/services/categories/new',
    category: (id?: string) => `/users/services/categories/${id ?? ':id'}`,
    billingModelsView: '/users/payment/billingModels',
    billingModelsNew: '/users/payment/billingModels/new',
    billingModel: (id?: string) =>
        `/users/payment/billingModels/${id ?? ':id'}`,
    rdrsView: '/users/rdrs',
    verifiEthocaView: '/users/verifi-ethoca',
    accountsView: `/users/accounts/accounts`,
    partnersNew: '/users/partners/new',
    partner: (id?: string) => `/users/partners/${id ?? ':id'}`,
    leadsNew: '/users/leads/new',
    lead: (id?: string) => `/users/leads/${id ?? ':id'}`,
    merchantsNew: '/users/merchants/new',
    merchant: (id?: string) => `/users/merchants/${id ?? ':id'}`,
    organizations: '/users/organizations/organizations',
    organizationNew: '/users/organizations/new',
    midsView: '/users/mids',
    midNew: '/users/mids/new',
    mid: (id?: string) => `/users/mids/${id ?? ':id'}`,
    dashboard: '/dashboard',
    managementUsers: '/management/users',
    managementUserNew: '/management/users/new',
    managementUser: (id?: string) => `/management/users/${id ?? ':id'}`,
    permissionsRoles: '/permissions/roles',
    permissionsRoleNew: '/permissions/roles/new',
    permissionsRole: (id?: string) => `/permissions/roles/${id ?? ':id'}`
};

export const MAIN_ROUTE = routes.dashboard;

export const AUTH_MAIN_ROUTE = routes.login;
