import {
    CommonApiGetParams,
    UserDto,
    ApiResponse,
    UserInfoDto,
    UserManagementPost
} from 'src/types';
import { apiClient } from '../client';
import {
    withDefaultHandlers,
    withDefaultHandlersPagination,
    withDefaultHandlersWithoutResult
} from '../helpers/withDefaultHandlers';

const BASE_PATH = '/auth/auth-api/user-info';

export const apiGetUsers = ({ params }: { params?: CommonApiGetParams }) =>
    withDefaultHandlersPagination(
        apiClient.get<ApiResponse<UserDto[]>>(BASE_PATH, params)
    );

export const apiGetUser = (id: string) =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<UserInfoDto>>(`${BASE_PATH}/${id}`)
    );

export const apiPutUsers = (id: string, users: Partial<UserManagementPost>) =>
    withDefaultHandlersWithoutResult<UserInfoDto>(
        apiClient.put<ApiResponse<UserInfoDto>>(BASE_PATH, users, {
            params: { id }
        })
    );

export const apiPostUsers = (users: Partial<UserManagementPost>) =>
    withDefaultHandlersWithoutResult<UserInfoDto>(
        apiClient.post<ApiResponse<UserInfoDto>>(BASE_PATH, users)
    );
