import React, { ComponentProps, useCallback, useState } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { IconButton, TextField } from '@mui/material';
import { Control, Path, Controller } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export interface FormDateTimeInputProps<T = any> {
    name: Path<T>;
    label?: string;
    control: Control<T, object>;
    required?: boolean;
    inputProps: {
        dateTimeInputProps?: Partial<ComponentProps<typeof DateTimePicker>>;
    };
}

type FormDateTimeInputType = <T = any>(
    props: FormDateTimeInputProps<T>
) => JSX.Element;

const FormDateTimeInput: FormDateTimeInputType = ({
    control,
    required,
    name,
    label,
    inputProps
}) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required }}
            render={({ field: { onChange, value, ref }, fieldState }) => {
                const inputValue = value
                    ? dayjs(String(value)).format('MM/DD/YYYY hh:mm A')
                    : '';

                // if undefined we need null
                const dayjsValue = value ? value : null;

                const handleChange = useCallback((newValue: dayjs.Dayjs) => {
                    // we need this for null checking
                    if (!newValue) {
                        return newValue;
                    }

                    const utcStringValue = newValue.utc().format();
                    onChange(utcStringValue);
                }, []);

                return (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            open={isOpen}
                            onClose={() => setIsOpen(false)}
                            renderInput={props => (
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    {...props}
                                    inputProps={{
                                        ...props.inputProps,
                                        placeholder: 'Date Time',
                                        value: inputValue
                                    }}
                                    onClick={() => setIsOpen(true)}
                                    required={required}
                                    error={fieldState.invalid}
                                    helperText={fieldState.error?.message}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            )}
                            label={label}
                            value={dayjsValue}
                            clearable
                            onChange={handleChange}
                            InputAdornmentProps={{
                                position: 'start'
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            onChange('');
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )
                            }}
                            {...inputProps?.dateTimeInputProps}
                        />
                    </LocalizationProvider>
                );
            }}
        />
    );
};

export default FormDateTimeInput;
