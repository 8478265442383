import { BrandableServicePartnerFeaturesMidsModelsMerchantDto } from 'src/api/swagger/partnerTypes';
import IMidEditForm from 'src/pages/MIDs/types';
import {
    AgentFormFilter,
    CommonApiGetParams,
    CommonGetParams,
    Condition,
    FilterFormType,
    IAgentsApiGetById,
    IAgentsApiPostPut,
    IMIDEditDto,
    MerchantCreateOrUpdate,
    MerchantEditDto,
    PartnerCreateEditDto,
    PartnerGetByIdDto
} from 'src/types';
import { getFieldCategory } from './agents';

export const addNewLine = (text?: string) => (text ? `${text}\n` : '');

export const getApiCommonParams = (paramsArgs: CommonGetParams) => {
    const result: CommonApiGetParams = {
        params: {
            searchString: paramsArgs.searchString,
            status: paramsArgs.status
        },
        headers: {}
    };

    if (paramsArgs.filter) result.headers['X-Filter'] = paramsArgs.filter;
    if (paramsArgs.pageNumber)
        result.headers['X-PageNumber'] = paramsArgs.pageNumber;
    if (paramsArgs.pageSize) result.headers['X-PageSize'] = paramsArgs.pageSize;
    if (paramsArgs.sortBy) result.headers['X-SortBy'] = paramsArgs.sortBy;
    return result;
};

export const getFilterParam = (object: Record<string, any>) =>
    Object.entries(object).reduce(
        (prev, [key, values]) =>
            values?.value
                ? `${prev}${prev ? ',' : ''}${key}${
                      values?.condition === Condition.Equals ? '=' : '%'
                  }${values?.value}`
                : prev,
        ''
    );

export const getFilterWithoutEmptyValues = (filter: Record<string, any>) =>
    Object.entries(filter).reduce<Record<string, any>>(
        (prev, [key, values]) => {
            if (!values?.value) {
                return prev;
            }
            return {
                ...prev,
                [key]: values
            };
        },
        {}
    );

export const getAgentsFilterParam = (filterForm: Partial<AgentFormFilter>) => {
    const readyValues = Object.entries(filterForm).reduce<
        Partial<FilterFormType>
    >((prev, [key, values]) => {
        if (!values?.value) {
            return prev;
        }
        return {
            ...prev,
            [key]: values
        };
    }, {});

    return Object.entries(readyValues).reduce((prev, [key, values]) => {
        const fullName = getFieldCategory(key);
        return `${prev}${prev ? ',' : ''}${fullName}${
            values?.condition === Condition.Equals ? '=' : '%'
        }${values?.value}`;
    }, '');
};

export const formatPartnerTypeForForm = (
    partner: PartnerGetByIdDto
): PartnerCreateEditDto => {
    return {
        name: partner.name || '',
        whitelistedName: partner.whitelistedName || '',
        whitelistedUrl: partner.whitelistedUrl || '',
        accountInfo: {
            address: partner.accountInfo?.address || '',
            city: partner.accountInfo?.city || '',
            zip: partner.accountInfo?.zip || '',
            country: partner.accountInfo?.country || '',
            address2: partner.accountInfo?.address2 || '',
            state: partner.accountInfo?.state || '',
            status: partner.accountInfo?.status,
            activateAt: partner.accountInfo?.activateAt || '',
            deactivateAt: partner.accountInfo?.deactivateAt || ''
        },
        logo: partner.logo,
        commissionPaymentInfo: {
            accountHolder: partner.commissionPaymentInfo?.accountHolder || '',
            bankRouting: partner.commissionPaymentInfo?.bankRouting,
            bankAccount: partner.commissionPaymentInfo?.bankAccount
        },
        primaryContact: {
            firstName: partner.primaryContact?.firstName || '',
            faxNumber: partner.primaryContact?.faxNumber,
            phoneNumber: partner.primaryContact?.phoneNumber || '',
            lastName: partner.primaryContact?.lastName || '',
            emailAddress: partner.primaryContact?.emailAddress || ''
        },
        notes: partner.notes || [],
        services: partner.services || []
    };
};

export const formatMerchantForForm = (
    merchant: MerchantEditDto
): MerchantCreateOrUpdate => {
    return {
        name: merchant.name || '',
        externalIdentifier: merchant.externalIdentifier,
        timezone: merchant.timezone,
        webSite: merchant.webSite,
        mid: merchant.mid,
        mcc: merchant.mcc,
        dba: merchant.dba,
        rdrToken: merchant.rdrToken,
        paymentStatus: merchant.paymentStatus,
        recordOfPayments: merchant.recordOfPayments,
        organizationId: merchant.organizationId,
        partner: {
            id: merchant.partner?.id
        },
        agent: {
            id: merchant.agent?.id
        },
        accountInfo: {
            address: merchant.accountInfo?.address || '',
            country: merchant.accountInfo?.country || '',
            zip: merchant.accountInfo?.zip || '',
            city: merchant.accountInfo?.city || '',
            address2: merchant.accountInfo?.address2,
            state: merchant.accountInfo?.state || '',
            status: merchant.accountInfo?.status,
            activateAt: merchant.accountInfo?.activateAt,
            deactivateAt: merchant.accountInfo?.deactivateAt
        },
        commissionPaymentInfo: {
            accountHolderType:
                merchant.commissionPaymentInfo?.accountHolderType,
            accountType: merchant.commissionPaymentInfo?.accountType,
            bankAccount: merchant.commissionPaymentInfo?.bankAccount || '',
            bankRouting: merchant.commissionPaymentInfo?.bankRouting || ''
        },
        companyContact: {
            firstName: merchant.companyContact?.firstName || '',
            lastName: merchant.companyContact?.lastName || '',
            phoneNumber: merchant.companyContact?.phoneNumber || '',
            faxNumber: merchant.companyContact?.faxNumber || '',
            emailAddress: merchant.companyContact?.emailAddress || ''
        },
        notes: merchant.notes || [],
        tokens: merchant.tokens,
        services: merchant.services
    };
};

export const formatAgentForForm = (
    agent: IAgentsApiGetById
): IAgentsApiPostPut => {
    return {
        name: agent.name || '',
        timezone: agent.timezone || '',
        webSite: agent.webSite || '',
        partner: {
            id: agent.partner?.id || ''
        },
        outgoingStatusOfPayment: agent.outgoingStatusOfPayment,
        incomingStatusOfPayment: agent.incomingStatusOfPayment,
        accountInfo: {
            address: agent.accountInfo?.address || '',
            country: agent.accountInfo?.country || '',
            zip: agent.accountInfo?.zip || '',
            city: agent.accountInfo?.city || '',
            address2: agent.accountInfo?.address2,
            state: agent.accountInfo?.state || '',
            status: agent.accountInfo?.status,
            activateAt: agent.accountInfo?.activateAt,
            deactivateAt: agent.accountInfo?.deactivateAt
        },
        commissionPaymentInfo: {
            accountHolder: agent.commissionPaymentInfo?.accountHolder || '',
            businessType: agent.commissionPaymentInfo?.businessType,
            bankAccount: agent.commissionPaymentInfo?.bankAccount || '',
            bankRouting: agent.commissionPaymentInfo?.bankRouting || '',
            taxId: agent.commissionPaymentInfo?.taxId || ''
        },
        primaryContact: {
            firstName: agent.primaryContact?.firstName || '',
            lastName: agent.primaryContact?.lastName || '',
            phoneNumber: agent.primaryContact?.phoneNumber || '',
            faxNumber: agent.primaryContact?.faxNumber,
            emailAddress: agent.primaryContact?.emailAddress || ''
        },
        supportContact: {
            emailAddress: agent.supportContact?.emailAddress || '',
            phoneNumber: agent.supportContact?.phoneNumber || ''
        },
        accountingSupportContact: {
            emailAddress: agent.accountingSupportContact?.emailAddress || '',
            phoneNumber: agent.accountingSupportContact?.phoneNumber || ''
        },
        notes: agent.notes || [],
        files: agent.files || []
    };
};

export const formatMIDForForm = (mid: IMIDEditDto): IMidEditForm => {
    return {
        name: mid.name || '',
        externalIdentifier: mid.externalIdentifier || '',
        dba: mid.dba || '',
        bankMid: mid.bankMid?.id || '',
        processor: mid.processor?.id || '',
        mididentifier: mid.mididentifier || '',
        midalias: mid.midalias || '',
        merchant:
            mid.merchant as BrandableServicePartnerFeaturesMidsModelsMerchantDto,
        partner: mid.partner,
        services: mid.services
            ? mid.services.map(service => ({
                  ...service,
                  cost: service.cost || undefined
              }))
            : [],
        notes: mid.notes || [],
        accountInfo: {
            address: mid.accountInfo?.address || '',
            address2: mid.accountInfo?.address2,
            city: mid.accountInfo?.city || '',
            zip: mid.accountInfo?.zip || '',
            country: mid.accountInfo?.country || '',
            state: mid.accountInfo?.state || '',

            status: mid.accountInfo?.status,

            activateAt: mid.accountInfo?.activateAt || '',
            deactivateAt: mid.accountInfo?.deactivateAt || ''
        },
        primaryContact: {
            firstName: mid.primaryContact?.firstName || '',
            lastName: mid.primaryContact?.lastName || '',
            emailAddress: mid.primaryContact?.emailAddress || '',
            phoneNumber: mid.primaryContact?.phoneNumber || '',
            faxNumber: mid.primaryContact?.faxNumber
        },
        commissionPaymentInfo: {
            accountHolderType: mid.commissionPaymentInfo?.accountHolderType,
            bankRouting: mid.commissionPaymentInfo?.bankRouting || '',
            bankAccount: mid.commissionPaymentInfo?.bankAccount || '',
            accountType: mid.commissionPaymentInfo?.accountType
        }
    };
};
