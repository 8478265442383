import {
    CommonApiGetParams,
    RoleDto,
    ApiResponse,
    RoleInfoDto,
    RolePutDto,
    OrganizationRolesDto,
    IEmailConfirmationLinkResult
} from 'src/types';
import { apiClient } from '../client';
import {
    withDefaultHandlers,
    withDefaultHandlersPagination,
    withDefaultHandlersWithoutResult
} from '../helpers/withDefaultHandlers';

const BASE_PATH = '/auth/auth-api/role-info';
const TOKEN_PATH = 'auth/auth-api/token/account';

export const apiGetRoles = ({ params }: { params?: CommonApiGetParams }) =>
    withDefaultHandlersPagination(
        apiClient.get<ApiResponse<RoleDto[]>>(BASE_PATH, params)
    );

export const apiGetOrganizationsRoles = (
    userId: string,
    {
        params
    }: {
        params?: CommonApiGetParams;
    }
) =>
    withDefaultHandlersPagination(
        apiClient.get<ApiResponse<OrganizationRolesDto[]>>(
            `${BASE_PATH}/account/${userId}/organizations`,
            params
        )
    );

export const apiGetRole = (id: string) =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<RoleInfoDto>>(`${BASE_PATH}/${id}`)
    );

export const apiGetLink = (id: string) =>
    withDefaultHandlersWithoutResult<IEmailConfirmationLinkResult>(
        apiClient.get<ApiResponse<IEmailConfirmationLinkResult>>(
            `${TOKEN_PATH}/${id}/confirmation`
        )
    );

export const apiPutRole = (id: string, role: RolePutDto) =>
    withDefaultHandlersWithoutResult<RoleInfoDto>(
        apiClient.put<ApiResponse<RoleInfoDto>>(
            BASE_PATH,
            { ...role, permissionSet: [] },
            {
                params: { id }
            }
        )
    );

export const apiPostRole = (roles: Omit<RolePutDto, 'permissionSet'>) =>
    withDefaultHandlersWithoutResult<RoleInfoDto>(
        apiClient.post<ApiResponse<RoleInfoDto>>(BASE_PATH, roles)
    );

export const apiDeleteRole = (id: string) =>
    withDefaultHandlers(
        apiClient.delete<ApiResponse<RoleInfoDto>>(BASE_PATH, {
            params: { id }
        })
    );
