import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useAuth } from 'src/api/hooks';
import { FormInput } from 'src/components/Form';
import { routes } from 'src/constants';
import { showMessageAction } from 'src/store/actions';
import { SignUpBody } from 'src/types';
import { fields } from './fields';
import createAccountFormValidationYupResolver from './validation';

export type CreateAccountForm = SignUpBody & { passwordConfirm: string };

const INITIAL_VALUES: CreateAccountForm = {
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    password: '',
    passwordConfirm: '',
    country: ''
};

export const CreateAccount = () => {
    const { control, handleSubmit } = useForm<CreateAccountForm>({
        defaultValues: INITIAL_VALUES,
        resolver: createAccountFormValidationYupResolver()
    });
    const { fetchSignUp } = useAuth();
    const dispatch = useDispatch();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [showCreateUserForm, setShowCreateUserForm] = useState(true);
    const theme = useTheme();
    const history = useHistory();

    const onSubmit = (data: CreateAccountForm) => {
        const { passwordConfirm, ...requestData } = data;
        if (passwordConfirm !== requestData.password) {
            dispatch(
                showMessageAction({
                    message: `Your passwords do not match`,
                    type: 'error'
                })
            );
            return;
        }

        setButtonLoading(true);
        fetchSignUp(requestData)
            .then(() => {
                setShowCreateUserForm(false);
                setButtonLoading(false);
            })
            .catch(() => {
                setButtonLoading(false);
            });
    };

    return (
        <Stack alignItems="center" justifyContent="center">
            <Card sx={{ width: 480 }}>
                {showCreateUserForm ? (
                    <CardContent>
                        <Typography
                            variant="h6"
                            textAlign="center"
                            sx={{
                                mb: 1
                            }}
                        >
                            Create user account
                        </Typography>
                        <Typography
                            textAlign="center"
                            sx={{
                                mb: 2,
                                color: theme.palette.grey[400]
                            }}
                        >
                            Start using our tools right away! Create an account
                            today!
                        </Typography>
                        <Grid container spacing={1.5} sx={{ mb: 2.5 }}>
                            {fields.map(
                                (
                                    {
                                        name,
                                        xs,
                                        type,
                                        fieldType,
                                        ...otherProps
                                    },
                                    index
                                ) => (
                                    <Grid
                                        key={name || index}
                                        item
                                        xs={xs || 12}
                                    >
                                        {name && (
                                            <FormInput
                                                type={type}
                                                fieldType={fieldType}
                                                inputProps={{
                                                    name,
                                                    control,
                                                    fullWidth: true,

                                                    ...otherProps
                                                }}
                                            />
                                        )}
                                    </Grid>
                                )
                            )}
                        </Grid>

                        <Typography
                            sx={{
                                mb: 3
                            }}
                        >
                            By clicking the <b>Create account</b> button below
                            you agree to our terms of service and privacy
                            statement.
                        </Typography>
                        <LoadingButton
                            loading={buttonLoading}
                            variant="contained"
                            type="submit"
                            fullWidth
                            onClick={handleSubmit(onSubmit)}
                        >
                            Create user account
                        </LoadingButton>
                    </CardContent>
                ) : (
                    <CardContent>
                        <Typography
                            variant="h6"
                            textAlign="center"
                            sx={{
                                mb: 3
                            }}
                        >
                            Success! Your account is ready!
                        </Typography>
                        <Typography
                            textAlign="center"
                            sx={{
                                mb: 3,
                                color: theme.palette.grey[400]
                            }}
                        >
                            More insights are a click away
                        </Typography>
                        <LoadingButton
                            loading={buttonLoading}
                            variant="contained"
                            fullWidth
                            sx={{ mb: 3 }}
                            onClick={() => history.push(routes.login)}
                        >
                            Go to login
                        </LoadingButton>
                    </CardContent>
                )}
            </Card>
        </Stack>
    );
};
