import { useHistory, useParams } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Stack, Button, Typography } from '@mui/material';

import {
    MidDetailsForm,
    CompanyInfoForm,
    CompanyContactForm,
    BankingInfoForm,
    ServicesForm
} from 'src/components/Mids/Forms';
import { useMids, useMid, useFeeScheduleServices } from 'src/api/hooks';
import { routes } from 'src/constants';
import { useFieldsOptions } from 'src/utils/mids';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Spin } from 'src/components/Spin/Spin';
import { AutocompleteOption } from 'src/components/Form';
import { formatMIDForForm } from 'src/utils/formatters';
import MID_FORM_INITIAL_VALUES from 'src/pages/MIDs/midFormInitialValues';
import { logFormState } from 'src/utils/loggers';
import IMidEditForm from 'src/pages/MIDs/types';
import { getMidsFromForm } from 'src/pages/MIDs/helpers';
import MidActivitiesCard from 'src/components/Mids/Forms/MIDActivitiesCard';
import newMidMock from 'src/api/mocks/newMidMock';

export const MidEdit = () => {
    const { id } = useParams<{ id?: string }>();

    const isEdit = !!id && id !== 'new';

    const { createMid, updateMid } = useMids();

    const {
        partnersOptions,
        merchantsOptions,
        agentsOptions,
        banksOptions,
        processorsOptions,
        banks,
        processors
    } = useFieldsOptions();

    // FOR DEMONSTRATION PURPOSE
    const [leads, setLeads] = useState([
        {
            label: 'Lead 1',
            value: '0'
        },
        {
            label: 'Lead 2',
            value: '1'
        },
        {
            label: 'Lead 3',
            value: '2'
        }
    ]);

    const { scheduleServices, loading: scheduleServicesLoading } =
        useFeeScheduleServices();

    const [buttonLoading, setButtonLoading] = useState(false);
    const { fetchMid, mid, loading: midLoading } = useMid();
    const history = useHistory();

    useEffect(() => {
        if (id) {
            fetchMid(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const { handleSubmit, control, reset, getValues, formState } =
        useForm<IMidEditForm>({
            defaultValues: MID_FORM_INITIAL_VALUES /*newMidMock*/
        });

    logFormState(getValues, formState);

    useEffect(() => {
        if (mid && id && mid.id === id) {
            reset(formatMIDForForm(mid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mid]);

    const onSubmit: SubmitHandler<IMidEditForm> = data => {
        const body = getMidsFromForm(data, banks, processors);

        setButtonLoading(true);

        if (isEdit && id) {
            updateMid(id, body, () => history.push(routes.midsView)).finally(
                () => setButtonLoading(false)
            );
        } else {
            createMid(body, () => history.push(routes.midsView)).finally(() =>
                setButtonLoading(false)
            );
        }
    };

    const handleCancel = () => history.push(routes.midsView);

    return (
        <Spin loading={midLoading}>
            <Stack spacing={2} flexGrow={1} mb={3}>
                <Typography variant="h5">
                    {isEdit ? 'Edit MID' : 'Create MID'}
                </Typography>
                <form>
                    <Stack spacing={2} alignItems="end">
                        <MidDetailsForm
                            control={control}
                            partners={partnersOptions}
                            agents={agentsOptions}
                            merchants={merchantsOptions}
                            banks={banksOptions}
                            processors={processorsOptions}
                            leads={leads}
                            setNewLead={(newLead: AutocompleteOption) => {
                                // set value into form values, but to use it we need to filter options
                                // setValue(
                                //     'leads',
                                //     getValues('leads').concat([newLead.label])
                                // );
                                // set value into options which will go from back later
                                setLeads(prev => prev.concat([newLead]));
                            }}
                        />
                        <ServicesForm
                            services={scheduleServices}
                            loading={scheduleServicesLoading}
                            control={control}
                        />
                        <CompanyInfoForm control={control} />
                        <CompanyContactForm control={control} />
                        <BankingInfoForm control={control} />
                        {mid?.activities && (
                            <MidActivitiesCard activities={mid.activities} />
                        )}
                        <Stack direction="row" spacing={2}>
                            <Button
                                onClick={handleCancel}
                                variant="outlined"
                                disabled={buttonLoading}
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                onClick={handleSubmit(onSubmit)}
                                variant="contained"
                                loading={buttonLoading}
                            >
                                Save
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </form>
            </Stack>
        </Spin>
    );
};
