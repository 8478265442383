import { fCurrency } from './../../utils/formatNumber';
import { Field } from 'src/components/Form';
import {
    generateModalInputs as commonModalInputs,
    tableFields as commonTableFields
} from 'src/components/Services/addServiceCommonFields';
import {
    BillingModelDto,
    ColumnType,
    FormFieldType,
    ServiceDto
} from 'src/types';

export const tableFields: ColumnType<ServiceDto>[] = [
    ...commonTableFields,
    {
        dataIndex: 'agentCommission',
        title: 'Agent Commission',
        render: value => fCurrency(value)
    }
];

// MODAL FIELDS
export const generateFields = (
    services: Record<string, ServiceDto>,
    billingModels: Record<string, BillingModelDto>
): Field<ServiceDto>[] => [
    ...commonModalInputs(services, billingModels),
    {
        name: 'agentCommission',
        label: 'Agent Commission',
        type: FormFieldType.Price,
        required: true
    }
];
