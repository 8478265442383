import axios, { AxiosResponse } from 'axios';
import { ApiResponse, FilePost, IFileDownload, IFilePost } from 'src/types';
import { apiClient } from '../client';
import { withDefaultHandlers } from '../helpers/withDefaultHandlers';
import { IFilePutResponse } from 'src/types/files';

const BASE_PATH = '/files/files-api';

export const apiPostFile = (
    params: FilePost
): Promise<AxiosResponse<IFilePost>> =>
    apiClient.post<IFilePost>(BASE_PATH, params);

export const apiGetFile = <FileType>(id: string) =>
    withDefaultHandlers<IFileDownload>(
        apiClient.get<ApiResponse<IFileDownload>>(`${BASE_PATH}/${id}`)
    );

export const apiPutFile = (link: string, file: File) =>
    axios.put<any, IFilePutResponse>(link, file, {
        headers: {
            'Content-Type': file.type
        }
    });
