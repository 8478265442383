import { FC } from 'react';
import { Controller, Path, Control } from 'react-hook-form';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Condition } from 'src/types';

interface Props<T = any> {
    name: Path<T>;
    control: Control<T, object>;
}
export const FormFilterCondition: FC<Props> = ({ name, control }) => (
    <Controller
        name={name}
        control={control}
        defaultValue={Condition.Contains}
        render={({ field: { onChange, value } }) => (
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <Select onChange={onChange} value={value}>
                    <MenuItem value={Condition.Contains}>Contains</MenuItem>
                    <MenuItem value={Condition.Equals}>Equals</MenuItem>
                </Select>
            </FormControl>
        )}
    />
);
