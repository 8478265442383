import {
    withDefaultHandlers,
    withDefaultHandlersWithoutResult
} from 'src/api/helpers/withDefaultHandlers';
import { apiClient } from 'src/api/client';
import { LeadTypeDto } from 'src/types/leads';
import { ApiResponse } from 'src/types';

const BASE_LEADS_PATH = '/partner/partner-api/lead-info';

export const apiPostLead = (lead: LeadTypeDto): Promise<{ id: string }> =>
    withDefaultHandlersWithoutResult(
        apiClient.post<ApiResponse<{ id: string }>>(BASE_LEADS_PATH, lead)
    );

export const apiPutLead = (
    id: string,
    lead: LeadTypeDto
): Promise<LeadTypeDto> =>
    withDefaultHandlersWithoutResult(
        apiClient.put<ApiResponse<LeadTypeDto>>(BASE_LEADS_PATH, lead, {
            params: { id }
        })
    );

export const apiGetLeadById = (id: string): Promise<LeadTypeDto> =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<LeadTypeDto>>(`${BASE_LEADS_PATH}/${id}`)
    );
