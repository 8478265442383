import { useMemo } from 'react';

import { INote } from 'src/types/partners/common';

import { Table as CustomTable } from 'src/components/Table';
import { generateNoteFields } from './notesTableFields';

type NotesTableProps = {
    notes: INote[];
    onDelete: (note: INote) => void;
    onEdit: (note: INote) => void;
};

const NotesTable = ({ notes, onDelete, onEdit }: NotesTableProps) => {
    const fieldsToDisplay = useMemo(
        () => generateNoteFields({ onDelete, onEdit }),
        [onDelete, onEdit]
    );

    return (
        <CustomTable
            mobile={{ renderSummary: row => row.note || '' }}
            data={notes}
            columns={fieldsToDisplay}
        />
    );
};

export default NotesTable;
