import axios from 'axios';
import { BASE_URL } from 'src/constants';
import { clearLocalStorage } from 'src/utils/localStorage';
import { goToLogin } from 'src/utils/nav';
import setTentantIdHeader from 'src/api/helpers/setTentantIdHeader';
import setAuthorizationToken from 'src/api/helpers/setAuthorizationToken';
import { SIGN_IN_BASE_PATH } from 'src/api/endpoints/auth';

export const apiClient = axios.create({
    baseURL: BASE_URL
});

export const onErrorResponseCallback = (error: any) => {
    if (error.response && error.response.status === 401) {
        clearLocalStorage();
        goToLogin();
    }

    return Promise.reject(error);
};

const onSuccessRequestCallback = (config: any) => {
    config.headers['Content-Type'] = 'application/json';

    config.headers['applicationId'] = 'rE8ydynh_9cWjbMBgyuzB';

    setAuthorizationToken(config);
    setTentantIdHeader(config);

    return config;
};

apiClient.interceptors.request.use(onSuccessRequestCallback);

apiClient.interceptors.response.use(r => {
    if (process.env.NODE_ENV === 'development') {
        console.group(r.config.url);
        console.log(r);
        console.groupEnd();
    }

    return r;
}, onErrorResponseCallback);

apiClient.interceptors.request.use(onSuccessRequestCallback, error =>
    Promise.reject(error)
);
