import {
    ApiResponse,
    CommonApiGetParams,
    IMIDEditDto,
    IMIDListDto,
    ICreateMIDResult,
    ICreateOrUpdateMID,
    IMIDBankDto,
    IMIDProcessorsDto
} from 'src/types';
import { apiClient } from '../client';
import {
    withDefaultHandlers,
    withDefaultHandlersPagination
} from '../helpers/withDefaultHandlers';
import { MidServiceDto } from '../../pages/MIDs/types';

const BASE_PATH = '/partner/merchant-api/mid-info';
const BANKS_PATH = '/partner/merchant-api/mid-info/banks';
const SERVICES_PATH = '/partner/merchant-api/mid-info/services';
const PROCESSORS_PATH = '/partner/merchant-api/mid-info/processors';

export const apiGetMids = ({ params }: { params?: CommonApiGetParams }) =>
    withDefaultHandlersPagination<IMIDListDto[]>(
        apiClient.get(BASE_PATH, params)
    );

export const apiGetMid = (id: string): Promise<IMIDEditDto> =>
    withDefaultHandlers<IMIDEditDto>(
        apiClient.get<ApiResponse<IMIDEditDto>>(`${BASE_PATH}/${id}`)
    );

export const apiPostMid = (mid: ICreateOrUpdateMID) =>
    withDefaultHandlers<ICreateMIDResult>(
        apiClient.post<ApiResponse<ICreateMIDResult>>(BASE_PATH, mid)
    );

export const apiPutMid = (id: string, mid: ICreateOrUpdateMID) =>
    withDefaultHandlers<IMIDEditDto>(
        apiClient.put<ApiResponse<IMIDEditDto>>(BASE_PATH, mid, {
            params: { id }
        })
    );

export const apiDeleteMid = (id: string) =>
    withDefaultHandlers<IMIDEditDto>(
        apiClient.delete<ApiResponse<IMIDEditDto>>(BASE_PATH, {
            params: { id }
        })
    );

export const apiGetBanks = () =>
    withDefaultHandlers(apiClient.get<ApiResponse<IMIDBankDto[]>>(BANKS_PATH));

export const apiGetProcessors = () =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<IMIDProcessorsDto[]>>(PROCESSORS_PATH)
    );

export const apiGetServices = (partnerId: string): Promise<MidServiceDto[]> =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<MidServiceDto[]>>(
            `${SERVICES_PATH}?partnerId=${partnerId}`,
            {
                headers: {
                    'X-PageNumber': '0',
                    'X-PageSize': '1000'
                }
            }
        )
    );
