import {
    bankInfoCategory,
    accountInfoCategory,
    primaryContactCategory
} from 'src/constants';

export const getFieldCategory = (field: string) => {
    if (accountInfoCategory.includes(field)) {
        return `accountInfo.${field}`;
    }
    if (bankInfoCategory.includes(field)) {
        return `bankInfo.${field}`;
    }
    if (primaryContactCategory.includes(field)) {
        return `primaryContact.${field}`;
    }
    return field;
};
