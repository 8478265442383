import {
    Box,
    Card,
    CardContent,
    Stack,
    Tab,
    Tabs,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useVerifiAlerts } from 'src/api/hooks/useVerifiAlerts';
import { useDebounce } from 'src/hooks/useDebounce';
import { useIntervalAsync } from 'src/hooks/useIntervalAsync';
import { VerifiEthocaTable } from './VerifiEthocaTable';
import { VerifiEthocaUpload } from './VerifiEthocaUpload';

const FETCH_VERIFI_ETHOCA_ALERTS_TIMEOUT = 5000;

export const VerifiEthocaView = () => {
    const [alertsTab, setAlertsTab] = useState<number>(0);
    const alertsTabDebounced = useDebounce(alertsTab, 1000);
    const alertType = alertsTabDebounced === 0 ? 'verifi' : 'ethoca';

    const {
        fetchVerifiAlerts,
        verifiAlerts,
        loading: verifiAlertsIsLoading
    } = useVerifiAlerts();

    useIntervalAsync({
        callback: () => fetchVerifiAlerts(undefined, alertType),
        delay: FETCH_VERIFI_ETHOCA_ALERTS_TIMEOUT,
        onMount: false
    });

    const alertsIsLoading = verifiAlertsIsLoading;

    const handleTabChange = (
        _event: React.SyntheticEvent,
        newValue: number
    ) => {
        setAlertsTab(newValue);
    };

    useEffect(() => {
        fetchVerifiAlerts(undefined, alertType);
    }, [alertType, fetchVerifiAlerts]);

    return (
        <Stack spacing={2}>
            <Card>
                <CardContent>
                    <Stack
                        spacing={1}
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h6">
                            Verifi/Ethoca Alerts
                        </Typography>

                        <VerifiEthocaUpload currentTab={alertsTab} />
                    </Stack>
                </CardContent>
            </Card>

            <Box
                sx={{
                    width: '100%',
                    bgcolor: 'background.paper'
                }}
            >
                <Tabs value={alertsTab} onChange={handleTabChange} centered>
                    <Tab label="Verifi" />
                    <Tab label="Ethoca" />
                </Tabs>
            </Box>
            <VerifiEthocaTable
                alerts={verifiAlerts}
                loading={alertsIsLoading}
            />
        </Stack>
    );
};
