import { AutocompleteOption } from 'src/components/Form';
import { usePartners } from 'src/api/hooks';

interface IReturnValues {
    partnersAsOptions: AutocompleteOption[];
    loading: boolean;
}

export const usePartnersAsOptions = (): IReturnValues => {
    const { partners, loading } = usePartners();

    const partnersAsOptions = partners.map(({ name, id }) => ({
        label: name,
        value: id
    }));

    return { partnersAsOptions, loading };
};
