import React from 'react';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useQuery } from 'react-query';
import { apiGetFile } from 'src/api/endpoints/files';
import downloadFileByLink from 'src/utils/downloadFileByLink';

type FilePreviewProps = { fileId: string; onDelete: () => void };

const getExtension = (fileName: string): string => {
    const arr = fileName.split('.');

    if (arr.length === 1) {
        return '-';
    }

    return arr[arr.length - 1];
};

const FilePreview = ({ onDelete, fileId }: FilePreviewProps) => {
    const { data, isFetching, isError } = useQuery(
        ['file', fileId],
        () => apiGetFile(fileId),
        {
            refetchOnWindowFocus: false,
            enabled: !!fileId
        }
    );

    const handleClick = () => {
        data &&
            data.link &&
            downloadFileByLink(data.link, data.originalFileName || 'File');
    };

    if (isFetching) {
        return <CircularProgress />;
    }

    if (isError) {
        return <Chip label="Error" size="small" variant="outlined" />;
    }

    if (!data || !data.link) {
        return null;
    }

    return (
        <Box
            onClick={handleClick}
            sx={{
                position: 'relative',
                display: 'inline-block',
                margin: '0 10px 10px 0',
                cursor: 'pointer'
            }}
        >
            <InsertDriveFileOutlinedIcon sx={{ fontSize: '100px' }} />
            {data.originalFileName && (
                <>
                    <p
                        title={data.originalFileName}
                        style={{
                            width: '100px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {data.originalFileName}
                    </p>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: '60px',
                            left: '0px',
                            background: 'black',
                            color: 'white',
                            fontSize: '14px'
                        }}
                        p={0.5}
                    >
                        {getExtension(data.originalFileName)}
                    </Box>
                </>
            )}
            <Box
                sx={{
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                    cursor: 'pointer'
                }}
                onClick={e => {
                    e.stopPropagation();
                    onDelete();
                }}
            >
                <CancelIcon color="warning" />
            </Box>
        </Box>
    );
};

export default FilePreview;
