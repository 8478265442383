import {
    LOCAL_STORAGE_USER_DATA,
    LOCAL_STORAGE_USER_ORGANIZATIONS
} from 'src/constants';
import { IOrganizationDto, UserAccountDto } from 'src/types';
import { LOCAL_STORAGE_ORGANIZATION_USER_DATA } from '../../constants';
import { UserOrganizationAccountDto } from '../../types';

export const getUserDataLS = (): UserAccountDto => {
    const userData = localStorage.getItem(LOCAL_STORAGE_USER_DATA);
    return userData ? JSON.parse(userData) : null;
};

export const setUserDataLS = (userData: UserAccountDto) => {
    localStorage.setItem(LOCAL_STORAGE_USER_DATA, JSON.stringify(userData));
};

export const removeUserDataLS = () => {
    localStorage.removeItem(LOCAL_STORAGE_USER_DATA);
};

export const getUserOrganizationDataLS = (): UserOrganizationAccountDto => {
    const userOrganizationData = localStorage.getItem(
        LOCAL_STORAGE_ORGANIZATION_USER_DATA
    );
    return userOrganizationData ? JSON.parse(userOrganizationData) : null;
};

export const setUserOrganizationDataLS = (
    userData: UserOrganizationAccountDto
) => {
    localStorage.setItem(
        LOCAL_STORAGE_ORGANIZATION_USER_DATA,
        JSON.stringify(userData)
    );
};

export const removeUserOrganizationDataLS = () => {
    localStorage.removeItem(LOCAL_STORAGE_ORGANIZATION_USER_DATA);
};

export const getUserOrganizationsLS = (): IOrganizationDto[] => {
    const organizations = localStorage.getItem(
        LOCAL_STORAGE_USER_ORGANIZATIONS
    );
    return organizations ? JSON.parse(organizations) : undefined;
};

export const setUserOrganizationsLS = (organizations: IOrganizationDto[]) => {
    localStorage.setItem(
        LOCAL_STORAGE_USER_ORGANIZATIONS,
        JSON.stringify(organizations)
    );
};

export const removeUserOrganizationsLS = () => {
    localStorage.removeItem(LOCAL_STORAGE_USER_ORGANIZATIONS);
};
