import { ICreateOrUpdateMID } from 'src/types';
import IMidEditForm from 'src/pages/MIDs/types';

const MID_FORM_INITIAL_VALUES: IMidEditForm = {
    name: '',
    externalIdentifier: '',
    dba: '',
    mididentifier: '',
    midalias: '',
    billingStatus: undefined,
    url: '',
    processor: '',
    bankMid: '',
    agent: undefined,
    merchant: {
        id: null
    },
    partner: {
        id: null
    },
    notes: [],
    services: [],
    language: null,
    accountInfo: {
        address: '',
        city: '',
        zip: '',
        country: '',
        address2: undefined,
        state: '',
        email: undefined,
        status: undefined,
        notes: undefined,
        activateAt: undefined,
        deactivateAt: undefined
    },
    commissionPaymentInfo: {
        accountHolderType: undefined,
        bankAccount: '',
        bankRouting: '',
        accountType: undefined
    },
    primaryContact: {
        faxNumber: undefined,
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: ''
    }
};

export default MID_FORM_INITIAL_VALUES;
