import {
    LOCAL_STORAGE_AUTH_TOKEN,
    LOCAL_STORAGE_AUTH,
    LOCAL_STORAGE_ORGANIZATION_AUTH_TOKEN
} from 'src/constants';

export const getAuthTokenLS = () =>
    localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN) || '';

export const getOrganizationAuthTokenLS = () =>
    localStorage.getItem(LOCAL_STORAGE_ORGANIZATION_AUTH_TOKEN) || '';

export const checkAuthTokenLS = () => !!getAuthTokenLS();

export const removeAuthTokenLS = () => {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN);
};

export const setAuthTokenLS = (token: string) => {
    localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, token);
};

export const removeOrganizationAuthTokenLS = () => {
    localStorage.removeItem(LOCAL_STORAGE_ORGANIZATION_AUTH_TOKEN);
};

export const setOrganizationAuthTokenLS = (token: string) => {
    localStorage.setItem(LOCAL_STORAGE_ORGANIZATION_AUTH_TOKEN, token);
};

export const getAuthLS = () =>
    localStorage.getItem(LOCAL_STORAGE_AUTH) === 'true';

export const setAuthLS = () => {
    localStorage.setItem(LOCAL_STORAGE_AUTH, 'true');
};

export const removeAuthLS = () => {
    localStorage.removeItem(LOCAL_STORAGE_AUTH);
};
