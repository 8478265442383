import {
    ApiResponse,
    Account,
    CommonApiGetParams,
    Pagination
} from 'src/types';
import { apiClient } from '../client';
import {
    withDefaultHandlersPagination,
    withDefaultHandlersWithoutResult
} from '../helpers/withDefaultHandlers';
import { ExportAccountType } from 'src/types/partners/common';

const BASE_PATH = '/partner/partner-api/account-info';
const EXPORT_EXCEL_PATH = '/partner/partner-api/export/accounts/';

export const apiGetAccounts = ({
    params
}: {
    params?: CommonApiGetParams;
}): Promise<{ result: Account[]; pagination: Pagination | undefined }> =>
    withDefaultHandlersPagination(
        apiClient.get<ApiResponse<Account[]>>(BASE_PATH, params)
    );

export const apiExportAccountsInExcel = (
    type: 'csv' | 'xlsx',
    accountType: ExportAccountType
) =>
    withDefaultHandlersWithoutResult<string>(
        apiClient.get<ApiResponse>(
            `${EXPORT_EXCEL_PATH}${type}?type=${accountType}`,
            {
                responseType: 'blob'
            }
        )
    );
