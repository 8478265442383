import { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Stack } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { useBillingModels } from 'src/api/hooks';
import { ActionsCard } from 'src/components/TablePage/ActionsCard';
import { PAGE_SIZE, routes } from 'src/constants';
import { Pagination } from 'src/components/Pagination';
import {
    BillingModelsTable,
    BillingModelsFiltersModal
} from 'src/components/BillingModels';

export const BillingModels = () => {
    const {
        fetchBillingModels,
        billingModels,
        loading,
        paging,
        deleteBillingModel
    } = useBillingModels();

    const history = useHistory();
    const MENU_ITEMS = [
        {
            title: 'Add Billing Model',
            onClick: () => history.push(routes.billingModelsNew)
        },
        {
            title: 'Export CSV'
        }
    ];

    const { totalPages } = paging || {};
    const [pageNumber, setPageNumber] = useState(0);
    const [searchString, setSearchingString] = useState('');

    const [modalVisible, setModalVisible] = useState(false);
    const hideModal = () => setModalVisible(false);
    const openModal = () => setModalVisible(true);

    const { handleSubmit, control, reset } = useForm<any>();
    const [filterFormState, setFilterFormState] = useState<any>();
    const [filter, setFilter] = useState<string>('');

    const getBillingModels = (
        pageNumber: number,
        innerSearchString?: string
    ) => {
        fetchBillingModels({
            searchString: innerSearchString ?? searchString,
            pageNumber: `${pageNumber}`,
            pageSize: PAGE_SIZE,
            filter
        });
    };

    useEffect(() => {
        getBillingModels(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePageChange = (e: ChangeEvent<unknown>, page: number) => {
        setPageNumber(page - 1);
        getBillingModels(page - 1);
    };

    const handleSearchChange = (searchString?: string) => {
        setSearchingString(searchString || '');
        getBillingModels(0, searchString);

        if (pageNumber) {
            setPageNumber(0);
        }
    };

    const handleRefresh = () => {
        setFilterFormState(undefined);
        setFilter('');
        reset({});
        fetchBillingModels({
            searchString: searchString,
            pageNumber: `${pageNumber}`,
            pageSize: PAGE_SIZE
        });
    };

    const handleDeleteBillingModel = (id: string) => {
        deleteBillingModel(id, {
            searchString,
            pageNumber: '0',
            pageSize: PAGE_SIZE
        });
        setPageNumber(0);
    };

    const handleOpenFilterModal = () => {
        openModal();
    };

    const applyFilter = (filter: string) => {
        setPageNumber(0);
        setFilter(filter);
        return fetchBillingModels({
            searchString,
            pageNumber: '0',
            pageSize: PAGE_SIZE,
            filter
        });
    };

    const handleCancelFilter = () => {
        reset(filterFormState);
        hideModal();
    };

    return (
        <Stack spacing={2}>
            <ActionsCard
                onSearchChange={handleSearchChange}
                onRefresh={handleRefresh}
                openFilter={handleOpenFilterModal}
                actionsMenuItems={MENU_ITEMS}
                title="Billing Models"
            />
            <BillingModelsTable
                data={Object.values(billingModels)}
                loading={loading}
                onDelete={handleDeleteBillingModel}
            />
            {!isEmpty(billingModels) && (
                <Pagination
                    page={pageNumber + 1}
                    onChange={handlePageChange}
                    count={totalPages}
                />
            )}
            <BillingModelsFiltersModal
                open={modalVisible}
                onClose={hideModal}
                applyFilter={applyFilter}
                onCancel={handleCancelFilter}
                handleSubmit={handleSubmit}
                setFilterFormState={setFilterFormState}
                control={control}
            />
        </Stack>
    );
};
