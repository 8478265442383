import {
    CommonApiGetParams,
    ServiceDto,
    ServicePost,
    ApiResponse,
    SingleServiceDto
} from 'src/types';
import { apiClient } from '../client';
import {
    withDefaultHandlers,
    withDefaultHandlersPagination
} from '../helpers/withDefaultHandlers';

const BASE_PATH = '/product/Product-api/Product-info';

export const apiGetServices = ({ params }: { params?: CommonApiGetParams }) =>
    withDefaultHandlersPagination(
        apiClient.get<ApiResponse<ServiceDto[]>>(BASE_PATH, params)
    );

export const apiGetService = (id: string) =>
    withDefaultHandlers(
        apiClient.get<ApiResponse<SingleServiceDto>>(`${BASE_PATH}/${id}`)
    );

export const apiPostService = (service: ServicePost) =>
    withDefaultHandlers(
        apiClient.post<ApiResponse<{ id: string }>>(BASE_PATH, service)
    );

export const apiPutService = (id: string, service: ServicePost) =>
    withDefaultHandlers(
        apiClient.put<ApiResponse<SingleServiceDto>>(BASE_PATH, service, {
            params: { id }
        })
    );

export const apiDeleteService = (id: string) =>
    withDefaultHandlers(
        apiClient.delete<ApiResponse<ServiceDto>>(BASE_PATH, {
            params: { id }
        })
    );

export const apiArchiveService = (id: string) =>
    withDefaultHandlers(
        apiClient.put<ApiResponse<SingleServiceDto>>(
            `${BASE_PATH}/archive/${id}`
        )
    );

export const apiDearchiveService = (id: string) =>
    withDefaultHandlers(
        apiClient.put<ApiResponse<SingleServiceDto>>(
            `${BASE_PATH}/dearchive/${id}`
        )
    );
