import { FC } from 'react';
import { Control } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';
import { FormFieldType } from 'src/types';
import { Field, FormCard } from '../../Form/FormCard';
import { bankAccountTypes } from 'src/constants';
import { ACCOUNT_HOLDER_TYPES } from 'src/constants/partners/common';
import IMidEditForm from 'src/pages/MIDs/types';

interface Props {
    control: Control<IMidEditForm, object>;
}

const fields: (Field<IMidEditForm> & TextFieldProps)[] = [
    {
        name: 'commissionPaymentInfo.bankAccount',
        label: 'Account Number',
        required: true,
        inputProps: {
            maxLength: 34
        },
        xs: 6
    },
    {
        name: 'commissionPaymentInfo.bankRouting',
        label: 'Routing / ABA Number',
        required: true,
        inputProps: {
            maxLength: 9
        },
        xs: 6
    },
    {
        name: 'commissionPaymentInfo.accountHolderType',
        label: 'Account Holder Type',
        type: FormFieldType.Autocomplete,
        options: Object.values(ACCOUNT_HOLDER_TYPES).map(item => ({
            value: item,
            label: item
        })),
        required: true,
        xs: 6
    },
    {
        name: 'commissionPaymentInfo.accountType',
        label: 'Account Type',
        type: FormFieldType.Autocomplete,
        options: bankAccountTypes,
        required: true,
        xs: 6
    }
];

export const BankingInfoForm: FC<Props> = ({ control }) => (
    <FormCard
        control={control}
        fields={fields}
        title="Banking Information (for Billing)"
        description="The following bank information must be entered correctly as the fees will be withdrawn from this account. If the information is invalid, the merchant will be suspended and no commissions can be paid."
    />
);
