import {
    UserRoutesActionTypes,
    SET_USER_AUTHORIZED_ROUTES,
    SET_USER_UNAUTHORIZED_ROUTES,
    UserRoute
} from '../actions';

export interface RoutesState {
    authorizedRoutes: UserRoute[];
    unauthorizedRoutes: UserRoute[];
}

export const routes = (
    state: RoutesState = {
        authorizedRoutes: [],
        unauthorizedRoutes: []
    },
    action: UserRoutesActionTypes
): RoutesState => {
    switch (action.type) {
        case SET_USER_AUTHORIZED_ROUTES:
            return {
                ...state,
                authorizedRoutes: action.payload
            };
        case SET_USER_UNAUTHORIZED_ROUTES:
            return {
                ...state,
                unauthorizedRoutes: action.payload
            };
        default:
            return state;
    }
};
