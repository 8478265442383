import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { ExportAccountType } from 'src/types/partners/common';

interface ExportAccountTypesMenuProps {
    anchorEl: HTMLButtonElement | null;
    onClick: (type: ExportAccountType) => void;
    open: boolean;
    onClose: () => void;
}

const types: ExportAccountType[] = ['Agents', 'Leads', 'Merchants', 'Partners'];

const ExportAccountTypesMenu = ({
    anchorEl,
    onClick,
    open,
    onClose
}: ExportAccountTypesMenuProps) => {
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            {types.map(type => (
                <MenuItem
                    onClick={() => {
                        onClick(type);
                        onClose();
                    }}
                    key={type}
                    // style={{ minWidth: 100 }}
                >
                    {type}
                </MenuItem>
            ))}
        </Menu>
    );
};

export default ExportAccountTypesMenu;
