import { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
    Control,
    Controller,
    useFieldArray,
    useFormContext
} from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const FieldDatePicker = ({
    control,
    index,
    onDelete,
    fieldsNumber
}: {
    control: Control<any, object>;
    index: number;
    onDelete: (index: number) => void;
    fieldsNumber: number;
}) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <Controller
            name={`billingDates.${index}`}
            control={control}
            render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={dayjs(
                            `${value.monthNumber + 1}/${value.dayNumber}`,
                            'M/D'
                        ).format()}
                        open={isOpen}
                        onClose={() => setOpen(false)}
                        inputFormat="MM-DD"
                        onChange={date => {
                            onChange({
                                monthNumber: dayjs(date).month(),
                                dayNumber: dayjs(date).date()
                            });
                        }}
                        renderInput={({ value, ...params }) => (
                            <TextField
                                fullWidth
                                variant="standard"
                                {...params}
                                label="Date"
                                onClick={() => setOpen(true)}
                                InputProps={{
                                    endAdornment:
                                        fieldsNumber > 1 ? (
                                            <IconButton
                                                size="small"
                                                onClick={() => onDelete(index)}
                                                tabIndex={-1}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        ) : null
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            )}
        />
    );
};

export const BillByScheduleForm: FC = () => {
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'billingDates'
    });

    useEffect(() => {
        if (!fields.length) {
            append({
                monthNumber: dayjs(new Date()).month(),
                dayNumber: dayjs(new Date()).date()
            });
        }
    }, [append, fields]);

    return (
        <Grid container spacing={2}>
            {fields.map((field, index) => (
                <Grid key={field.id} item xs={12}>
                    <FieldDatePicker
                        index={index}
                        control={control}
                        onDelete={index => remove(index)}
                        fieldsNumber={fields.length}
                    />
                </Grid>
            ))}

            <Grid item xs={12}>
                <Button
                    onClick={() =>
                        append({
                            monthNumber: dayjs(new Date()).month(),
                            dayNumber: dayjs(new Date()).date()
                        })
                    }
                >
                    Add Date
                </Button>
            </Grid>
        </Grid>
    );
};
