import { Account, AccountType, ColumnType } from 'src/types';
import { styled } from '@mui/system';
import { GroupIcon } from './AccountsTable';
import { getDateMonthDayYear } from 'src/utils/date';
import { ActionsTableButton } from '../TablePage/ActionsTableButton';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const StatusColor = {
    Active: '#0acf83',
    Pending: '#0A75AD',
    Collections: '#F6546A',
    Notes: '#f2c672'
} as Record<string, string>;

const AccountStatusCell = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: #ffffff;
    height: 30px;
    padding: 8px;
`;

const AccountNameCell = styled('div')`
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
        margin-left: 10px;
    }
`;

const AccountInfoCell = styled('div')`
    span {
        font-weight: bold;
    }
`;

export const generateAccountFields = ({
    onAccountClick
}: {
    onAccountClick: ({ id, accountType }: Account) => void;
}): ColumnType<Account>[] => [
    {
        dataIndex: 'name',
        width: '60%',
        title: 'Name',
        render: (_data, row) => (
            <AccountNameCell onClick={() => onAccountClick(row)}>
                <GroupIcon
                    type={row.accountType}
                    isAccordionImg={false}
                    logo={row?.logo?.link}
                />
                <span>{row.name}</span>
            </AccountNameCell>
        )
    },
    {
        dataIndex: 'dba',
        title: 'DBA',
        render: (_data, { accountType, dba }) =>
            accountType === AccountType.Merchant ? dba : '',
        mobile: {
            render: dba => dba
        }
    },
    {
        dataIndex: 'status',
        title: 'Status',
        render: (_data, { status }) => (
            <AccountStatusCell
                style={{
                    backgroundColor: StatusColor[status] || ''
                }}
            >
                {status}
            </AccountStatusCell>
        )
    },
    {
        dataIndex: 'created',
        title: 'Created / Contact',
        render: (_data, { created, contact }) => (
            <>
                <AccountInfoCell>
                    <span>Created </span>
                    {getDateMonthDayYear(created)}
                </AccountInfoCell>

                <AccountInfoCell>
                    <span>Contact </span> {contact}
                </AccountInfoCell>
            </>
        )
    },
    {
        dataIndex: 'id',
        title: '',
        render: (_id, row) => (
            <ActionsTableButton
                menuItems={[
                    {
                        title: 'Edit',
                        onClick: () => onAccountClick(row)
                    }
                ]}
                color="primary"
            />
        ),
        mobile: {
            render: (_id, row) => (
                <Button
                    fullWidth
                    onClick={() => onAccountClick(row)}
                    variant="contained"
                >
                    Edit
                </Button>
            ),
            fullWidth: true
        }
    }
];
