import React from 'react';
import { Box, Modal } from '@mui/material';
import LeadForm, { OnSuccessAddLeadType } from 'src/pages/Leads/LeadForm';
import { ModalProps } from 'src/types';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

type ModalLeadFormProps = ModalProps & {
    onSuccessAddLead: OnSuccessAddLeadType;
};

const ModalLeadForm: React.FC<ModalLeadFormProps> = ({
    isOpen,
    onClose,
    onSuccessAddLead
}) => (
    <Modal open={isOpen} onClose={onClose}>
        <Box sx={style}>
            <LeadForm onCancel={onClose} onSuccessAddLead={onSuccessAddLead} />
        </Box>
    </Modal>
);

export default ModalLeadForm;
