import { Pagination } from 'src/types';
import { IAlert } from 'src/types/alerts';
import { SET_LOADING, SET_PAGINATION } from '../actions/billingModels';
import {
    SET_VERIFI_ALERTS,
    VerifiAlertsActionTypes
} from './../actions/verifiAlerts';

export interface VerifiAlertsState {
    verifiAlerts: IAlert[];
    loading: boolean;
    pagination: Pagination | undefined;
}
const initialState: VerifiAlertsState = {
    verifiAlerts: [],
    loading: false,
    pagination: undefined
};

export const verifiAlerts = (
    state: VerifiAlertsState = initialState,
    action: VerifiAlertsActionTypes
): VerifiAlertsState => {
    switch (action.type) {
        case SET_VERIFI_ALERTS:
            return {
                ...state,
                verifiAlerts: action.payload,
                loading: false
            };
        case SET_PAGINATION:
            return {
                ...state,
                pagination: action.payload
            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
};
