import { useEffect, useState } from 'react';
import { getSelectedRows } from 'src/components/Table/helpers';

export const useTable = <T>({
    data,
    selectable
}: {
    data: T[];
    selectable?: boolean;
}) => {
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const handleSelectAllRows = () => {
        if (data?.length) {
            setSelectedRows(prev =>
                prev.length < data.length
                    ? Array.from({ length: data.length }, (_, i) => i)
                    : []
            );
        }
    };

    const handleSelectRow = (index: number) => {
        setSelectedRows(getSelectedRows(index));
    };

    const handleRowClick = (index: number) => () =>
        selectable ? handleSelectRow(index) : {};

    const resetSelection = () => {
        setSelectedRows([]);
    };

    useEffect(() => {
        resetSelection();
    }, [data, selectable]);

    return {
        selectedRows,
        handleRowClick,
        handleSelectAllRows
    };
};
